import { DataGrid } from "devextreme-react";
import { Column, HeaderFilter } from "devextreme-react/data-grid";
import { logOut } from "../../../components/Logout";
import { useNavigate } from "react-router-dom";
import httpService from "../../../services/http-service";
import { getAuthToken } from "../../../services/auth-service";

const CopyUserList = ({ userList, setOpenCopyUserList, setCopiedData }) => {
  const navigate = useNavigate();
  const token = getAuthToken();
  let domainName = (url) => url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
  let domailURL = window.location.href;
  let pathname = domainName(domailURL);

  const UserAccessList = async (gUserId) => {
    await httpService
      .get(`api/user/get-user-access-by-userid?gUserId=${gUserId}`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((res) => {
        
        if (res.data.isSuccess) {
          setCopiedData(res.data.data);
          setOpenCopyUserList(false);
        }
      })
      .catch((err) => {
        if (!err?.response) {
          navigate(`/error/500`);
        } else if (err?.response?.status === 401) {
          logOut();
        }
      });
  };
  return (
    <div
      style={{
        height: "598px",
        border: "3px solid var(--button2)",
        background: "var(--popup-background-white-black)",
      }}
    >
      <div className="RecivePaymentModel_header">
        <div style={{ width: "200px" }} className="RecivePaymentModel_header_name">
          <p>Select Another User</p>
        </div>
        <svg
          onClick={() => {
            setOpenCopyUserList(false);
          }}
          style={{
            cursor: "pointer",
            marginTop: "17px",
            marginRight: "2%",
          }}
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
            fill="white"
          />
        </svg>
      </div>
      <div style={{ padding: "5px" }}>
        <DataGrid dataSource={userList} height={"auto"}>
          <HeaderFilter visible={true} allowSearch={true} />
          <Column
            cellRender={(rowData) => {
              return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    style={{ width: "100px" }}
                    onClick={() => {
                      UserAccessList(rowData?.data?.gUserId);
                    }}
                  >
                    Copy
                  </button>
                </div>
              );
            }}
          ></Column>
          <Column dataField="sUserId" caption="User Id" />
          <Column dataField="sFirstName" caption="First Name" />
          <Column dataField="sLastName" caption="Last Name" />
          <Column dataField="bActive" caption="Active" filterValue={true} />
        </DataGrid>
      </div>
    </div>
  );
};

export default CopyUserList;
