import React from "react";
import {
  DataGrid,
  Column,
  Summary,
  TotalItem,
  HeaderFilter,
} from "devextreme-react/data-grid";
import { useSelector } from "react-redux";

export const DetailTemplate = (props) => {
  const { tableExpandData } = useSelector((store) => store.AdminTable);
  const desired_gPaymentId = props?.data?.data?.gPaymentId;
  const filteredData = [];

  for (const item of tableExpandData) {
    if (item?.gPaymentId === desired_gPaymentId) {
      filteredData.push(item);
    }
  }

  return (
    <>
      <DataGrid
        dataSource={filteredData}
        showBorders={true}
        onCellPrepared={(e) => {
          if (e.isAltRow) {
            e.cellElement.style.backgroundColor =
              "var(--table-second-row-light-black)";
          }
        }}
        allowColumnResizing={true}
        columnResizingMode={"nextColumn"}
      >
        <Column dataField="Obilgation Ref" width={"auto"} />
        <Column dataField="Fee Code" width={"auto"} />
        <Column
          dataField="Amount"
          width={"auto"}
          format={{ type: "currency", precision: 2 }}
        />
        <Column dataField="Issued" width={"auto"} />
        <Column dataField="Fee Remarks" width={"auto"} />
        <HeaderFilter visible={true} allowSearch={true} />
        <Summary>
          <TotalItem
            column="Amount"
            summaryType="sum"
            valueFormat={{ type: "currency", precision: 2 }}
            displayFormat="{0}"
          />
          /
          <TotalItem column="Obilgation Ref" summaryType="count" />
        </Summary>
      </DataGrid>
    </>
  );
};
