import { useEffect, useRef, useState } from "react";
import { RecivePaymentModel } from "./HeaderPopupComponent/RecivePaymentModel";
import { Button, Popup, Toast } from "devextreme-react";
import { ProcessCCPayment } from "./HeaderPopupComponent/ProcessCCPayment";
import {
  setAllocationAdjustment,
  setDocumentWizardInfoData,
  setrecivepaymentPiReset,
  setCloseoutWizardInfoData,
} from "../../reducer/ParticipateReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SendEmail } from "./HeaderPopupComponent/SendEmail";
import { handleGetLocal } from "../../services/auth-service";
import axios from "axios";
import { Toster } from "../../components/Toster/Toster";
import { AddQuickTask } from "./HeaderPopupComponent/AddQuickTask/AddQuickTask";
import { useNavigate } from "react-router-dom";
import { AddDocket } from "./AddDocket/AddDocket";
import { setDynamicTab } from "../../reducer/HomePageReducer";
import { PersonFind } from "../PersonFind/PersonFind";

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const AddDocketPOpup = ({ addDocketPopupshow, setaddDocketPopupshow, userinfo }) => {
  return (
    <>
      <Popup
        visible={addDocketPopupshow}
        onHiding={() => {
          setaddDocketPopupshow(false);
        }}
        showTitle={false}
        width={650}
        height={900}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddDocket
          addDocketPopupshow={addDocketPopupshow}
          setaddDocketPopupshow={setaddDocketPopupshow}
          userinfo={userinfo}
        />
      </Popup>
    </>
  );
};

export const ParticipentHeaderPopup = ({ userinfo, editParticipant, name, setLoding, docketAddAccess }) => {
  const [topPopup, settopPopup] = useState();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [recivePaymentModel, setRecivePaymentModel] = useState(false);
  const dispatch = useDispatch();
  const { recivepaymentPiReset } = useSelector((store) => store.participate);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [showmore, setshowmore] = useState(false);
  const [addDocketPopupshow, setaddDocketPopupshow] = useState(false);
  const [closeoutWizardVisible, setCloseoutWizardVisible] = useState(true);

  const ProntoPayWebURL = async () => {
    setLoding(true);
    const pathname = handleGetLocal("pathURL");
    const urlResult = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/User/get-company-options?gUserID=${userinfo?.gPersonId
      }&sName=${"Credit Card Client URL"}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      }
    );

    const tokenResult = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/Payment/getSureBankToken?gUserID=${userinfo?.gEntryUserId}&gPersonID=${userinfo?.gPersonId}&sOffenderNumber=${userinfo?.sOffenderNumber}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      }
    );

    const url = urlResult.data.data[0]?.sValue;
    const result = url.replace("{0}", tokenResult.data.data);
    const payURL = result.replace("{1}", userinfo?.sOffenderNumber);

    window.open(payURL, "_blank");
    setLoding(false);
  };

  const CloseoutOption = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/Account/getCompanyOption?Name=CloseOut Enabled`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );
      setCloseoutWizardVisible(response?.data?.data.trim().toLowerCase() === "true");

    } catch (error) {
      console.error("Error fetching EM Enabled option:", error);
    }
  }

  const navigation = useNavigate();
  const pathname = handleGetLocal("pathURL");

  const NotebookSummary = async () => {
    setLoding(true);
    await axios
      .get(
        `${process.env.REACT_APP_CRYSTAL_API_URL}/reports-api/reports/get-summary-report?sUserId=${userinfo?.gEntryUserId}&sOffenderNo=${userinfo?.sOffenderNumber}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
          responseType: "blob",
        }
      )
      .then((val) => {
        setLoding(false);
        const pdfBlob = new Blob([val.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const downloadLink = document.createElement("a");
        downloadLink.href = pdfUrl;
        downloadLink.download = "OFR1_Notebook_Summary.pdf";
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch((error) => {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "error.responseMessage",
          type: "error",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "error.responseMessage",
            type: "error",
          });
        }, 1400);
        setLoding(false);
      });
  };
  const ref = useRef();
  useOutsideClick(ref, async () => {
    setshowmore(false);
  });

  useEffect(() => {
    CloseoutOption();
  }, []);

  return (
    <>
      <Popup
        visible={recivePaymentModel}
        onHiding={() => {
          setRecivePaymentModel(false);
        }}
        showTitle={false}
        width={topPopup === 1 ? 1500 : topPopup === 4 ? 1180 : topPopup === 2 ? 400 : 650}
        height={topPopup === 2 ? 660 : topPopup === 4 ? 860 : topPopup !== 1 ? 650 : 860}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div>
          {topPopup === 1 && (
            <RecivePaymentModel
              recivePaymentModel={recivePaymentModel}
              setRecivePaymentModel={setRecivePaymentModel}
              userinfo={userinfo}
              editParticipant={editParticipant}
              name={name}
            />
          )}
          {topPopup === 2 && <AddQuickTask setRecivePaymentModel={setRecivePaymentModel} userinfo={userinfo} />}
          {topPopup === 3 && (
            <ProcessCCPayment
              setRecivePaymentModel={setRecivePaymentModel}
              userinfo={userinfo}
              editParticipant={editParticipant}
              settopPopup={settopPopup}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
            />
          )}
          {topPopup === 4 && (
            <SendEmail
              recivePaymentModel={recivePaymentModel}
              setRecivePaymentModel={setRecivePaymentModel}
              userinfo={userinfo}
              toastConfig={toastConfig}
              setToastConfig={setToastConfig}
            />
          )}
        </div>
      </Popup>

      <AddDocketPOpup
        addDocketPopupshow={addDocketPopupshow}
        setaddDocketPopupshow={setaddDocketPopupshow}
        userinfo={userinfo}
      />

      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      {isPopupVisible && <PersonFind fromParticipantHeader={true} isPopupVisible={isPopupVisible} setIsPopupVisible={setIsPopupVisible} />}
      <button onClick={() => {
        setIsPopupVisible(true);
      }} style={{ width: "7%", fontSize: "12px", cursor: "pointer" }}>
        Find
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          width="24"
          height="24"
        >
          <path
            d="M9.5 2C5.364 2 2 5.364 2 9.5S5.364 17 9.5 17c1.827 0 3.504-.606 4.85-1.617l4.885 4.884a1 1 0 0 0 1.415-1.414l-4.884-4.885A7.463 7.463 0 0 0 17 9.5C17 5.364 13.636 2 9.5 2zm0 2C12.537 4 15 6.463 15 9.5S12.537 15 9.5 15 4 12.537 4 9.5 6.463 4 9.5 4z"
          />
        </svg>
      </button>
      <button
        onClick={() => {
          if (editParticipant) {
            settopPopup(1);
            setRecivePaymentModel(true);
            dispatch(setrecivepaymentPiReset(!recivepaymentPiReset));
          }
        }}
        style={{ width: "13%", fontSize: "12px", cursor: "pointer" }}
      >
        Receive Payment
        <svg
          className="svg-blue-white"
          width="17"
          height="16"
          viewBox="0 0 22 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22 4V14C22 15.1 21.1 16 20 16H4C3.45 16 3 15.55 3 15C3 14.45 3.45 14 4 14H20V4C20 3.45 20.45 3 21 3C21.55 3 22 3.45 22 4ZM3 12C1.34 12 0 10.66 0 9V3C0 1.34 1.34 0 3 0H15C16.66 0 18 1.34 18 3V10C18 11.1 17.1 12 16 12H3ZM6 6C6 7.66 7.34 9 9 9C10.66 9 12 7.66 12 6C12 4.34 10.66 3 9 3C7.34 3 6 4.34 6 6Z"
            fill="#424167"
          />
        </svg>
      </button>
      <button
        style={{ width: "12%", fontSize: "12px", cursor: "pointer" }}
        onClick={() => {
          if (editParticipant) {
            settopPopup(2);
            setRecivePaymentModel(true);
          }
        }}
      >
        Add Quick task
        <svg
          className="svg-blue-white"
          width="17"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.2931 4.89019L9.29305 14.8902C8.90305 15.2802 8.27305 15.2802 7.88305 14.8902L5.05305 12.0602C4.66305 11.6702 4.66305 11.0402 5.05305 10.6502C5.44305 10.2602 6.07305 10.2602 6.46305 10.6502L8.58305 12.7702L17.8731 3.48019C18.2631 3.09019 18.8931 3.09019 19.2831 3.48019C19.6831 3.87019 19.6831 4.50019 19.2931 4.89019ZM10.0031 19.0002C5.29305 19.0002 1.52305 14.9102 2.05305 10.1002C2.44305 6.58019 5.17305 3.69019 8.66305 3.11019C10.4731 2.81019 12.1931 3.13019 13.6531 3.89019C14.0431 4.09019 14.5131 4.02019 14.8231 3.71019C15.3031 3.23019 15.1831 2.42019 14.5831 2.11019C13.1131 1.36019 11.4531 0.950189 9.68305 1.00019C4.54305 1.16019 0.273052 5.34019 0.0130525 10.4702C-0.276948 16.2402 4.30305 21.0002 10.0031 21.0002C11.2031 21.0002 12.3431 20.7902 13.4131 20.4002C14.0931 20.1502 14.2831 19.2702 13.7631 18.7502C13.4931 18.4802 13.0831 18.3802 12.7231 18.5202C11.8731 18.8302 10.9531 19.0002 10.0031 19.0002ZM17.0031 14.0002H15.0031C14.4531 14.0002 14.0031 14.4502 14.0031 15.0002C14.0031 15.5502 14.4531 16.0002 15.0031 16.0002H17.0031V18.0002C17.0031 18.5502 17.4531 19.0002 18.0031 19.0002C18.5531 19.0002 19.0031 18.5502 19.0031 18.0002V16.0002H21.0031C21.5531 16.0002 22.0031 15.5502 22.0031 15.0002C22.0031 14.4502 21.5531 14.0002 21.0031 14.0002H19.0031V12.0002C19.0031 11.4502 18.5531 11.0002 18.0031 11.0002C17.4531 11.0002 17.0031 11.4502 17.0031 12.0002V14.0002Z"
            fill="#424167"
          />
        </svg>
      </button>
      <button
        style={{ width: "10%", fontSize: "12px", cursor: "pointer" }}
        onClick={() => {
          if (editParticipant) {
            settopPopup(4);
            setRecivePaymentModel(true);
          }
        }}
      >
        Send Email
        <svg
          className="svg-blue-white"
          width="17"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17.6 4.25L11.06 8.34C10.41 8.75 9.59 8.75 8.94 8.34L2.4 4.25C2.15 4.09 2 3.82 2 3.53C2 2.86 2.73 2.46 3.3 2.81L10 7L16.7 2.81C17.27 2.46 18 2.86 18 3.53C18 3.82 17.85 4.09 17.6 4.25Z"
            fill="#424167"
          />
        </svg>
      </button>
      <button
        style={{ width: "16%", fontSize: "12px", cursor: "pointer" }}
        onClick={() => {
          if (editParticipant) {
            ProntoPayWebURL();
          }
        }}
      >
        ProntoPay - Web URL
        <svg
          className="svg-blue-white"
          width="17"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11.41 16.09V16.67C11.41 17.4 10.81 18 10.08 18H10.07C9.34 18 8.74 17.4 8.74 16.67V16.07C7.41 15.79 6.23 15.06 5.73 13.83C5.5 13.28 5.93 12.67 6.53 12.67H6.77C7.14 12.67 7.44 12.92 7.58 13.27C7.87 14.02 8.63 14.54 10.09 14.54C12.05 14.54 12.49 13.56 12.49 12.95C12.49 12.12 12.05 11.34 9.82 10.81C7.34 10.21 5.64 9.19 5.64 7.14C5.64 5.42 7.03 4.3 8.75 3.93V3.33C8.75 2.6 9.35 2 10.08 2H10.09C10.82 2 11.42 2.6 11.42 3.33V3.95C12.8 4.29 13.67 5.15 14.05 6.21C14.25 6.76 13.83 7.34 13.24 7.34H12.98C12.61 7.34 12.31 7.08 12.21 6.72C11.98 5.96 11.35 5.47 10.09 5.47C8.59 5.47 7.69 6.15 7.69 7.11C7.69 7.95 8.34 8.5 10.36 9.02C12.38 9.54 14.54 10.41 14.54 12.93C14.52 14.76 13.15 15.76 11.41 16.09Z"
            fill="#424167"
          />
        </svg>
      </button>
      <button
        onClick={() => {
          setaddDocketPopupshow(true);
        }}
        style={{ width: "10%", fontSize: "12px", cursor: "pointer" }}
      >
        Add Docket
        <svg
          className="svg-blue-white"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM13 10H10V13C10 13.55 9.55 14 9 14C8.45 14 8 13.55 8 13V10H5C4.45 10 4 9.55 4 9C4 8.45 4.45 8 5 8H8V5C8 4.45 8.45 4 9 4C9.55 4 10 4.45 10 5V8H13C13.55 8 14 8.45 14 9C14 9.55 13.55 10 13 10Z"
            fill="#424167"
          />
        </svg>
      </button>
      <button
        style={{
          backgroundColor: "var(--color2)",
          border: "1px solid #D2D2D2",
          width: "130px",
          cursor: "pointer",
        }}
        onClick={() => {
          setshowmore(!showmore);
        }}
        ref={ref}
      >
        More actions
        <svg
          className="svg-black-white"
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.11875 1.29006L5.99875 5.17006L9.87875 1.29006C10.2688 0.900059 10.8988 0.900059 11.2888 1.29006C11.6788 1.68006 11.6788 2.31006 11.2888 2.70006L6.69875 7.29006C6.30875 7.68006 5.67875 7.68006 5.28875 7.29006L0.69875 2.70006C0.30875 2.31006 0.30875 1.68006 0.69875 1.29006C1.08875 0.910059 1.72875 0.900059 2.11875 1.29006Z"
            fill="black"
            fillOpacity="0.6"
          />
        </svg>
      </button>
      {showmore && (
        <div
          style={{
            width: "150px",
            height: closeoutWizardVisible ? "110px" : "90px",
            backgroundColor: "var(--color2)",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
            borderRadius: 8,
            position: "absolute",
            textAlign: "left",
            padding: "8px",
            marginTop: "3.4%",
            right: "24%",
          }}
        >
          <p
            style={{
              color: "var(--TextColor)",
              fontWeight: "bold",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              userinfo?.gEntryUserId && NotebookSummary();
            }}
          >
            Notebook Summary
          </p>
          <p
            style={{
              color: "var(--TextColor)",
              fontWeight: "bold",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(
                setDynamicTab({
                  Offender: "/documentwizard",
                  title: "Document Wizard",
                  link: `/documentwizard`
                })
              );
              navigation(`/documentwizard`);
              dispatch(
                setDocumentWizardInfoData({
                  sOffenderNumber: userinfo?.sOffenderNumber,
                  sLastFirst: userinfo?.sLastFirst,
                  gPersonId: userinfo?.gPersonId,
                  userinfo: userinfo,
                })
              );
            }}
          >
            Document Wizard
          </p>
          <p
            style={{
              color: "var(--TextColor)",
              fontWeight: "bold",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              dispatch(
                setDynamicTab({
                  Offender: "/allocationadjustment",
                  link: "/allocationadjustment",
                  title: "Allocation Adjustment",
                  sOffenderNumber: userinfo?.sOffenderNumber,
                  sLastFirst: userinfo?.sLastFirst,
                  gPersonId: userinfo?.gPersonId,
                })
              );
              navigation(`/allocationadjustment`);
              dispatch(
                setAllocationAdjustment({
                  sOffenderNumber: userinfo?.sOffenderNumber,
                  sLastFirst: userinfo?.sLastFirst,
                  gPersonId: userinfo?.gPersonId,
                })
              );
            }}
          >
            Allocation Adjustment
          </p>
          {closeoutWizardVisible && (
            <p
              style={{
                color: "var(--TextColor)",
                fontWeight: "bold",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(
                  setDynamicTab({
                    Offender: "/closeoutwizard",
                    title: "Closeout Wizard",
                    link: `/closeoutwizard`
                  })
                );
                navigation(`/closeoutwizard`);
                dispatch(
                  setCloseoutWizardInfoData({
                    gPersonId: userinfo?.gPersonId,
                  })
                );
              }}
            >
              Closeout Wizard
            </p>
          )}
        </div>
      )}
    </>
  );
};
