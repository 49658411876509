import React, { useEffect, useState } from "react";
import "../financialPop.css";
import { Popup } from "devextreme-react";
import axios from "axios";
import { handleGetLocal } from "../../../../../../services/auth-service";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../../../../components/Logout";
import { useDispatch, useSelector } from "react-redux";
import { setDynamicTab } from "../../../../../../reducer/HomePageReducer";

export const PersonMergedPopup = ({ confirmPopup, setConfirmPopup, mergedperson }) => {

    const [data, setdata] = useState("");
    const [loading, setloading] = useState(false);
    const pathname = handleGetLocal("pathURL");
    const [hasFetched, setHasFetched] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onPopupClose = () => {
        setConfirmPopup(false);
        setHasFetched(false);
    };
    const saveCookieCrum = async (perdonId) => {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${perdonId}`, null, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${pathname}`,
            },
          })
          .then(() => { })
          .catch((val) => { });
      };

    const jumptoperson = () => {
        saveCookieCrum(mergedperson);
        dispatch(
            setDynamicTab({
                link: `/participant/${data.sOffenderNumber}`,
                Offender: data.sOffenderNumber,
                title: data?.sLastNameSpaceFirstName,
            })
        );
        navigate(`/participant/${data.sOffenderNumber}`);
    }

    const getPersonDetails = async () => {
        setloading(true);
        await axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/Person/person-details?gPersonId=${mergedperson}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                        Domain: `${pathname}`,
                    },
                }
            )
            .then((val) => {
                setdata(val.data.data);
                setloading(false);
            })
            .catch((val) => {
                setloading(false);
                if (!val?.response) {
                    navigate(`/error/500`);
                } else if (val?.response?.status == 401) {
                    logOut();
                }
            });
    };

    useEffect(() => {
        if (confirmPopup && mergedperson && !hasFetched) {

            getPersonDetails();
            setHasFetched(true);
        }
    }, [confirmPopup, mergedperson]);

    return (
        <Popup
            visible={confirmPopup && hasFetched && !loading}
            onHiding={onPopupClose}
            showTitle={false}
            width={350}
            height={230}
            showCloseButton={true}
            hideOnOutsideClick={true}
        >
            <div className="edit_confirmation">
                <div className="popup_header">
                    <div className="popup_header_title">
                        <div className="popup_header_title_data">
                        </div>
                    </div>
                    <svg
                        onClick={() => setConfirmPopup(false)}
                        style={{
                            cursor: "pointer",
                        }}
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                            fill="white"
                        />
                    </svg>
                </div>
                <div className="edit_confirmation_content">
                    <p className="sub_heading">This participant has been merged to {data.sOffenderNumber} - {data.sLastNameSpaceFirstName} </p>
                </div>
                <div className="popup_buttons">
                    <button className="btn primary_btn" onClick={jumptoperson}>Jump to Person</button>
                    <button
                        className="btn edit_payment_btn"
                        onClick={() => setConfirmPopup(false)}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </Popup>
    );
};
