import { useEffect, useState } from "react";
import "../drugTestRandomizer.css";
import { DateBox, LoadPanel, Scheduler, ScrollView } from "devextreme-react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { logOut } from "../../../../../components/Logout";
import { handleGetLocal } from "../../../../../services/auth-service";
import { Editing } from "devextreme-react/data-grid";
import AppointmentTooltip from "./AppointmentTooltip";
import { DynamicTabb } from "../../../../../components/DynamicTab/DynamicTab";

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const Calendar = ({
  // calendarPopup,
  // setCalendarPopup,
  // locationDropdownValues,
}) => {
  const [locationDropdownValues, setLocationDropdownValues] = useState([]);

  const pathname = handleGetLocal("pathURL");
  const currentDate = new Date();
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const year = lastDayOfMonth.getFullYear();
  const month = (lastDayOfMonth.getMonth() + 1).toString().padStart(2, "0");
  const day = lastDayOfMonth.getDate().toString().padStart(2, "0");
  const formattedEndDate = `${year}-${month}-${day}`;
  const [date, setdate] = useState({
    startDate: new Date(new Date().setDate(1)).toISOString().slice(0, 10),
    endDate: formattedEndDate,
  });
  const [fields, setFields] = useState({
    location: { name: "", id: "" },
  });
  const [calendarData, setCalendarData] = useState([]);
  const views = ["month"];
  const [loading, setLoading] = useState(false);
  const [schedulerOptions, setSchedulerOptions] = useState({
    currentView: "month",
    currentDate: date.startDate, // Set the initial currentDate to startDate
  });

  const getLocationDropdownValues = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/RandomDrugTesting/dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLocationDropdownValues(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          Navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    const filteredLocation = locationDropdownValues?.location?.find((item) => item.value === localStorage.getItem('location'));
    if (filteredLocation) {
      setFields((prevFields) => ({
        ...prevFields,
        location: { name: filteredLocation.value, id: filteredLocation.key },
      }));
    }
  }, [locationDropdownValues]);

  useEffect(() => {
    getLocationDropdownValues();
    setCalendarData([]);
    setFields({
      location: { name: "", id: "" },
    });
    setdate({
      startDate: new Date(new Date().setDate(1)).toISOString().slice(0, 10),
      endDate: formattedEndDate,
    });
    setSchedulerOptions((prevOptions) => ({
      ...prevOptions,
      currentDate: date.startDate,
    }));
  }, []);

  const getCalendarDataTests = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/RandomDrugTesting/get-test-date?Location=${fields.location.id}&dStart=${date.startDate}&dEnd=${date.endDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        setCalendarData(
          val?.data?.data?.map((item) => {
            return {
              text: `${item?.sTestTime} (${item?.sGender}) - Test: ${item?.iCount}`,
              title: `(${item?.sGender}) - Tests: ${item?.iCount} - Appointment`,
              startDate: item?.dTest,
              endDate: item?.dTest,
              location: "",
              description: "",
              toolTipName: `(${item?.sGender}) - Tests: ${item?.iCount}`,
              toolTipTime: item?.sTestTime,
            };
          })
        );
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          Navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    if (fields.location.id) {
      if (
        date.endDate !== "Invalid Date" &&
        date.startDate !== "Invalid Date"
      ) {
        getCalendarDataTests();
        setSchedulerOptions((prevOptions) => ({
          ...prevOptions,
          currentDate: date.startDate,
        }));
      }
    }
  }, [date, fields.location?.id]);

  const onAppointmentFormOpening = (e) => {
    e.popup.option("showTitle", true);
    e.popup.option(
      "title",
      e.appointmentData.title ? e.appointmentData.title : "Appointment"
    );

    const form = e.form;
    let formItems = form.option("items");
    if (!formItems.find((i) => i.dataField === "location")) {
      formItems.push({
        colSpan: 2,
        label: { text: "Location" },
        editorType: "dxTextBox",
        dataField: "location",
      });
      form.option("items", formItems);
    }
  };

  const handleCurrentViewChange = (newView) => {
    setSchedulerOptions((prevOptions) => ({
      ...prevOptions,
      currentView: newView,
    }));
  };

  const handleCurrentDateChange = (newDate) => {
    setSchedulerOptions((prevOptions) => ({
      ...prevOptions,
      currentDate: newDate,
    }));
  };

  return (
    <div style={{margin:'20px 0', padding: "20px", background: 'white'}}>
      <DynamicTabb />
      <br/>
      <p className="page-info-number">DT.3</p>
      <div className="calendar-content">
        <div className="page-details-search-content">
          <div className="display-flex-div dropdown-space">
            <label className="fee-payment-content-info-para">
              Location
            </label>
            <DropDown
              setfields={setFields}
              fields={fields}
              fieldNmae={"location"}
              dropDownData={locationDropdownValues?.location?.map(
                (vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                }
              )}
            />
          </div>
          <div className="display-flex-div">
            <label className="fee-payment-content-info-para">
              Period Start
            </label>
            <DateBox
              useMaskBehavior={true}
              value={date.startDate}
              onValueChange={(e) => {
                if (e) {
                  setdate({
                    ...date,
                    startDate: e ? e : null,
                  });
                }
              }}
            />
          </div>
          <div className="display-flex-div">
            <label className="fee-payment-content-info-para">
              Period End
            </label>
            <DateBox
              useMaskBehavior={true}
              value={date.endDate}
              onValueChange={(e) => {
                if (e) {
                  setdate({
                    ...date,
                    endDate: e ? e : null,
                  });
                }
              }}
            />
          </div>
        </div>
        <Scheduler
          views={views}
          dataSource={calendarData}
          height={600}
          showAllDayPanel={false}
          allDayExpr={false}
          defaultCurrentView={schedulerOptions.currentView}
          currentDate={schedulerOptions.currentDate}
          onCurrentViewChange={handleCurrentViewChange}
          onCurrentDateChange={handleCurrentDateChange}
          onAppointmentFormOpening={onAppointmentFormOpening}
          appointmentTooltipComponent={AppointmentTooltip}
        >
          <Editing allowAdding={false} />
        </Scheduler>
      </div>
    </div>
  );
};
