import axios from "axios";
import { handleGetLocal } from "../../../services/auth-service";
import { setTableData } from "./Data";
import { logOut } from "../../../components/Logout";

export class CloseoutWizardFunction {
    constructor() {
        this.pathname = handleGetLocal("pathURL");
    }

    loadCloseoutWizardData = async (navigate, setLoading, setheaderdata, setgridrows, setclosestatuses, setapproverlist, setprogramendreason, gPersonId) => {
        setLoading(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}/api/close-out-wizard/load-person-close-out?personId=${gPersonId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Domain: `${this.pathname}`,
                },
            })
            .then((val) => {
                setLoading(false);
                console.log('val?.data?',val?.data);
                setgridrows(
                    setTableData(val?.data?.data?.grid)
                );
                setheaderdata(val?.data?.data?.header);
                setclosestatuses(val?.data?.data?.closeStatuses);
                setapproverlist(val?.data?.data?.approverList);
                setprogramendreason(val?.data?.data?.programEndReason);
            })
            .catch((val) => {
                setLoading(false);
                if (val?.response?.status === 401) {
                    logOut();
                } else {
                    if (val?.response?.status) {
                        navigate(`/error/500`);
                    } else {
                        navigate("/error/500");
                    }
                }
            });
    };

    submitCloseoutWizardData = async ( navigate,
        setLoading,
        setToastConfig,
        toastConfig,
        data) => {
            setLoading(true);
            const pathname = handleGetLocal("pathURL");
            await axios
              .post(`${process.env.REACT_APP_API_URL}/api/close-out-wizard/close-out-submit`, data, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                  Domain: `${pathname}`,
                },
              })
              .then((val) => {
                setLoading(false);
                if (val?.data?.success) {
                //   resetFields();
                  setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    message: "Successfully Submitted!",
                    type: "success",
                  });
                  setTimeout(() => {
                    setToastConfig({
                      ...toastConfig,
                      isVisible: false,
                      message: "Successfully Submitted!",
                      type: "success",
                    });
                  }, 1900);
                } else {
                  setToastConfig({
                    ...toastConfig,
                    isVisible: true,
                    message: "Error",
                    type: "error",
                  });
                  setTimeout(() => {
                    setToastConfig({
                      ...toastConfig,
                      isVisible: false,
                      message: "Error",
                      type: "error",
                    });
                  }, 1900);
                }
              })
              .catch((val) => {
                setLoading(false);
                if (val?.response?.status === 401) {
                  logOut();
                } else {
                  if (val?.response?.status) {
                    navigate(`/error/500`);
                  } else {
                    navigate("/error/500");
                  }
                }
              });
    };
}