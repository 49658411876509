import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { logOut } from "../../../../components/Logout";
import { setDocketFindTable } from "./GoToDoketPopup/TableColumn";
import {
  remove_dynamicParticipatTab,
  resetDocyINdynamicTab,
  resetDynamicTabDocket,
} from "../../../../reducer/ParticipateReducer";
import { setdockerValue } from "../../../../reducer/participantNoteReducer";
import { setDynamicTab } from "../../../../reducer/HomePageReducer";

export class GoToDocketHeaderFunction {
  constructor(navigate) {
    this.pathname = handleGetLocal("pathURL");
    this.navigate = navigate;
  }

  async getDocket(setLoading, dockerValue, setData, dispatch, param, dynamicParticipatTab, setcustomFlag) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/docket/get-dockets?personId=${dockerValue?.userinfo?.gPersonId}&docketId=${dockerValue?.getTopDynamicDocketid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          this.getCustomFlag(dockerValue?.getTopDynamicDocketid, setcustomFlag);
          if (param?.id == "tempdata2 - tempdata2") {
            dispatch(
              setDynamicTab({
                Offender: `/docket/${val?.data?.data?.[0].sDocketNumberCourtCounts.replace(/\//g, "-")}`,
                link: `/docket/${val?.data?.data?.[0].sDocketNumberCourtCounts.replace(/\//g, "-")}`,
                title: `Docket ${val?.data?.data?.[0].sDocketNumberCourtCounts}`,
                sDocketNumberCourtCounts: val?.data?.data?.[0].sDocketNumberCourtCounts,
                userinfo: {
                  ...val?.data?.data?.[0],
                  gPersonId: val?.data?.data?.[0]?.gPersonId,
                },
                getTopDynamicDocketid: val?.data?.data?.[0].gDocketId,
              })
            );

            this.navigate(`/docket/${val?.data?.data?.[0].sDocketNumberCourtCounts.replace(/\//g, "-")}`);
          }

          if (!val?.data?.data?.length) {
            dispatch(remove_dynamicParticipatTab(`/docket/${param?.id?.replace(/\//g, "-")}`));
            if (!dynamicParticipatTab[dynamicParticipatTab.length - 2]?.getTopDynamicDocketid) {
              this.navigate(`/participant/${dynamicParticipatTab[dynamicParticipatTab.length - 2].Offender}`);
            } else {
              this.navigate(dynamicParticipatTab[dynamicParticipatTab.length - 2].Offender);
              dispatch(
                setdockerValue({
                  getTopDynamicDocketid: dynamicParticipatTab[dynamicParticipatTab.length - 2]?.getTopDynamicDocketid,
                  userinfo: dynamicParticipatTab[dynamicParticipatTab.length - 2]?.userinfo,
                })
              );
            }
            return;
          }
          setData(val?.data?.data);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          this.navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async getJugge(setLoading, id, setJudgedropDown, data, setfields, fields) {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/judges-dropdown?entityId=${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          val?.data?.data?.length &&
            setJudgedropDown(
              val?.data?.data?.map((vall) => {
                return { name: vall?.sContactLastName, id: vall?.gContactID };
              })
            );
          let datas = { name: "", id: "" };
          val?.data?.data
            ?.map((vall) => {
              return { name: vall?.sContactLastName, id: vall?.gContactID };
            })
            ?.map((val) => {
              if (val?.id == data?.[0]?.gSentenceJudgeContactId) {
                datas = val;
              }
            });
          setfields({ ...fields, Judge: datas });
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          this.navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async getDropDown(
    setLoading,
    setLocationdropDown,
    setTyperopDown,
    setCourtropDown,
    setJudgedropDown,
    setOffensedropDown,
    setSupervisionStatusdropdown,
    setchangeReasonDRopDown
  ) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/docket-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          axios
            .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Court`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Domain: `${this.pathname}`,
              },
            })
            .then((res) => {
              setCourtropDown(
                res.data?.data?.map((entity) => {
                  return {
                    name: entity?.value,
                    id: entity?.id,
                  };
                })
              );
            })
            .catch((err) => {
              console.log(err);
            });
          val?.data?.data?.offenses?.length &&
            setOffensedropDown(
              val?.data?.data?.offenses.map((vall) => {
                return {
                  name: vall?.value,
                  id: vall?.key,
                  sConvictionType: vall?.sConvictionType,
                  sOffenseType: vall?.sOffenseType,
                };
              })
            );
          val?.data?.data?.supervisionStatuses?.length &&
            setSupervisionStatusdropdown(
              val?.data?.data?.supervisionStatuses.map((vall) => {
                return { name: vall?.value, id: vall?.key };
              })
            );
          val?.data?.data?.docketTypes?.length &&
            setTyperopDown(
              val?.data?.data?.docketTypes.map((vall) => {
                return { name: vall?.value, id: vall?.key };
              })
            );
          val?.data?.data?.location?.length &&
            setLocationdropDown(
              val?.data?.data?.location.map((vall) => {
                return { name: vall?.value, id: vall?.key };
              })
            );
          val?.data?.data?.supervisionStatusChangeReason?.length &&
            setchangeReasonDRopDown(
              val?.data?.data?.supervisionStatusChangeReason.map((vall) => {
                return { name: vall?.value, id: vall?.key };
              })
            );
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          this.navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  resetField(setfields, data, goToDocketPerson, JudgedropDown) {
    let datas = { name: "", id: "" };
    JudgedropDown?.map((val) => {
      if (val?.id == data?.[0]?.gSentenceJudgeContactId) {
        datas = val;
      }
    });
    setfields({
      Location: {
        name: data?.[0]?.sGpmlocationEntityName,
        id: data?.[0]?.gGpmEntityId,
      },
      "Docket Number": data?.[0]?.sDocketNumber,
      Type: { name: data?.[0]?.sDocketTypeName, id: data?.[0]?.gDocketTypeId },
      "Create Date": data?.[0]?.dCreate ? data?.[0]?.dCreate : "",
      "Docket Number2": data?.[0]?.iCourtCounts,
      Person: goToDocketPerson.name ? goToDocketPerson.name : data?.[0]?.sPersonNameLastFirst,
      Court: {
        name: data?.[0]?.sCourtName,
        id: data?.[0]?.gSentenceCourtEntityId,
      },
      Judge: datas,
      Offense: {
        name: data?.[0]?.sOffenseDescription,
        id: data?.[0]?.gOffenseId,
      },
      "Supervision Status": {
        name: data?.[0]?.sSupervisionStatusName,
        id: data?.[0]?.gSupervisionStatusId,
      },
      "First Offender": data?.[0]?.bFirstOffender,
      "Conditional Discharge": data?.[0]?.bConditionalDischarge,
      "Random Drug Testing": data?.[0]?.bRandomDrugTesting,
      "Electronic Monitoring": false,
      "Pay Only": false,
      sDocketNumberCourtCounts: data?.[0]?.sDocketNumberCourtCounts,
      gPersonId: goToDocketPerson.id ? goToDocketPerson.id : data?.[0]?.gPersonId,
      sSentenceNotes: data?.[0]?.sSentenceNotes,
    });
  }

  async getFIndTable(setLoading, search, setdata) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/find?search=${search ? search : null}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setdata(setDocketFindTable(val?.data?.data));
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          this.navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  SaveChangeSuperVisorDate = async (
    body,
    navigate,
    setLoading,
    setChangesuperviserDatePOpup,
    setToastConfig,
    toastConfig,
    getDcket
  ) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/obligations/change-financial-status`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully Updated!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully Updated!",
            type: "success",
          });
        }, 1400);
        if (getDcket) getDcket();
        setLoading(false);
        setChangesuperviserDatePOpup(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  ChangeSuperVisorDateresetValue(setFields, data, setcommentValidation) {
    setcommentValidation("");
    setFields({
      "Docket Reference": { name: "", id: "" },
      "Supervision Status": {
        name: data?.[0]?.sSupervisionStatusName,
        id: data?.[0]?.gSupervisionStatusId,
      },
      "Effective Date": data?.[0]?.dSupervisionStatus
        ? data?.[0]?.dSupervisionStatus
        : new Date().toISOString().split("T")[0] + "T00:00:00",
      "Change Reason": { name: "", id: "" },
      comment: "",
    });
  }
  async Addeditsupervisor(data2deopdown, setrefreshsupervision, refreshsupervision) {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/add-edit-supervisor`, data2deopdown, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setrefreshsupervision(!refreshsupervision);
      })
      .catch((val) => {
        console.log("err");
      });
  }

  async ChangeSupervisor(body, navigate, setLoading, setToastConfig, reloadApi) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/set-supervisor`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setToastConfig({
          isVisible: true,
          message: "Supervisor Changed!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            isVisible: false,
            message: "Supervisor Changed!",
            type: "success",
          });
        }, 1400);
        if (reloadApi) {
          reloadApi();
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          setToastConfig({
            isVisible: true,
            message: "Change Supervisor Failed!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              isVisible: false,
              message: "Change Supervisor Failed!",
              type: "error",
            });
          }, 1400);
        }
      });
  }

  Saveheader = async (
    body,
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    dockerValue,
    setData,
    dispatch,
    data,
    param,
    dynamicParticipatTab,
    setcustomFlag,
    body2,
    data2deopdown,
    setrefreshsupervision,
    refreshsupervision,
    setEditTogel
  ) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/edit-docket`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          this.setCustomFlag(body2);
          setEditTogel(false);
          this.Addeditsupervisor(data2deopdown, setrefreshsupervision, refreshsupervision);
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1400);
          this.getDocket(setLoading, dockerValue, setData, dispatch, param, dynamicParticipatTab, setcustomFlag);

          dispatch(
            resetDocyINdynamicTab({
              last: `${data?.sDocketNumber}-${data?.iCourtCounts}`,
              new: `${val?.data?.data?.sDocketNumber}-${val?.data?.data?.iCourtCounts}`,
            })
          );
          navigate(`/docket/${val?.data?.data?.sDocketNumber?.replace(/\//g, "-")}-${val?.data?.data?.iCourtCounts}`);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Docket already exists!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Docket already exists!",
              type: "error",
            });
          }, 1400);
        }
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Docket already exists!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Docket already exists!",
              type: "error",
            });
          }, 1400);
          this.getDocket(setLoading, dockerValue, setData, dispatch, param, dynamicParticipatTab, setcustomFlag);
        }
      });
  };

  Save(
    fields,
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    goToDocketPerson,
    docketId,
    dockerValue,
    setData,
    dispatch,
    data,
    param,
    dynamicParticipatTab,
    setcustomFlag,
    body2,
    data2deopdown,
    setrefreshsupervision,
    refreshsupervision,
    setEditTogel
  ) {
    let temp = false;
    data2deopdown?.length &&
      data2deopdown?.map((vall2) => {
        if (vall2.GSupervisorTypeId == "00000000-0000-0000-0000-000000000000") {
          temp = true;
        }
      });
    if (temp) {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "Please select Supervision Type!",
        type: "error",
      });
      setTimeout(() => {
        setToastConfig({
          ...toastConfig,
          isVisible: false,
          message: "Please select Supervision Type!!",
          type: "error",
        });
      }, 1400);
      return;
    }

    this.Saveheader(
      {
        GDocketId: docketId,
        GDocketTypeId: fields?.["Type"]?.id,
        DCreate: fields?.["Create Date"],
        GPersonId: goToDocketPerson.id ? goToDocketPerson.id : fields?.gPersonId,
        GSupervisionStatusId: fields?.["Supervision Status"]?.id,
        GSentenceCourtEntityId: fields?.["Court"]?.id,
        GSentenceJudgeContactId: fields?.["Judge"]?.id,
        BFirstOffender: fields?.["First Offender"] ? true : false,
        GOffenseId: fields?.["Offense"]?.id,
        ICourtCounts: fields?.["Docket Number2"],
        SDocketNumber: fields?.["Docket Number"],
        BConditionalDischarge: fields?.["Conditional Discharge"] ? true : false,
        BRandomDrugTesting: fields?.["Random Drug Testing"] ? true : false,
        BElectronicMonitoring: fields?.["Electronic Monitoring"] ? true : false,
        BPayOnly: fields?.["Pay Only"] ? true : false,
        sSentenceNotes: fields?.sSentenceNotes,
      },
      navigate,
      setLoading,
      setToastConfig,
      toastConfig,
      dockerValue,
      setData,
      dispatch,
      data,
      param,
      dynamicParticipatTab,
      setcustomFlag,
      body2,
      data2deopdown,
      setrefreshsupervision,
      refreshsupervision,
      setEditTogel
    );
  }

  getDrugTestDropdownData = async (settext, getTopDynamicDocketid, setDrugDropdownData, navigate) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/DrugTest/DrugTestDropdownAsync?docketId=${getTopDynamicDocketid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        settext(val?.data?.data?.sConditionText);
        setDrugDropdownData(
          val?.data?.data?.drugTestConditionFrequency?.map((val) => {
            return {
              name: val?.value,
              id: val?.key,
            };
          })
        );
      })
      .catch((error) => {
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        }
      });
  };

  getCustomFlag = async (recordId, setcustomFlag) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/CustomFlag/getCustomFlagData?recordId=${recordId}&Type=Docket`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setcustomFlag(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          this.navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  setCustomFlag = async (body) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/CustomFlag/updateCustomFlagData`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        console.log("done");
      })
      .catch((val) => {
        if (!val?.response) {
          this.navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  async DocketSupervisionDropdown(navigate, setLoading, setDocketSupervisionDropdowndata) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/supervision-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setDocketSupervisionDropdowndata(val?.data?.data);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async getSupervisionAdjustmentPeriod(setLoading, navigate, setEnablePeriod) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Account/getCompanyOption?Name=Supervision Adjustment Period Enabled`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess && val?.data?.data?.toLowerCase() === "true") setEnablePeriod(true);
        return null;
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }
}
