import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import moment from "moment";
import { ApiError } from "../../../../../../Error/ApiError";
import { Table1 } from "../../../../../../Administration/Table1/Table1";
import { MonitoringFunction } from "../../../../../../Monitoring/MonitoringFunction";
import { ColumnDropdown } from "../../../../../../../components/Dropdownn";
import { useNavigate } from "react-router-dom";
import { emCreateInvoiceTabelColumn } from "../../Data";
import { handleGetLocal } from "../../../../../../../services/auth-service";
import { logOut } from "../../../../../../../components/Logout";
import { getLastDayOfMonth } from "../../../../../../../utils/date-helpers";
import { Toster } from "../../../../../../../components/Toster/Toster";
import { useSelector } from "react-redux";
import { getAccessData } from "../../../../../../../utils/getAccessPermission";
import { DynamicTabb } from "../../../../../../../components/DynamicTab/DynamicTab";
import MonitoringCrumbs from "../../../../../../Monitoring/components/MonitoringCrumbs";
import { CreateInvoicePopup } from "../../../../Financial/components/overviewPopUp/CreateInvoicePopup";
import { InvoiceCreatedPopup } from "../../../../Financial/components/overviewPopUp/InvoiceCreatedPopup";
import { Column, FilterRow, Selection } from "devextreme-react/data-grid";
import { DataGrid, DateBox, DropDownBox, LoadPanel } from "devextreme-react";
import SpreadsheetUploader from "../../../../../../../utils/SpreadSheetUploader";
import { ImportInvoicePopup } from "../../../../Financial/components/overviewPopUp/ImportInvoicePopup";


const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled, width, value }) => {
    return (
        <>
            <ColumnDropdown
                data={dropDownData}
                setfields={setfields}
                fields={fields}
                fieldNmae={fieldNmae}
                value={fields[fieldNmae]?.name || value}
                reset={reset}
                disabled={disabled}
                width={width}
            />
        </>
    );
};


export const ImportInvoice = () => {
    const { userAccessData, user_data } = useSelector((store) => store.Home);
    const [viewAccess, setViewAccess] = useState(true);
    const [editAccess, setEditAccess] = useState(false);
    const [addAccess, setAddAccess] = useState(false);
    const [paymentAgencyDropdownData, setPaymentAgencyDropdownData] = useState([]);
    const [billingCompanyDropdownData, setBillingCompanyDropdownData] = useState([]);
    const [invoiceType, setInvoiceType] = useState("createNewInvoice");
    const initialState = {
        paymentagency: {
            id: "",
            name: "",
        },
        year: {
            id: "",
            name: "",
        },
        month: {
            id: "",
            name: "",
        },
        previousmonth: {
            id: "",
            name: "",
        },
        billingcompany: {
            id: "",
            name: "",
        },
        periodstart: "",
        periodend: "",
        invoicedate: "",
        notes: "",
        quickdate: "",
        checkboxinvoices: false,
        checkboxnames: false,
        createNewInvoice: true,
        addtoExistingInvoice: false,
        invoice: "",
    };

    // Use useState to manage the fields state
    const [fields, setFields] = useState(initialState);
    const [selectedRowCheckbox, setSelectedRowCheckbox] = useState([]);
    const [receiveInvoiceData, setReceiveInvoiceData] = useState([]);
    const [errorhandling, setErrorhandling] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: "",
        message: "",
    });
    const currentYear = new Date().getFullYear();
    const previousMonthID = new Date().getMonth();

    const navigate = useNavigate();
    const ApiClass = new MonitoringFunction();
    const currentDate = new Date();
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const lastDayOfPerviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    const year = lastDayOfMonth.getFullYear();
    const month = (lastDayOfMonth.getMonth() + 1).toString().padStart(2, "0");
    const previousmonth = (lastDayOfPerviousMonth.getMonth() + 1).toString().padStart(2, "0");
    const day = lastDayOfMonth.getDate().toString().padStart(2, "0");
    const prevoiusmonthday = lastDayOfPerviousMonth.getDate().toString().padStart(2, "0");
    const formattedEndDate = `${year}-${month}-${day}`;
    const invoiceDate = `${year}-${previousmonth}-${prevoiusmonthday}`;
    const [confirmPopup, setConfirmPopup] = useState(false);
    const dateRanges = [
        { name: "Current Month", id: "Current Month" },
        { name: "Previous Month", id: "Previous Month" },
    ];
    const [excelParsedData, setExcelParsedData] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
    const [excelName, setExcelName] = useState("");
    const [message, setMessage] = useState("");

    const convertDateFormat = (dateString) => {
        if (dateString) {
            const dateObj = new Date(dateString);
            return dateObj.toLocaleDateString("en-US");
        } else {
            return "";
        }
    };

    const resetFields = () => {
        setFields(initialState);
        setFields((prev) => ({
            ...prev,
            invoicedate: invoiceDate,
        }));
    };
    const getInvoiceDropdownData = (data) => {
        const arr = [];
        data.map((val, i) => {
            arr.push({
                "Invoice #": val?.sInvoiceNumber,
                Amount: val?.fAmount,
                Date: convertDateFormat(val?.dInvoice),
                ID: i,
            });
        });
        return arr;
    };

    const handleInvoiceTypeChange = (event) => {
        setInvoiceType(event.target.value);
        if (event.target.value === "createNewInvoice") {
            setFields((prevFields) => ({
                ...prevFields,
                createNewInvoice: true,
                addtoExistingInvoice: false,
                invoice: "",
            }));
        }
        if (event.target.value === "addtoExistingInvoice") {
            setFields((prevFields) => ({
                ...prevFields,
                addtoExistingInvoice: true,
                createNewInvoice: false,
            }));
        }
    };

    const handleCreate = async () => {
        const data = {
            CompanyId: fields?.paymentagency.id,
            EntityId: fields?.billingcompany.id,
            IsCreateNewInvoice: fields?.createNewInvoice,
            IsAddtoExistingInvoice: fields.addtoExistingInvoice,
            Invoice: fields.invoice,
            InvoiceDate: fields.invoicedate,
            FileToImport: fields.excelName,
            ExcelData: excelParsedData || []
        };

        setLoading(true);
        await axios
            .post(`${process.env.REACT_APP_API_URL}/api/monitoring/import-invoice`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Domain: `${handleGetLocal("pathURL")}`,
                },
            })
            .then((val) => {
                setLoading(false);
                setConfirmPopup(true);
                setMessage(val?.data?.message);
            })
            .catch((error) => {
                setLoading(false);
                if (!error?.response) {
                    navigate(`/error/500`);
                } else if (error?.response?.status === 401) {
                    logOut();
                } else {
                    setErrorhandling(true);
                }
            });
    };

    const getInvoicedropdown = async () => {
        setLoading(true);
        await axios
            .get(`${process.env.REACT_APP_API_URL}/api/monitoring/get-invoice-dropdown?gEntityID=${fields?.billingcompany.id}&gCompanyEntityID=${fields?.paymentagency.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Domain: `${handleGetLocal("pathURL")}`,
                },
            })
            .then((val) => {
                setDataSource(getInvoiceDropdownData(val?.data?.data));
                setLoading(false);
            })
            .catch((val) => {
                setLoading(false);
                if (!val?.response) {
                    navigate(`/error/500`);
                } else if (val?.response?.status === 401) {
                    logOut();
                }
            });
    }

    const onGridBoxOpened = useCallback((e) => {
        if (e.name === "opened") {
            setIsGridBoxOpened(e.value);
        }
    }, []);

    useEffect(() => {
        if (fields.addtoExistingInvoice) { // Check if the condition is true
            getInvoicedropdown();
        }
    }, [fields.addtoExistingInvoice]);

    useEffect(() => {
        getUserAccessPermissions();
        ApiClass.getPaymentAgencyDropdown(setLoading, navigate, setPaymentAgencyDropdownData);
        setFields((prev) => ({
            ...prev,
            invoicedate: invoiceDate,
        }));
    }, []);

    const getUserAccessPermissions = () => {
        const accessData = getAccessData(userAccessData, "EM6");
        setAddAccess(accessData?.Add);
        setEditAccess(accessData?.Edit);
        setViewAccess(accessData?.View);
    };
    useEffect(() => {
        if (user_data?.gLocationId) {
            ApiClass.getBillingCompanyDropdown(setLoading, navigate, setBillingCompanyDropdownData, user_data?.gLocationId);
            setFields((prev) => ({
                ...prev,
                paymentagency: { name: user_data?.sLocationName, id: user_data?.gLocationId },
            }));
        }
        if (fields?.paymentagency?.id) {
            ApiClass.getBillingCompanyDropdown(setLoading, navigate, setBillingCompanyDropdownData, fields?.paymentagency?.id);
        }
    }, [fields?.paymentagency?.id, user_data?.gLocationId]);

    return (
        <>
            <ImportInvoicePopup
                confirmPopup={confirmPopup}
                setConfirmPopup={setConfirmPopup}
                message={message}
            />
            <LoadPanel shadingColor="true" visible={loading} delay={10} />
            <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
            <MonitoringCrumbs title={"Create Agency Invoice"} />

            <div className="financial-page">
                <DynamicTabb />
                <div className="financial-page-section">
                    <p className="page-info-number">EM.33</p>
                    {viewAccess ? (
                        <div
                            className="financial-page-section receive-agency-payment"
                            style={{
                                pointerEvents: editAccess || addAccess ? "auto" : "none",
                                opacity: editAccess || addAccess ? 1 : 0.6,
                                cursor: editAccess || addAccess ? "default" : "not-allowed",
                            }}
                        >
                            <div className="page-details-search-content">
                                <div className="display-flex-div edit_content_space" >
                                    <label className="edit_container_label" style={{ textAlign: "right", width: "100px" }}>Company</label>
                                    <DropDown
                                        setfields={setFields}
                                        fields={fields}
                                        fieldNmae={"paymentagency"}
                                        dropDownData={paymentAgencyDropdownData}
                                        width={300}
                                        value={user_data?.sLocationName}
                                    />
                                </div>
                            </div>
                            <div className="page-details-search-content">
                                <div className="display-flex-div edit_content_space">
                                    <label className="edit_container_label">Agency / Entity</label>
                                    <DropDown
                                        width={300}
                                        setfields={setFields}
                                        fields={fields}
                                        fieldNmae={"billingcompany"}
                                        dropDownData={billingCompanyDropdownData}
                                    />
                                </div>
                            </div>
                            <div className="page-details-search-content">
                                <div className="display-flex-div edit_content_space">
                                    {/* <label className="edit_container_label" style={{ textAlign: "right", width: "160px" }}>
                                        Invoice Note
                                    </label> */}
                                    <div className="edit_container_first space_removed">
                                        <input
                                            id="createNewInvoice"
                                            name="invoiceType"
                                            type="radio"
                                            value="createNewInvoice"
                                            checked={invoiceType === "createNewInvoice"}
                                            onChange={handleInvoiceTypeChange}
                                        />
                                        <label htmlFor="hoursServed" className="edit_container_label">
                                            Create New Invoice
                                        </label>
                                        <input
                                            id="addtoExistingInvoice"
                                            name="invoiceType"
                                            type="radio"
                                            value="addtoExistingInvoice"
                                            checked={invoiceType === "addtoExistingInvoice"}
                                            onChange={handleInvoiceTypeChange}
                                        />
                                        <label htmlFor="hoursWrittenOff" className="edit_container_label">
                                            Add to Existing Invoice
                                        </label>
                                    </div>

                                </div>
                            </div>
                            {invoiceType === "addtoExistingInvoice" && (
                                <div className="page-details-search-content">
                                    <div className="display-flex-div edit_content_space" >
                                        <label className="edit_container_label" style={{ textAlign: "right", width: "100px" }}>Select Invoice</label>
                                        <DropDownBox
                                            placeholder="Select..."
                                            width={300}
                                            dataSource={dataSource}
                                            value={fields.invoice}
                                            valueExpr={"Invoice #"}
                                            displayExpr={(item) => item?.["Invoice #"] || ""}
                                            opened={isGridBoxOpened}
                                            onOptionChanged={onGridBoxOpened}
                                            dropDownOptions={{
                                                width: "auto", // Adjust popup width to accommodate all columns
                                            }}
                                            contentRender={(value, element) => (
                                                <DataGrid
                                                    dataSource={dataSource}
                                                    hoverStateEnabled={true}
                                                    height={"auto"}
                                                    width="300px"
                                                    onRowClick={(e) => {
                                                        // setSelectedValue(e?.data);
                                                        // setSelectedRowCheckbox([]);
                                                        setIsGridBoxOpened(false);
                                                        setFields((prev) => ({
                                                            ...prev,
                                                            invoice: e?.data?.["Invoice #"],
                                                        }));
                                                    }}
                                                    paging={{ pageSize: 20 }} // Limit to 20 rows per page
                                                >
                                                    <Selection mode="single" />
                                                    <FilterRow visible={true} />
                                                    <Column
                                                        dataField="Invoice #"
                                                        caption="Invoice #"
                                                        width={"auto"}
                                                    />
                                                    <Column
                                                        dataField="Amount"
                                                        caption="Amount"
                                                        width={"auto"}
                                                        format={{ type: "currency", precision: 2 }}
                                                    />
                                                    <Column dataField="Date" caption="Date" width={"auto"} />
                                                </DataGrid>
                                            )}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="page-details-search-content">
                                <div className="display-flex-div edit_content_space">
                                    <label className="edit_container_label" style={{ textAlign: "right", width: "100px" }}>Invoice Date</label>
                                    <DateBox
                                        useMaskBehavior={true}
                                        value={fields.invoicedate}
                                        onValueChange={(e) => {
                                            if (e) {
                                                setFields((prev) => ({
                                                    ...prev,
                                                    invoicedate: e ? e : null,
                                                }));
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="page-details-search-content">
                                <div className="display-flex-div edit_content_space">
                                    <label className="edit_container_label" style={{ textAlign: "right", width: "100px" }}>File to Import</label>
                                    <SpreadsheetUploader setExcelParsedData={setExcelParsedData} setExcelName={setExcelName} />
                                </div>
                            </div>
                            <div className="page-info-buttons">
                                <div className="buttons" style={{ alignItems: "baseline" }}>
                                    <div className="agency-column-fields">
                                        <button className="btn"
                                            onClick={handleCreate}
                                        >
                                            Ok
                                        </button>
                                    </div>
                                    <div className="agency-column-fields">
                                        <button className="btn"
                                        // onClick={handleDeselectAll}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
};
