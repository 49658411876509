import axios from "axios";
import { handleGetLocal } from "../../services/auth-service";
import { logOut } from "../../components/Logout";
import moment from "moment";

export class ReportWizaerdFuncition {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }
  async getActivityReportWizard(setLoading, seterrorhandler, navigate, setData, startdate, enddate) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportWizard/getActivityReportWizard?startDate=${startdate}&endDate=${enddate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandler(false);
          setData(this.getActivityReportWizardTable(val?.data?.data));
        } else {
          seterrorhandler(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandler(true);
        }
      });
  }

  getActivityReportWizardTable = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        Date: val?.dDoe,
        "Entered By": val?.sEnteredByUserName,
        "Activity Note": val?.sMemo,
        "Note Type": val?.sActivityTypeName,
        References: val?.sReference,
        Source: val?.sType,
        "Offender #": val?.sOffenderNumber,
        "Offender Name": val?.sLastFirst,
        Location: val?.sgpM_EntityName,
        Supervisor: val?.sPrimarySupervisorName,
        System: val?.bSystem,
        Court: val?.sCourtName,
        ID: i,
      });
    });
    return arr;
  };

  async DisbursementReportWizard(setLoading, seterrorhandler, navigate, setData, startdate, enddate, RecipientName) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportWizard/getDisbursementReportWizard?startDate=${
          startdate ? startdate : new Date().toISOString()
        }&endDate=${enddate ? enddate : new Date().toISOString()}&recipientName=${RecipientName}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandler(false);
          setData(this.DisbursementReportWizardTable(val?.data?.data));
        } else {
          seterrorhandler(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandler(true);
        }
      });
  }

  DisbursementReportWizardTable = (data) => {
    const arr = [];
    data?.map((val, i) => {
      arr.push({
        Date: val?.dDoe,
        "Check #": val?.sCheckNumber,
        "Recipient Name": val?.sRecipientName,
        "Bank Acct": val?.sBankAccountNumber,
        "Offender #": val?.sOffenderNumber,
        "Offender Name": val?.sLastFirst,
        Company: val?.sCompanyName,
        "Fee Code": val?.sddsLegalCode,
        "Fee Name": val?.sddsViolationDescription,
        Docket: val?.sDocketNumber,
        Amount: val?.fAmount,
        Void: val?.bVoid,
        "Disburse Frequency": val?.sDisburseFrequencyName,
        "Recipient Category": val?.sRecipientCategoryName,
        "Void Date": val?.dVoid,
        Court: val?.sCourtName,
        "Address 1": val?.sRecipient_Address1,
        "Address 2": val?.sRecipient_Address2,
        City: val?.sRecipient_City,
        State: val?.sRecipient_State,
        Zip: val?.sRecipient_Zip,
        sDocketNumber: val?.sDocketNumber,
        iCourtCounts: val?.iCourtCounts,
        gDocketID: val?.gDocketID,
        userinfo: val,
        sddsLegalCode: val?.sddsLegalCode,
        gObligationId: val?.gObligationId,
        ID: i,
      });
    });
    return arr;
  };

  async DocketLIstapi(setLoading, seterrorhandler, navigate, setData, Active, Location, Supervisor) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportWizard/getDocketReportWizard?bActiveStatus=${
          Active == "Yes" ? true : false
        }&sGPMEntityName=${Location}&sPrimarySupervisorName=${Supervisor}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandler(false);
          setData(this.DocketLIstapiTable(val?.data?.data));
        } else {
          seterrorhandler(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandler(true);
        }
      });
  }

  DocketLIstapiTable = (data) => {
    const arr = [];
    data?.map((val, i) => {
      arr.push({
        Select: "",
        Offender: val?.sOffenderNumber,
        "Offender Name": val?.sLastFirst,
        "Docket Status": val?.sSupervisionStatusName,
        "Court Name": val?.sCourtName,
        "Person Supervisor": val?.sPersonSupervisorName,
        "Status Date": val?.dSupervisionStatus,
        "Person Status": val?.sPersonSupervisionStatusName,
        "Sentence Start": val?.dSentence,
        "Sentence End": val?.dSentence_End,
        "Adjusted End": val?.dSentence_AdjustedEnd,
        "Court Sentence": val?.dCourtSentence,
        "Sentence Notes": val?.sSentenceNotes,
        "Days Remaining": val?.iSentence_DaysRemaining,
        "Link Type": val?.sDocketLinkTypeName,
        "Docket Number": val?.sDocketNumber,
        Count: val?.iCourtCounts,
        Months: val?.iSentence_Months,
        Days: val?.iSentence_Days,
        Judge: val?.sJudgeName,
        Offense: val?.sOffenseName,
        CD: val?.bConditionalDischarge,
        EM: val?.bEm,
        DT: val?.bRandomDrugTesting,
        Amount: val?.fAmount,
        Paid: val?.fPaid,
        "Write Off": val?.fWriteOff,
        Balance: val?.fBalance,
        "Docket Age": val?.iDocketAgeMonths,
        Frequency: val?.sDrugTestFrequencyName,
        "Quantity/Freq": val?.iDrugTestQuantity,
        "Range Drug": val?.bRandomDrugTesting,
        FOA: val?.bFirstOffender,
        "Date Entered": val?.dDoe,
        "Entered By": val?.sEnteredByName,
        "Docket Type": val?.sDocketLinkTypeName,
        sDocketNumber: val?.sDocketNumber,
        iCourtCounts: val?.iCourtCounts,
        gDocketId: val?.gDocketId,
        userinfo: val,
        ID: i,
      });
    });
    return arr;
  };

  async getPaymentReportWizardapi(setLoading, seterrorhandler, navigate, setData, startdate, enddate) {
    setLoading(true);
    if(startdate) {
      startdate = moment(startdate)
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }
    
    if(enddate) {
      enddate = moment(enddate)
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportWizard/getPaymentReportWizard?startDate=${
          (startdate && startdate != "Invalid date") ? startdate : new Date().toISOString()
        }&endDate=${(enddate && enddate != "Invalid date") ? enddate : new Date().toISOString()}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandler(false);
          setData(this.getPaymentReportWizardTable(val?.data?.data));
        } else {
          seterrorhandler(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandler(true);
        }
      });
  }

  getPaymentReportWizardTable = (data) => {
    const arr = [];
    data?.map((val, i) => {
      arr.push({
        "Receipt Date": val?.dReceipt,
        "Person Name": val?.sPersonName,
        "Offender No": val?.sOffenderNumber,
        "GPM Location": val?.sgpM_Location_Name,
        "Court Name": val?.sCourtName,
        "Cash Drawer": val?.sCashDrawerName,
        "Fee Code": val?.sacdCode,
        "Fee Name": val?.sLocalViolationDescription,
        "Fee Description": val?.sLocalViolationDescription,
        "Allocation Amount": val?.fAmount,
        "Receipt Number": val?.sReceiptNumber,
        "Receipt Note": val?.sReceiptNote,
        "Receipt Comment": val?.sReceiptComment,
        "Manual #": val?.sManualReceiptNumber,
        "Deposit Reference": val?.sDepositReference,
        "Deposit Date": val?.dDeposit,
        "Bank Account": val?.sDepositBankAccount,
        "Trans Type": val?.sTransactionType,
        "Payment Reference": val?.sReference,
        "OBL Reference": val?.sObligationReference,
        "Payment Amount": val?.fPaymentAmount,
        Supervisor: val?.sSupervisorName,
        "Entered By": val?.sEnteredByName,
        "Payment Method": val?.sPaymentMethodName,
        "Person Status": val?.sPersonSupervisionStatusName,
        "Docket Status": val?.sDocketSupervisionStatusName,
        "Transaction ID": val?.sCC_TransactionID,
        sCashDrawerName: val?.sCashDrawerName,
        gDocketID: val?.gDocketID,
        userinfo: val,
        sObligationReference: val?.sObligationReference,
        gObligationID: val?.gObligationID,
        ID: i,
        "Agency Pay": val?.bIsBilling
      });
    });
    return arr;
  };

  async getObligationReportWizardapi(setLoading, seterrorhandler, navigate, setData, Active, Location, Court) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportWizard/getObligationReportWizard?bActiveStatus=${
          Active === "Yes" || Active ? true : false
        }&sGPMEntityName=${Location}&sCourtName=${Court}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandler(false);
          setData(this.getObligationReportWizardTable(val?.data?.data));
        } else {
          seterrorhandler(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandler(true);
        }
      });
  }

  getObligationReportWizardTable = (data) => {
    const arr = [];
    data?.map((val, i) => {
      arr.push({
        Offender: val?.sOffenderNumber,
        "Offender Name": val?.sLastFirst,
        "Fee Code": val?.sddsLegalCode,
        "Fee Name": val?.sLocalViolationDescription,
        "Fin Status": val?.sFinancialStatusName,
        "Person Status": val?.sPersonSupervisionStatusName,
        "Docket Status": val?.sDocketStatusName,
        "Docket Number": val?.sDocketNumber,
        Count: val?.iCourtCounts,
        Location: val?.sgpM_EntityName,
        Amount: val?.fAmount,
        Paid: val?.fPaid,
        "Write Off": val?.fWriteOff,
        Balance: val?.fBalance,
        "On Hand": val?.fBalanceEligible,
        Arrears: val?.fArrears,
        Supervisor: val?.sSupervisorName,
        "Sentence End": val?.dSentence_End,
        "Sent, Adj End": val?.dSentence_AdjustedEnd,
        "PP Start": val?.dPaymentPlanStart,
        "Billing Agency": "",
        Reference: val?.sReference,
        "Date Entered": val?.dDoe,
        "Date Active": val?.dActive,
        Court: val?.sCourtName,
        "Sentence Start": val?.dSentence,
        sDocketNumber: val?.sDocketNumber,
        iCourtCounts: val?.iCourtCounts,
        userinfo: val,
        ID: i,
      });
    });
    return arr;
  };

  async getDrugTestReportWizardapi(setLoading, seterrorhandler, navigate, setData, TestDate, Location, Supervisor) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportWizard/getDrugTestReportWizard?dTest=${TestDate}&sGPMEntityName=${Location}&SupervisorName=${Supervisor}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandler(false);
          setData(this.getDrugTestReportWizardTable(val?.data?.data));
        } else {
          seterrorhandler(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandler(true);
        }
      });
  }

  getDrugTestReportWizardTable = (data) => {
    const arr = [];
    data?.map((val, i) => {
      arr.push({
        "Offender #": val?.sOffenderNumber,
        "Test Date": val?.dTest,
        "Offender Name": val?.sLastFirst,
        "Result Date": val?.dResult,
        "Test Type": val?.sDrugTestTypeName,
        "Test Reason": val?.sDrugTestReasonName,
        "Test Result": val?.sDrugTestResultName,
        "Tested By": val?.sDrugTestByName,
        "Person Status": val?.sSupervisionStatusName,
        Location: val?.sgpM_EntityName,
        Supervisor: val?.sPrimarySupervisorName,
        "Requisition #": val?.sRequisitionNumber,
        "Test Location": val?.sLocationName,
        Court: val?.sCourtName,
        "Docket / Count": val?.sDocketNumberCourtCounts,
        "Docket Status": val?.sDocketStatus,
        sDocketNumberCourtCounts: val?.sDocketNumberCourtCounts,
        userinfo: val,
        gDocketId: val?.gDocketId,
        ID: i,
      });
    });
    return arr;
  };

  async getQueryReportWizard(setLoading, navigate, setquerydropdown, data, setfield, field, setxmldata, setisdefault) {
    setLoading(true);

    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportWizard/getQueryReportWizard?entryUserId=${localStorage.getItem(
          "gUserId"
        )}&sLogicalUnitName=${data}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          if (val?.data?.data?.length) {
            setquerydropdown(
              val?.data?.data?.map((val2) => {
                return {
                  name: val2?.sName,
                  id: val2?.gStoredQueryId,
                  sData: val2?.sData,
                  bDefault: val2?.bDefault,
                };
              })
            );
            let data = { name: "", id: "" };
            val?.data?.data?.map((valll) => {
              if (valll?.bDefault) {
                data = {
                  name: valll?.sName,
                  id: valll?.gStoredQueryId,
                };
                setxmldata(valll?.sData);
                setisdefault(valll?.bDefault);
              }
            });
            setfield({ ...field, query: data });
          }
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async getQueryFilterReportWizard(
    setLoading,
    navigate,
    data,
    setfilterdropdown,
    temp,
    setfield,
    field,
    setfilterdata
  ) {
    setLoading(true);

    await axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/api/ReportWizard/getQueryFilterReportWizard?entryUserId=${localStorage.getItem(
          "gUserId"
        )}&sLogicalUnitName=${data}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setfilterdropdown(
            val?.data?.data?.map((vall) => {
              return {
                name: vall?.sName,
                id: vall?.gStoredFilterId,
                data: vall?.sData,
              };
            })
          );
          if (temp) {
            setfield({
              ...field,
              filter: {
                name: val?.data?.data?.[0]?.sName,
                id: val?.data?.data?.[0]?.gStoredFilterId,
              },
            });
            setfilterdata(val?.data?.data?.[0]?.sData);
          }
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async getPersonReportWizard(
    setLoading,
    seterrorhandler,
    navigate,
    setData,
    startdate,
    Location,
    ActiveOnly,
    Supervisor
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportWizard/getPersonReportWizard?dTest=${
          startdate ? startdate : new Date().toISOString()
        }&bActiveStatus=${
          ActiveOnly == "Yes" ? true : false
        }&sGPMEntityName=${Location}&sPrimarySupervisorName=${Supervisor}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandler(false);
          setData(this.getPersonReportWizardTable(val?.data?.data));
        } else {
          seterrorhandler(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandler(true);
        }
      });
  }

  getPersonReportWizardTable = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        DOE: val?.dDoe,
        "First Name": val?.sFirstName,
        "Last Name": val?.sLastName,
        "Primary Location": val?.sGpm_EntityName,
        Supervisor: val?.sPrimarySupervisorName,
        "Offender Number": val?.sOffenderNumber,
        DOB: val?.dDob,
        "Person Status": val?.sPersonSupervisionStatusName,
        "F/S": val?.sFinancialStatusName,
        "S/S": val?.sPersonSupervisionStatusName,
        "Active Dockets": val?.iActiveDocketCount,
        "Court Count": val?.iCourtCount,
        "Mailing Address": val?.sMailing_Address1,
        "Compliance Level": val?.sComplianceLevelName,
        Balance: val?.fBalance,
        Email: val?.sOptInEmail,
        Text: val?.sOptInText,
        "Online Reporting": val?.bOnlineReporting,
        "Payment Block": val?.bPaymentBlock,
        Paid: val?.fPaid,
        "Region Name": val?.sRegionName,
        "Agency Name": val?.sAgencyName,
        "Net Balance": val?.fNetBalance,
        "Credit Balance": val?.fCreditBalance,
        ID: i,
      });
    });
    return arr;
  };

  async getReportDateReportWizard(
    setLoading,
    seterrorhandler,
    navigate,
    setData,
    startdate,
    EndDate,
    Location,
    Supervisor
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportWizard/getReportDateReportWizard?dReportStart=${
          startdate ? startdate : new Date().toISOString()
        }&dReportEnd=${
          EndDate ? EndDate : new Date().toISOString()
        }&sGPMEntityName=${Location}&SupervisorName=${Supervisor}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandler(false);
          setData(this.getReportDateReportWizardTable(val?.data?.data));
        } else {
          seterrorhandler(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandler(true);
        }
      });
  }

  getReportDateReportWizardTable = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        "Offender #": val?.sOffenderNumber,
        "Offender Name": val?.sLastFirst,
        "Report Date": val?.dReport,
        Location: val?.sLocation,
        Supervisor: val?.sResource,
        "Report Type": val?.sReportTypeName,
        Attendance: val?.sAttendanceName,
        "Pre Entered": val?.sPreEnteredBy,
        "Post Entered": val?.sPostEnteredBy,
        "Pre Report Note": val?.sPreReportNote,
        "Post Report Note": val?.sPostReportNote,
        Void: val?.bVoid,
        ID: i,
      });
    });
    return arr;
  };

  async getReportScheduleReportWizard(setLoading, seterrorhandler, navigate, setData) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/ReportWizard/getReportScheduleReportWizard`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandler(false);
          setData(this.getReportScheduleReportWizardTable(val?.data?.data));
        } else {
          seterrorhandler(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandler(true);
        }
      });
  }

  getReportScheduleReportWizardTable = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        "Report Name": val?.sReportName,
        Date: val?.dDoe,
        Expiration: "",
        Comment: val?.sComment,
        "Query XML": val?.sQueryXml,
        "Filter XML": val?.sFilterXml,
        "File UNC": val?.sFileUnc,
        "Logical Unit": val?.sLogicalUnitName,
        ID: i,
      });
    });
    return arr;
  };

  async saveAndEditQueryReportWizard(
    setLoading,
    navigate,
    GStoredQueryId,
    SName,
    DDoe,
    GEntryUserId,
    SData,
    SLogicalUnitName,
    getQueryReportWizard,
    setCustomizequerypopupshow,
    defaultt
  ) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/ReportWizard/saveAndEditQueryReportWizard`,
        {
          GStoredQueryId: GStoredQueryId,
          SName: SName,
          DDoe: DDoe,
          GEntryUserId: GEntryUserId,
          SData: SData,
          SLogicalUnitName: SLogicalUnitName,
          BDefault: defaultt,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        getQueryReportWizard();
        setCustomizequerypopupshow(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  convertJsonToXml = (jsonData) => {
    let xml = "<DocumentElement>\n";

    for (const key in jsonData) {
      if (jsonData.hasOwnProperty(key)) {
        const item = jsonData[key];
        xml += `  <Parameter>\n`;
        xml += `    <sName>${key}</sName>\n`;
        xml += `    <sType>${item.type}</sType>\n`;
        xml += `    <sValue>${item.value}</sValue>\n`;
        xml += `    <sFunction>${item.function || ""}</sFunction>\n`;
        xml += `  </Parameter>\n`;
      }
    }

    xml += "</DocumentElement>";
    return xml;
  };

  async getparametervalues(setLoading, navigate, setData) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/ReportDashboard/get-parameter-values?parameterName=Active Users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setData(this.getparametervaluesTable(val?.data?.data));
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  getparametervaluesTable = (data) => {
    const arr = [];
    data.map((val, i) => {
      arr.push({
        "User Name": val?.sParameterValue,
        Location: "",
        gParameterID: val?.gParameterID,
        ID: i,
      });
    });
    return arr;
  };

  async ReportWizardShare(setLoading, navigate, body, setsharequeryshow) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/ReportWizard/Share`, body?.length ? body : [], {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setsharequeryshow(false);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async saveAndEditFilterReportWizard(
    setLoading,
    navigate,
    body,
    setshowCretaeNewFilterpopup,
    getQueryFilterReportWizard,
    setToastConfig,
    toastConfig,
    setfield,
    field,
    setfilterdata
  ) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/ReportWizard/saveAndEditFilterReportWizard`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setshowCretaeNewFilterpopup(false);
          getQueryFilterReportWizard();

          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Added!",
            type: "success",
          });

          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Added!",
              type: "success",
            });
          }, 1400);

          setfield({
            ...field,
            filter: {
              name: val?.data?.data?.sName,
              id: val?.data?.data?.gStoredFilterId,
            },
          });
          setfilterdata(val?.data?.data?.sData);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async deleteFilterFilterReportWizard(setLoading, navigate, id, getQueryFilterReportWizard) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/ReportWizard/deleteFilter?filterId=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          getQueryFilterReportWizard(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }

  async getCompanyEntities(setLoading, navigate, setCompanyEntities) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=COMPANY ENTITY`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
        setCompanyEntities(
            val?.data?.data?.map((vall) => {
              return {
                name: vall.value,
                id: vall.id ? vall.id : "00000000-0000-0000-0000-000000000000",
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }

  async getSupervisors(setLoading, navigate, setSupervisors) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/ReportWizard/get-supervisor-list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setSupervisors(val?.data?.data);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  }
}
