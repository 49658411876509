import { DateBox, LoadPanel, NumberBox, ScrollView, SelectBox } from "devextreme-react";
import { ColumnDropdown } from "../../../../../../components/Dropdownn";
import { handleGetLocal } from "../../../../../../services/auth-service";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "axios";
import { logOut } from "../../../../../../components/Logout";
import { getAccessData } from "../../../../../../utils/getAccessPermission";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        // reset={reset}
        disabled={disabled}
      />
    </>
  );
};

const dayofMonthData = [
  { name: "1", id: "1" },
  { name: "2", id: "2" },
  { name: "3", id: "3" },
  { name: "4", id: "4" },
  { name: "5", id: "5" },
  { name: "6", id: "6" },
  { name: "7", id: "7" },
  { name: "8", id: "8" },
  { name: "9", id: "9" },
  { name: "10", id: "10" },
  { name: "11", id: "11" },
  { name: "12", id: "12" },
  { name: "13", id: "13" },
  { name: "14", id: "14" },
  { name: "15", id: "15" },
  { name: "16", id: "16" },
  { name: "17", id: "17" },
  { name: "18", id: "18" },
  { name: "19", id: "19" },
  { name: "20", id: "20" },
  { name: "21", id: "21" },
  { name: "22", id: "22" },
  { name: "23", id: "23" },
  { name: "24", id: "24" },
  { name: "25", id: "25" },
  { name: "26", id: "26" },
  { name: "27", id: "27" },
  { name: "28", id: "28" },
];

const quickSetData = [
  { name: "3 Month Term", id: "3" },
  { name: "6 Month Term", id: "6" },
  { name: "9 Month Term", id: "9" },
];

export const GeneratePaymentPopUp = ({
  generatePaymentPlanPopUp,
  setGeneratePaymentPlanPopUp,
  rowData,
  refreshGetGeneratePaymentPlanTableData,
  gObligationID,
  toastConfig,
  setToastConfig,
  monthStartDropdown,
}) => {
  const { userAccessData } = useSelector((store) => store.Home);
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const [fields, setFields] = useState({
    "Sentence Date": "",
    "Obligation Start Date": "",
    "Fee Code": "",
    "Monthly Payment": "",
    "Month Start": { name: "", id: "" },
    "Day Of Month": { name: "", id: "" },
    "Start Date Of Payment Plan": "",
    "Payment Duration": 0,
    "Quick Set": false,
    quickSetDropdown: { name: "", id: "" },
  });
  const [PaymentDurationvalidation, setPaymentDurationvalidation] = useState("");
  const [DayOfMonthvalidation, setDayOfMonthvalidation] = useState("");
  const [Amount, setAmount] = useState();
  const navigate = useNavigate();
  const pathname = handleGetLocal("pathURL");
  const [loading, setLoading] = useState(false);

  const formattedPrice = (price) => {
    let formatPrice = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(Math.abs(price));
    return price >= 0 ? formatPrice : `(${formatPrice})`;
  };

  const convertDateFormat = (dateString) => {
    if (dateString) {
      const dateObj = new Date(dateString);
      return dateObj.toLocaleDateString("en-US");
    } else {
      return "";
    }
  };

  function updateMonthTo01(dateString) {
    if (generatePaymentPlanPopUp === true) {
      const dateComponents = dateString?.split("-");
      dateComponents[2] = "01";
      const updatedDate = dateComponents?.join("-");
      return updatedDate;
    }
  }

  useEffect(() => {
    const findId = monthStartDropdown?.find((item) => item?.name === rowData?.sPaymentPlanStartMonthOffsetName);
    setAmount(
      rowData?.fAmount
        ? rowData?.iPaymentPlanDurationMonths === 0
          ? 0
          : rowData?.fAmount / rowData?.iPaymentPlanDurationMonths
        : 0
    );
    setFields({
      "Sentence Date": rowData?.dSentence,
      "Obligation Start Date": rowData?.dActive,
      "Fee Code": rowData?.sLocalLegalCodeFull,
      "Monthly Payment": rowData?.fAmount
        ? rowData?.iPaymentPlanDurationMonths === 0
          ? 0
          : rowData?.fAmount / rowData?.iPaymentPlanDurationMonths
        : 0,
      "Month Start": {
        name: rowData?.sPaymentPlanStartMonthOffsetName
          ? rowData?.sPaymentPlanStartMonthOffsetName
          : monthStartDropdown[0]?.name,
        id: findId?.id ? findId?.id : monthStartDropdown[0]?.id,
      },
      "Day Of Month": {
        name: String(rowData?.iPaymentPlanStartDay ? rowData?.iPaymentPlanStartDay : 1),
        id: String(rowData?.iPaymentPlanStartDay ? rowData?.iPaymentPlanStartDay : 1),
      },
      "Start Date Of Payment Plan": rowData?.dPaymentPlanStart
        ? rowData?.dPaymentPlanStart
        : updateMonthTo01(rowData?.dSentence),
      "Payment Duration": rowData?.iPaymentPlanDurationMonths,
      "Quick Set": false,
      quickSetDropdown: { name: "", id: "" },
    });
    setPaymentDurationvalidation("");
    setDayOfMonthvalidation("");
  }, [generatePaymentPlanPopUp]);

  const OnSave = async (data) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/docket/save-generate-payment-plan`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Successfully updated!",
          type: "success",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: "Successfully updated!",
            type: "success",
          });
        }, 1400);
        refreshGetGeneratePaymentPlanTableData();
        setGeneratePaymentPlanPopUp(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    setFields((prev) => ({
      ...prev,
      "Monthly Payment": rowData?.fAmount / fields?.quickSetDropdown?.id,
      "Payment Duration": fields?.quickSetDropdown?.id,
    }));
    setAmount(rowData?.fAmount / fields?.quickSetDropdown?.id);
  }, [fields?.quickSetDropdown?.id]);

  const save = () => {
    if (fields?.["Payment Duration"]) {
      setPaymentDurationvalidation("");
      if (fields["Quick Set"]) {
        gObligationID &&
          OnSave({
            obligationId: gObligationID,
            sentenceDate: fields["Sentence Date"],
            obligationStartDate: fields?.["Obligation Start Date"],
            feeCode: fields?.["Fee Code"],
            monthlyPayment: fields?.["Monthly Payment"],
            monthStart: fields?.["Month Start"].id,
            dayOfMonth: fields["Quick Set"] ? 2 : Number(fields?.["Day Of Month"].name),
            paymentPlanStartDate: fields?.["Start Date Of Payment Plan"],
            paymentDuration: fields?.["Payment Duration"],
          });
      } else {
        setDayOfMonthvalidation("");
        gObligationID &&
          OnSave({
            obligationId: gObligationID,
            sentenceDate: fields["Sentence Date"],
            obligationStartDate: fields?.["Obligation Start Date"],
            feeCode: fields?.["Fee Code"],
            monthlyPayment: fields?.["Monthly Payment"],
            monthStart: fields?.["Month Start"].id,
            dayOfMonth: fields["Quick Set"] ? 2 : Number(fields?.["Day Of Month"].name),
            paymentPlanStartDate: fields?.["Start Date Of Payment Plan"],
            paymentDuration: fields?.["Payment Duration"],
          });
      }
    } else {
      !fields?.["Payment Duration"] && setPaymentDurationvalidation("Payment Duration must be greater than 0.");
      !fields?.["Day Of Month"].name && setDayOfMonthvalidation("Pease Select Day Of Month");
    }
  };

  const getUserAccessPermissions = () => {
    const accessData = getAccessData(userAccessData, "OB11");
    setAddAccess(accessData?.Add);
    setEditAccess(accessData?.Edit);
    setViewAccess(accessData?.View);
  };

  useEffect(() => {
    getUserAccessPermissions();
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div className="generate-payment-popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_1306_381769)">
                  <path
                    d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1306_381769">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <p>Generate Payment Plan</p>
            </div>
            <p class="popup_header_title_para">OB.11</p>
          </div>
          <svg
            onClick={() => {
              setGeneratePaymentPlanPopUp(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        {/* <ScrollView style={{ width: "100%", height: "540px" }}> */}
         {viewAccess ? <div className="inputs-field-wrapper"
          style={{
            pointerEvents: (editAccess || addAccess) ? "auto" : "none",
            opacity: (editAccess || addAccess) ? 1 : 0.6,
            cursor: (editAccess || addAccess) ? "default" : "not-allowed"
          }}
         >
            <div className="generate-payment-text-container">
              <div className="generate-payment-text-content">
                <p className="fee-payment-content-info-para">Sentence Date</p>
                <span className="fee-payment-content-info-span">
                  {convertDateFormat(fields["Sentence Date"] ? fields["Sentence Date"] : "")}
                </span>
              </div>
              <div className="generate-payment-text-content">
                <p className="fee-payment-content-info-para">Obligation Start date</p>
                <span className="fee-payment-content-info-span">
                  {convertDateFormat(fields["Obligation Start Date"] ? fields["Obligation Start Date"] : "")}
                </span>
              </div>
            </div>
            <div className="generate-payment-text-container">
              <div className="generate-payment-text-content">
                <p className="fee-payment-content-info-para">Fee Code</p>
                <span className="fee-payment-content-info-span">{fields["Fee Code"] ? fields["Fee Code"] : ""}</span>
              </div>
              <div className="generate-payment-text-content">
                <p className="fee-payment-content-info-para">Monthly Payment</p>
                <span className="fee-payment-content-info-span">{formattedPrice(Amount ? Amount : 0)}</span>
              </div>
            </div>
            <div className="inputs-fields-container">
              <div className="inputs-field-content dropdown-inputs-field-content">
                <p className="edit_container_label">Month Start</p>
                <SelectBox
                  dataSource={monthStartDropdown}
                  valueExpr="name"
                  disabled={fields["Quick Set"]}
                  value={fields?.["Month Start"]?.name}
                  displayExpr="name"
                  searchEnabled={true}
                  searchExpr={"name"}
                  dropDownOptions={{ height: "180px" }}
                  onItemClick={(e) => {
                    const currentMonthStart = fields?.["Month Start"]?.name;
                    if (currentMonthStart !== e.itemData.name) {
                      const sentenceDate = new Date(fields["Start Date Of Payment Plan"]);
                      let newMonth = sentenceDate.getMonth() + (e.itemData.name === "Next Month" ? +1 : -1);
                      const newStartDate = new Date(sentenceDate.getFullYear(), newMonth, sentenceDate.getDate());
                      setFields({
                        ...fields,
                        "Month Start": {
                          name: e.itemData.name,
                          id: e.itemData.id,
                        },
                        "Start Date Of Payment Plan": convertDateFormat(newStartDate),
                      });
                    }
                  }}
                />
              </div>
              <div className="inputs-field-content dropdown-inputs-field-content">
                <p className="edit_container_label">Day of Month</p>
                <SelectBox
                  dataSource={dayofMonthData}
                  valueExpr="name"
                  disabled={fields["Quick Set"]}
                  value={fields?.["Day Of Month"]?.name}
                  displayExpr="name"
                  searchEnabled={true}
                  searchExpr={"name"}
                  dropDownOptions={{ height: "180px" }}
                  onItemClick={(e) => {
                    const sentenceDate = new Date(fields["Start Date Of Payment Plan"]);
                    const newStartDate = new Date(
                      sentenceDate.getFullYear(),
                      sentenceDate.getMonth(),
                      parseInt(e.itemData.name)
                    );
                    const formattedStartDate = newStartDate.toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    });
                    setFields({
                      ...fields,
                      "Start Date Of Payment Plan": formattedStartDate,
                      "Day Of Month": {
                        name: e.itemData.name,
                        id: e.itemData.id,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="inputs-fields-container">
              <div className="inputs-field-content dropdown-inputs-field-content">
                <p className="edit_container_label">Start Date of Payment Plan</p>
                <DateBox
                  disabled={!fields["Quick Set"]}
                  value={fields["Start Date Of Payment Plan"]}
                  onValueChange={(e) => {
                    setFields({
                      ...fields,
                      "Start Date Of Payment Plan": e ? e : null,
                    });
                  }}
                  useMaskBehavior={true}
                />
              </div>
              <div className="generate-check-box" style={{ marginTop: "11px" }}>
                <div className="generate-check-box">
                  <input
                    type="checkbox"
                    id="Quick Set"
                    checked={fields["Quick Set"]}
                    onClick={() => {
                      setFields({
                        ...fields,
                        "Quick Set": !fields["Quick Set"],
                      });
                    }}
                  />
                  <label htmlFor="Quick Set" className="edit_container_label">
                    Set Directly
                  </label>
                </div>
              </div>
            </div>
            <div className="inputs-fields-container">
              <div className="inputs-field-content dropdown-inputs-field-content">
                <p className="edit_container_label">
                  Payment Duration <span className="marks_mandatary">*</span>
                </p>
                <NumberBox
                  style={{ height: "40px", width: "203px" }}
                  onKeyDown={(e) => {
                    const { event } = e;
                    const str = event.key || String.fromCharCode(event.which);
                    if (/^[.,e]$/.test(str)) {
                      event.preventDefault();
                    }
                  }}
                  value={fields?.["Payment Duration"]}
                  min={0}
                  onValueChange={(e) => {
                    setPaymentDurationvalidation("");
                    e && setAmount(Amount / e);
                    setFields({ ...fields, "Payment Duration": e });
                  }}
                  placeholder={`Payment Duration`}
                />
                <p className="error_validation">{PaymentDurationvalidation}</p>
                <p className="input-red-label">Max Duration (in months)</p>
              </div>
              <div className="inputs-fields-container">
                <div className="inputs-field-content dropdown-inputs-field-content">
                  <p className="edit_container_label">Quick Set</p>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"quickSetDropdown"}
                    dropDownData={quickSetData}
                  />
                </div>
              </div>
            </div>
            <div className="popup_buttons">
              <button
                className="btn primary_btn"
                onClick={() => {
                  save();
                }}
              >
                Save
              </button>
              <button
                className="btn"
                onClick={() => {
                  setGeneratePaymentPlanPopUp(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div> : 'No view access'}
        {/* </ScrollView> */}
      </div>
    </>
  );
};
