import { DateBox, LoadPanel, NumberBox } from "devextreme-react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { ApiError } from "../../../../Error/ApiError";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../../../components/Logout";
import { handleGetLocal } from "../../../../../services/auth-service";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        reset={reset}
        margin={"7px"}
        disabled={disabled}
      />
    </>
  );
};

export const Drugtextcondition = ({
  setEditDRuogTextPOpuptogal,
  reset,
  toastConfig,
  setToastConfig,
  getTopDynamicDocketid,
  drugDropdownData,
  setreset,
}) => {
  const navigate = useNavigate();
  const pathname = handleGetLocal("pathURL");
  const [loading, setLoading] = useState(false);
  const [editdrugtest, seteditdrugtest] = useState({});
  const [errorhandling2, seterrorhandling2] = useState(false);
  const [StartDate, setStartDate] = useState("");
  const [Frequency, setFrequency] = useState("");
  const [County, setCounty] = useState("");

  const DrugTestbData = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/DrugTest/getDrugTestForDocket?DocketId=${getTopDynamicDocketid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seteditdrugtest(val?.data?.data);
          seterrorhandling2(false);
        } else {
          seterrorhandling2(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling2(true);
        }
      });
  };

  const save = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/DrugTest/addEditDrugTestCondition`,
        {
          TestingBy: fields?.none ? 0 : fields?.Company ? 1 : fields?.County ? 2 : 0,
          gDrugTestConditionId: editdrugtest?.gDrugTestConditionId
            ? editdrugtest?.gDrugTestConditionId
            : "7f0ce5ee-97e0-4456-9103-187ebb0edbe3",
          gEntryUserId: editdrugtest?.gEntryUserId
            ? editdrugtest?.gEntryUserId
            : "7f0ce5ee-97e0-4456-9103-187ebb0edbe3",
          gDocketId: getTopDynamicDocketid,
          gDrugTestConditionFrequencyId: fields?.Frequency?.id,
          dStart: fields?.["Start Date"],
          iQuantity: fields?.Quantity,
          bTestingByGpm: fields.Company ? 1 : fields.County ? 0 : null,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1400);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "No Record to delete!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "No Record to delete!",
              type: "success",
            });
          }, 1400);
        }
        resett();
        setreset(!reset);
        setEditDRuogTextPOpuptogal(false);
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "No Record to delete!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "No Record to delete!",
              type: "error",
            });
          }, 1400);
          resett();
          setreset(!reset);
          setEditDRuogTextPOpuptogal(false);
          setLoading(false);
        }
      });
  };

  const saveas = () => {
    if (!fields?.Company) {
      save();
    } else {
      if (fields?.["Start Date"] && fields?.Frequency?.id && fields?.Quantity) {
        setStartDate("");
        setFrequency("");
        setCounty("");
        save();
      } else {
        !fields?.["Start Date"] && setStartDate("Please Enter Start Date ");
        !fields?.Frequency?.id && setFrequency("Please Enter Frequency ");
        !fields?.Quantity && setCounty("Please Enter County ");
      }
    }
  };

  const [fields, setfields] = useState({
    none: editdrugtest?.bTestingByGpm == null ? true : false,
    Company: editdrugtest?.bTestingByGpm ? true : false,
    "Start Date": editdrugtest?.dStart ? editdrugtest?.dStart : "",
    Frequency: {
      name: editdrugtest?.sName,
      id: editdrugtest?.gDrugTestConditionFrequencyId,
    },
    Quantity: editdrugtest?.iQuantity,
    County: editdrugtest?.bTestingByGpm == false ? true : false,
  });

  const resett = () => {
    setStartDate("");
    setFrequency("");
    setCounty("");
    setfields({
      none: editdrugtest?.bTestingByGpm == null ? true : false,
      Company: editdrugtest?.bTestingByGpm ? true : false,
      "Start Date": editdrugtest?.dStart ? editdrugtest?.dStart : "",
      Frequency: {
        name: editdrugtest?.sName,
        id: editdrugtest?.gDrugTestConditionFrequencyId,
      },
      Quantity: editdrugtest?.iQuantity,
      County: editdrugtest?.bTestingByGpm == false ? true : false,
    });
  };

  useEffect(() => {
    setStartDate("");
    setFrequency("");
    setCounty("");
  }, [fields?.none, fields?.Company, fields?.County]);

  useEffect(() => {
    resett();
  }, [reset, editdrugtest]);

  useEffect(() => {
    getTopDynamicDocketid && DrugTestbData();
  }, [reset]);
  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      {errorhandling2 ? (
        <ApiError />
      ) : (
        <>
          <div style={{ border: "3px solid rgb(67, 66, 102)", height: "468px" }}>
            <div className="RecivePaymentModel_header">
              <div style={{ width: "150px" }} className="RecivePaymentModel_header_name">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="add_task" clipPath="url(#clip0_5501_7881)">
                    <path
                      id="Vector"
                      d="M21.2931 5.89019L11.2931 15.8902C10.9031 16.2802 10.2731 16.2802 9.88305 15.8902L7.05305 13.0602C6.66305 12.6702 6.66305 12.0402 7.05305 11.6502C7.44305 11.2602 8.07305 11.2602 8.46305 11.6502L10.5831 13.7702L19.8731 4.48019C20.2631 4.09019 20.8931 4.09019 21.2831 4.48019C21.6831 4.87019 21.6831 5.50019 21.2931 5.89019ZM12.0031 20.0002C7.29305 20.0002 3.52305 15.9102 4.05305 11.1002C4.44305 7.58019 7.17305 4.69019 10.6631 4.11019C12.4731 3.81019 14.1931 4.13019 15.6531 4.89019C16.0431 5.09019 16.5131 5.02019 16.8231 4.71019C17.3031 4.23019 17.1831 3.42019 16.5831 3.11019C15.1131 2.36019 13.4531 1.95019 11.6831 2.00019C6.54305 2.16019 2.27305 6.34019 2.01305 11.4702C1.72305 17.2402 6.30305 22.0002 12.0031 22.0002C13.2031 22.0002 14.3431 21.7902 15.4131 21.4002C16.0931 21.1502 16.2831 20.2702 15.7631 19.7502C15.4931 19.4802 15.0831 19.3802 14.7231 19.5202C13.8731 19.8302 12.9531 20.0002 12.0031 20.0002ZM19.0031 15.0002H17.0031C16.4531 15.0002 16.0031 15.4502 16.0031 16.0002C16.0031 16.5502 16.4531 17.0002 17.0031 17.0002H19.0031V19.0002C19.0031 19.5502 19.4531 20.0002 20.0031 20.0002C20.5531 20.0002 21.0031 19.5502 21.0031 19.0002V17.0002H23.0031C23.5531 17.0002 24.0031 16.5502 24.0031 16.0002C24.0031 15.4502 23.5531 15.0002 23.0031 15.0002H21.0031V13.0002C21.0031 12.4502 20.5531 12.0002 20.0031 12.0002C19.4531 12.0002 19.0031 12.4502 19.0031 13.0002V15.0002Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5501_7881">
                      <rect width="24" height="24" fill="black" />
                    </clipPath>
                  </defs>
                </svg>
                <p style={{ marginLeft: "-10px" }}>Drug Test Conditions </p>
              </div>
              <svg
                onClick={() => {
                  setEditDRuogTextPOpuptogal(false);
                }}
                style={{
                  cursor: "pointer",
                  marginTop: "17px",
                  marginRight: "2%",
                }}
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="Drugtextcondition">
              <div style={{ marginTop: "2%", display: "flex" }}>
                <p>Testing By</p>
                <div className="Drugtextcondition_sub">
                  <div style={{ display: "flex" }}>
                    <input
                      checked={fields.none}
                      onChange={() => {
                        setfields({
                          ...fields,
                          none: true,
                          Company: false,
                          County: false,
                        });
                      }}
                      id="None"
                      name="seleect"
                      value={"None"}
                      type="radio"
                    />
                    <p style={{ marginLeft: "10px", marginTop: "4px" }}>None</p>
                  </div>
                  <div>
                    <div style={{ display: "flex" }}>
                      <input
                        id="Company/Internal"
                        name="seleect"
                        value={"Company/Internal"}
                        type="radio"
                        onChange={() => {
                          setfields({
                            ...fields,
                            none: false,
                            Company: true,
                            County: false,
                          });
                        }}
                        checked={fields.Company}
                      />
                      <p style={{ marginLeft: "10px", marginTop: "4px" }}>Company/Internal</p>
                    </div>
                    <div
                      style={{
                        marginLeft: "13%",
                        marginTop: "15px",
                        border: "1px solid #c7c7c7",
                        padding: "20px",
                        borderRadius: "7px",
                      }}
                    >
                      <p>Testing Condition</p>
                      <div style={{ marginTop: "10px" }}>
                        <div style={{ marginTop: "5px" }}>
                          <p>Start Date</p>
                          <DateBox
                            disabled={!fields?.Company}
                            style={{ marginTop: "2%" }}
                            value={fields["Start Date"]}
                            onValueChange={(e) => {
                              setStartDate("");
                              setfields({
                                ...fields,
                                "Start Date": e ? e : null,
                              });
                            }}
                            useMaskBehavior={true}
                          />
                          <p
                            style={{
                              color: "red",
                              position: "absolute",
                              fontWeight: "400",
                            }}
                          >
                            {StartDate}
                          </p>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <p>Frequency</p>
                          <DropDown
                            disabled={!fields?.Company}
                            setfields={setfields}
                            fields={fields}
                            fieldNmae={"Frequency"}
                            dropDownData={drugDropdownData}
                            reset={setFrequency}
                          />
                          <p
                            style={{
                              color: "red",
                              position: "absolute",
                              fontWeight: "400",
                            }}
                          >
                            {Frequency}
                          </p>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <p>Quantity</p>
                          <NumberBox
                            disabled={!fields?.Company}
                            onKeyDown={(e) => {
                              const { event } = e;
                              const str = event.key || String.fromCharCode(event.which);
                              if (/^[.,e]$/.test(str)) {
                                event.preventDefault();
                              }
                            }}
                            style={{ marginTop: "2%" }}
                            value={fields?.Quantity}
                            min={0}
                            onValueChange={(e) => {
                              setCounty("");
                              setfields({
                                ...fields,
                                Quantity: e,
                              });
                            }}
                          />
                          <p
                            style={{
                              color: "red",
                              position: "absolute",
                              fontWeight: "400",
                            }}
                          >
                            {County}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <input
                      checked={fields.County}
                      id="None"
                      name="seleect"
                      value={"None"}
                      type="radio"
                      onChange={() => {
                        setfields({
                          ...fields,
                          none: false,
                          Company: false,
                          County: true,
                        });
                      }}
                    />
                    <p style={{ marginLeft: "10px", marginTop: "4px" }}>County/other</p>
                  </div>
                </div>
              </div>
              <div
                className="popup_buttons"
                style={{
                  marginTop: "-10px",
                }}
              >
                <button
                  className="primary_btn"
                  onClick={() => {
                    saveas();
                  }}
                >
                  Save
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    setEditDRuogTextPOpuptogal(false);
                    resett();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
