import { DateBox, LoadPanel, ScrollView } from "devextreme-react";
import React, { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../../components/Dropdownn";
import { Table1 } from "../../../Administration/Table1/Table1";
import { logTablecoloumn } from "./Data";
import { ApiError } from "../../../Error/ApiError";
import { logOut } from "../../../../components/Logout";
import axios from "axios";
import { handleGetLocal } from "../../../../services/auth-service";
import { useNavigate } from "react-router-dom";
import { MultipalDropdown } from "../../../../components/MultipalDRopDown";
import { useDispatch } from "react-redux";
import { setaddquickadd } from "../../../../reducer/ParticipateReducer";
import { useSelector } from "react-redux";
import { Table2 } from "../../../../components/Table2/Table2";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
      />
    </>
  );
};

const setTableData = (dataa) => {
  const arr = [];
  dataa.map((val, i) => {
    arr.push({
      Date: val?.date,
      User: val?.user,
      Note: val?.note,
      ID: i,
    });
  });

  return arr;
};

export const QuickAddTaskPopup = ({
  setAddQuickPopup,
  addQuickPopup,
  errorhandling,
  loading,
  Dropdowndata,
  toastConfig,
  setToastConfig,
  userinfo,
  reset,
  setreset,
  titalNmae,
  gettaskEditDAta,
  addTaskPopupType
}) => {
  const [quickAddTaskTab, setQuickAddTaskTab] = useState(1);
  const [statusesdropdown, setstatusesdropdown] = useState([]);
  const [categoriesdropdown, setcategoriesdropdown] = useState([]);
  const [personsdropdown, setpersonsdropdown] = useState([]);
  const [assignedTodropdown, setassignedTodropdown] = useState([]);
  const [Loading, setLoading] = useState();
  const { addquickadd } = useSelector((store) => store.participate);
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    Status: { name: "", id: "" },
    Person: { name: "", id: "" },
    "Assigned To": { name: "", id: "" },
    Category: { name: "", id: "" },
    Title: "",
    "Due Date": "",
    "Include Inactive": "",
    Task: "",
  });
  const dispatch = useDispatch();
  const [prewviewtogal, setprewviewtogal] = useState(undefined);

  const [titalvalidation, settitalvalidation] = useState("");
  const [AssignedTovalidation, setAssignedTovalidation] = useState("");
  const [Statustitalvalidation, setStatustitalvalidation] = useState("");
  const [Categoryvalidation, setCategoryvalidation] = useState("");
  const [DueDatevalidation, setDueDatevalidation] = useState("");
  const [taskvalidation, settaskvalidation] = useState("");
  const [data, setdata] = useState([]);

  const pathname = handleGetLocal("pathURL");
  const saveapi = async (body) => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/task/save-task`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: gettaskEditDAta?.taskId ? "Successfully Updated!" : "Successfully Added!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: gettaskEditDAta?.taskId ? "Successfully Updated!" : "Successfully Added!",
              type: "success",
            });
          }, 1400);
          setAddQuickPopup(false);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Network Error!",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Network Error!",
              type: "error",
            });
          }, 1400);
        }

        // resetFUnc();
        // getRevocationsDataApi();
        dispatch(setaddquickadd(!addquickadd));
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const save = () => {
    if (
      fields["Due Date"] &&
      fields?.Task &&
      fields?.Title &&
      fields["Assigned To"].id &&
      fields?.Category.id &&
      fields?.Status?.id
    ) {
      setDueDatevalidation("");
      settaskvalidation("");
      settitalvalidation("");
      setAssignedTovalidation("");
      setCategoryvalidation("");
      setStatustitalvalidation("");
      saveapi({
        taskId: gettaskEditDAta?.taskId,
        dueDate: fields["Due Date"],
        note: fields?.Task,
        title: fields?.Title,
        assignedToId: fields["Assigned To"].id,
        categoryId: fields?.Category.id,
        statusId: fields?.Status?.id,
        personId: userinfo?.gPersonId,
      });
    } else {
      !fields["Due Date"] && setDueDatevalidation("Please Select Due Date");
      !fields?.Task && settaskvalidation("Please Enter Task");
      !fields?.Title && settitalvalidation("Please Enter Title");
      !fields["Assigned To"].id && setAssignedTovalidation("Please Select Assigned To");
      !fields?.Category.id && setCategoryvalidation("Please Select Category");
      !fields?.Status?.id && setStatustitalvalidation("Please Select Status");
    }
  };

  const getLOgApi = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/task/get-task-logs?taskId=${gettaskEditDAta?.taskId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setdata(setTableData(val.data.data));
        } else {
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
        }
      });
  };

  useEffect(() => {
    if (addTaskPopupType === "Add") {
      setFields({
        Status: { name: "", id: "" },
        Person: { name: "", id: "" },
        "Assigned To": { name: "", id: "" },
        Category: { name: "", id: "" },
        Title: "",
        "Due Date": "",
        "Include Inactive": "",
        Task: "",
      });
      setQuickAddTaskTab(1);
      setDueDatevalidation("");
      settaskvalidation("");
      settitalvalidation("");
      setAssignedTovalidation("");
      setCategoryvalidation("");
      setStatustitalvalidation("");
    }
  }, [addQuickPopup]);

  const resetFUnc = () => {
    setQuickAddTaskTab(1);
    setDueDatevalidation("");
    settaskvalidation("");
    settitalvalidation("");
    setAssignedTovalidation("");
    setCategoryvalidation("");
    setStatustitalvalidation("");
    const data = categoriesdropdown?.find((val) => {
      if (val?.id == "FADBA414-3E35-423C-9ACF-6D428FA4CB72") {
        return val;
      }
    });
    const assignto = assignedTodropdown?.find((val) => {
      if (val?.assignedToId == "44D5C4C6-4E61-4CD2-8A78-0BA1B9917E72") {
        return val;
      }
    });
    setFields({
      Status: {
        name: gettaskEditDAta?.Status ? gettaskEditDAta?.Status : statusesdropdown?.[0]?.name,
        id: gettaskEditDAta?.statusId ? gettaskEditDAta?.statusId : statusesdropdown?.[0]?.id,
      },
      "Assigned To": {
        name: gettaskEditDAta?.["Assigned To"] ? gettaskEditDAta?.["Assigned To"] : assignto?.name,
        id: gettaskEditDAta?.assignedToId ? gettaskEditDAta?.assignedToId : assignto?.assignedToId,
      },
      Category: {
        name: gettaskEditDAta?.Category ? gettaskEditDAta?.Category : data?.name,
        id: gettaskEditDAta?.categoryId ? gettaskEditDAta?.categoryId : data?.id,
      },
      Title: titalNmae ? titalNmae : gettaskEditDAta?.Title ? gettaskEditDAta?.Title : "",
      "Due Date": gettaskEditDAta?.["Due Date"] ? gettaskEditDAta?.["Due Date"] : "",
      Task: gettaskEditDAta?.Note ? gettaskEditDAta?.Note : "",
    });
  };

  useEffect(() => {
    resetFUnc();
  }, [reset]);
  useEffect(() => {
    Dropdowndata?.statuses?.length &&
      setstatusesdropdown(
        Dropdowndata?.statuses?.map((val) => {
          return { name: val?.value, id: val?.key };
        })
      );
    Dropdowndata?.categories?.length &&
      setcategoriesdropdown(
        Dropdowndata?.categories?.map((val) => {
          return { name: val?.value, id: val?.key };
        })
      );
    Dropdowndata?.persons?.length &&
      setpersonsdropdown(
        Dropdowndata?.persons?.map((val) => {
          return { name: val?.name, id: val?.personId };
        })
      );
    console.log(Dropdowndata?.assignedTo);
    Dropdowndata?.assignedTo?.length &&
      setassignedTodropdown(
        Dropdowndata?.assignedTo?.map((val) => {
          return val;
        })
      );
  }, [Dropdowndata]);
  useEffect(() => {
    gettaskEditDAta?.taskId && getLOgApi();
  }, [gettaskEditDAta?.taskId, reset]);

  return (
    <>
      {errorhandling ? (
        <ApiError />
      ) : (
        <div className="quick_add_task">
          <LoadPanel shadingColor="true" visible={loading} delay={10} />
          <LoadPanel shadingColor="true" visible={Loading} delay={10} />
          <ScrollView style={{ height: "827px", border: "3px solid rgb(67, 66, 102)" }}>
            <div className="popup_header">
              <div className="popup_header_title">
                <div class="popup_header_title_data">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clipPath="url(#clip0_651_351767)">
                      <path
                        d="M21.2931 5.89019L11.2931 15.8902C10.9031 16.2802 10.2731 16.2802 9.88305 15.8902L7.05305 13.0602C6.66305 12.6702 6.66305 12.0402 7.05305 11.6502C7.44305 11.2602 8.07305 11.2602 8.46305 11.6502L10.5831 13.7702L19.8731 4.48019C20.2631 4.09019 20.8931 4.09019 21.2831 4.48019C21.6831 4.87019 21.6831 5.50019 21.2931 5.89019ZM12.0031 20.0002C7.29305 20.0002 3.52305 15.9102 4.05305 11.1002C4.44305 7.58019 7.17305 4.69019 10.6631 4.11019C12.4731 3.81019 14.1931 4.13019 15.6531 4.89019C16.0431 5.09019 16.5131 5.02019 16.8231 4.71019C17.3031 4.23019 17.1831 3.42019 16.5831 3.11019C15.1131 2.36019 13.4531 1.95019 11.6831 2.00019C6.54305 2.16019 2.27305 6.34019 2.01305 11.4702C1.72305 17.2402 6.30305 22.0002 12.0031 22.0002C13.2031 22.0002 14.3431 21.7902 15.4131 21.4002C16.0931 21.1502 16.2831 20.2702 15.7631 19.7502C15.4931 19.4802 15.0831 19.3802 14.7231 19.5202C13.8731 19.8302 12.9531 20.0002 12.0031 20.0002ZM19.0031 15.0002H17.0031C16.4531 15.0002 16.0031 15.4502 16.0031 16.0002C16.0031 16.5502 16.4531 17.0002 17.0031 17.0002H19.0031V19.0002C19.0031 19.5502 19.4531 20.0002 20.0031 20.0002C20.5531 20.0002 21.0031 19.5502 21.0031 19.0002V17.0002H23.0031C23.5531 17.0002 24.0031 16.5502 24.0031 16.0002C24.0031 15.4502 23.5531 15.0002 23.0031 15.0002H21.0031V13.0002C21.0031 12.4502 20.5531 12.0002 20.0031 12.0002C19.4531 12.0002 19.0031 12.4502 19.0031 13.0002V15.0002Z"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <p>Quick-Add task</p>
                </div>
                <p class="popup_header_title_para">TM.2</p>
              </div>
              <svg
                onClick={() => {
                  setAddQuickPopup(false);
                }}
                style={{
                  cursor: "pointer",
                }}
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                  fill="white"
                />
              </svg>
            </div>
            <div style={{ gap: 10 }} className="quick_add_task_container">
              <div className="tabs_container">
                <p
                  className={
                    quickAddTaskTab === 1 ? "tabs_container_tab tabs_container_tab_active" : "tabs_container_tab"
                  }
                  onClick={() => {
                    setQuickAddTaskTab(1);
                  }}
                >
                  Task
                </p>
                <p
                  className={
                    quickAddTaskTab === 2 ? "tabs_container_tab tabs_container_tab_active" : "tabs_container_tab"
                  }
                  onClick={() => {
                    setQuickAddTaskTab(2);
                  }}
                >
                  Log
                </p>
                {gettaskEditDAta && <p style={{background: 'lightgrey', padding:'5px 10px', borderRadius:'5px'}}>{gettaskEditDAta['Associated Person']}</p>}
              </div>
              {quickAddTaskTab === 1 && (
                <>
                  <div>
                    <div className="collectionpopupBLOCK2">
                      <div className="collectionpopupBLOCK2inputt">
                        <p className="edit_container_label">
                          Title <span style={{ fontSize: "15px" }}>*</span>
                        </p>
                        <input
                          style={{ marginTop: "3.3%" }}
                          id="daysCompleted"
                          name="daysCompleted"
                          type="text"
                          placeholder="Enter Title"
                          className="edit_container_input"
                          onChange={(e) => {
                            settitalvalidation("");
                            setFields({ ...fields, Title: e.target.value });
                          }}
                          value={fields?.Title}
                        />
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            position: "absolute",
                            fontWeight: "500",
                          }}
                        >
                          {titalvalidation}
                        </p>
                      </div>
                      <div className="collectionpopupBLOCK2inputt">
                        <p className="edit_container_label">
                          Assigned To <span style={{ fontSize: "15px" }}>*</span>
                        </p>
                        <MultipalDropdown
                          coloum={[
                            { key: "Name", pair: "name" },
                            { key: "Type", pair: "type" },
                            { key: "Location", pair: "location" },
                            { key: "id", pair: "assignedToId" },
                          ]}
                          dataSource={assignedTodropdown}
                          setField={setFields}
                          field={fields}
                          fieldname={"Assigned To"}
                          mainFieldName={"Name"}
                          reset={setAssignedTovalidation}
                          showFilter={false}
                        />
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            position: "absolute",
                          }}
                        >
                          {AssignedTovalidation}
                        </p>
                      </div>
                    </div>
                    <div className="collectionpopupBLOCK2">
                      <div className="collectionpopupBLOCK2inputt">
                        <p className="edit_container_label">
                          Status <span style={{ fontSize: "15px" }}>*</span>
                        </p>
                        <DropDown
                          setfields={setFields}
                          dropDownData={statusesdropdown}
                          fieldNmae={"Status"}
                          fields={fields}
                          reset={setStatustitalvalidation}
                        />
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            position: "absolute",
                          }}
                        >
                          {Statustitalvalidation}
                        </p>
                      </div>
                      <div className="collectionpopupBLOCK2inputt">
                        <p className="edit_container_label">
                          Category <span style={{ fontSize: "15px" }}>*</span>
                        </p>
                        <DropDown
                          setfields={setFields}
                          fields={fields}
                          dropDownData={categoriesdropdown}
                          fieldNmae={"Category"}
                          reset={setCategoryvalidation}
                        />
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            position: "absolute",
                          }}
                        >
                          {Categoryvalidation}
                        </p>
                      </div>
                    </div>
                    <div className="collectionpopupBLOCK2">
                      <div className="collectionpopupBLOCK2inputt">
                        <p className="edit_container_label">
                          Due Date <span style={{ fontSize: "15px" }}>*</span>
                        </p>

                        <DateBox
                          style={{ marginTop: "3.5%" }}
                          value={fields["Due Date"]}
                          onValueChange={(e) => {
                            setDueDatevalidation("");
                            setFields({
                              ...fields,
                              "Due Date": e ? e : null,
                            });
                          }}
                          useMaskBehavior={true}
                        />
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            position: "absolute",
                          }}
                        >
                          {DueDatevalidation}
                        </p>
                      </div>
                    </div>
                    <div className="edit_container_first" style={{ justifyContent: "end" }}></div>
                    <div className="collectionpopupBLOCK2">
                      <div className="collectionpopupBLOCK2inputt">
                        <p className="edit_container_label">
                          Task <span style={{ fontSize: "15px" }}>*</span>
                        </p>
                        <textarea
                          value={fields?.Task}
                          onChange={(e) => {
                            settaskvalidation("");
                            setFields({ ...fields, Task: e.target.value });
                          }}
                          name="remark"
                          id="remark"
                          placeholder="Write Task"
                          style={{ width: "336px", height: "140px" }}
                        ></textarea>
                        <p
                          style={{
                            color: "red",
                            fontWeight: "400",
                            position: "absolute",
                          }}
                        >
                          {taskvalidation}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {quickAddTaskTab === 2 && (
                <>
                  <Table2
                    Tablecoloum={logTablecoloumn}
                    tableRow={data}
                    editTable={false}
                    load={false}
                    coloumWidthText={"quickaddtask"}
                    height={"auto"}
                    mandatoryColoum={"Date"}
                    showTotalBlock={false}
                    preview={true}
                    prewviewtogal={prewviewtogal}
                  />
                </>
              )}
            </div>
            <div
              className="popup_buttons"
              style={{
                marginRight: "2%",
              }}
            >
              {quickAddTaskTab === 2 && (
                <button
                  className="btn"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    setprewviewtogal(!prewviewtogal);
                  }}
                >
                  Print
                  <svg
                    className="svg-blue-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_5985_7014)">
                      <path
                        d="M19 8H5C3.34 8 2 9.34 2 11V15C2 16.1 2.9 17 4 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H20C21.1 17 22 16.1 22 15V11C22 9.34 20.66 8 19 8ZM15 19H9C8.45 19 8 18.55 8 18V14H16V18C16 18.55 15.55 19 15 19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM17 3H7C6.45 3 6 3.45 6 4V6C6 6.55 6.45 7 7 7H17C17.55 7 18 6.55 18 6V4C18 3.45 17.55 3 17 3Z"
                        fill="#424167"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5985_7014">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              )}
              <button
                onClick={() => {
                  save();
                }}
                className="btn primary_btn"
              >
                Save
              </button>
              <button
                className="btn"
                onClick={() => {
                  setAddQuickPopup(false);
                  setreset(!reset);
                }}
              >
                Cancel
              </button>
            </div>
          </ScrollView>
        </div>
      )}
    </>
  );
};
