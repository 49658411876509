import { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { TextBox, DateBox, NumberBox } from "devextreme-react";
import { EMFunction } from "./EMFunction";
import { useNavigate } from "react-router-dom";
import { LoadPanel } from "devextreme-react";
import { Toster } from "../../../../../components/Toster/Toster";
import { Popup } from "devextreme-react";
import { Conformation } from "./Conformation";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        margin={"7px"}
        reset={reset}
      />
    </>
  );
};

const ConformationPopup = ({
  ConformationPopupshow,
  setConformationPopupshow,
  onSave,
}) => {
  return (
    <>
      <Popup
        visible={ConformationPopupshow}
        onHiding={() => {
          setConformationPopupshow(false);
        }}
        showTitle={false}
        width={530}
        height={250}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <Conformation
          ConformationPopupshow={ConformationPopupshow}
          setConformationPopupshow={setConformationPopupshow}
          onSave={onSave}
        />
      </Popup>
    </>
  );
};

export const DetailTab = ({
  rowleveldata,
  setEMPriodpopshow,
  getEmDataApi,
  setemperiodsucces,
  emperiodsucces,
  EMPriodpopshow,
  fields,
  setfields,
  setQuickAddTaskTab,
  parentRowData,
  emPopupType,
}) => {
  const clas = new EMFunction();
  const [loading, setLoading] = useState(false);
  const [ConformationPopupshow, setConformationPopupshow] = useState(false);
  const navigate = useNavigate();
  const [EndReasondropdown, setEndReasondropdown] = useState([]);
  const [EMTypedropdown, setEMTypedropdown] = useState([]);
  const [startdatevalidation, setstartdatevalidation] = useState("");
  const [emtypevalidation, setemtypevalidation] = useState("");
  const [createperiod, setcreateperiod] = useState(false);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    clas.getDropdownApi(
      setLoading,
      navigate,
      setEndReasondropdown,
      "EM End Reason"
    );
    clas.getmasterdropdown(setLoading, navigate, setEMTypedropdown);
  }, []);

  

  const onSave = () => {
    if (fields?.["Start Date"] && fields?.["EM Type"]?.id) {
      setstartdatevalidation("");
      setemtypevalidation("");
      if (!createperiod) {
        clas?.saveEMPeriod(
          setLoading,
          navigate,
          {
            GEmPeriodId: !rowleveldata?.gEmPeriodId
              ? "00000000-0000-0000-0000-000000000000"
              : rowleveldata?.gEmPeriodId,
            GEntryUserId: localStorage.getItem("gUserId"),
            GDocketId: parentRowData?.userinfo?.gDocketId
              ? parentRowData?.userinfo?.gDocketId
              : rowleveldata?.userinfo?.gDocketId,
            GEmTypeId: fields?.["EM Type"]?.id,
            DStart: fields?.["Start Date"],
            DEnd: fields?.["End Date"],
            GEmEndReasonId: fields?.["End Reason"]?.id,
            SComment: fields?.Comment,
          },
          setToastConfig,
          toastConfig,
          getEmDataApi,
          setEMPriodpopshow,
          setConformationPopupshow
        );
      } else {
        craeteperiod();
      }
    } else {
      !fields?.["Start Date"]
        ? setstartdatevalidation("Please Select Start Date")
        : setstartdatevalidation("");
      !fields?.["EM Type"]?.id
        ? setemtypevalidation("Please Select EM Type")
        : setemtypevalidation("");
    }
  };

  const craeteperiod = () => {
    clas.createPeriods(
      setLoading,
      navigate,
      {
        sOffenderNumber: parentRowData?.userinfo?.sOffenderNumber
          ? parentRowData?.userinfo?.sOffenderNumber
          : rowleveldata?.userinfo?.sOffenderNumber,
        sLastFirst: parentRowData?.userinfo?.sPersonNameLastFirst
          ? parentRowData?.userinfo?.sPersonNameLastFirst
          : rowleveldata?.userinfo?.sPersonNameLastFirst,
        sDocketNumberCourtCounts: parentRowData?.userinfo
          ?.sDocketNumberCourtCounts
          ? parentRowData?.userinfo?.sDocketNumberCourtCounts
          : rowleveldata?.userinfo?.sDocketNumberCourtCounts,
        dStart: fields?.["Start Date"],
        dEnd: fields?.["End Date"],
        num: null,
        sEMType: fields?.["EM Type"]?.id,
        gEMEndReasonID: fields?.["End Reason"]?.id,
        sComment: fields?.["Comment"],
      },
      setemperiodsucces,
      setConformationPopupshow,
      setQuickAddTaskTab,
      rowleveldata
    );
  };

  useEffect(() => {
    setstartdatevalidation("");
    setemtypevalidation("");
  }, [EMPriodpopshow]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <ConformationPopup
        ConformationPopupshow={ConformationPopupshow}
        setConformationPopupshow={setConformationPopupshow}
        onSave={onSave}
      />
      <Toster
        message={toastConfig.message}
        type={toastConfig.type}
        visible={toastConfig.isVisible}
      />
      <div>
        <div className="AddDocketBLockfield">
          <p>Offender</p>
          <p style={{ width: "490px" }}>
            {parentRowData?.userinfo?.sOffenderNumber
              ? parentRowData?.userinfo?.sOffenderNumber
              : rowleveldata?.userinfo?.sOffenderNumber}
            -
            {parentRowData?.userinfo?.sPersonNameLastFirst
              ? parentRowData?.userinfo?.sPersonNameLastFirst
              : rowleveldata?.userinfo?.sPersonNameLastFirst}
          </p>
        </div>

        <div className="AddDocketBLockfield">
          <p>Docket</p>
          <p style={{ width: "490px" }}>
            {parentRowData?.userinfo?.sDocketNumberCourtCounts
              ? parentRowData?.userinfo?.sDocketNumberCourtCounts
              : rowleveldata?.userinfo?.sDocketNumberCourtCounts}
          </p>
        </div>

        <div className="AddDocketBLockfield">
          <p>
            Start Date <span className="marks_mandatary">*</span>
          </p>
          <DateBox
            value={fields?.["Start Date"]}
            onValueChange={(e) => {
              setstartdatevalidation("");
              setfields({
                ...fields,
                "Start Date": e ? e : null,
              });
            }}
            useMaskBehavior={true}
          />
        </div>
        <p
          style={{
            color: "red",
            fontWeight: "400",
            marginLeft: "27.5%",
          }}
        >
          {startdatevalidation}
        </p>
        <div
          style={{ marginTop: "20px", marginLeft: "27.5%" }}
          className="AddDocketBLockfield"
        >
          <NumberBox
            style={{ width: "10%" }}
            onKeyDown={(e) => {
              const { event } = e;
              const str = event.key || String.fromCharCode(event.which);
              if (/^[.,e]$/.test(str)) {
                event.preventDefault();
              }
            }}
            defaultValue={0}
            onValueChange={(e) => {
              setfields({ ...fields, days: e });
            }}
            isValid={true}
            value={fields?.days}
          />
          <button
            onClick={() => {
              if (!fields || !fields["Start Date"] || !fields.days) {
                return;
              }

              const startDate = new Date(fields["Start Date"]);
              const daysToAdd = parseInt(fields.days, 10);

              if (isNaN(daysToAdd)) {
                return;
              }

              const endDate = new Date(startDate);
              endDate.setDate(startDate.getDate() + daysToAdd);
              const formattedEndDate = endDate.toISOString().split("T")[0];

              setfields({
                ...fields,
                "End Date": formattedEndDate,
              });
            }}
            style={{ width: "auto", marginLeft: "10px" }}
          >
            Add Days
          </button>
          <button
            onClick={() => {
              if (!fields || !fields["Start Date"] || !fields.days) {
                return;
              }

              const startDate = new Date(fields["Start Date"]);
              const monthsToAdd = parseInt(fields.days, 10);

              if (isNaN(monthsToAdd)) {
                return;
              }

              const endDate = new Date(startDate);
              endDate.setMonth(startDate.getMonth() + monthsToAdd);
              const formattedEndDate = endDate.toISOString().split("T")[0];

              setfields({
                ...fields,
                "End Date": formattedEndDate,
              });
            }}
            style={{ width: "auto", marginLeft: "10px" }}
          >
            Add Months
          </button>
        </div>

        <div className="AddDocketBLockfield">
          <p>End Date</p>
          <DateBox
            style={{ marginTop: "3%" }}
            value={fields?.["End Date"]}
            onValueChange={(e) => {
              setfields({
                ...fields,
                "End Date": e ? e : null,
              });
            }}
            useMaskBehavior={true}
          />
        </div>

        <div className="AddDocketBLockfield">
          <p>
            EM Type <span className="marks_mandatary">*</span>
          </p>
          <DropDown
            dropDownData={EMTypedropdown}
            fieldNmae={"EM Type"}
            fields={fields}
            setfields={setfields}
            reset={setemtypevalidation}
          />
        </div>
        <p
          style={{
            color: "red",
            fontWeight: "400",
            marginLeft: "27.5%",
          }}
        >
          {emtypevalidation}
        </p>

        <div className="AddDocketBLockfield">
          <p>End Reason</p>
          <DropDown
            dropDownData={EndReasondropdown}
            fieldNmae={"End Reason"}
            fields={fields}
            setfields={setfields}
          />
        </div>

        <div style={{ marginTop: "3%" }} className="AddDocketBLockfield">
          <p>Comment</p>
          <textarea
            value={fields?.Comment}
            onChange={(e) => {
              setfields({ ...fields, Comment: e.target.value });
            }}
            style={{ width: "50%" }}
          />
        </div>
      </div>
      {!emperiodsucces && (
        <button
          onClick={() => {
            if (fields?.["Start Date"] && fields?.["EM Type"]?.id) {
              setstartdatevalidation("");
              setemtypevalidation("");
              setConformationPopupshow(true);
              setcreateperiod(true);
            } else {
              !fields?.["Start Date"]
                ? setstartdatevalidation("Please Select Start Date")
                : setstartdatevalidation("");
              !fields?.["EM Type"]?.id
                ? setemtypevalidation("Please Select EM Type")
                : setemtypevalidation("");
            }
          }}
        >
          Create Period
        </button>
      )}

      <div className="popup_buttons">
        <button
          onClick={() => {
            if (fields?.["Start Date"] && fields?.["EM Type"]?.id) {
              setstartdatevalidation("");
              setemtypevalidation("");
              setcreateperiod(false);
              setConformationPopupshow(true);
            } else {
              !fields?.["Start Date"]
                ? setstartdatevalidation("Please Select Start Date")
                : setstartdatevalidation("");
              !fields?.["EM Type"]?.id
                ? setemtypevalidation("Please Select EM Type")
                : setemtypevalidation("");
            }
          }}
          className="btn primary_btn"
        >
          Save
        </button>
        <button
          className="btn"
          onClick={() => {
            setEMPriodpopshow(false);
          }}
        >
          Cancel
        </button>
      </div>
    </>
  );
};
