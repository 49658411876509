import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "./../../carousel/css/slick.css";
import "./../../carousel/css/slick-theme.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import UserOnline from "./../middleSections/userOnline";
import CookieCrumbs from "./../middleSections/cookieCrumbs";
import CaseloadReview from "./../middleSections/caseloadReview";
import TaskManager from "./../middleSections/taskManager";
import LoadPanel from "devextreme-react/load-panel";
import { getAuthToken, handleGetLocal } from "../../services/auth-service";
import httpService from "../../services/http-service";
import { useDispatch, useSelector } from "react-redux";
import { setwigitArr } from "../../reducer/HomePageReducer";
import axios from "axios";
import { logOut } from "../../components/Logout";
import { useNavigate } from "react-router-dom";
import RevenueSummary from "./../middleSections/revenueSummary";

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

let count = 0;

export default function Home() {
  const hideWidgets = (name) => {
    menuCheck({ target: { name, checked: false } });
  };

  const widgets = [
    {
      id: "cookieCrumbs",
      gUserWidgetId: "",
      name: "GPM.Pronto.Home.usrWidget_CookieCrumb",
      status: false,
      width: 400,
      height: 400,
      order: 5,
    },
    {
      id: "userOnline",
      gUserWidgetId: "",
      name: "GPM.Pronto.Home.usrWidget_UsersOnline",
      status: false,
      width: 400,
      height: 400,
      order: 6,
    },
    {
      id: "caseloadReview",
      gUserWidgetId: "",
      name: "GPM.Pronto.Home.usrWidget_Caseload",
      status: false,
      component: <CaseloadReview hideWidgets={hideWidgets} />,
      width: 400,
      height: 400,
      order: 7,
    },
    {
      id: "taskManager",
      gUserWidgetId: "",
      name: "GPM.Pronto.Home.usrWidget_TaskManager",
      status: false,
      component: <TaskManager hideWidgets={hideWidgets} />,
      width: 400,
      height: 400,
      order: 8,
    },
    {
      id: "revenueSummary",
      gUserWidgetId: "",
      name: "GPM.Pronto.Home.usrWidget_RevenueSummary",
      status: false,
      component: <RevenueSummary hideWidgets={hideWidgets} />,
      width: 400,
      height: 400,
      order: 9,
    },
  ];
  
  const [loading, setLoading] = useState(false);
  const [isShowNotiB, setIsShowNotiB] = useState(false),
    [widgetMenu, setWidgetMenu] = useState(false),
    [isWidgetLock, setIsWidgetLock] = useState(false),
    [characters, updateCharacters] = useState(widgets),
    [showNoti, setShowNoti] = useState("");

  const ref = useRef();
  useOutsideClick(ref, () => {
    setWidgetMenu(false);
  });

  const showNotiButton = () => {
    localStorage.setItem("show_noti", isShowNotiB);
    setIsShowNotiB(!isShowNotiB);
  };

  const { wigitArr } = useSelector((store) => store.Home);

  const dispatch = useDispatch();
  const addList = () => {
    dispatch(setwigitArr(wigitArr + 1));
    return wigitArr + 1;
  };

  const menuCheck = async (e) => {
    let menuData = characters,
      { target: { name = "", checked = false } = {} } = e;
    menuData = menuData.map((item, index) => {
      if (item.id === name) {
        item.status = checked;
        saveSelectedWidget({
          name,
          checked,
          gUserWidgetId: item.gUserWidgetId,
          order: addList(e.target.name),
          width: item.width,
          height: item.height,
        });
      }
      return item;
    });

    updateCharacters(() => [...menuData]);
  },
    saveSize = (data) => {
      const { name = "", width = 0, height = 0 } = data,
        saveData = characters.find((item) => item.id === name);
      saveData?.gUserWidgetId &&
        saveSelectedWidget({
          name,
          checked: true,
          gUserWidgetId: saveData.gUserWidgetId,
          order: saveData.order,
          width,
          height,
        });
    };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateCharacters(() => [...items]);

    for (let index = 0; index < items.length; index++) {
      const { id, gUserWidgetId, status, width, height } = items[index];
      if (status)
        saveSelectedWidget({
          name: id,
          checked: true,
          gUserWidgetId,
          order: index + 1,
          width,
          height,
        });
    }
  };

  const getNotiData = async (e) => {
    let domainName = (url) =>
      url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
    let domailURL = window.location.href;
    let pathURL = domainName(domailURL);
    const token = getAuthToken();
    try {
      const response = await httpService.get(
        `api/Notification/GetNotification`,
        { headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` } }
      );
      if (response.data.isSuccess) {
        setShowNoti(response.data);
      } else {
      }
    } catch (error) { }
  };

  const selectWidgetData = async () => {
    setLoading(true);
    let domainName = (url) =>
      url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
    let domailURL = window.location.href;
    let pathURL = domainName(domailURL);
    const token = getAuthToken();
    try {
      const response = await httpService.get(`api/Account/chooseWidget`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
      });

      if (response.data.isSuccess) {
        setLoading(false);
        dispatch(
          setwigitArr(
            response?.data?.data.reduce((max, widget) => {
              return widget?.sWidgetOrder > max ? widget?.sWidgetOrder : max;
            }, 0)
          )
        );
        const data = response.data.data,
          chars = characters.map((item) => {
            const s = data.find((stuff) => stuff.name === item.id);
            if (s) {
              item.gUserWidgetId = s.gUserWidgetId;
              item.order = s.sWidgetOrder;
              item.status = true;
              item.width = s.iWidth < 20 ? 150 : s.iWidth;
              item.height = s.iHeight < 20 ? 150 : s.iHeight;
            }
            return item;
          }),
          sortedArray = sortArraybasedOnOrder([...chars]);
        updateCharacters(() => sortedArray);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const sortArraybasedOnOrder = (arr) => {
    return arr.sort(function (a, b) {
      var keyA = new Date(a.order),
        keyB = new Date(b.order);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  };

  const saveSelectedWidget = async ({
    name,
    checked,
    gUserWidgetId,
    order,
    width = 0,
    height = 0,
  }) => {
    setLoading(true);
    const domainName = (url) =>
      url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1],
      domailURL = window.location.href,
      pathURL = domainName(domailURL),
      token = getAuthToken(),
      body = {
        GUserWidgetId: gUserWidgetId,
        Name: name,
        iX: 0,
        iY: 0,
        iWidth: width,
        iHeight: height,
        iHomePage: 0,
        sWidgetOrder: checked ? order : 0,
      };

    try {
      const response = await httpService.post(
        `api/User/saveUserWidgetPersonalisation`,
        body,
        { headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` } }
      );
      if (response?.data?.isSuccess) {
        setLoading(false);
        selectWidgetData();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let showNotData = localStorage.getItem("show_noti");
    if (showNotData == null || showNotData === "false") {
      setIsShowNotiB(true);
    }
    if (showNotData === "true") {
      setIsShowNotiB(false);
    }
    getNotiData();
    selectWidgetData();
  }, []);

  const [cou, setcou] = useState(count);

  useEffect(() => {
    count = 0;
    characters?.map((val) => {
      if (val.status) {
        count = count + 1;
      }
    });
    setcou(count);
  }, [characters]);
  const pathname = handleGetLocal("pathURL");
  const [CookieCrumData, setCookieCrumData] = useState([]);
  const [errorHandling, seterrorHandling] = useState(false);
  const navigate = useNavigate();

  const getCookieCrum = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/cookie-crumbs/get-cookie-crumbs`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorHandling(false);
          setCookieCrumData(val.data.data);
        } else {
          seterrorHandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorHandling(true);
        }
      });
  };

  useEffect(() => {
    getCookieCrum();
  }, []);

  return (
    <>
      <div>
        <LoadPanel shadingColor="true" visible={loading} delay={10} />
        <div className="breadCrumb">
          <ul style={{ width: "200px" }}>
            <li>Home</li>
            <li className="active">Dashboard </li>
            <div
              className="screen_num"
              style={{ marginLeft: "2%", marginTop: ".2px" }}
            >
              <p style={{ marginTop: "1px" }}>D.1</p>
            </div>
          </ul>
          {isShowNotiB ? (
            <button className="showNotification" onClick={showNotiButton}>
              Show Notification
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_1491_2171)">
                  <path
                    d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1491_2171">
                    <rect width={24} height={24} fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          ) : (
            ""
          )}
        </div>
        {isShowNotiB ? (
          " "
        ) : (
          <>
            <div className={`sliderTipDays`}>
              <div className="closeIcon" onClick={showNotiButton}>
                <svg
                  className="svg-black-white"
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.2987 0.709971C12.9087 0.319971 12.2787 0.319971 11.8887 0.709971L6.99875 5.58997L2.10875 0.699971C1.71875 0.309971 1.08875 0.309971 0.69875 0.699971C0.30875 1.08997 0.30875 1.71997 0.69875 2.10997L5.58875 6.99997L0.69875 11.89C0.30875 12.28 0.30875 12.91 0.69875 13.3C1.08875 13.69 1.71875 13.69 2.10875 13.3L6.99875 8.40997L11.8887 13.3C12.2787 13.69 12.9087 13.69 13.2987 13.3C13.6887 12.91 13.6887 12.28 13.2987 11.89L8.40875 6.99997L13.2987 2.10997C13.6787 1.72997 13.6787 1.08997 13.2987 0.709971Z"
                    fill="black"
                    fillOpacity={1}
                  />
                </svg>
              </div>
              <div className="owl-carousel">
                <Slider {...settings}>
                  {showNoti?.data?.map((data, index) => {
                    return (
                      <div className="item" key={index}>
                        <h2>Tip of the day</h2>
                        <p>{data?.sTip}</p>
                      </div>
                    );
                  })}
                </Slider>
                <div className="owl-nav">
                  <button
                    type="button"
                    role="presentation"
                    className="owl-prev"
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    role="presentation"
                    className="owl-next"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="widgetsWrap">
          <div className="widgetsWrap">
            <div className="widgetNav widget_nav_sec">
              <ul>
                <li
                  onClick={() => {
                    setWidgetMenu(!widgetMenu);
                  }}
                  className={widgetMenu ? "active" : " "}
                  ref={ref}
                >
                  <div className="link">
                    <div>
                      Select Widgets ({cou}/5)
                      <svg
                        width={12}
                        height={8}
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.11875 1.29006L5.99875 5.17006L9.87875 1.29006C10.2688 0.900059 10.8988 0.900059 11.2888 1.29006C11.6788 1.68006 11.6788 2.31006 11.2888 2.70006L6.69875 7.29006C6.30875 7.68006 5.67875 7.68006 5.28875 7.29006L0.69875 2.70006C0.30875 2.31006 0.30875 1.68006 0.69875 1.29006C1.08875 0.910059 1.72875 0.900059 2.11875 1.29006Z"
                          fill="#424167"
                        />
                      </svg>
                    </div>
                    {widgetMenu ? (
                      <div className="dropwDownItem">
                        <ul>
                          <li>
                            <em className="check">
                              <input
                                type="checkbox"
                                checked={characters.find(
                                  (item) =>
                                    item.id === "cookieCrumbs" && item.status
                                )}
                                name="cookieCrumbs"
                                onClick={menuCheck}
                              />
                            </em>
                            <div className="icon">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M20.87 9.5C20.6 9 20 9 20 9H18V8C18 7 17 7 17 7H15V6C15 5 14 5 14 5H13V3C13 2 12 2 12 2C7.03 2 3 6.03 3 11C3 15.97 7.03 20 12 20C16.97 20 21 15.97 21 11C21 10.5 20.96 10 20.87 9.5ZM6.5 12C5.67 12 5 11.33 5 10.5C5 9.67 5.67 9 6.5 9C7.33 9 8 9.67 8 10.5C8 11.33 7.33 12 6.5 12ZM8 6.5C8 5.67 8.67 5 9.5 5C10.33 5 11 5.67 11 6.5C11 7.33 10.33 8 9.5 8C8.67 8 8 7.33 8 6.5ZM11 18C10.17 18 9.5 17.33 9.5 16.5C9.5 15.67 10.17 15 11 15C11.83 15 12.5 15.67 12.5 16.5C12.5 17.33 11.83 18 11 18ZM11.5 13C10.67 13 10 12.33 10 11.5C10 10.67 10.67 10 11.5 10C12.33 10 13 10.67 13 11.5C13 12.33 12.33 13 11.5 13ZM16.5 15C15.67 15 15 14.33 15 13.5C15 12.67 15.67 12 16.5 12C17.33 12 18 12.67 18 13.5C18 14.33 17.33 15 16.5 15ZM7 22H9V24H7V22ZM11 22H13V24H11V22ZM15 22H17V24H15V22Z"
                                  fill="black"
                                />
                              </svg>
                              Cookie Crumbs
                            </div>
                          </li>
                          <li>
                            <em className="check">
                              <input
                                type="checkbox"
                                checked={characters.find(
                                  (item) =>
                                    item.id === "userOnline" && item.status
                                )}
                                name="userOnline"
                                onClick={menuCheck}
                              />
                            </em>
                            <div className="icon">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V14C16 11.34 10.67 10 8 10Z"
                                  fill="black"
                                  fillOpacity={1}
                                />
                              </svg>
                              Users Online
                            </div>
                          </li>
                          <li>
                            <em className="check">
                              <input
                                type="checkbox"
                                checked={characters.find(
                                  (item) =>
                                    item.id === "caseloadReview" && item.status
                                )}
                                name="caseloadReview"
                                onClick={menuCheck}
                              />
                            </em>
                            <div className="icon">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_24_300459)">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.6719 13.13C18.0419 14.06 19.0019 15.32 19.0019 17V20H22.0019C22.5519 20 23.0019 19.55 23.0019 19V17C23.0019 14.82 19.4319 13.53 16.6719 13.13Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z"
                                    fill="black"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15.0019 12C17.2119 12 19.0019 10.21 19.0019 8C19.0019 5.79 17.2119 4 15.0019 4C14.5319 4 14.0919 4.1 13.6719 4.24C14.5019 5.27 15.0019 6.58 15.0019 8C15.0019 9.42 14.5019 10.73 13.6719 11.76C14.0919 11.9 14.5319 12 15.0019 12Z"
                                    fill="black"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M9 13C6.33 13 1 14.34 1 17V19C1 19.55 1.45 20 2 20H16C16.55 20 17 19.55 17 19V17C17 14.34 11.67 13 9 13Z"
                                    fill="black"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_24_300459">
                                    <rect width={24} height={24} fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              Caseload Review
                            </div>
                          </li>
                          <li>
                            <em className="check">
                              <input
                                type="checkbox"
                                checked={characters.find(
                                  (item) =>
                                    item.id === "taskManager" && item.status
                                )}
                                name="taskManager"
                                onClick={menuCheck}
                              />
                            </em>
                            <div className="icon">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_644_346676)">
                                  <path
                                    d="M21.2931 5.89001L11.2931 15.89C10.9031 16.28 10.2731 16.28 9.88305 15.89L7.05305 13.06C6.66305 12.67 6.66305 12.04 7.05305 11.65C7.44305 11.26 8.07305 11.26 8.46305 11.65L10.5831 13.77L19.8731 4.48001C20.2631 4.09001 20.8931 4.09001 21.2831 4.48001C21.6831 4.87001 21.6831 5.50001 21.2931 5.89001ZM12.0031 20C7.29305 20 3.52305 15.91 4.05305 11.1C4.44305 7.58001 7.17305 4.69001 10.6631 4.11001C12.4731 3.81001 14.1931 4.13001 15.6531 4.89001C16.0431 5.09001 16.5131 5.02001 16.8231 4.71001C17.3031 4.23001 17.1831 3.42001 16.5831 3.11001C15.1131 2.36001 13.4531 1.95001 11.6831 2.00001C6.54305 2.16001 2.27305 6.34001 2.01305 11.47C1.72305 17.24 6.30305 22 12.0031 22C13.2031 22 14.3431 21.79 15.4131 21.4C16.0931 21.15 16.2831 20.27 15.7631 19.75C15.4931 19.48 15.0831 19.38 14.7231 19.52C13.8731 19.83 12.9531 20 12.0031 20ZM19.0031 15H17.0031C16.4531 15 16.0031 15.45 16.0031 16C16.0031 16.55 16.4531 17 17.0031 17H19.0031V19C19.0031 19.55 19.4531 20 20.0031 20C20.5531 20 21.0031 19.55 21.0031 19V17H23.0031C23.5531 17 24.0031 16.55 24.0031 16C24.0031 15.45 23.5531 15 23.0031 15H21.0031V13C21.0031 12.45 20.5531 12 20.0031 12C19.4531 12 19.0031 12.45 19.0031 13V15Z"
                                    fill="black"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_644_346676">
                                    <rect width={24} height={24} fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              Task Manager
                            </div>
                          </li>
                          <li>
                            <em className="check">
                              <input
                                type="checkbox"
                                checked={characters.find(
                                  (item) =>
                                    item.id === "revenueSummary" && item.status
                                )}
                                name="revenueSummary"
                                onClick={menuCheck}
                              />
                            </em>
                            <div className="icon">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V14C16 11.34 10.67 10 8 10Z"
                                  fill="black"
                                  fillOpacity={1}
                                />
                              </svg>
                              Revenue Summary
                            </div>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                <li
                  onClick={() => {
                    setIsWidgetLock(!isWidgetLock);
                  }}
                  className={isWidgetLock ? "active" : ""}
                >
                  <div className="link">
                    {!isWidgetLock ? "Lock Widgets" : "UnLock Widgets"}
                    <svg
                      width={16}
                      height={21}
                      viewBox="0 0 16 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM5 7V5C5 3.34 6.34 2 8 2C9.66 2 11 3.34 11 5V7H5Z"
                        fill="#424167"
                      />
                    </svg>
                    <svg
                      style={{ display: "none" }}
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1491_74966)">
                        <path
                          d="M12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13ZM18 8H17V6C17 3.24 14.76 1 12 1C9.72 1 7.73 2.54 7.16 4.75C7.02 5.29 7.34 5.83 7.88 5.97C8.41 6.11 8.96 5.79 9.1 5.25C9.44 3.93 10.63 3 12 3C13.65 3 15 4.35 15 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM18 19C18 19.55 17.55 20 17 20H7C6.45 20 6 19.55 6 19V11C6 10.45 6.45 10 7 10H17C17.55 10 18 10.45 18 11V19Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1491_74966">
                          <rect width={24} height={24} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </li>
                <li>
                  <div className="link">
                    Refresh all
                    <svg
                      width={16}
                      height={21}
                      viewBox="0 0 16 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.99905 4.00003V1.21003C7.99905 0.760031 7.45905 0.540031 7.14905 0.860031L3.34905 4.65003C3.14905 4.85003 3.14905 5.16003 3.34905 5.36003L7.13905 9.15003C7.45905 9.46003 7.99905 9.24003 7.99905 8.79003V6.00003C11.7291 6.00003 14.6791 9.42003 13.8591 13.29C13.3891 15.56 11.5491 17.39 9.28905 17.86C5.71905 18.61 2.53905 16.16 2.05905 12.85C1.98905 12.37 1.56905 12 1.07905 12C0.479051 12 -0.000948563 12.53 0.0790514 13.13C0.699051 17.52 4.87905 20.77 9.60905 19.85C12.7291 19.24 15.2391 16.73 15.8491 13.61C16.8391 8.48003 12.9391 4.00003 7.99905 4.00003Z"
                        fill="#424167"
                      />
                    </svg>
                  </div>
                </li>
              </ul>

              <div id="checkDrop">
                {isWidgetLock ? (
                  <>
                    <div className="characters">
                      {characters?.map((data, key) =>
                        data?.status ? (
                          <div key={key}>
                            {data.id === "userOnline" ? (
                              <UserOnline
                                hideWidgets={hideWidgets}
                                saveHeightWidth={saveSize}
                                width={data.width}
                                height={data.height}
                              />
                            ) : data.id === "cookieCrumbs" ? (
                              <CookieCrumbs
                                errorHandling={errorHandling}
                                hideWidgets={hideWidgets}
                                saveHeightWidth={saveSize}
                                width={data.width}
                                height={data.height}
                                CookieCrumData={CookieCrumData}
                              />
                            ) : data.id === "revenueSummary" ? (
                              <RevenueSummary
                                errorHandling={errorHandling}
                                hideWidgets={hideWidgets}
                                saveHeightWidth={saveSize}
                                width={data.width}
                                height={data.height}
                              />
                            ) : data.id === "caseloadReview" ? (
                              <CaseloadReview
                                hideWidgets={hideWidgets}
                                saveHeightWidth={saveSize}
                                width={data.width}
                                height={data.height}
                              />
                            ) : (
                              <TaskManager
                                hideWidgets={hideWidgets}
                                saveHeightWidth={saveSize}
                                width={data.width}
                                height={data.height}
                              />
                            )}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="characters">
                        {(provided) => (
                          <div
                            className="characters"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {characters?.map((data, key) =>
                              data?.status ? (
                                <Draggable
                                  key={data.id}
                                  draggableId={`draggable_${data.id}`}
                                  index={key}
                                >
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      {data.id === "userOnline" ? (
                                        <UserOnline
                                          hideWidgets={hideWidgets}
                                          saveHeightWidth={saveSize}
                                          width={data.width}
                                          height={data.height}
                                        />
                                      ) : data.id === "cookieCrumbs" ? (
                                        <CookieCrumbs
                                          errorHandling={errorHandling}
                                          hideWidgets={hideWidgets}
                                          saveHeightWidth={saveSize}
                                          width={data.width}
                                          height={data.height}
                                          CookieCrumData={CookieCrumData}
                                        />
                                      ) : data.id === "revenueSummary" ? (
                                        <RevenueSummary
                                          errorHandling={errorHandling}
                                          hideWidgets={hideWidgets}
                                          saveHeightWidth={saveSize}
                                          width={data.width}
                                          height={data.height}
                                        />
                                      ) : data.id === "caseloadReview" ? (
                                        <CaseloadReview
                                          hideWidgets={hideWidgets}
                                          saveHeightWidth={saveSize}
                                          width={data.width}
                                          height={data.height}
                                        />
                                      ) : (
                                        <TaskManager
                                          hideWidgets={hideWidgets}
                                          saveHeightWidth={saveSize}
                                          width={data.width}
                                          height={data.height}
                                        />
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              ) : (
                                ""
                              )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
