import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Detail_Tab_Name_getData: {
    StatePreference: { name: "", id: "" },
    LastName: "",
    Gender: { name: "", id: "" },
    EyeColor: { name: "", id: "" },
    AltID: "",
    SSN: "",
    Height: { feet: "", inch: "" },
    Language: { name: "", id: "" },
    FirstName: "",
    DateOfBirth: "",
    Weight: "",
    ComplianceLevel: { name: "", id: "" },
    MiddleName: "",
    Race: { name: "", id: "" },
    HairColor: { name: "", id: "" },
    EffectiveDate: "",
    isChange: false,
  },
  Detail_Tab_address_getData: {
    MailingAddress: {
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      State: "",
      ZIP: "",
      Country: "",
      Comment: "",
    },
    HomeAddress: {
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      State: "",
      ZIP: "",
      Country: "",
      Comment: "",
    },
    WorkAddress: {
      Employer: "",
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      State: "",
      ZIP: "",
      Country: "",
      Comment: "",
    },
    isChange: false,
  },
  Detail_Payment_Tab_getData: {
    PaymentNotes: "",
    Frequency: "",
    Amount: "",
    NextPaymentDate: "",
    ComplianceLevel: "",
    ActionPlan: "",
    isChange: false,
  },
  Detail_Notification_Tab_getData: {
    AlertNote: "",
    email: "",
    phone: "",
    isChange: false,
  },
  Detail_co_obligor_Tab_getData: {
    FirstNmae: "",
    MiddleName: "",
    LastName: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    State: "",
    ZIP: "",
    SSN: "",

    DOB: "",
    HomePhone: "",
    WorkPhone: "",
    CellPhone: "",
    CardOnFile: "",

    isChange: false,
  },
  Detail_Tab_address_Validation: {
    MailingAddress: {
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      State: "",
      ZIP: "",
      Country: "",
      Comment: "",
    },
    HomeAddress: {
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      State: "",
      ZIP: "",
      Country: "",
      Comment: "",
    },
    WorkAddress: {
      Employer: "",
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      State: "",
      ZIP: "",
      Country: "",
      Comment: "",
    },
  },
  Detail_name_tab_Validation: {
    LastName: "",
    DateOfBirth: "",
  },
  Detail_Payment_Tab_Validation: {
    PaymentNotes: "",
    Frequency: "",
    Amount: "",
    NextPaymentDate: "",
    ComplianceLevel: "",
    ActionPlan: "",
  },
  Detail_Notification_Tab_Validation: {
    PaymentNote: "",
  },
  Detail_co_obligor_Tab_Validation: {
    FirstNmae: "",
    MiddleName: "",
    LastName: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    State: "",
    ZIP: "",
    SSN: "",
  },
  dynamicParticipatTab: [],

  //dropdown temp
  race: [],
  gender: [],
  hairColor: [],
  eyeColor: [],
  language: [],
  complianceLevel: [],

  PaymentBlock: null,
  OnlineReporting: null,
  RecurringCreditCard: null,
  Feeblock: null,

  cancel: null,

  showalertPopup: false,
  showalertPopupcount: {},
  contectInfoTableCount: 0,
  setChangesavedatapopup: false,
  showAddParticipatePopUp: false,
  participantdata: null,

  contectInfoTabWhileAddParticipent: [],
  contectTableAddData: [],
  contectTableAddData2: [],

  efffectiveData: "",
  stateparticipent: "",

  recevePaymentReference: "",
  firstnamevalidation: "",
  recivepaymentPiReset: true,
  headerCheckBoxChange: false,
  addworentREfresh: false,
  addquickadd: false,
  goToDocketPerson: {},
  GoToDocketIsChange: false,
  GoToobligationIsChange: false,
  entityperson: false,
  AllocationAdjustment: {},
  documentWizardInfo: {},
  closeoutWizardInfo: {},
  GPersonEmailContactInfoId: "",
  GPersonTextContactInfoId: "",
  AddParticipateStep: 1,
  AddParticipateUserInfo: {},
  AddParticipateDockets: [],
  DocketReload: false,
};

//slice

const slice = createSlice({
  name: "ParticipateReducer",
  initialState,
  reducers: {
    detail_Tab_Name_getData(state, action) {
      state.Detail_Tab_Name_getData = action.payload;
    },
    detail_Tab_address_getData(state, action) {
      state.Detail_Tab_address_getData = action.payload;
    },
    detail_Payment_Tab_getData(state, action) {
      state.Detail_Payment_Tab_getData = action.payload;
    },
    detail_Notification_Tab_getData(state, action) {
      state.Detail_Notification_Tab_getData = action.payload;
    },
    detail_Tab_address_Validation(state, action) {
      state.Detail_Tab_address_Validation = action.payload;
    },
    detail_name_tab_Validation(state, action) {
      state.Detail_name_tab_Validation = action.payload;
    },
    detail_Payment_Tab_Validation(state, action) {
      state.Detail_Payment_Tab_Validation = action.payload;
    },
    detail_Notification_Tab_Validation(state, action) {
      state.Detail_Notification_Tab_Validation = action.payload;
    },
    detail_co_obligor_Tab_Validation(state, action) {
      state.Detail_co_obligor_Tab_Validation = action.payload;
    },
    detail_co_obligor_Tab_getData(state, action) {
      state.Detail_co_obligor_Tab_getData = action.payload;
    },
    set_dynamicParticipatTab(state, action) {
      if (!state.dynamicParticipatTab.some((obj) => obj?.Offender === action.payload.Offender)) {
        state.dynamicParticipatTab = [...state.dynamicParticipatTab, action.payload];
      } else {
        if (action.payload.Offender === "/image-viewer") {
          state.dynamicParticipatTab = state.dynamicParticipatTab.map((val) => {
            if (val?.Offender === "/image-viewer") {
              return action.payload;
            } else {
              return val;
            }
          });
        }
      }
    },
    updateset_dynamicParticipatTab(state, action) {
      state.dynamicParticipatTab = action.payload;
    },
    remove_dynamicParticipatTab(state, action) {
      state.dynamicParticipatTab = state.dynamicParticipatTab.filter((item) => item.Offender !== action.payload);
    },
    allTabDelete(state, action) {
      state.dynamicParticipatTab = [];
    },
    setDropDown(state, action) {
      state.race = action.payload.race;
      state.gender = action.payload.gender;
      state.hairColor = action.payload.hairColor;
      state.eyeColor = action.payload.eyeColor;
      state.language = action.payload.language;
      state.complianceLevel = action.payload.complianceLevel;
    },
    changeCancel(state, action) {
      state.cancel = !state.cancel;
    },
    headerCheckBoc(state, action) {
      state.PaymentBlock = action.payload.PaymentBlock;
      state.OnlineReporting = action.payload.OnlineReporting;
      state.RecurringCreditCard = action.payload.RecurringCreditCard;
      state.Feeblock = action.payload.Feeblock;
    },
    setAltertPopup(state, action) {
      state.showalertPopup = action.payload.condition;
      if (state.showalertPopupcount[action.payload.offender] == undefined) {
        state.showalertPopupcount = {
          ...state.showalertPopupcount,
          [action.payload.offender]: true,
        };
      }
    },
    setAltertPopupoblig(state, action) {
      state.showalertPopupcount = {
        ...state.showalertPopupcount,
        [action.payload]: false,
      };
    },
    setcontectInfoTableCount(state, action) {
      state.contectInfoTableCount = action.payload;
    },
    setsetChangesavedatapopup(state, action) {
      state.setChangesavedatapopup = action.payload;
    },
    setShowAddParticipatePopUp(state, action) {
      state.showAddParticipatePopUp = action.payload;
    },

    setparticipantdata(state, action) {
      state.participantdata = action.payload;
    },
    setcontectInfoTabWhileAddParticipent(state, action) {
      state.contectInfoTabWhileAddParticipent = action.payload;
    },
    setcontectTableAddDataaa(state, action) {
      state.contectTableAddData = action.payload.map((val) => {
        const { ID, ...vall } = val;
        return { ...val };
      });
    },
    setcontectTableAddDataaa2(state, action) {
      state.contectTableAddData2 = action.payload.map((val) => {
        const { ID, ...vall } = val;
        return { ...val };
      });
    },
    setefffectiveData(state, action) {
      state.efffectiveData = action.payload;
    },
    setstateparticipent(state, action) {
      state.stateparticipent = action.payload;
    },
    setrecevePaymentReference(state, action) {
      state.recevePaymentReference = action.payload;
    },
    setfirstnamevalidation(state, action) {
      state.firstnamevalidation = action.payload;
    },
    setrecivepaymentPiReset(state, action) {
      state.recivepaymentPiReset = action.payload;
    },
    setheaderCheckBoxChange(state, action) {
      state.headerCheckBoxChange = action.payload;
    },
    setaddworentREfresh(state, action) {
      state.addworentREfresh = action.payload;
    },
    setaddquickadd(state, action) {
      state.addquickadd = action.payload;
    },
    setgoToDocketPerson(state, action) {
      state.goToDocketPerson = action.payload;
    },
    resetDocyINdynamicTab(state, action) {
      state.dynamicParticipatTab = JSON.parse(JSON.stringify(state.dynamicParticipatTab)).map((val) => {
        if (`/docket/${action.payload?.last?.replace(/\//g, "-")}` == val?.Offender) {
          return {
            ...val,
            title: `Docket ${action.payload?.new}`,
            Offender: `/docket/${action.payload?.new?.replace(/\//g, "-")}`,
            sDocketNumberCourtCounts: action.payload?.new,
          };
        } else {
          return val;
        }
      });
    },
    setGoToDocketIsChange(state, action) {
      state.GoToDocketIsChange = action.payload;
    },
    setDocketReload(state, action) {
      state.DocketReload = action.payload;
    },

    resetObligationDynamicTab(state, action) {
      state.dynamicParticipatTab = JSON.parse(JSON.stringify(state.dynamicParticipatTab)).map((val) => {
        if (`/obligation/${action.payload.old?.replace(/\//g, "-")}/${action.payload.sReference}` == val?.Offender) {
          return {
            ...val,
            title: `Obligation : ${action.payload.sDocketNumberCourtCounts} / ${action.payload.sReference}`,
            Offender: `/obligation/${action.payload.sDocketNumberCourtCounts?.replace("/", "-")}/${
              action.payload.sReference
            }`,
            sDocketNumberCourtCounts: action.payload.sDocketNumberCourtCounts,
          };
        } else {
          return val;
        }
      });
    },

    addnewObligationDynamicTab(state, action) {
      state.dynamicParticipatTab = JSON.parse(JSON.stringify(state.dynamicParticipatTab)).map((val) => {
        if (
          `/obligation/${action.payload.sDocketNumberCourtCounts?.replace("/", "-")}/${action.payload.sReference}` ==
          val?.Offender
        ) {
          return {
            ...val,
            title: `Obligation : [ New ]`,
          };
        } else {
          return val;
        }
      });
    },
    removenewObligationDynamicTab(state, action) {
      state.dynamicParticipatTab = JSON.parse(JSON.stringify(state.dynamicParticipatTab)).map((val) => {
        if (
          `/obligation/${action.payload.sDocketNumberCourtCounts?.replace("/", "-")}/${action.payload.sReference}` ==
          val?.Offender
        ) {
          return {
            ...val,
            title: `Obligation : ${action.payload.sDocketNumberCourtCounts} / ${action.payload.sReference}`,
          };
        } else {
          return val;
        }
      });
    },

    updatenewObligationDynamicTab(state, action) {
      state.dynamicParticipatTab = JSON.parse(JSON.stringify(state.dynamicParticipatTab)).map((val) => {
        if (
          `/obligation/${action.payload.sDocketNumberCourtCounts?.replace("/", "-")}/${action.payload.sReference}` ==
          val?.Offender
        ) {
          return {
            ...val,
            title: `Obligation : ${action.payload.newsDocketNumberCourtCounts} / ${action.payload.newsReference}`,
            Offender: `/obligation/${action.payload.newsDocketNumberCourtCounts?.replace("/", "-")}/${
              action.payload.newsReference
            }`,
            gObligationId: action.payload.gObligationId,
            ObligationRef: action.payload.newsReference,
            sDocketNumberCourtCounts: action.payload.newsDocketNumberCourtCounts,
          };
        } else if (`/obligation/add/addObligation` == val?.Offender) {
          return {
            ...val,
            title: `Obligation : ${action.payload.newsDocketNumberCourtCounts} / ${action.payload.newsReference}`,
            Offender: `/obligation/${action.payload.newsDocketNumberCourtCounts?.replace("/", "-")}/${
              action.payload.newsReference
            }`,
            gObligationId: action.payload.gObligationId,
            ObligationRef: action.payload.newsReference,
            sDocketNumberCourtCounts: action.payload.newsDocketNumberCourtCounts,
          };
        } else {
          return val;
        }
      });
    },

    setGoToobligationIsChange(state, action) {
      state.GoToobligationIsChange = action.payload;
    },
    setentityperson(state, action) {
      state.entityperson = action.payload;
    },
    setAllocationAdjustment(state, action) {
      state.AllocationAdjustment = action.payload;
    },
    setDocumentWizardInfoData(state, action) {
      state.documentWizardInfo = action.payload;
    },
    setCloseoutWizardInfoData(state, action) {
      state.closeoutWizardInfo = action.payload;
    },
    
    resettopdynamictab(state, action) {
      state.dynamicParticipatTab = state.dynamicParticipatTab.map((val) => {
        if (val?.gObligationId == action?.payload?.gObligationId) {
          return {
            ...val,
            Offender: action.payload?.Offender,
            title: action.payload?.title,
            sDocketNumberCourtCounts: action.payload?.sDocketNumberCourtCounts,
            ObligationRef: action.payload?.ObligationRef,
            gObligationId: action?.payload?.gObligationId,
          };
        } else {
          return val;
        }
      });
    },
    resetDynamicTabDocket(state, action) {
      state.dynamicParticipatTab = state.dynamicParticipatTab.map((val) => {
        if (val?.getTopDynamicDocketid == action?.payload?.getTopDynamicDocketid) {
          return {
            ...val,
            Offender: action.payload?.Offender,
            title: action.payload?.title,
            sDocketNumberCourtCounts: action.payload?.sDocketNumberCourtCounts,
            userinfo: action.payload?.userinfo,
            getTopDynamicDocketid: action.payload?.getTopDynamicDocketid,
          };
        } else {
          return val;
        }
      });
    },
    setGPersonEmailContactInfoId(state, action) {
      state.GPersonEmailContactInfoId = action.payload;
    },
    setGPersonTextContactInfoId(state, action) {
      state.GPersonTextContactInfoId = action.payload;
    },
    setAddParticipateStep(state, action) {
      state.AddParticipateStep = action.payload;
    },
    setAddParticipateUserInfo(state, action) {
      state.AddParticipateUserInfo = action.payload;
    },
    setAddParticipateDockets(state, action) {
      state.AddParticipateDockets = action.payload;
    },
  },
});

export const {
  detail_Tab_Name_getData,
  detail_Tab_address_getData,
  detail_Payment_Tab_getData,
  detail_Notification_Tab_getData,
  detail_Tab_address_Validation,
  detail_name_tab_Validation,
  detail_Payment_Tab_Validation,
  detail_co_obligor_Tab_Validation,
  detail_Notification_Tab_Validation,
  detail_co_obligor_Tab_getData,
  set_dynamicParticipatTab,
  remove_dynamicParticipatTab,
  resettopdynamictab,
  setDropDown,
  changeCancel,
  headerCheckBoc,
  updateset_dynamicParticipatTab,
  setAltertPopup,
  setcontectInfoTableCount,
  setsetChangesavedatapopup,
  setparticipantdata,
  setcontectInfoTabWhileAddParticipent,
  setcontectTableAddDataaa,
  setcontectTableAddDataaa2,
  setefffectiveData,
  setstateparticipent,
  setrecevePaymentReference,
  setfirstnamevalidation,
  allTabDelete,
  setrecivepaymentPiReset,
  setheaderCheckBoxChange,
  setaddworentREfresh,
  setaddquickadd,
  setgoToDocketPerson,
  resetDocyINdynamicTab,
  setGoToDocketIsChange,
  setDocketReload,
  resetObligationDynamicTab,
  addnewObligationDynamicTab,
  removenewObligationDynamicTab,
  setAltertPopupoblig,
  updatenewObligationDynamicTab,
  setGoToobligationIsChange,
  setentityperson,
  setAllocationAdjustment,
  setDocumentWizardInfoData,
  resetDynamicTabDocket,
  setNotificationStateData,
  setGPersonEmailContactInfoId,
  setGPersonTextContactInfoId,
  setAddParticipateStep,
  setAddParticipateUserInfo,
  setAddParticipateDockets,
  setShowAddParticipatePopUp,
  setCloseoutWizardInfoData
} = slice.actions;

export default slice.reducer;
