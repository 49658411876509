import { useNavigate } from "react-router-dom";
import AdminCrumbs from "../components/AdminCrumbs";
import "./feeSchedule.css";
import { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../components/Dropdownn";
import { LoadPanel, Popup } from "devextreme-react";
import { Toster } from "../../../components/Toster/Toster";
import { FeeScheduleFind } from "./FeeSchedulePopup/FeeScheduleFind";
import axios from "axios";
import { logOut } from "../../../components/Logout";
import { handleGetLocal } from "../../../services/auth-service";
import { ApiError } from "../../Error/ApiError";
import { useSelector } from "react-redux";
import { DynamicTabb } from "../../../components/DynamicTab/DynamicTab";

const DropDown = ({ dropDownData, setfields, fields, fieldName, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldName}
        value={fields[fieldName]?.name}
        reset={reset}
        disabled={disabled}
        width={"340px"}
      />
    </>
  );
};

const ObligationFee = () => {
  const { DynamicTab } = useSelector((store) => store.Home);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [fields, setFields] = useState({
    gFeeId: "00000000-0000-0000-0000-000000000000",
    sFeeCode: "",
    sFeeName: "",
    dActive: "1900-01-01T00:00:00",
    dInactive: null,
    // gFeeScheduleTypeId: "9c6c2487-98e5-44de-baf7-ef4ae5f57c08",
    gFeeScheduleTypeId: {
      name: '',
      id: '',
    },
    gBankAccountId: "",
    iPayPriority: 0,
    gObligationTypeId: {
      name: '',
      id: '',
    },
    gFeeScheduleCategoryId: {
      name: '',
      id: '',
    },
    gDisburseFrequencyId: {
      name: '',
      id: '',
    },
    gDisburseMethodId: {
      name: '',
      id: '',
    },
    gDisburseEntityId: {
      name: '',
      id: '',
    },
    fAmount: 0,
    // gFeeScheduleId: "5bb8ff6c-3495-41a0-8277-6d87934f2651",
    // gFeeScheduleFeeId: "b47d25fc-318b-ef11-87f6-000d3a14adc9",
    gFeeScheduleId: null,
    gFeeScheduleFeeId: null,
    sFeeScheduleCategoryName: "",
    sFeeScheduleTypeId: "Fee",
    sBankAccount: {
      name: '',
      id: '',
    },
    sObligationTypeName: "",
    sDisburseMethodName: "",
    sDisburseEntityName: "",
    sDisburseFrequencyName: ""
  });
  const [isEditable, setIsEditable] = useState(false);
  const [isEditableButton, setIsEditableButton] = useState(false);
  const [findFeeSchedulePopup, setFindFeeSchedulePopup] = useState(false);
  const [feeScheduleDropdownData, setFeeScheduleDropdownData] = useState([]);
  const [typeFeeSchedule, setTypeFeeSchedule] = useState("Edit");

  const [errors, setErrors] = useState({});

  const pathname = handleGetLocal("pathURL");

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const resetValidation = (name) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  }

  const emptyField = () => {
    setFields({
      gFeeId: "00000000-0000-0000-0000-000000000000",
      sFeeCode: "",
      sFeeName: "",
      dActive: "1900-01-01T00:00:00",
      dInactive: null,
      // gFeeScheduleTypeId: "9c6c2487-98e5-44de-baf7-ef4ae5f57c08",
      gFeeScheduleTypeId: {
        name: '',
        id: '',
      },
      gBankAccountId: "",
      iPayPriority: 0,
      gObligationTypeId: {
        name: '',
        id: '',
      },
      gFeeScheduleCategoryId: {
        name: '',
        id: '',
      },
      gDisburseFrequencyId: {
        name: '',
        id: '',
      },
      gDisburseMethodId: {
        name: '',
        id: '',
      },
      gDisburseEntityId: {
        name: '',
        id: '',
      },
      fAmount: 0.0,
      // gFeeScheduleId: "5bb8ff6c-3495-41a0-8277-6d87934f2651",
      // gFeeScheduleFeeId: "b47d25fc-318b-ef11-87f6-000d3a14adc9",
      gFeeScheduleId: null,
      gFeeScheduleFeeId: null,
      sFeeScheduleCategoryName: "",
      sFeeScheduleTypeId: "",
      sBankAccount: {
        name: '',
        id: '',
      },
      sObligationTypeName: "",
      sDisburseMethodName: "",
      sDisburseEntityName: "",
      sDisburseFrequencyName: ""
    });
  };

  const oncancel = () => {
    setIsEditableButton(!isEditableButton);
    setIsEditable(false);
    emptyField();
  };

  const OnAdd = () => {
    setIsEditableButton(true);
    setTypeFeeSchedule("Add");
    setIsEditable(true);
    emptyField();
  };

  const getFeeScheduleDropdownData = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/feeschedule/feeschedule-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setFeeScheduleDropdownData(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const isDropdownField = (key) => {
    const dropdownKeys = ["sBankAccount", "gObligationTypeId", "gFeeScheduleCategoryId", "gDisburseFrequencyId", "gDisburseMethodId", "gDisburseEntityId"]; // Replace with actual dropdown field keys
    return dropdownKeys.includes(key);
  };

  const validateFields = () => {
    const newErrors = {};
    Object.keys(fields).forEach((key) => {
      if (!fields[key]) {
        newErrors[key] = "This field is required.";
      } else if (isDropdownField(key) && !fields[key].name) {
        newErrors[key] = "Please select a value.";
      }
    });
    // Delete some keys as validation check is not required on them
    delete newErrors.dInactive;
    delete newErrors.fAmount;
    delete newErrors.gBankAccountId;
    delete newErrors.sFeeScheduleCategoryName;
    delete newErrors.sFeeScheduleTypeId;
    delete newErrors.sObligationTypeName;
    delete newErrors.sDisburseMethodName;
    delete newErrors.sDisburseEntityName;
    delete newErrors.sDisburseFrequencyName;
    delete newErrors.gFeeScheduleId;
    delete newErrors.gFeeScheduleFeeId;
    setErrors(newErrors);
    console.log('newErrors', newErrors)
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateFields()) return;
    console.log(fields);
    let body = {
      ...fields,
      gObligationTypeId: fields?.gObligationTypeId?.id,
      gFeeScheduleCategoryId: fields?.gFeeScheduleCategoryId?.id,
      gDisburseFrequencyId: fields?.gDisburseFrequencyId?.id,
      gDisburseMethodId: fields?.gDisburseMethodId?.id,
      gDisburseEntityId: fields?.gDisburseEntityId?.id,
      gBankAccountId: fields?.sBankAccount?.id,
      sBankAccount: fields?.sBankAccount?.name,
      sObligationTypeName: fields?.gObligationTypeId?.name,
      sDisburseMethodName: fields?.gDisburseMethodId?.name,
      sDisburseEntityName: fields?.gDisburseEntityId?.name,
      sFeeScheduleCategoryName: fields?.gFeeScheduleCategoryId?.name,
      sDisburseFrequencyName: fields?.gDisburseFrequencyId?.name,
      // gFeeScheduleTypeId: "9c6c2487-98e5-44de-baf7-ef4ae5f57c08",
      gFeeScheduleTypeId: fields?.gFeeScheduleTypeId?.id,
      // gFeeScheduleId: "5bb8ff6c-3495-41a0-8277-6d87934f2651",
      // gFeeScheduleFeeId: "b47d25fc-318b-ef11-87f6-000d3a14adc9",
      gFeeScheduleId: fields?.gFeeScheduleId,
      gFeeScheduleFeeId: fields.gFeeScheduleFeeId,
      dActive: "1900-01-01T00:00:00",
    };
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/Administration/add-edit-obligation-fee`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );
      setLoading(false);
      if (response?.data?.isSuccess) {
        setToastConfig({
          isVisible: true,
          message: typeFeeSchedule === "Add" ? "Successfully Added!" : "Successfully Updated!",
          type: "success",
        });

        setIsEditableButton(!isEditableButton);
        setIsEditable(false);
        setTimeout(() => {
          setToastConfig({ ...toastConfig, isVisible: false });
        }, 1900);
      }
    } catch (error) {
      setLoading(false);
      setToastConfig({
        isVisible: true,
        type: "error",
        message: "An error occurred while saving. Please try again.",
      });
      console.error(error);
    }
  };

  useEffect(() => {
    let existingData = DynamicTab.find(item => item.title === 'Obligation Fee');
    const obj = existingData?.Offender?.obj;
    console.log('obj', obj)
    setFields({
      ...fields,
      sFeeCode: obj?.sFeeCode,
      sFeeName: obj?.sFeeName,
      gFeeId: obj?.gFeeId,
      iPayPriority: obj?.iPayPriority,
      fAmount: obj?.fAmount,
      gObligationTypeId: {
        name: obj?.sObligationTypeName,
        id: obj?.gObligationTypeId,
      },
      gFeeScheduleCategoryId: {
        name: obj?.sFeeScheduleCategoryName,
        id: obj?.gFeeScheduleCategoryId,
      },
      gDisburseFrequencyId: {
        name: obj?.sDisburseFrequencyName,
        id: obj?.gDisburseFrequencyId,
      },
      gDisburseMethodId: {
        name: obj?.sDisburseMethodName,
        id: obj?.gDisburseMethodId,
      },
      gDisburseEntityId: {
        name: obj?.sDisburseEntityName,
        id: obj?.gDisburseEntityId,
      },
      sBankAccount: {
        name: obj?.sBankAccount,
        id: obj?.gBankAccountId,
      },
      gFeeScheduleTypeId: {
        name: obj?.sFeeScheduleTypeName,
        id: obj?.gFeeScheduleTypeId,
      },
      gFeeScheduleId: obj?.gFeeScheduleId,
      gFeeScheduleFeeId: obj?.gFeeScheduleFeeId
    })
  }, [DynamicTab]);

  useEffect(() => {
    if (localStorage.getItem("user_designation") !== "Administrator") {
      navigate("/");
    }
    getFeeScheduleDropdownData();
  }, []);

  console.log('fields', fields)
  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Popup
        visible={findFeeSchedulePopup}
        onHiding={() => {
          setFindFeeSchedulePopup(false);
        }}
        showTitle={false}
        width={700}
        height={520}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <FeeScheduleFind
          findFeeSchedulePopup={findFeeSchedulePopup}
          setFindFeeSchedulePopup={setFindFeeSchedulePopup}
        />
      </Popup>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <AdminCrumbs title={"Obligation Fee"} user={"Data"} />
      <div className="fee-schedule-container">
        <DynamicTabb />
        {true ? <div className="fee-schedule-content">
          <p className="page-info-number">OF.1</p>
          <div className="fee-schedule-buttons">
            <div className="buttons">
              {(
                <button
                  className="btn"
                  onClick={() => {
                    OnAdd();
                  }}
                >
                  Add
                  <svg
                    className="svg-blue-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_6247_15083)">
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16 13H13V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V13H8C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11H11V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V11H16C16.55 11 17 11.45 17 12C17 12.55 16.55 13 16 13Z"
                        fill="#424167"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6247_15083">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              )}
            </div>
            {typeFeeSchedule === "Edit" && !isEditableButton && (
              <>
                <div className="buttons">
                  <button
                    className="primary_btn"
                    onClick={() => {
                      setIsEditableButton(true);
                      setIsEditable(true);
                      setTypeFeeSchedule("Edit");
                    }}
                  >
                    Edit
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <g clipPath="url(#clip0_6809_74139)">
                        <path
                          d="M3 17.4586V20.4986C3 20.7786 3.22 20.9986 3.5 20.9986H6.54C6.67 20.9986 6.8 20.9486 6.89 20.8486L17.81 9.93859L14.06 6.18859L3.15 17.0986C3.05 17.1986 3 17.3186 3 17.4586ZM20.71 7.03859C21.1 6.64859 21.1 6.01859 20.71 5.62859L18.37 3.28859C17.98 2.89859 17.35 2.89859 16.96 3.28859L15.13 5.11859L18.88 8.86859L20.71 7.03859Z"
                          fill="#fff"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_6809_74139">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </>
            )}
            {isEditableButton && (
              <div className="buttons">
                <button
                  className="secondary_btn"
                  onClick={() => {
                    oncancel();
                  }}
                >
                  Cancel
                </button>
                <button
                  className="primary_btn"
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.5 10V15C16.5 15.55 16.05 16 15.5 16H3.5C2.95 16 2.5 15.55 2.5 15V10C2.5 9.45 2.05 9 1.5 9C0.95 9 0.5 9.45 0.5 10V16C0.5 17.1 1.4 18 2.5 18H16.5C17.6 18 18.5 17.1 18.5 16V10C18.5 9.45 18.05 9 17.5 9C16.95 9 16.5 9.45 16.5 10ZM10.5 9.67L12.38 7.79C12.77 7.4 13.4 7.4 13.79 7.79C14.18 8.18 14.18 8.81 13.79 9.2L10.2 12.79C9.81 13.18 9.18 13.18 8.79 12.79L5.2 9.2C4.81 8.81 4.81 8.18 5.2 7.79C5.59 7.4 6.22 7.4 6.61 7.79L8.5 9.67V1C8.5 0.45 8.95 0 9.5 0C10.05 0 10.5 0.45 10.5 1V9.67Z"
                      fill="white"
                    ></path>
                  </svg>
                </button>
              </div>
            )}
          </div>
          {false ? (
            <ApiError />
          ) : (
            <div className="fee-schedule-fields" style={{ width: "1100px" }}>
              <div className="fields-row">
                <div className="go-to-inputs-field">
                  <label htmlFor="sFeeCode" className="go-to-label-field">
                    Fee Code <span className="marks_mandatory"></span>
                  </label>
                  <input
                    type="text"
                    name="sFeeCode"
                    id="sFeeCode"
                    value={fields.sFeeCode}
                    placeholder="Enter Fee Code"
                    className="fee-payment-content-info-input input-width"
                    onChange={handleInputChange}
                    disabled={!isEditable}
                  />
                  {errors.sFeeCode && <span className="error">{errors.sFeeCode}</span>}
                </div>
                <div className="go-to-inputs-field">
                  <label htmlFor="sFeeType" className="go-to-label-field">
                    Fee Type <span className="marks_mandatory"></span>
                  </label>
                  <div style={{ marginTop: "-3.5%" }}>
                    <DropDown style={{ Margin: "0,-3.5%" }}
                      setfields={setFields}
                      fields={fields}
                      fieldName={"gFeeScheduleTypeId"}
                      reset={() => resetValidation("gFeeScheduleTypeId")}
                      dropDownData={feeScheduleDropdownData?.feeScheduleType?.map((val) => ({
                        name: val?.value,
                        id: val?.key,
                      }))}
                      disabled={!isEditable}
                    />
                  </div>

                  {errors.gFeeScheduleTypeId && (
                    <span className="error">{errors.gFeeScheduleTypeId}</span>
                  )}
                </div>
                <div className="go-to-inputs-field">
                  <label htmlFor="iPayPriority" className="go-to-label-field">
                    Pay Priority
                  </label>
                  <input style={{ width: "25%" }}
                    type="number"
                    name="iPayPriority"
                    id="iPayPriority"
                    value={fields.iPayPriority}
                    placeholder="Enter Payment Priority"
                    className="fee-payment-content-info-input input-width input-short"
                    onChange={handleInputChange}
                    disabled={!isEditable}
                  />
                  {errors.iPayPriority && <span className="error">{errors.iPayPriority}</span>}
                </div>
              </div>
              <div className="fields-row">
                <div className="go-to-inputs-field">
                  <label htmlFor="sFeeName" className="go-to-label-field">
                    Fee Name
                  </label>
                  <input
                    type="text"
                    name="sFeeName"
                    id="sFeeName"
                    value={fields.sFeeName}
                    placeholder="Enter Fee Name"
                    className="fee-payment-content-info-input input-width"
                    onChange={handleInputChange}
                    disabled={!isEditable}
                  />
                  {errors.sFeeName && <span className="error">{errors.sFeeName}</span>}
                </div>
                <div className="go-to-inputs-field">
                  <label htmlFor="gObligationTypeId" className="go-to-label-field">
                    Obligation Type
                  </label>
                  <div style={{ marginTop: "-3.5%" }}>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      reset={() => resetValidation("gObligationTypeId")}
                      fieldName={"gObligationTypeId"}
                      dropDownData={feeScheduleDropdownData?.obligationType?.map((val) => ({
                        name: val?.value,
                        id: val?.key,
                      }))}
                      disabled={!isEditable}
                    />
                  </div>
                  {errors.gObligationTypeId && (
                    <span className="error">{errors.gObligationTypeId}</span>
                  )}
                </div>
                <div className="go-to-inputs-field">
                  <label htmlFor="gFeeScheduleCategoryId" className="go-to-label-field">
                    Category Name
                  </label>
                  <div style={{ marginTop: "-3.5%" }}>
                    <DropDown
                      setfields={setFields}
                      fields={fields}
                      reset={() => resetValidation("gFeeScheduleCategoryId")}
                      fieldName={"gFeeScheduleCategoryId"}
                      dropDownData={feeScheduleDropdownData?.feeScheduleCategory?.map((val) => ({
                        name: val?.value,
                        id: val?.key,
                      }))}
                      disabled={!isEditable}
                    />
                  </div>
                  {errors.gFeeScheduleCategoryId && (
                    <span className="error">{errors.gFeeScheduleCategoryId}</span>
                  )}
                </div>
              </div>
              <div className="fields-row">
                <div className="go-to-inputs-field">
                  <label htmlFor="fAmount" className="go-to-label-field">
                    Default Amount
                  </label>
                  <input style={{ width: "35%" }}
                    type="number"
                    name="fAmount"
                    id="fAmount"
                    value={fields.fAmount}
                    placeholder="Enter Amount"
                    className="fee-payment-content-info-input input-width"
                    onChange={handleInputChange}
                    disabled={!isEditable}
                  />
                  {errors.fAmount && <span className="error">{errors.fAmount}</span>}
                </div>
              </div>
              <div className="fields-row">
                <div className="go-to-inputs-field">
                  <label htmlFor="gDisburseFrequencyId" className="go-to-label-field">
                    Disburse Frequency
                  </label>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    reset={() => resetValidation("gDisburseFrequencyId")}
                    fieldName={"gDisburseFrequencyId"}
                    dropDownData={feeScheduleDropdownData?.disburseFrequency?.map((val) => ({
                      name: val?.value,
                      id: val?.key,
                    }))}
                    disabled={!isEditable}
                  />
                  {errors.gDisburseFrequencyId && (
                    <span className="error">{errors.gDisburseFrequencyId}</span>
                  )}
                </div>
                <div className="go-to-inputs-field">
                  <label htmlFor="gDisburseMethodId" className="go-to-label-field">
                    Disburse Method
                  </label>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldName={"gDisburseMethodId"}
                    reset={() => resetValidation("gDisburseMethodId")}
                    dropDownData={feeScheduleDropdownData?.disburseMethod?.map((val) => ({
                      name: val?.value,
                      id: val?.key,
                    }))}
                    disabled={!isEditable}
                  />
                  {errors.gDisburseMethodId && (
                    <span className="error">{errors.gDisburseMethodId}</span>
                  )}
                </div>
                <div className="go-to-inputs-field">
                  <label htmlFor="gDisburseEntityId" className="go-to-label-field">
                    Disburse Entity
                  </label>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldName={"gDisburseEntityId"}
                    reset={() => resetValidation("gDisburseEntityId")}
                    dropDownData={feeScheduleDropdownData?.entity?.map((val) => ({
                      name: val?.sName,
                      id: val?.gEntityId,
                    }))}
                    disabled={!isEditable}
                  />
                  {errors.gDisburseEntityId && (
                    <span className="error">{errors.gDisburseEntityId}</span>
                  )}
                </div>
              </div>
              <div className="fields-row">
                <div className="go-to-inputs-field">
                  <label htmlFor="sFeeCode" className="go-to-label-field">
                    Disburse Bank Account <span className="marks_mandatory"></span>
                  </label>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldName={"sBankAccount"}
                    reset={() => resetValidation("sBankAccount")}
                    dropDownData={feeScheduleDropdownData?.bankAccount?.map((val) => ({
                      name: val?.value,
                      id: val?.key,
                    }))}
                    disabled={!isEditable}
                  />
                  {errors.sBankAccount && (
                    <span className="error">{errors.sBankAccount}</span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
          :
          "No OF.1 view access"}
      </div>
    </>
  );
};

export default ObligationFee;
