import { DateBox, LoadPanel, NumberBox, Popup, ScrollView, TextBox } from "devextreme-react";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import { Table2 } from "../../../../../components/Table2/Table2";
import { useEffect, useState } from "react";
import { EditCheckistPopupFunction } from "./EditCheckistPopupFunction";
import { useNavigate } from "react-router-dom";
import { Toster } from "../../../../../components/Toster/Toster";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        reset={reset}
        margin={"7px"}
      />
    </>
  );
};

const Conformpopup = ({ Conformpopupshow, setConformpopupshow, setCollectionpopup }) => {
  return (
    <>
      <Popup
        visible={Conformpopupshow}
        onHiding={() => {
          setConformpopupshow(false);
        }}
        showTitle={false}
        width={500}
        height={250}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div style={{ border: "3px solid var(--button2)", height: "248px" }}>
          <div className="RecivePaymentModel_header">
            <div style={{ width: "160px" }} className="RecivePaymentModel_header_name">
              <p>Confirm</p>
            </div>
            <svg
              onClick={() => {
                setConformpopupshow(false);
                setCollectionpopup(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <p className="go-to-label-filed" style={{ padding: "10px" }}>
            This Docket or Person already has a Collection record. Would you like to overwrite it with current data ?
          </p>
          <div className="popup_buttons" style={{ marginTop: "90px", marginRight: "20px" }}>
            <button
              onClick={() => {
                setConformpopupshow(false);
              }}
              className="btn primary_btn"
            >
              Save
            </button>
            <button
              onClick={() => {
                setConformpopupshow(false);
                setCollectionpopup(false);
              }}
              className="btn"
            >
              Cancel
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
};

const Deletempopup = ({
  Deletepopupshow,
  setDeletepopupshow,
  ondelete,
  settextareadata,
  textareadata,
  settextareadatavalidation,
  textareadatavalidation,
}) => {
  return (
    <>
      <Popup
        visible={Deletepopupshow}
        onHiding={() => {
          setDeletepopupshow(false);
        }}
        showTitle={false}
        width={500}
        height={370}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <div style={{ border: "3px solid var(--button2)", height: "368px" }}>
          <div className="RecivePaymentModel_header">
            <div style={{ width: "160px" }} className="RecivePaymentModel_header_name">
              <p>Confirm</p>
            </div>
            <svg
              onClick={() => {
                setDeletepopupshow(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div style={{ padding: "10px" }}>
            <div style={{ fontSize: "20px" }}>Are you sure to Delete?</div>
            <p style={{ fontWeight: "bold", marginTop: "6%" }}>
              Please enter a Notes.
              <span style={{ fontSize: "15px" }}> *</span>
            </p>
            <textarea
              style={{ width: "100%" }}
              value={textareadata}
              onChange={(e) => {
                settextareadata(e.target.value);
                settextareadatavalidation("");
              }}
            />
            <p style={{ color: "red", position: "absolute" }}>{textareadatavalidation}</p>
          </div>
          <div className="popup_buttons" style={{ marginTop: "30px", marginRight: "20px" }}>
            <button
              onClick={() => {
                ondelete();
              }}
              className="btn primary_btn"
            >
              Save
            </button>
            <button
              onClick={() => {
                setDeletepopupshow(false);
              }}
              className="btn"
            >
              Cancel
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
};

export const Collection = ({ setCollectionpopup, userinfo, Collectionpopup, getTopDynamicDocketid }) => {
  const clas = new EditCheckistPopupFunction();
  const [tierdropdown, settierdropdown] = useState([
    { name: "Tier 1", id: "Tier 1" },
    { name: "Tier 2", id: "Tier 2" },
  ]);
  const [collectionstatusdropdown, setcollectionstatusdropdown] = useState([
    { name: "Send to Collections", id: "Send to Collections" },
  ]);
  const [fields, setFields] = useState({
    "Level/Tier": {
      name: "",
      id: "",
    },
    "Delinquent Date": "",
    "Collection Status": {
      name: "Send to Collections",
      id: "Send to Collections",
    },
    "Service Date": "",
    "Amount Due": "",
    "Offender Address 1": userinfo?.sHomeAddress1,
    "Offender Address 2": userinfo?.sHomeAddress2,
    "Offender City": userinfo?.sHomeCity,
    "Offender State": userinfo?.sHomeState,
    "Offender Zip": userinfo?.sHomeZip,
    "Offender Home Phone": "",
    "Offender Work Phone": "",
    "Offender Other Phone": "",
    "Guarantor Name": `${userinfo?.sGuarantorLastName}, ${userinfo?.sGuarantorMiddleName} ${userinfo?.sGuarantorFirstName}`,
    "Guarantor DOB": userinfo?.dGuarantorDob,
    "Guarantor SSN": userinfo?.sGuarantorSsn,
    "Guarantor City": userinfo?.sGuarantorCity,
    "Guarantor State": userinfo?.sGuarantorState,
    "Guarantor ZIP": userinfo?.sGuarantorZip,
    "Guarantor Home Phone": userinfo?.sGuarantorHomePhone,
    "Guarantor Work Phone": userinfo?.sGuarantorWorkPhone,
    "Guarantor Other Phone": userinfo?.sGuarantorOtherPhone,
    "Guarantor Address1": userinfo?.sGuarantorAddress1,
    "Guarantor Address2": userinfo?.sGuarantorAddress2,
  });
  const [levelTierValidation, setLevelTierValidation] = useState("");
  const [amountDueValidation, setAmountDueValidation] = useState("");
  const [doeValidation, setdoeValidation] = useState("");
  const [delinquentDateValidation, setDelinquentDateValidation] = useState("");
  const [serviceDateValidation, SetServiceDateValidation] = useState("");
  const [data, setdata] = useState();
  const [Conformpopupshow, setConformpopupshow] = useState(false);
  const [Deletepopupshow, setDeletepopupshow] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [textareadata, settextareadata] = useState("");
  const [textareadatavalidation, settextareadatavalidation] = useState("");

  const onSave = () => {
    let isValid = true;
    if (fields?.[`Level/Tier`]?.id === "") {
      setLevelTierValidation("Please select Level/Tier");
      isValid = false;
    }
    if (!fields?.[`Amount Due`]) {
      setAmountDueValidation("Please Enter Amount Due");
      isValid = false;
    }
    if (!fields?.[`Delinquent Date`]) {
      setDelinquentDateValidation("Please Select Delinquent Date");
      isValid = false;
    }
    if (!fields?.[`Delinquent Date`]) {
      SetServiceDateValidation("Please Select Service Date");
      isValid = false;
    }
    if (!fields?.[`Guarantor DOB`]) {
      setdoeValidation("Please Select Guarantor DOB");
      isValid = false;
    }
    if (isValid) {
      clas.addeditcollection(
        setLoading,
        navigate,
        {
          GPersonId: userinfo?.gPersonId,
          DDelinquent: fields?.["Delinquent Date"],
          FAmount: fields?.["Amount Due"],
          SGuarantorAddress1: fields?.["Guarantor Address1"],
          SGuarantorAddress2: fields?.["Guarantor Address2"],
          SGuarantorCity: fields?.["Guarantor City"],
          SGuarantorState: fields?.["Guarantor State"],
          SGuarantorZip: fields?.["Guarantor ZIP"],
          SGuarantorSsn: fields?.["Guarantor SSN"],
          SGuarantorHomePhone: fields?.["Guarantor Home Phone"],
          SGuarantorWorkPhone: fields?.["Guarantor Work Phone"],
          SStatus: fields?.["Collection Status"].id,
          DService: fields?.["Service Date"],
          SGuarantorName: fields?.["Guarantor Name"],
          SGuarantorOtherPhone: fields?.["Guarantor Other Phone"],
          DGuarantorDob: fields?.["Guarantor DOB"],
          SHomePhone: fields?.["Offender Home Phone"],
          SWorkPhone: fields?.["Offender Work Phone"],
          SOtherPhone: fields?.["Offender Other Phone"],
          SLevel: fields?.["Level/Tier"].id,
          SHomeAddress1: fields?.["Offender Address 1"],
          SHomeAddress2: fields?.["Offender Address 2"],
          SHomeCity: fields?.["Offender City"],
          SHomeState: fields?.["Offender State"],
          SHomeZip: fields?.["Offender Zip"],
          DDob: userinfo?.dDob,
          SOffenderNumber: userinfo?.sOffenderNumber,
          SLastFirst: userinfo?.sLastFirst,
          GDocketId: getTopDynamicDocketid,
        },
        setCollectionpopup,
        setToastConfig,
        toastConfig
      );
    }
  };

  const ondelete = () => {
    if (!textareadata) {
      settextareadatavalidation("Please enter notes");
      return;
    }
    clas.deletecollection(
      setLoading,
      navigate,
      {
        GPersonId: userinfo?.gPersonId,
        GDocketId: getTopDynamicDocketid,
        Comments: textareadata,
      },
      setCollectionpopup,
      setToastConfig,
      toastConfig,
      setDeletepopupshow
    );
  };

  useEffect(() => {
    setdata();
    if (userinfo?.gPersonId && getTopDynamicDocketid && Collectionpopup) {
      clas.getcollection(
        setLoading,
        navigate,
        userinfo?.gPersonId,
        getTopDynamicDocketid,
        setdata,
        setConformpopupshow,
        setFields,
        fields
      );
    }
  }, [Collectionpopup]);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    setFields({
      "Level/Tier": {
        name: "",
        id: "",
      },
      "Delinquent Date": "",
      "Collection Status": {
        name: "Send to Collections",
        id: "Send to Collections",
      },
      "Service Date": "",
      "Amount Due": "",
      "Offender Address 1": userinfo?.sHomeAddress1,
      "Offender Address 2": userinfo?.sHomeAddress2,
      "Offender City": userinfo?.sHomeCity,
      "Offender State": userinfo?.sHomeState,
      "Offender Zip": userinfo?.sHomeZip,
      "Offender Home Phone": "",
      "Offender Work Phone": "",
      "Offender Other Phone": "",
      "Guarantor Name": `${userinfo?.sGuarantorLastName}, ${userinfo?.sGuarantorMiddleName} ${userinfo?.sGuarantorFirstName}`,
      "Guarantor DOB": userinfo?.dGuarantorDob,
      "Guarantor SSN": userinfo?.sGuarantorSsn,
      "Guarantor City": userinfo?.sGuarantorCity,
      "Guarantor State": userinfo?.sGuarantorState,
      "Guarantor ZIP": userinfo?.sGuarantorZip,
      "Guarantor Home Phone": userinfo?.sGuarantorHomePhone,
      "Guarantor Work Phone": userinfo?.sGuarantorWorkPhone,
      "Guarantor Other Phone": userinfo?.sGuarantorOtherPhone,
      "Guarantor Address1": userinfo?.sGuarantorAddress1,
      "Guarantor Address2": userinfo?.sGuarantorAddress2,
    });
    setLevelTierValidation("");
    setAmountDueValidation("");
    setDelinquentDateValidation("");
    SetServiceDateValidation("");
    setdoeValidation("");
    settextareadata("");
  }, [Collectionpopup]);

  return (
    <>
      <Conformpopup
        Conformpopupshow={Conformpopupshow}
        setConformpopupshow={setConformpopupshow}
        setCollectionpopup={setCollectionpopup}
      />

      <Deletempopup
        Deletepopupshow={Deletepopupshow}
        setDeletepopupshow={setDeletepopupshow}
        ondelete={ondelete}
        settextareadata={settextareadata}
        textareadata={textareadata}
        settextareadatavalidation={settextareadatavalidation}
        textareadatavalidation={textareadatavalidation}
      />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <div
        style={{
          width: "100%",
          height: "648px",
          border: "3px solid var(--button2)",
        }}
        className="CreateAppointmentPopup"
      >
        <ScrollView>
          <div className="RecivePaymentModel_header">
            <div style={{ width: "160px" }} className="RecivePaymentModel_header_name">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="payments" clipPath="url(#clip0_5501_11449)">
                  <g id="Group">
                    <path
                      id="Vector"
                      d="M23 8V18C23 19.1 22.1 20 21 20H5C4.45 20 4 19.55 4 19C4 18.45 4.45 18 5 18H21V8C21 7.45 21.45 7 22 7C22.55 7 23 7.45 23 8ZM4 16C2.34 16 1 14.66 1 13V7C1 5.34 2.34 4 4 4H16C17.66 4 19 5.34 19 7V14C19 15.1 18.1 16 17 16H4ZM7 10C7 11.66 8.34 13 10 13C11.66 13 13 11.66 13 10C13 8.34 11.66 7 10 7C8.34 7 7 8.34 7 10Z"
                      fill="white"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_5501_11449">
                    <rect width="24" height="24" fill="black" />
                  </clipPath>
                </defs>
              </svg>
              <p style={{ marginLeft: "-10px" }}>Collections</p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  PS.12
                </p>
              </span>
            </div>
            <svg
              onClick={() => {
                setCollectionpopup(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>
          <div style={{ width: "96%", marginLeft: "2%" }}>
            <div style={{ marginTop: "1%" }} className="collectionpopup">
              <div className="dataTabblock1">
                <div>
                  <p style={{ width: "135px" }}>Offender Number</p>
                  <p
                    style={{
                      width: "160px",
                      marginLeft: "6px",
                    }}
                  >
                    {userinfo?.sOffenderNumber}
                  </p>
                </div>
                <div>
                  <p style={{ width: "135px" }}>Offender Name</p>
                  <p
                    style={{
                      width: "160px",
                      marginLeft: "6px",
                    }}
                  >
                    {userinfo?.sLastFirst}
                  </p>
                </div>
                <div>
                  <p style={{ width: "135px" }}>Offender DOB</p>
                  <p
                    style={{
                      width: "160px",
                      marginLeft: "6px",
                    }}
                  >
                    {new Date(userinfo?.dDob).toLocaleDateString("en-US")}
                  </p>
                </div>
                <div>
                  <p style={{ width: "135px" }}>Offender SSN</p>
                  <p
                    style={{
                      width: "160px",
                      marginLeft: "6px",
                    }}
                  >
                    {userinfo?.sSsn}
                  </p>
                </div>
              </div>
            </div>

            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2input">
                <p>
                  Level/Tier <span className="marks_mandatary">*</span>
                </p>
                <div>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"Level/Tier"}
                    dropDownData={tierdropdown}
                    reset={setLevelTierValidation}
                  />
                </div>
                {levelTierValidation && (
                  <p className="error_validation" style={{ color: "red" }}>
                    {levelTierValidation}
                  </p>
                )}
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>
                  Delinquent Date <span className="marks_mandatary">*</span>
                </p>
                <div>
                  <DateBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Delinquent Date"]}
                    onValueChange={(e) => {
                      setFields({
                        ...fields,
                        "Delinquent Date": e ? e : null,
                      });
                      setDelinquentDateValidation("");
                    }}
                    useMaskBehavior={true}
                  />
                </div>
                {delinquentDateValidation && (
                  <p className="error_validation" style={{ color: "red" }}>
                    {delinquentDateValidation}
                  </p>
                )}
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>Collection Status</p>
                <div>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"Collection Status"}
                    dropDownData={collectionstatusdropdown}
                  />
                </div>
              </div>
            </div>
            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2input">
                <p>
                  Service Date <span className="marks_mandatary">*</span>
                </p>
                <div>
                  <DateBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Service Date"]}
                    onValueChange={(e) => {
                      setFields({
                        ...fields,
                        "Service Date": e ? e : null,
                      });
                      SetServiceDateValidation("");
                    }}
                    useMaskBehavior={true}
                  />
                </div>
                {serviceDateValidation && (
                  <p className="error_validation" style={{ color: "red" }}>
                    {serviceDateValidation}
                  </p>
                )}
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>
                  Amount Due <span className="marks_mandatary">*</span>
                </p>
                <div>
                  <NumberBox
                    onKeyDown={(e) => {
                      const { event } = e;
                      const str = event.key || String.fromCharCode(event.which);
                      if (/^[.,e]$/.test(str)) {
                        event.preventDefault();
                      }
                    }}
                    style={{ marginTop: "2%" }}
                    min={0}
                    value={fields?.["Amount Due"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Amount Due": e });
                      setAmountDueValidation("");
                    }}
                    placeholder={"Amount Due"}
                  />
                </div>
                {amountDueValidation && (
                  <p className="error_validation" style={{ color: "red" }}>
                    {amountDueValidation}
                  </p>
                )}
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>Offender Address 1</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Offender Address 1"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Offender Address 1": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
            </div>
            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2input">
                <p>Offender Address 2</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Offender Address 2"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Offender Address 2": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>Offender City</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Offender City"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Offender City": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>Offender State</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Offender State"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Offender State": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
            </div>
            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2input">
                <p>Offender Zip</p>
                <div>
                  <NumberBox
                    onKeyDown={(e) => {
                      const { event } = e;
                      const str = event.key || String.fromCharCode(event.which);
                      if (/^[.,e]$/.test(str)) {
                        event.preventDefault();
                      }
                    }}
                    style={{ marginTop: "2%" }}
                    value={fields?.["Offender Zip"]}
                    min={0}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Offender Zip": e });
                    }}
                    placeholder={"ZIP"}
                  />
                </div>
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>Offender Home Phone</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Offender Home Phone"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Offender Home Phone": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>

              <div className="collectionpopupBLOCK2input">
                <p>Offender Work Phone</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Offender Work Phone"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Offender Work Phone": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
            </div>
            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2input">
                <p>Offender Other Phone</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Offender Other Phone"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Offender Other Phone": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>Guarantor Address 1</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Guarantor Address1"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Guarantor Address1": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>Guarantor Address 2</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Guarantor Address2"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Guarantor Address2": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
            </div>
            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2input">
                <p>Guarantor Name</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Guarantor Name"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Guarantor Name": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>
                  Guarantor DOB <span className="marks_mandatary">*</span>
                </p>
                <div>
                  <DateBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Guarantor DOB"]}
                    onValueChange={(e) => {
                      setFields({
                        ...fields,
                        "Guarantor DOB": e ? e : null,
                      });
                      setdoeValidation("");
                    }}
                    useMaskBehavior={true}
                  />
                  {doeValidation && (
                    <p className="error_validation" style={{ color: "red" }}>
                      {doeValidation}
                    </p>
                  )}
                </div>
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>Guarantor SSN</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Guarantor SSN"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Guarantor SSN": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
            </div>
            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2input">
                <p>Guarantor City</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Guarantor City"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Guarantor City": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>Guarantor State</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Guarantor State"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Guarantor State": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>Guarantor ZIP</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Guarantor ZIP"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Guarantor ZIP": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
            </div>
            <div className="collectionpopupBLOCK2">
              <div className="collectionpopupBLOCK2input">
                <p>Guarantor Home Phone</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Guarantor Home Phone"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Guarantor Home Phone": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>Guarantor Work Phone</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Guarantor Work Phone"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Guarantor Work Phone": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
              <div className="collectionpopupBLOCK2input">
                <p>Guarantor Other Phone</p>
                <div>
                  <TextBox
                    style={{ marginTop: "2%" }}
                    value={fields?.["Guarantor Other Phone"]}
                    onValueChange={(e) => {
                      setFields({ ...fields, "Guarantor Other Phone": e });
                    }}
                    isValid={true}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "2%",
                marginBottom: "2%",
              }}
            >
              <button
                style={{
                  backgroundColor: "var(--button2)",
                  color: "white",
                  width: "7%",
                }}
                onClick={() => {
                  onSave();
                }}
              >
                Save
              </button>
              <button
                style={{
                  backgroundColor: "var(--button2)",
                  color: "white",
                  width: "7%",
                  marginLeft: "2%",
                }}
                onClick={() => {
                  setDeletepopupshow(true);
                  settextareadata("");
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </ScrollView>
      </div>
    </>
  );
};
