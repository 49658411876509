import axios from "axios";
import { logOut } from "../../../components/Logout";
import { TextBox, NumberBox, DateBox } from "devextreme-react";
import { ColumnDropdown } from "../../../components/Dropdownn";
import "./AddDocket.css";
import { useEffect, useState } from "react";
import { AdDocketFunction } from "./AdDocketFunction";
import { useNavigate } from "react-router-dom";
import { PersonFind } from "../../PersonFind/PersonFind";
import { useSelector } from "react-redux";
import { LoadPanel } from "devextreme-react";
import { Toster } from "../../../components/Toster/Toster";
import { useDispatch } from "react-redux";
import { handleGetLocal } from "../../../services/auth-service";
import { getAccessData } from "../../../utils/getAccessPermission";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, searchMode }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        margin={"7px"}
        reset={reset}
        searchMode={searchMode}
      />
    </>
  );
};

export const AddDocket = ({ addDocketPopupshow, setaddDocketPopupshow, userinfo, updateDocketManagement }) => {
  const { userAccessData } = useSelector((store) => store.Home);

  const pathname = handleGetLocal("pathURL");

  const [loaging, setLoading] = useState(false);
  const [Courtdropdown, setCourtdropdown] = useState([]);
  const [Judgedropdown, setJudgedropdown] = useState([]);
  const [Offensedropdown, setOffensedropdown] = useState([]);
  const [defaultsupervisionstatus, setdefaultsupervisionstatus] = useState([]);
  const [defaultoffence, setdefaultoffence] = useState([]);

  const [typedropdown, settypedropdown] = useState([]);
  const [SupervisionStatusdropdown, setSupervisionStatusdropdown] = useState([]);
  const [supervisorDropdown, setSupervisorDropdown] = useState([]);

  const [countvalidation, setcountvalidation] = useState("");
  const [Offensevalidation, setOffensevalidation] = useState("");
  const [DocketNUmbervalidation, setDocketNUmbervalidation] = useState("");
  const [typevalidation, settypevalidation] = useState("");
  const [supervisorvalidation, setsupervisorvalidation] = useState("");

  const [defaultDocketTypeName, setDefaultDocketTypeName] = useState("");
  const [isSupervisionEnabled, setIsSupervisionEnabled] = useState(false);

  const { goToDocketPerson } = useSelector((store) => store.participate);
  const navigate = useNavigate();

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);

  const clas = new AdDocketFunction();
  const [popupOPen, setpopupOPen] = useState();
  const [field, setFields] = useState({
    "Docket Number": "",
    "Docket Number count": 1,
    Type: { name: "", id: "" },
    "Create/Sentence Date": new Date().toISOString().split("T")[0] + "T00:00:00",
    Person: "",
    Court: { name: "", id: "" },
    Judge: { name: "", id: "" },
    Offence: { name: "", id: "" },
    "First Offender": false,
    "Conditional Discharge": false,
    "Supervision Status": { name: "", id: "" },
    Supervisor: { name: "", id: "" },
    "Start Date": new Date().toISOString().split("T")[0] + "T00:00:00",
    Month: 0,
    Days: 0,
    "End Date": new Date().toISOString().split("T")[0] + "T00:00:00",
  });

  const [dateError, setDateError] = useState(false);

  const getSupervisionAdjustmentPeriod = async () => {
    
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/Account/getCompanyOption?Name=Supervision Adjustment Period Enabled`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      );
      setLoading(false);
      if (response.data.isSuccess) {
        setIsSupervisionEnabled(response.data.data.toLowerCase() === "true");
      }
    } catch (error) {
      setLoading(false);
      if (!error.response) {
        console.log('error-', error );
        navigate(`/error/500`);
      } else if (error.response.status === 401) {
        logOut();
      }
    }
  };

  const isDateAllowed = (date) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const dateToCheck = new Date(date);
    if (isSupervisionEnabled) {
      return dateToCheck.getMonth() === currentMonth && dateToCheck.getFullYear() === currentDate.getFullYear();
    }
    return true;
  };

  useEffect(() => {
    getSupervisionAdjustmentPeriod();
    clas?.getDropdownApi(setLoading, navigate, setCourtdropdown, "Court");
    clas?.getDropdownApi(setLoading, navigate, setSupervisionStatusdropdown, "Supervision Status");
    clas?.getDropDown(setLoading, navigate, settypedropdown, setOffensedropdown);
    clas?.getDropdownApi(setLoading, navigate, setdefaultsupervisionstatus, "Default Docket Status");
    clas?.getDropdownApi(setLoading, navigate, setdefaultoffence, "Default Offense");
    clas?.getDropdownApi(setLoading, navigate, setSupervisorDropdown, "Supervisor");
    clas.getDefaultDocketType(setLoading, navigate, setDefaultDocketTypeName);

    getUserAccessPermissions();
  }, []);

  const getUserAccessPermissions = () => {
    const accessData = getAccessData(userAccessData, "DK6");
    setAddAccess(accessData?.Add);
    setEditAccess(accessData?.Edit);
    setViewAccess(accessData?.View);
  };

  const dispatch = useDispatch();

  const Save = () => {
    if (
      field?.["Court"]?.id &&
      field?.["Offence"]?.id &&
      field?.["Docket Number"] &&
      field?.["Type"]?.id &&
      field?.["Supervision Status"]?.id
    ) {
      setcountvalidation("");
      setOffensevalidation("");
      setDocketNUmbervalidation("");
      settypevalidation("");
      setsupervisorvalidation("");
      clas?.addDocket(
        setLoading,
        navigate,
        {
          GDocketId: "00000000-0000-0000-0000-000000000000",
          GEntryUserID: localStorage.getItem("gUserId"),
          SRefYear: String(new Date().getFullYear()).slice(2, 4),
          SRefSequence: null,
          SDocketNumber: field?.["Docket Number"],
          ICourtCounts: field?.["Docket Number count"],
          GDocketTypeId: field?.["Type"]?.id,
          dCourtSentence: field?.["Create/Sentence Date"],
          GPersonId: goToDocketPerson?.id ? goToDocketPerson?.id : userinfo?.gPersonId,
          GSentenceCourtEntityId: field?.["Court"]?.id,
          gSentenceCourtContactID: field?.["Judge"]?.id,
          GOffenseId: field?.["Offence"]?.id,
          BFirstOffender: field?.["First Offender"],
          BConditionalDischarge: field?.["Conditional Discharge"],
          GSupervisionStatusId: field?.["Supervision Status"]?.id,
          dSentenceStart: field?.["Start Date"],
          iSentenceMonths: field?.["Month"],
          iSentenceDays: field?.["Days"],
          dSentenceEnd: field?.["End Date"],
          GSupervisorId: field?.Supervisor?.id,
        },
        toastConfig,
        setToastConfig,
        setaddDocketPopupshow,
        dispatch,
        userinfo?.next,
        updateDocketManagement
      );
    } else {
      !field?.["Court"]?.id && setcountvalidation("Please Select Court");
      !field?.["Offence"]?.id && setOffensevalidation("Please Select Offence");
      !field?.["Docket Number"] && setDocketNUmbervalidation("Please enter the Docket Number");

      !field?.["Type"]?.id && settypevalidation("Please Select Type");
      !field?.["Supervision Status"]?.id && setsupervisorvalidation("Please Select Supervision Status");
    }
  };

  function addMonthsToDate(monthsToAdd) {
    let date = new Date(field['Start Date']);
    var newDate = new Date(date.setMonth(date.getMonth() + monthsToAdd));
    return newDate;
  }

  useEffect(() => {
    if (field?.Court?.id) {
      clas.getJugge(setLoading, field?.Court?.id, setJudgedropdown, setFields, field);
    }
  }, [field?.Court?.id]);

  useEffect(() => {
    setcountvalidation("");
    setOffensevalidation("");
    setDocketNUmbervalidation("");
    settypevalidation("");
    setsupervisorvalidation("");
    let defaultDocketType = typedropdown[0];
    if (defaultDocketTypeName)
      defaultDocketType = typedropdown.find((docketType) => docketType?.name === defaultDocketTypeName);
    const defaultStatus = SupervisionStatusdropdown.find((status) => status.id === defaultsupervisionstatus[0]?.id);
    setFields({
      "Docket Number": "",
      "Docket Number count": 1,
      Type: { name: defaultDocketType?.name, id: defaultDocketType?.id },
      "Create/Sentence Date": new Date().toISOString().split("T")[0] + "T00:00:00",
      Person: "",
      Court: { name: "", id: "" },
      Judge: { name: "", id: "" },
      Offence: { name: defaultoffence[0]?.name, id: defaultoffence[0]?.id },
      "First Offender": false,
      "Conditional Discharge": false,
      "Supervision Status": {
        name: defaultStatus?.name,
        id: defaultStatus?.id,
      },
      "Start Date": new Date().toISOString().split("T")[0] + "T00:00:00",
      Month: 0,
      Days: 0,
      "End Date": new Date().toISOString().split("T")[0] + "T00:00:00",
    });
  }, [addDocketPopupshow, defaultsupervisionstatus, defaultoffence, defaultDocketTypeName]);

  var currentDate = new Date();
  var startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  var endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  var startDateFormatted = startDate.getMonth() + 1 + "/" + startDate.getDate() + "/" + startDate.getFullYear();
  var endDateFormatted = endDate.getMonth() + 1 + "/" + endDate.getDate() + "/" + endDate.getFullYear();

  return (
    <>
      <LoadPanel shadingColor="true" visible={loaging} delay={10} />
      <PersonFind nojump={true} isPopupVisible={popupOPen} setIsPopupVisible={setpopupOPen} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
       
        <div style={{ border: "3px solid  var(--button2)", height: "898px" }} className="PersonModalbody">
        <div className="PersonModal">
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              width: "38%",
            }}
            className="modelHeader"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                marginTop: "4px",
                marginLeft: "8%",
              }}
            >
              <path
                fill="white"
                d="M12.5006 10.9999H11.7106L11.4306 10.7299C12.6306 9.32989 13.2506 7.41989 12.9106 5.38989C12.4406 2.60989 10.1206 0.389893 7.32063 0.0498932C3.09063 -0.470107 -0.469374 3.08989 0.0506256 7.31989C0.390626 10.1199 2.61063 12.4399 5.39063 12.9099C7.42063 13.2499 9.33063 12.6299 10.7306 11.4299L11.0006 11.7099V12.4999L15.2506 16.7499C15.6606 17.1599 16.3306 17.1599 16.7406 16.7499C17.1506 16.3399 17.1506 15.6699 16.7406 15.2599L12.5006 10.9999ZM6.50063 10.9999C4.01063 10.9999 2.00063 8.98989 2.00063 6.49989C2.00063 4.00989 4.01063 1.99989 6.50063 1.99989C8.99063 1.99989 11.0006 4.00989 11.0006 6.49989C11.0006 8.98989 8.99063 10.9999 6.50063 10.9999Z"
              />
            </svg>
            <p
              style={{
                color: "white",
                fontWeight: "500",
                fontSize: "13px",
              }}
            >
              Docket Quick Add
            </p>
            <span
              style={{
                padding: "2px 9px",
                gap: "8px",
                width: "45px",
                height: "24px",
                background: "#FEF2F2",
                borderRadius: "4px",
                marginTop: "-1%,",
              }}
            >
              <p
                style={{
                  color: "#DC2626",
                  fontFamily: "Readex Pro",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "20px",
                  width: "21px",
                  height: "20px",
                }}
              >
                DK.6
              </p>
            </span>
          </div>
          <span
            style={{
              fontSize: "30px",
              marginRight: "2%",
              cursor: "pointer",
              color: "white",
            }}
            onClick={() => {
              setaddDocketPopupshow(false);
            }}
          >
            &times;
          </span>
        </div>
        {
        viewAccess ?
        <div className="AddDocketBLock"
          style={{
            pointerEvents: (editAccess || addAccess) ? "auto" : "none",
            opacity: (editAccess || addAccess) ? 1 : 0.6,
            cursor: (editAccess || addAccess) ? "default" : "not-allowed"
          }}
        >
          <div className="AddDocketBLockfield">
            <p>Docket Number</p>
            <TextBox
              style={{ marginTop: "3%", width: "33.5%" }}
              onValueChange={(e) => {
                setDocketNUmbervalidation("");
                setFields({ ...field, "Docket Number": e });
              }}
              isValid={true}
              value={field?.["Docket Number"]}
              placeholder={"Docket Number"}
            />
            <NumberBox
              style={{ marginTop: "2.9%", marginLeft: "10px" }}
              onKeyDown={(e) => {
                const { event } = e;
                const str = event.key || String.fromCharCode(event.which);
                if (/^[.,e]$/.test(str)) {
                  event.preventDefault();
                }
              }}
              value={field?.["Docket Number count"]}
              onValueChange={(e) => {
                setFields({ ...field, "Docket Number count": e });
              }}
              min={1}
            />
          </div>
          <p
            style={{
              color: "red",
              fontWeight: "400",
              marginLeft: "31.5%",
            }}
          >
            {DocketNUmbervalidation}
          </p>

          <div className="AddDocketBLockfield">
            <p>
              Type <span>*</span>
            </p>
            <DropDown
              dropDownData={typedropdown}
              fieldNmae={"Type"}
              fields={field}
              setfields={setFields}
              reset={settypevalidation}
            />
          </div>
          <p
            style={{
              color: "red",
              fontWeight: "400",
              marginLeft: "31.5%",
            }}
          >
            {typevalidation}
          </p>

          <div className="AddDocketBLockfield">
            <p>Create/Sentence Date</p>
            <DateBox
              style={{ marginTop: "3%", width: "33.5%" }}
              value={field?.["Create/Sentence Date"]}
              onValueChange={(e) => {
                setFields({
                  ...field,
                  "Create/Sentence Date": e ? e : null,
                });
              }}
              useMaskBehavior={true}
            />
          </div>

          <div className="AddDocketBLockfield">
            <p>Person</p>
            <TextBox
              style={{ marginTop: "3%", width: "33.5%" }}
              disabled={true}
              isValid={true}
              value={goToDocketPerson?.name ? goToDocketPerson?.name : userinfo?.sLastFirst}
              placeholder={"Person"}
            />
            <button
              onClick={() => {
                setpopupOPen(true);
              }}
              style={{
                display: "flex",
                marginTop: "2%",
                marginLeft: "2%",
                width: "10%",
                cursor: "pointer",
                background: "var(--button-lightblack-lightblue)",
                color: "var(--TextColor)",
              }}
            >
              <svg
                className="svg-black-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="arrow_forward" clipPath="url(#clip0_5440_11577)">
                  <path
                    id="Vector"
                    d="M5 13.0007H16.17L11.29 17.8807C10.9 18.2707 10.9 18.9107 11.29 19.3007C11.68 19.6907 12.31 19.6907 12.7 19.3007L19.29 12.7107C19.68 12.3207 19.68 11.6907 19.29 11.3007L12.71 4.7007C12.32 4.3107 11.69 4.3107 11.3 4.7007C10.91 5.0907 10.91 5.7207 11.3 6.1107L16.17 11.0007H5C4.45 11.0007 4 11.4507 4 12.0007C4 12.5507 4.45 13.0007 5 13.0007Z"
                    fill="#424167"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5440_11577">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>

          <div className="AddDocketBLockfield">
            <p>
              Court <span>*</span>
            </p>
            <DropDown
              dropDownData={Courtdropdown}
              fieldNmae={"Court"}
              fields={field}
              setfields={setFields}
              reset={setcountvalidation}
              searchMode={"startsWith"}
            />
          </div>
          <p
            style={{
              color: "red",
              fontWeight: "400",
              marginLeft: "31.5%",
            }}
          >
            {countvalidation}
          </p>

          <div className="AddDocketBLockfield">
            <p>Judge</p>
            <DropDown dropDownData={Judgedropdown} fieldNmae={"Judge"} fields={field} setfields={setFields} />
          </div>

          <div className="AddDocketBLockfield">
            <p>
              Offense <span>*</span>
            </p>
            <DropDown
              dropDownData={Offensedropdown}
              fieldNmae={"Offence"}
              fields={field}
              setfields={setFields}
              reset={setOffensevalidation}
            />
          </div>
          <p
            style={{
              color: "red",
              fontWeight: "400",
              marginLeft: "31.5%",
            }}
          >
            {Offensevalidation}
          </p>

          <div className="AddDocketBLockfield">
            <input
              checked={field?.["First Offender"]}
              onClick={() => {
                setFields({
                  ...field,
                  "First Offender": !field?.["First Offender"],
                });
              }}
              style={{ marginTop: "2%" }}
              type="checkbox"
            />
            <p>First Offender</p>
            <input
              checked={field?.["Conditional Discharge"]}
              onClick={() => {
                setFields({
                  ...field,
                  "Conditional Discharge": !field?.["Conditional Discharge"],
                });
              }}
              style={{ marginTop: "2%" }}
              type="checkbox"
            />
            <p style={{ width: "200px" }}>Conditional Discharge</p>
          </div>

          <div className="AddDocketBLockfield">
            <p>
              Supervision Status <span>*</span>
            </p>
            <DropDown
              dropDownData={SupervisionStatusdropdown}
              fieldNmae={"Supervision Status"}
              fields={field}
              setfields={setFields}
              reset={setsupervisorvalidation}
            />
          </div>
          <p
            style={{
              color: "red",
              fontWeight: "400",
              marginLeft: "31.5%",
            }}
          >
            {supervisorvalidation}
          </p>

          <div className="AddDocketBLockfield">
            <p>Primary Supervisor</p>
            <DropDown dropDownData={supervisorDropdown} fieldNmae={"Supervisor"} fields={field} setfields={setFields} />
          </div>

          <div className="AddDocketBLockfield">
            <p>Start Date</p>
            
            <DateBox
              disabled={isSupervisionEnabled}
              style={{ marginTop: "3%", width: "33.5%" }}
              value={field["Start Date"]}
              onValueChange={(e) => {
                let startDateUnix = Math.floor(new Date(e).getTime() / 1000);
                let endDateUnix = Math.floor(new Date(field['End Date']).getTime() / 1000);
                if(endDateUnix < startDateUnix) {
                  setDateError(true);
                } else {
                  setDateError(false);
                }
                if (isDateAllowed(e)) {
                  setFields({
                    ...field,
                    "Start Date": e ? e : null,
                  });
                } else {
                  alert("Selected date is not allowed.");
                }
              }}
              useMaskBehavior={true}
            />
            {isSupervisionEnabled && (
              <p style={{ marginLeft: "10px" }}>
                Valid Date: {startDateFormatted} through {endDateFormatted}{" "}
              </p>
            )}
          </div>
          <div className="AddDocketBLockfield">
            <p>Months</p>
            <NumberBox
              style={{ marginTop: "3%", width: "33.5%" }}
              onKeyDown={(e) => {
                const { event } = e;
                const str = event.key || String.fromCharCode(event.which);
                if (/^[.,e]$/.test(str)) {
                  event.preventDefault();
                }
              }}
              value={field?.["Month"]}
              onValueChange={(e) => {
                setFields({
                  ...field,
                  Month: e,
                  "End Date": addMonthsToDate(e),
                  Days: 0,
                });
              }}
              placeholder={"Zip Code"}
              defaultValue={0}
            />
          </div>

          <div className="AddDocketBLockfield">
            <p>or</p>
          </div>

          <div className="AddDocketBLockfield">
            <p>Days</p>
            <NumberBox
              style={{ marginTop: "3%", width: "33.5%" }}
              onKeyDown={(e) => {
                const { event } = e;
                const str = event.key || String.fromCharCode(event.which);
                if (/^[.,e]$/.test(str)) {
                  event.preventDefault();
                }
              }}
              value={field?.["Days"]}
              min={0}
              onValueChange={(e) => {
                function addDaysToDate(daysToAdd) {
                  var currentDate = new Date(field['Start Date']);
                  currentDate.setDate(currentDate.getDate() + daysToAdd);
                  return currentDate;
                }
                var inputDays = e;
                var newDate = addDaysToDate(inputDays);

                setFields({
                  ...field,
                  Days: e,
                  "End Date": newDate,
                  Month: 0,
                });
              }}
            />
          </div>

          <div className="AddDocketBLockfield">
            <p>or</p>
          </div>

          <div className="AddDocketBLockfield">
            <p>End Date</p>
            <DateBox
              style={{ marginTop: "3%", width: "33.5%" }}
              value={field?.["End Date"]}
              onValueChange={(e) => {
                let startDateUnix = Math.floor(new Date(field['Start Date']).getTime() / 1000);
                let endDateUnix = Math.floor(new Date(e).getTime() / 1000);
                if(endDateUnix < startDateUnix) {
                  setDateError(true);
                } else {
                  setDateError(false);
                }
                function getDaysDifferenceFromDate(newDate) {
                  var currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
                  var differenceInMilliseconds = newDate - currentDate;
                  var differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

                  return differenceInDays;
                }
                var inputNewDate = new Date(e);
                var daysDifference = getDaysDifferenceFromDate(inputNewDate);

                setFields({
                  ...field,
                  "End Date": e ? e : null,
                  Month: 0,
                  Days: daysDifference,
                });
              }}
              useMaskBehavior={true}
            />
          <p style={{ color: "red", fontSize: "13px", marginLeft: "10px", fontWeight: 'normal' }}>{dateError && "End date should not be less than start date."}</p>
          </div>
          <p style={{ color: "red", fontSize: "13px", marginTop: "15px" }}>
            NOTE : If this is a Consecutive or Concurrent docket you must edit the docket to establish the parent /
            child relationship.
          </p>

          <div className="popup_buttons" style={{ marginTop: "2%", marginBottom: "2%" }}>
            <button
              disabled={dateError}
              className="btn primary_btn"
              onClick={() => {
                Save();
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                setaddDocketPopupshow(false);
              }}
              className="btn"
            >
              Cancel
            </button>
          </div>
        </div>
        : 
        "No view access"
      }
      </div>
       
    </>
  );
};
