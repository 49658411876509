import { useRef, useState, useEffect } from "react";
import { LoadPanel, ScrollView } from "devextreme-react";
import { Table2 } from "../../../components/Table2/Table2";
import { handleGetLocal } from "../../../services/auth-service";
import axios from "axios";
import { useSelector } from "react-redux";
import { ColumnDropdown } from "../../../components/Dropdownn";
import { logOut } from "../../../components/Logout";
import { useNavigate } from "react-router-dom";
import { Toster } from "../../../components/Toster/Toster";
import { getAccessData } from "../../../utils/getAccessPermission";

const Tablecoloum = [
  { title: "Docket #", editable: false },
  { title: "Active", editable: false, bool: true, checkIconBool: true },
  { title: "Obligation Ref", editable: false },
  { title: "Alert", bool: true, editable: false },
  { title: "Plan Start", editable: false },
  { title: "Obligation Start", date: true, editable: false },
  { title: "Fee Code", editable: false },
  { title: "No Adv Pay", bool: true, editable: false },
  { title: "Amount Due", number: true, editable: false, currency: true },
  { title: "Balance Due", number: true, editable: false, currency: true },
  { title: "Arrears", number: true, editable: false, currency: true },
  { title: "Amount to Pay", number: true, editable: true, currency: true },
];

const setTableData = (dataa) => {
  const arr = [];
  dataa?.map((val, i) => {
    arr.push({
      "Docket #": val?.sDocketNumberCourtCounts,
      Active: val?.bFinancialStatusActive,
      "Obligation Ref": val?.sReference,
      Alert: val?.bPaymentAlert,
      "Plan Start": val?.dPaymentPlanStart,
      "Obligation Start": val?.dActive,
      "Fee Code": val?.sLocalLegalCodeFull,
      "No Adv Pay": val?.bNoAdvancedPayment,
      Priority: val?.iPayPriority,
      "Amount Due": Number(val?.fAmount),
      "Balance Due": Number(val?.fBalance),
      "Amount Payable": val?.fBalanceEligible,
      Arrears: Number(val?.fArrears),
      ID: i,
      "Amount to Pay": Number(val?.fAmountToPay),
      gObligationId: val?.gObligationId,
    });
  });
  return arr;
};

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae].name}
        reset={reset}
      />
    </>
  );
};

export const RecivePaymentModel = ({
  recivePaymentModel,
  setRecivePaymentModel,
  editParticipant,
  userinfo,
  name,
  setPaymentModal,
  personID,
  gPersonId,
}) => {
  const componentRef = useRef(null);
  const pathname = handleGetLocal("pathURL");

  const { userAccessData } = useSelector((store) => store.Home);
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);

  const { recevePaymentReference, recivepaymentPiReset } = useSelector((store) => store.participate);

  const [visibleRows, setVisibleRows] = useState([]);

  const [totalPay, setTotalPay] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  const [bankAccount, setbankAccount] = useState([]);
  const [cashDrawer, setcashDrawer] = useState([]);
  const [paymentType, setpaymentType] = useState([]);
  const [data, setdata] = useState([]);
  const [tablebody, settablebody] = useState("");
  const [processPaymentData, setProcessPaymentData] = useState([]);
  const [paymentNote, setpaymentNote] = useState("");
  const [sentenceNotes, setsentenceNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onTimeApi, setonTimeApi] = useState(false);
  const { user_data } = useSelector((store) => store.Home);
  const [fields, setfieldes] = useState({
    Reference: recevePaymentReference,
    AmountReceived: 0,
    useCredit: false,
    Override: false,
    BankAccount: { name: "", id: "" },
    PaymentMethod: { name: "", id: "" },
    CashDrawer: {
      name: user_data?.sCashDrawerName ? user_data?.sCashDrawerName : "",
      id: user_data?.gCashDrawerId ? user_data?.gCashDrawerId : "",
    },
    chek: false,
    receiptDate: new Date().toISOString().slice(0, 10),
    manualReceipt: "",
    receiptNote: "",
  });
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const navigate = useNavigate();
  const [errorhandling, seterrorhandling] = useState(false);
  const [errorhandling2, seterrorhandling2] = useState(false);
  const [errorValidation, setErrorValidation] = useState("");
  const [reportFolder, setReportFolder] = useState("");
  const [creditErrorPopup, setCreditErrorPopup] = useState(false);

  const processpaymentpostdata = {
    paymentAllocationList: processPaymentData,
    personID: userinfo?.gPersonId,
    paymentAmount: fields?.AmountReceived != 0 ? fields?.AmountReceived : 0,
    useCredit: fields.useCredit,
    noReceiptClicked: false,
    cashDrawerID: fields.CashDrawer.id,
    referenceNumber: fields.Reference,
    receiptNote: fields.receiptNote,
    manualNumber: fields.manualReceipt,
    paymentNote: fields.paymentNote,
    paymentMethodId: fields.PaymentMethod.id,
  };

  const getReportFolder = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/Account/getCompanyOption?Name=Receipt File Name`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setReportFolder(val.data.data);
      })
      .catch((val) => {
        setLoading(false);
      });
  };

  const handleReceiptNumber = (receiptNumber) => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_CRYSTAL_API_URL}/reports-api/payment/get-report-by-rn?receiptNumber=${receiptNumber}&reportFolder=${reportFolder}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
          responseType: "blob",
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.size > 20) {
          const pdfBlob = new Blob([val.data], {
            type: "application/pdf",
          });
          const pdfUrl = URL.createObjectURL(pdfBlob);
          const downloadLink = document.createElement("a");
          downloadLink.href = pdfUrl;
          downloadLink.download = "PY1_Receipt" + ".pdf";
          downloadLink.style.display = "none";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      })
      .catch((val) => {
        setLoading(false);
      });
  };

  const PaymentaLocation = async () => {
    const newBody = visibleRows.map((row) => {
      return { ...tablebody[row.data.ID], fAmountToPay: 0 };
    });
    if (newBody.length === 0) return;
    setLoading(true);
    let amountReceived = fields.AmountReceived ? parseInt(fields.AmountReceived) : 0;
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/Payment/allocatePayment?paymentAmount=${amountReceived}&useCredit=${fields.useCredit}&personid=${userinfo?.gPersonId}`,
        newBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        let map = new Map(val.data.data.paymentList.map((obj) => [obj.gObligationId, obj]));
        const updatedTable = tablebody.map((obj) => {
          if (map.has(obj.gObligationId)) {
            return map.get(obj.gObligationId);
          }
          return obj;
        });
        settablebody(updatedTable);
        setdata(setTableData(updatedTable));
        setTotalCredit(val?.data?.data?.remainingAmount?.toFixed(2));
        const modifiedData = val?.data?.data?.paymentList.map((item) => ({
          obligationid: item.gObligationId,
          amount: item.fAmountToPay,
        }));
        setProcessPaymentData(modifiedData);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    const totalAmount = data.reduce((amountToPay, row) => (amountToPay += row["Amount to Pay"]), 0);
    setTotalPay(totalAmount);
    if (fields.AmountReceived > totalAmount) setTotalCredit((fields?.AmountReceived - totalAmount)?.toFixed(2));
  }, [data]);

  const ProcessPayment = async (flag) => {
    let isValid = true;
    if (fields?.PaymentMethod.id === "") {
      isValid = false;
      setErrorValidation("Payment Method Required");
    }
    if (fields?.CashDrawer.id === "") {
      isValid = false;
      setErrorValidation("Cash Drawer Required");
    }
    const modifiedData = data.map((item) => ({
      obligationid: item.gObligationId,
      amount: item["Amount to Pay"],
    }));
    processpaymentpostdata.paymentAllocationList = modifiedData;
    if (isValid) {
      processpaymentpostdata.noReceiptClicked = flag;
      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/Payment/processPayment`, processpaymentpostdata, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        })
        .then((val) => {
          setLoading(false);
          if (val.data.isSuccess) {
            setToastConfig({
              ...toastConfig,
              isVisible: true,
              message: "Apply payment successfully",
              type: "success",
            });
            setTimeout(() => {
              setToastConfig({
                ...toastConfig,
                isVisible: false,
                message: "Apply payment successfully",
                type: "success",
              });
            }, 1900);
            setRecivePaymentModel(false);
            if (!flag) {
              handleReceiptNumber(val?.data?.data?.sTransactionID);
            }
          } else {
            setToastConfig({
              ...toastConfig,
              isVisible: true,
              message: val.data.errors[0],
              type: "error",
            });
            setTimeout(() => {
              setToastConfig({
                ...toastConfig,
                isVisible: false,
                message: val.data.errors[0],
                type: "error",
              });
            }, 5000);
          }
        })
        .catch((val) => {
          setLoading(false);

          if (!val?.response) {
            navigate(`/error/500`);
          } else if (val?.response?.status == 401) {
            logOut();
          }
        });
    }
  };

  const ref2 = useRef();
  useOutsideClick(ref2, async () => {
    if (onTimeApi) {
      setonTimeApi(false);
      // PaymentaLocation();
    }
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setfieldes((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getMasterDropDown = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Payment/masterDropDownValuesGet`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          seterrorhandling(false);
          setbankAccount(
            val.data.data.bankAccount.map((val) => {
              return {
                name: val.value,
                id: val.key,
              };
            })
          );
          setcashDrawer(
            val.data.data.cashDrawer.map((val) => {
              return {
                name: val.value,
                id: val.key,
              };
            })
          );
          setpaymentType(
            val.data.data.paymentType.map((val) => {
              return {
                name: val.value,
                id: val.key,
              };
            })
          );
        } else {
          seterrorhandling(true);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        } else {
          seterrorhandling(true);
        }
      });
  };

  const getTableData = async (personid) => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Payment/ReceivePaymentScreenValuesGet?personid=${personid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        settablebody(val?.data?.data?.receivePaymentGrid);
        setdata(setTableData(val?.data?.data?.receivePaymentGrid));
        setpaymentNote(val?.data?.data?.paymentNote);
        setsentenceNotes(val?.data?.data?.sentenceNotes ? val?.data?.data?.sentenceNotes : []);
        const modifiedData = val?.data?.data?.receivePaymentGrid.map((item) => ({
          obligationid: item.gObligationId,
          amount: item.fAmountToPay,
        }));
        setProcessPaymentData(modifiedData);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    PaymentaLocation();
  }, [fields.AmountReceived]);

  useEffect(() => {}, [visibleRows]);

  const getUserAccessPermissions = () => {
    const accessData = getAccessData(userAccessData, "FN5");
    setAddAccess(accessData?.Add);
    setEditAccess(accessData?.Edit);
    setViewAccess(accessData?.View);
  };

  useEffect(() => {
    getUserAccessPermissions();
    getReportFolder();
  }, []);

  const Reset = () => {
    setfieldes({
      Reference: recevePaymentReference,
      AmountReceived: "",
      useCredit: false,
      Override: false,
      BankAccount: { name: "", id: "" },
      PaymentMethod: { name: "", id: "" },
      CashDrawer: {
        name: user_data?.sCashDrawerName ? user_data?.sCashDrawerName : "",
        id: user_data?.gCashDrawerId ? user_data?.gCashDrawerId : "",
      },
      chek: false,
      receiptDate: new Date().toISOString().slice(0, 10),
      manualReceipt: "",
      receiptNote: "",
    });
  };

  const exportReport = async () => {
    setLoading(true);
    // await axios
    //   .get(`https://crystal.firsttry.live/reports-api/payment/get-report`)
    //   .then((val) => {
    //     setLoading(false);
    //     const openFileInNewTab = (base64Data, fileName, fileType) => {
    //       const byteCharacters = atob(base64Data);
    //       const byteNumbers = new Array(byteCharacters.length);
    //       for (let i = 0; i < byteCharacters.length; i++) {
    //         byteNumbers[i] = byteCharacters.charCodeAt(i);
    //       }
    //       const byteArray = new Uint8Array(byteNumbers);
    //       const blob = new Blob([byteArray], { type: fileType });
    //       const url = URL.createObjectURL(blob);

    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.download = fileName;
    //       link.target = "_blank";
    //       link.rel = "noopener noreferrer";
    //       link.click();

    //       URL.revokeObjectURL(url);
    //     };

    //     const imageData = val?.data?.ReportFile;
    //     let imageType = "application/pdf";
    //     const imageName = val?.data?.FileName;

    //     openFileInNewTab(imageData, imageName, imageType);
    //   })
    //   .catch((val) => {
    //     setLoading(false);
    //     if (!val?.response) {
    //       navigate(`/error/500`);
    //     } else if (val?.response?.status == 401) {
    //       logOut();
    //     }
    //   });
    PaymentaLocation();
  };

  const handleApplyPayment = async (flag) => {
     ProcessPayment(flag);
  };

  useEffect(() => {
    getMasterDropDown();
    if (userinfo?.gPersonId) {
      getTableData(userinfo?.gPersonId);
    }
    if (gPersonId) {
      getTableData(gPersonId);
    }
    Reset();
  }, [recivePaymentModel, userinfo?.gPersonId, gPersonId]);

  const clear = () => {
    if (userinfo?.gPersonId) {
      getTableData(userinfo?.gPersonId);
    }
    if (gPersonId) {
      getTableData(gPersonId);
    }
  };

  const updateTotalAmount = (amount, i) => {
    const totalAmount = data.reduce((amountToPay, row) => (amountToPay += row["Amount to Pay"]), 0);
    setTotalPay(totalAmount + amount - data[i]["Amount to Pay"]);
    if (fields.AmountReceived > totalAmount) setTotalCredit((fields.AmountReceived - (totalAmount + amount - data[i]["Amount to Pay"]))?.toFixed(2));
  }

  useEffect(() => {
    if (fields.chek) {
      if (!fields.Override) {
        // PaymentaLocation();
      }
    }
  }, [fields.Override]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <ScrollView
        style={{
          width: "100%",
          height: "858px",
          border: "3px solid rgb(67, 66, 102)",
        }}
      >
        <div ref={componentRef}>
          <div className="RecivePaymentModel_header">
            <div className="RecivePaymentModel_header_name">
              <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M22 4V14C22 15.1 21.1 16 20 16H4C3.45 16 3 15.55 3 15C3 14.45 3.45 14 4 14H20V4C20 3.45 20.45 3 21 3C21.55 3 22 3.45 22 4ZM3 12C1.34 12 0 10.66 0 9V3C0 1.34 1.34 0 3 0H15C16.66 0 18 1.34 18 3V10C18 11.1 17.1 12 16 12H3ZM6 6C6 7.66 7.34 9 9 9C10.66 9 12 7.66 12 6C12 4.34 10.66 3 9 3C7.34 3 6 4.34 6 6Z"
                  fill="white"
                />
              </svg>
              <p>Receive Payment </p>
              <span
                style={{
                  padding: "2px 9px",
                  gap: "8px",
                  width: "45px",
                  height: "24px",
                  background: "#FEF2F2",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    color: "#DC2626",
                    fontFamily: "Readex Pro",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "10px",
                    lineHeight: "20px",
                    width: "21px",
                    height: "20px",
                  }}
                >
                  FN.5
                </p>
              </span>
            </div>

            <svg
              onClick={() => {
                setRecivePaymentModel(false);
              }}
              style={{
                cursor: "pointer",
                marginTop: "17px",
                marginRight: "2%",
              }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
                fill="white"
              />
            </svg>
          </div>

          {viewAccess ? (
            <div
              style={{
                pointerEvents: editAccess ? "auto" : "none",
                opacity: editAccess ? 1 : 0.6,
                cursor: editAccess ? "default" : "not-allowed",
              }}
            >
              <div className="RecivePaymentModel_block1">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "30%",
                    paddingTop: "2%",
                  }}
                >
                  <p style={{ fontWeight: "600", fontSize: "16px" }}>{name}</p>
                  {/* <div>
                <p className="go-to-label-filed">Bank Account</p>
                <DropDown
                  dropDownData={bankAccount}
                  setfields={setfieldes}
                  fields={fields}
                  fieldNmae={"BankAccount"}
                />
              </div> */}
                </div>
                <div style={{ flexGrow: "1" }}>
                  <div className="RecivePaymentModel_block1_sub">
                    <div>
                      <p className="go-to-label-filed">
                        Payment Method <span>*</span>
                      </p>
                      <DropDown
                        dropDownData={paymentType}
                        setfields={setfieldes}
                        fields={fields}
                        fieldNmae={"PaymentMethod"}
                        reset={setErrorValidation}
                      />
                    </div>

                    <div className="RecivePaymentModel_block1_sub_input_block">
                      <p className="go-to-label-filed">Reference</p>
                      <input
                        style={{ marginTop: "3%" }}
                        placeholder={`Enter reference`}
                        value={fields.Reference}
                        name="Reference"
                        id="Reference"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="RecivePaymentModel_block1_sub_input_block">
                      <p className="go-to-label-filed">
                        Amount Received <span>*</span>
                      </p>
                      <input
                        ref={ref2}
                        style={{ marginTop: "3%" }}
                        type="number"
                        value={fields.AmountReceived}
                        placeholder={`$0.00`}
                        onChange={(e) => {
                          setonTimeApi(true);
                          setfieldes({ ...fields, AmountReceived: e.target.value });
                        }}
                        disabled={fields.useCredit}
                      />
                    </div>
                    <div className="RecivePaymentModel_block1_sub_input_block">
                      <p className="go-to-label-filed">Receipt Note</p>
                      <input
                        // className="RecivePaymentModel_block1_sub_textarea"
                        placeholder="Enter Note"
                        name="receiptNote"
                        id="manualReceipt"
                        value={fields?.receiptNote}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="RecivePaymentModel_block1_sub">
                    <div>
                      <p className="go-to-label-filed">Cash Drawer <span>*</span></p>
                      <DropDown
                        dropDownData={cashDrawer}
                        setfields={setfieldes}
                        fields={fields}
                        fieldNmae={"CashDrawer"}
                        reset={setErrorValidation}
                      />
                    </div>
                    <div className="RecivePaymentModel_block1_sub_input_block">
                      <p className="go-to-label-filed">Manual Receipt #</p>
                      <input
                        style={{ marginTop: "3%" }}
                        placeholder={`Enter Manual Receipt`}
                        name="manualReceipt"
                        id="manualReceipt"
                        value={fields?.manualReceipt}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div style={{ marginTop: "2%" }}>
                      <button
                        className="btn"
                        onClick={() => {
                          clear();
                        }}
                      >
                        Clear Allocation
                        <svg
                          className="svg-black-white"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.2987 0.709727C12.9087 0.319727 12.2787 0.319727 11.8887 0.709727L6.99875 5.58973L2.10875 0.699727C1.71875 0.309727 1.08875 0.309727 0.69875 0.699727C0.30875 1.08973 0.30875 1.71973 0.69875 2.10973L5.58875 6.99973L0.69875 11.8897C0.30875 12.2797 0.30875 12.9097 0.69875 13.2997C1.08875 13.6897 1.71875 13.6897 2.10875 13.2997L6.99875 8.40973L11.8887 13.2997C12.2787 13.6897 12.9087 13.6897 13.2987 13.2997C13.6887 12.9097 13.6887 12.2797 13.2987 11.8897L8.40875 6.99973L13.2987 2.10973C13.6787 1.72973 13.6787 1.08973 13.2987 0.709727Z"
                            fill="#424167"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        marginTop: "2%",
                      }}
                    >
                      <input
                        checked={fields.Override}
                        onChange={() => {
                          setfieldes({
                            ...fields,
                            Override: !fields.Override,
                            chek: true,
                          });
                        }}
                        type="checkbox"
                      />
                      <p className="go-to-label-filed">Override</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        marginTop: "2%",
                      }}
                    >
                      <input
                        onChange={() => {
                          if (fields.useCredit === false) {
                            setfieldes((prev) => ({ ...prev, AmountReceived: userinfo?.fCreditBalance }));
                          }
                          setfieldes((prev) => ({ ...prev, useCredit: !fields.useCredit }));
                        }}
                        checked={fields.useCredit}
                        type="checkbox"
                        disabled={!(userinfo?.fCreditBalance > 0)}
                      />
                      <p className="go-to-label-filed">
                        {userinfo?.fCreditBalance > 0
                          ? `Use Credit of $${userinfo?.fCreditBalance}`
                          : "No credit available"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "1.5%", marginTop: "2.5%" }}>
                <Table2
                  editTable={false}
                  height={"auto"}
                  Tablecoloum={Tablecoloum}
                  tableRow={data}
                  load={false}
                  coloumWidthText={"RecivePaymentModelTableWidth"}
                  mandatoryColoum={"Docket #"}
                  showTotalBlock={true}
                  recivePayment={true}
                  editableperticularValue={fields.Override}
                  setCheckedFalse={true}
                  setVisibleRows={setVisibleRows}
                  visibleRows={visibleRows}
                  setTotalPay={setTotalPay}
                  setTotalCredit={setTotalCredit}
                  amountReceived={fields.AmountReceived}
                  updateTotalAmount={updateTotalAmount}
                />
              </div>
              <div className="RecivePaymentModel_block3">
                <div
                  style={{
                    marginLeft: "1.5%",
                    width: "80%",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      justifyContent: "space-between",
                      width: "20%",
                      alignItems: "center",
                    }}
                    className="RecivePaymentModel_block3_p"
                  >
                    <p className="go-to-label-filed">Total Selected to Pay </p>
                    <p style={{ color: "var(--TextColor)", fontWeight: "600" }}>$ {totalPay.toFixed(2)}</p>
                  </div>
                  <div
                    style={{
                      justifyContent: "space-between",
                      width: "14%",
                      alignItems: "center",
                    }}
                    className="RecivePaymentModel_block3_p"
                  >
                    <p className="go-to-label-filed">Total Credit</p>
                    <p style={{ color: "var(--TextColor)", fontWeight: "600" }}>$ {totalCredit}</p>
                  </div>
                  {/* <div
                style={{
                  justifyContent: "space-between",
                  width: "17%",
                  alignItems: "center",
                }}
                className="RecivePaymentModel_block3_p"
              >
                <input type="checkbox" />
                <p style={{ color: "var(--TextColor)", fontWeight: "600" }}>
                  Include Closed F/S
                </p>
              </div> */}
                  {/* <div
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="RecivePaymentModel_block3_p"
              >
                <p className="go-to-label-filed">Cash Drawer</p>
                <p
                  style={{
                    color: "var(--TextColor)",
                    fontWeight: "600",
                    marginLeft: "20px",
                  }}
                >
                  {fields?.CashDrawer.name}
                </p>
              </div> */}
                </div>
                <div style={{ margin: "1.5%", marginTop: "1.5%" }}>
                  <div style={{ flexDirection: "column", width: "50%" }}>
                    <p className="go-to-label-filed">Payment Notes</p>
                    <textarea value={paymentNote} style={{ margin: "1%", height: "80px" }} placeholder="Write note" />
                  </div>
                  <div style={{ flexDirection: "column", width: "50%" }}>
                    <p className="go-to-label-filed">Sentence Notes</p>
                    <textarea
                      value={sentenceNotes?.join(" ")}
                      style={{ margin: "1%", height: "80px" }}
                      placeholder="Write note"
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "1%",
                    marginLeft: "1.5%",
                    marginBottom: "1%",
                    display: "flex",
                    gap: "25px",
                    alignItems: "center",
                  }}
                >
                  {/* <button
                className="primary_btn"
                onClick={() => {
                  exportReport();
                }}
              >
                Done
              </button> */}
                  <button
                    className="primary_btn"
                    onClick={() => {
                      handleApplyPayment(false);
                    }}
                  >
                    Apply Payment
                  </button>
                  <button
                    className="primary_btn"
                    onClick={() => {
                      handleApplyPayment(true);
                    }}
                  >
                    Apply Payment (No Receipt)
                  </button>
                  <button
                    className="primary_btn"
                    onClick={() => {
                      setRecivePaymentModel(false);
                    }}
                  >
                    Cancel
                  </button>
                  <div>
                    {errorValidation && (
                      <p
                        className="error_validation"
                        // style={{ position: "absolute", top: "362px" }}
                      >
                        {errorValidation}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            "No view access"
          )}
        </div>
      </ScrollView>
    </>
  );
};
