import { DateBox, LoadPanel, Popup, SelectBox } from "devextreme-react";
import { SchedulingTabs } from "./SchedulingTabs";
import { useEffect, useRef, useState } from "react";
import { Toster } from "../../components/Toster/Toster";
import "./scheduling.css";
import { ColumnDropdown } from "../../components/Dropdownn";
import { ApiError } from "../Error/ApiError";
import { Table1 } from "../Administration/Table1/Table1";
import { schedulingDateRanges, schedulingTableTitle, setSchedulingTableColumnData } from "./Data";
import axios from "axios";
import { handleGetLocal } from "../../services/auth-service";
import { logOut } from "../../components/Logout";
import { Navigate } from "react-router-dom";
import { SendEmail } from "../person-list/SendEmail";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";
import { dateRangeSelector } from "../../utils/date-helpers";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

export const Scheduling = () => {
  const [loading, setLoading] = useState(false);
  const [schedulingErrorHandling, setSchedulingErrorHandling] = useState(false);
  const [schedulingData, setSchedulingData] = useState([]);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const pathname = handleGetLocal("pathURL");
  const [date, setdate] = useState({
    startDate: new Date(new Date().setDate(1)).toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
  });
  const [quickDatevalue, setquickDatevalue] = useState("Month to Date");
  const today = new Date();
  const [fields, setFields] = useState({
    location: { name: "", id: "" },
    user: { name: "", id: "00000000-0000-0000-0000-000000000000" },
  });
  const [masterDropdownValues, setMasterDropdownValues] = useState([]);
  const [userDropdown, setUserDropdown] = useState([]);
  const [reportDateRowData, seReportDateRowData] = useState({});
  const [prewviewtogal, setprewviewtogal] = useState(undefined);
  const [selectedPersonIds, setSelectedPersonIds] = useState([]);
  const [sendEmailPopup, setSendEmailPopup] = useState(false);

  const getMasterDropdownValues = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/PersonReportDates/masterDropdownValues`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setMasterDropdownValues(val?.data?.data);
        setFields((prev) => ({
          ...prev,
          location: {
            name: val?.data?.data?.locationEntity[0]?.value,
            id: val?.data?.data?.locationEntity[0]?.key,
          },
        }));
      })
      .catch((val) => {
        if (!val?.response) {
          Navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const getUserDropdown = async () => {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Supervisor`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setUserDropdown(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          Navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  useEffect(() => {
    getMasterDropdownValues();
    getUserDropdown();
  }, []);

  useEffect(() => {
    if (fields.location.id) {
      if (date.endDate !== "Invalid Date" && date.startDate !== "Invalid Date") {
        getReportDateRange();
      }
    }
  }, [date, fields]);

  const getReportDateRange = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportDate/get?dStart=${date.startDate}&dEnd=${date.endDate}&gUserId=${fields.user.id}&gEntityId=${fields.location.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          val?.data?.data?.length
            ? setSchedulingData(setSchedulingTableColumnData(val?.data?.data))
            : setSchedulingData(setSchedulingTableColumnData([]));
        } else {
          setSchedulingErrorHandling(true);
        }
      })
      .catch((error) => {
        if (!error?.response) {
          Navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setSchedulingErrorHandling(true);
        }
      });
  };

  const getCheckInApi = async (data) => {
    console.log(data, 'data');
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/ReportDate/participant-checkin?gPersonID=${data?.gPersonId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          getReportDateRange();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Check In!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Check In!",
              type: "success",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        if (!val?.response) {
          Navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const showSendEmailPopup = () => {
    if (selectedPersonIds?.length) {
      setSendEmailPopup(true);
    } else {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        message: "No Person Selected!",
        type: "error",
      });
      setTimeout(() => {
        setToastConfig({
          ...toastConfig,
          isVisible: false,
          message: "No Person Selected!",
          type: "error",
        });
      }, 1900);
    }
  };

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />

      <Popup
        visible={sendEmailPopup}
        onHiding={() => setSendEmailPopup(false)}
        showTitle={false}
        width={1000}
        height={700}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <SendEmail setSendEmailPopup={setSendEmailPopup} personIds={selectedPersonIds} />
      </Popup>

      <div className="page-section">
        <DynamicTabb />
        <div className="page-content">
          <p className="page-info-number">RD.13</p>
          <div className="buttons">
            <button
              className="btn"
              onClick={() => {
                setprewviewtogal(!prewviewtogal);
              }}
            >
              Print
              <svg
                className="svg-blue-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_7019_47972)">
                  <path
                    d="M19 8H5C3.34 8 2 9.34 2 11V15C2 16.1 2.9 17 4 17H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V17H20C21.1 17 22 16.1 22 15V11C22 9.34 20.66 8 19 8ZM15 19H9C8.45 19 8 18.55 8 18V14H16V18C16 18.55 15.55 19 15 19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM17 3H7C6.45 3 6 3.45 6 4V6C6 6.55 6.45 7 7 7H17C17.55 7 18 6.55 18 6V4C18 3.45 17.55 3 17 3Z"
                    fill="#424167"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_7019_47972">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <button
              className="btn"
              onClick={() => {
                setexportPopupOpen(true);
              }}
            >
              Export
              <svg
                className="svg-blue-white"
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                  fill="#424167"
                />
              </svg>
            </button>
            <button
              className="btn"
              onClick={() => {
                getReportDateRange();
              }}
            >
              Refresh
              <svg
                className="svg-blue-white"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_6247_15093)">
                  <path
                    d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                    fill="#424167"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6247_15093">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          <p className="page-info-text-detail">Report Date Range</p>
          <div className="page-details-search-content">
            <div className="display-flex-div">
              <label className="edit_container_label">Date</label>
              <DateBox
                useMaskBehavior={true}
                value={date.startDate}
                onValueChange={(e) => {
                  if (e) {
                    setdate({
                      ...date,
                      startDate: e ? e : null,
                    });
                  }
                }}
              />
            </div>
            <div className="display-flex-div">
              <label className="edit_container_label">To</label>
              <DateBox
                useMaskBehavior={true}
                value={date.endDate}
                onValueChange={(e) => {
                  if (e) {
                    setdate({
                      ...date,
                      endDate: e ? e : null,
                    });
                  }
                }}
              />
            </div>

            <div className="display-flex-div">
              <label className="edit_container_label">Quick Date</label>
              <SelectBox
                dataSource={schedulingDateRanges}
                valueExpr="name"
                displayExpr="name"
                searchEnabled={true}
                searchExpr={"name"}
                value={quickDatevalue}
                dropDownOptions={{ height: "180px" }}
                onItemClick={(e) => {
                  setquickDatevalue(e?.itemData?.name);
                  let { startDate, endDate } = dateRangeSelector(e?.itemData?.name);
                  setdate({
                    startDate: startDate.toISOString().slice(0, 10),
                    endDate: endDate.toISOString().slice(0, 10),
                  });
                }}
              />
            </div>
            <div className="display-flex-div dropdown-space">
              <label className="edit_container_label">Location</label>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"location"}
                dropDownData={masterDropdownValues?.locationEntity?.map((vall) => {
                  return {
                    name: vall?.value,
                    id: vall?.key,
                  };
                })}
              />
            </div>
            <div className="display-flex-div dropdown-space">
              <label className="edit_container_label">User</label>
              <DropDown setfields={setFields} fields={fields} fieldNmae={"user"} dropDownData={userDropdown} />
            </div>
          </div>
          <div className="scheduling-table">
            {schedulingErrorHandling ? (
              <ApiError />
            ) : (
              <Table1
                editTable={false}
                height={"auto"}
                Tablecoloum={schedulingTableTitle}
                tableRow={schedulingData?.map((val, i) => {
                  return { ...val, ID: i };
                })}
                load={loading}
                coloumWidthText={"Report-Date-Range"}
                mandatoryColoum={"Status"}
                exportInDifferentFormate={true}
                exportPopupOpen={exportPopupOpen}
                setexportPopupOpen={setexportPopupOpen}
                exportwidth={27}
                exporttitle={"Report-Date-Range"}
                reportDateRange={true}
                reportDateRowData={reportDateRowData}
                seReportDateRowData={seReportDateRowData}
                getCheckInApi={(data) => getCheckInApi(data)}
                preview={true}
                prewviewtogal={prewviewtogal}
                prewviewfontsize={11}
                schedulingListControl={true}
                selectedPersonIds={selectedPersonIds}
                setSelectedPersonIds={setSelectedPersonIds}
                showSendEmailPopup={showSendEmailPopup}
                wordWrapEnabled={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
