import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoadPanel } from "devextreme-react";
import { DataGrid, Column } from 'devextreme-react/data-grid'
import { ProntactEntityFunction } from "../../ProntactEntityFunction";
import { ColumnDropdown } from "../../../../../components/Dropdownn";
import './ProntactEntitiesSubTabEntity.css';

const Tablecoloum = [
  {
    title: "Select",
    Selectionbool2: true,
  },
  {
    title: "Entity Type",
  },
];

const Tablecoloum2 = [
  {
    title: "Entity Type",
  },
];

const DropDown = ({
  dropDownData,
  setfields,
  fields,
  fieldNmae,
  reset,
  disabled,
}) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        disabled={disabled}
      />
    </>
  );
};

export const ProntactEntitiesSubTabEntity = ({
  setentitidata,
  entitidata,
  entitydatadropdown,
  EditTogel,
  addProntactEntities,
  entitydatadropdown2,
  fields2,
  setfields2,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [input, setinput] = useState();
  const [data, setdata] = useState([]);
  const clas = new ProntactEntityFunction();
  const { Entitiesid } = useParams();
  const [locationappend, setlocationappend] = useState(entitidata ? entitidata : []);

  useEffect(() => {
    setdata([]);
  }, [EditTogel, addProntactEntities]);

  useEffect(() => {
    if (setentitidata) {
      setentitidata(locationappend);
    }
  }, [locationappend]);

  useEffect(() => {
    setlocationappend(entitidata ? entitidata : []);
  }, [entitidata]);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      {Entitiesid && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ marginTop: "5px" }}>Company Entity</p>
          <div style={{ marginLeft: "1%" }}>
            <DropDown
              dropDownData={entitydatadropdown2}
              fieldNmae={"Company Entity"}
              fields={fields2}
              setfields={setfields2}
              disabled={!EditTogel}
            />
          </div>
          <p style={{ marginTop: "5px", marginLeft: "3%" }}>Reporting Entity</p>
          <div style={{ marginLeft: "1%" }}>
            <DropDown
              dropDownData={entitydatadropdown2}
              fieldNmae={"Reporting Entity"}
              fields={fields2}
              setfields={setfields2}
              disabled={!EditTogel}
            />
          </div>
          {(EditTogel || addProntactEntities) && (
            <div
              style={{
                width: "30%",
                alignItems: "center",
                marginLeft: "0px",
                marginLeft: "3%",
              }}
              className="Notes_serch_block"
            >
              <p style={{ fontWeight: "bold" }}>Search Entity</p>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (input) {
                    clas.getentityFIndTable(
                      setLoading,
                      input,
                      setdata,
                      navigate
                    );
                  } else {
                    setdata([]);
                  }
                }}
              >
                <input
                  type="text"
                  onChange={(e) => {
                    setinput(e.target.value);
                  }}
                />
              </form>

              <button
                style={{
                  backgroundColor: "var(--button2)",
                  color: "white",
                  justifyContent: "space-around",
                  display: "flex",
                  width: "95px",
                  height: "40px",
                }}
                onClick={() => {
                  if (input) {
                    clas.getentityFIndTable(
                      setLoading,
                      input,
                      setdata,
                      navigate
                    );
                  } else {
                    setdata([]);
                  }
                }}
              >
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  Search
                </p>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5006 11.0006H11.7106L11.4306 10.7306C12.6306 9.33063 13.2506 7.42063 12.9106 5.39063C12.4406 2.61063 10.1206 0.390626 7.32063 0.0506256C3.09063 -0.469374 -0.469374 3.09063 0.0506256 7.32063C0.390626 10.1206 2.61063 12.4406 5.39063 12.9106C7.42063 13.2506 9.33063 12.6306 10.7306 11.4306L11.0006 11.7106V12.5006L15.2506 16.7506C15.6606 17.1606 16.3306 17.1606 16.7406 16.7506C17.1506 16.3406 17.1506 15.6706 16.7406 15.2606L12.5006 11.0006ZM6.50063 11.0006C4.01063 11.0006 2.00063 8.99063 2.00063 6.50063C2.00063 4.01063 4.01063 2.00063 6.50063 2.00063C8.99063 2.00063 11.0006 4.01063 11.0006 6.50063C11.0006 8.99063 8.99063 11.0006 6.50063 11.0006Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      )}

      <div style={{ marginTop: "1%" }}>
        <DataGrid
          dataSource={
            EditTogel || addProntactEntities
              ? data.length
                ? data
                : entitydatadropdown
              : entitidata?.map((val, i) => ({
                ...val,
                ID: i
              }))
          }
          showBorders={true}
          height="auto"
          columnAutoWidth={true}
          keyExpr="ID" 
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          onCellPrepared={(e) => {
            if (e.isAltRow) {
              e.cellElement.style.backgroundColor = "var(--table-second-row-light-black)";
              e.cellElement.style.color = "var(--white-black)";
            }
            if (e.rowType === "data" && e?.data?.bStrikeOut) {
              if (!e.column.dataField || e.column.dataField !== "Balance") {
                e.cellElement.style.textDecoration = "line-through";
              }
            }
          }}
        >
          {(EditTogel || addProntactEntities ? Tablecoloum : Tablecoloum2).map((col, index) => (
            <Column
              key={index}
              dataField={col.title}
              caption={col.title}
              width={col.width || 'auto'} 
              allowFiltering={true}
              allowSorting={true}
              cellRender={
                col.Selectionbool2 ? (rowData) => {
                  let data = false;
                  locationappend.map((val) => {
                    if (val.id?.toUpperCase() == rowData?.data?.id?.toUpperCase()) {
                      data = true;
                    } 
                  });
                  return (
                    <>
                      <input
                        type="checkbox"
                        checked={data}
                        onChange={() => {
                          let temp;
                          locationappend.map((val) => {
                            if (val.id.toLocaleLowerCase() == rowData?.data?.id.toLocaleLowerCase()) {
                              temp = true;
                            }
                          });
                          if (temp) {
                            setlocationappend(
                              locationappend.filter((val) => {
                                if (val?.id.toLocaleLowerCase() !== rowData?.data?.id.toLocaleLowerCase()) {
                                  return val;
                                }
                              })
                            );
                          } else {
                            setlocationappend([
                              ...locationappend,
                              {
                                "Entity Type": rowData?.data?.["Entity Type"],
                                id: rowData?.data?.id,
                              },
                            ]);
                          }
                        }}
                      />
                    </>
                  );
                } : ''
              }
            />
          ))}
        </DataGrid>
      </div>
    </>
  );
};
