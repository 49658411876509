import moment from "moment";

export function getLastDayOfMonth(year, month) {
 
  // These are the last days of each month on index based
  const lastDays = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
 
  if(month != 2) {
    return lastDays[month]
  }
 
  return isLeapYear(year) ? 29 : 28;
}
 
 
 
 
export function isLeapYear(year) {
  // A year is a leap year if it is divisible by 4,
  // but not divisible by 100 unless it is also divisible by 400.
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}

export function dateRangeSelector (value) {
  let startDate = '';
  let endDate = '';
  switch (value) {
    case "Today":
      startDate = moment().startOf("day").toDate(); // Start of today
      endDate = moment().endOf("day").toDate(); // End of today
      break;
  
    case "Yesterday":
      startDate = moment().subtract(1, "days").startOf("day").toDate(); // Start of yesterday
      endDate = moment().subtract(1, "days").endOf("day").toDate(); // End of yesterday
      break;
  
    case "This Week":
      startDate = moment().startOf("week").toDate(); // Start of current week
      endDate = moment().endOf("week").toDate(); // End of current week
      break;
  
    case "Last Week":
      startDate = moment().subtract(1, "weeks").startOf("week").toDate(); // Start of last week
      endDate = moment().subtract(1, "weeks").endOf("week").toDate(); // End of last week
      break;
  
    case "This Month":
      startDate = moment().startOf("month").toDate(); // Start of current month
      endDate = moment().endOf("month").toDate(); // End of current month
      break;
  
    case "Last Month":
      startDate = moment().subtract(1, "months").startOf("month").toDate(); // Start of last month
      endDate = moment().subtract(1, "months").endOf("month").toDate(); // End of last month
      break;
  
    case "This Quarter":
      startDate = moment().startOf("quarter").toDate(); // Start of current quarter
      endDate = moment().endOf("quarter").toDate(); // End of current quarter
      break;
  
    case "Last Quarter":
      startDate = moment().subtract(1, "quarters").startOf("quarter").toDate(); // Start of last quarter
      endDate = moment().subtract(1, "quarters").endOf("quarter").toDate(); // End of last quarter
      break;
  
    case "This Year":
      startDate = moment().startOf("year").toDate(); // Start of current year
      endDate = moment().endOf("year").toDate(); // End of current year
      break;
  
    case "Last Year":
      startDate = moment().subtract(1, "years").startOf("year").toDate(); // Start of last year
      endDate = moment().subtract(1, "years").endOf("year").toDate(); // End of last year
      break;
  
    default:
      startDate = moment().startOf("day").toDate(); // Default to today
      endDate = moment().endOf("day").toDate(); // Default to today
      break;
  } 
  return { startDate, endDate };
}