import React, { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../../components/Dropdownn";
import { DateBox } from "devextreme-react";
import { GoTOObligationFunction } from "./GoTOObligationFunction";
import { useNavigate } from "react-router-dom";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

const GoToObligationChangeFinancialStatus = ({
  changeFinancialStatusPopup,
  setChangeFinancialStatusPopup,
  financialStatusDropdownData,
  changeReasondropdowndata,
  obligationData,
  obligationHeaderData,
  addobligation,
  setToastConfig,
  toastConfig,
  refreshApi,
  setLoading,
  setField2,
}) => {
  const [fields, setFields] = useState({
    financialStatus: { name: "", id: "" },
    effectiveDate: "",
    changeReason: { name: "", id: "" },
    applyDocket: false,
    detailedComment: "",
    obligationReference: "",
    caseDocket: "",
    person: "",
  });
  const [financialStatusErrorValidation, setFinancialStatusErrorValidation] = useState("");
  const [changeReasonErrorValidation, setChangeReasonErrorValidation] = useState("");
  const [detailedCommentErrorValidation, setDetailedCommentErrorValidation] = useState("");
  const apiClass = new GoTOObligationFunction();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (name === "detailedComment") {
      if (fields?.detailedComment.charAt(0) === " ") {
        setDetailedCommentErrorValidation("Please Enter detailed comment.");
      } else {
        setDetailedCommentErrorValidation("");
      }
    }
  };

  useEffect(() => {
    if (changeFinancialStatusPopup) {
      setFinancialStatusErrorValidation("");
      setChangeReasonErrorValidation("");
      setDetailedCommentErrorValidation("");
      setFields((prev) => ({
        ...prev,
        financialStatus: {
          name: obligationData?.[`Financial Status`].name,
          id: obligationData?.[`Financial Status`].id,
        },
        effectiveDate: obligationData?.[`Change Financial Status`],
        changeReason: { name: "", id: "" },
        applyDocket: false,
        detailedComment: "",
        obligationReference: obligationData?.[`Obligation Ref #`],
        caseDocket: obligationHeaderData?.sDocketNumberCourtCounts,
        person: obligationHeaderData?.sPersonNameLastFirst,
      }));
    }
  }, [changeFinancialStatusPopup]);

  const apiPayload = {
    recordId: [obligationHeaderData?.gObligationId],
    obligationReference: fields.obligationReference,
    statusId: fields.financialStatus.id,
    effectiveDate: fields.effectiveDate,
    statusChangeReasonId: fields.changeReason.id,
    comment: fields.detailedComment,
    isDocket: false,
    apply: fields.applyDocket,
  };

  const handleSave = () => {
    let isValid = true;
    if (fields.financialStatus.id === "") {
      isValid = false;
      setFinancialStatusErrorValidation("Please Select Financial Status.");
    }
    if (fields.changeReason.id === "") {
      isValid = false;
      setChangeReasonErrorValidation("Please Select Change Reason.");
    }
    if (!fields.detailedComment || fields.detailedComment.charAt(0) === " ") {
      isValid = false;
      setDetailedCommentErrorValidation("Please Enter detailed comment.");
    }
    if (isValid) {
      if (addobligation) {
        setField2((prev) => ({
          ...prev,
          "Financial Status": {
            name: fields.financialStatus.name,
            id: fields.financialStatus.id,
          },
          "Change Financial Status": fields.effectiveDate,
        }));
        setChangeFinancialStatusPopup(false);
      } else {
        apiClass.changeFinancialStatus(
          navigate,
          setLoading,
          setToastConfig,
          toastConfig,
          apiPayload,
          setChangeFinancialStatusPopup,
          refreshApi
        );
      }
    }
  };

  return (
    <>
      <div className="goto_obligation_change_financialStatus">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_974_363096)">
                  <path d="M0 0H24V24H0V0Z" fill="var(--button2)" />
                  <path
                    d="M20 3H19V2C19 1.45 18.55 1 18 1C17.45 1 17 1.45 17 2V3H7V2C7 1.45 6.55 1 6 1C5.45 1 5 1.45 5 2V3H4C2.9 3 2 3.9 2 5V21C2 22.1 2.9 23 4 23H20C21.1 23 22 22.1 22 21V5C22 3.9 21.1 3 20 3ZM19 21H5C4.45 21 4 20.55 4 20V8H20V20C20 20.55 19.55 21 19 21Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_974_363096">
                    <rect width="24" height="24" fill="rgb(67, 66, 102)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Change Financial Status</p>
            </div>
            <p class="popup_header_title_para">OB.12</p>
          </div>
          <svg
            onClick={() => {
              setChangeFinancialStatusPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="popup-container-data">
          <div className="input-flex-div">
            <p className="input-flex fee-payment-content-info-para">
              Obligation Reference
              <span className="fee-payment-content-info-span">
                {fields?.obligationReference ? fields?.obligationReference : "N/A"}
              </span>
            </p>
            <p className="input-flex fee-payment-content-info-para">
              Case/Docket #
              <span className="fee-payment-content-info-span">{fields?.caseDocket ? fields?.caseDocket : "N/A"}</span>
            </p>
            <p className="input-flex fee-payment-content-info-para">
              Person
              <span className="fee-payment-content-info-span">{fields?.person ? fields?.person : "N/A"}</span>
            </p>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Financial Status <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"financialStatus"}
                dropDownData={financialStatusDropdownData}
                reset={setFinancialStatusErrorValidation}
              />
              {financialStatusErrorValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "182px" }}>
                  {financialStatusErrorValidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">Effective Date</p>
              <DateBox
                useMaskBehavior={true}
                value={fields.effectiveDate}
                onValueChange={(e) => {
                  if (e) {
                    setFields((prev) => ({
                      ...prev,
                      effectiveDate: e ? e : null,
                    }));
                  }
                }}
              />
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Change Reason <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"changeReason"}
                dropDownData={changeReasondropdowndata}
                reset={setChangeReasonErrorValidation}
              />
              {changeReasonErrorValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "273px" }}>
                  {changeReasonErrorValidation}
                </p>
              )}
            </div>
          </div>
          <div className="input-flex">
            <input
              type="checkbox"
              name="applyDocket"
              id="applyDocket"
              checked={fields.applyDocket}
              onChange={handleInputChange}
            />
            <label htmlFor="applyDocket" className="fee-payment-content-info-para">
              Apply to all obligation in this docket
            </label>
          </div>
          <div className="edit_container_second edit_content_flex">
            <p className="edit_container_label">
              Please add a detailed comment
              <span className="marks_mandatary">*</span>
            </p>
            <textarea
              name="detailedComment"
              id="detailedComment"
              placeholder="Add your comment here"
              className="dark-mode-textarea"
              value={fields?.detailedComment}
              onChange={handleInputChange}
            ></textarea>
            {detailedCommentErrorValidation && (
              <p className="error_validation" style={{ position: "absolute", top: "495px" }}>
                {detailedCommentErrorValidation}
              </p>
            )}
          </div>
          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handleSave}>
              Done
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoToObligationChangeFinancialStatus;
