export const feepaymentTablecoloumn = [
  { title: "Pay Date" },
  { title: "Payment Due", currency: true },
  { title: "Paid", currency: true },
  { title: "Total Paid", currency: true },
  { title: "Total Due", currency: true },
  { title: "Balance", currency: true },
  { title: "Status", pause1: true, bool: true },
];

export const paymentTabelColumn = [
  { title: "DOE" },
  { title: "Date of Receipt" },
  { title: "Amount", currency: true },
  { title: "Cash Drawer" },
  { title: "Payment Method" },
  { title: "Settled", bool: true },
  { title: "Entered By" },
  { title: "Reference" },
  { title: "Receipt #" },
  { title: "Receipt", receipt: true, bool: true },
  { title: "Void", bool: true },
  { title: "Deposit Ref" },
  { title: "Card Number" },
];
export const recipientsTableColumn = [
  { title: "Disburse Frequency", rowClickData: true },
  { title: "Disburse Method" },
  { title: "Recipient Category" },
  { title: "D %" },
  { title: "Amount on Hand", currency: true },
  { title: "Amount to Disburse", currency: true },
  { title: "Eligible to Disburse", currency: true },
  { title: "Amount Disbursed", currency: true },
  { title: "Receipt Balance", currency: true },
  { title: "Void", bool: true },
  { title: "DOE" },
  { title: "Entered By" },
  { title: "Phone" },
];

export const disbursementsTableColumn = [
  { title: "Date" },
  { title: "Bak Account" },
  { title: "Disburse Method" },
  { title: "Recipient Entry" },
  { title: "Recipient Name" },
  { title: "Amount Disbursed", currency: true },
  { title: "Check #/ACH/Misc" },
  { title: "Check Batch" },
  { title: "Reference" },
  { title: "Void", bool: true },
  { title: "DOE" },
  { title: "Entered By" },
];

export const violationCodeTableColumn = [{ title: "Local Legal Code" }, { title: "Description" }];

export const violationFineTableColumn = [{ title: "" }, { title: "Description" }, { title: "Fine" }];

const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};
export const emPeriodTabelColumn = [
  { title: "", moreoption: true },
  { title: "Ref" },
  { title: "Obligation" },
  { title: "Equipment Type" },
  { title: "Rate Start", date: true },
  { title: "Rate End", date: true },
  { title: "Amount", currency: true },
  { title: "Frequency" },
  { title: "Days Active" },
  { title: "Void", bool: true },
  { title: "Payment Type" }
];
export const emEquipmentLogTabelColumn = [
  { title: "Hookup Date", date: true },
  { title: "Type" },
  { title: "Serial No" },
  { title: "Return Status" },
  { title: "Removal Date", date: true },
];

export const emCreateInvoiceTabelColumn = [
  // { title: "Select", bool: true },
  { title: "Select", bool: true, selectAllCustomCheckBox: true },
  { title: "Offender #" },
  { title: "Name" },
  { title: "Type" },
  { title: "Fee" },
  { title: "Start", date: true },
  { title: "End", date: true },
  { title: "Days" },
  { title: "Rate", currency: true },
  { title: "Court" },
  { title: "Paid By" },
  { title: "Agency Amount", currency: true },
  { title: "Invoice" },
  { title: "Flag", bool: true },
  { title: "Remarks" },
];

export const setTableRecipientsTableData = (dataa) => {
  const arr = [];
  dataa.map((val, i) => {
    arr.push({
      "Disburse Frequency": val?.sDisburseFrequencyName,
      "Disburse Method": val?.sDisburseMethodName,
      "Recipient Category": val?.sRecipientCategoryName,
      "D %": val?.iDisbursePercent,
      "Amount on Hand": val?.fBalanceEligible,
      "Amount to Disburse": val?.fAmountToDisburse,
      "Eligible to Disburse": val?.fDisburseEligible,
      "Amount Disbursed": val?.fAmountDisbursed,
      "Receipt Balance": val?.fBalanceRecipient,
      Void: val?.bVoid,
      DOE: convertDateFormat(val?.dDoe),
      "Entered By": val?.sEntryUserName,
      Phone: val?.sAddressComment,
      ID: i,
      gBankAccountId: val?.gBankAccountId,
      gDisburseId: val?.gDisburseId,
      gEntityId: val?.gEntityId,
      gEntryUserId: val?.gEntryUserId,
      gObligationId: val?.gObligationId,
      gDisburseMethodId: val?.gDisburseMethodId,
      gDisburseFrequencyId: val?.gDisburseFrequencyId,
      gDisburseRecipientId: val?.gDisburseRecipientId,
      gRestitutionRecipientId: val?.gRestitutionRecipientId,
      gFeeScheduleCategoryId: val?.gFeeScheduleCategoryId,
      sRecipientDisbursementName: val?.sRecipientDisbursementName,
      sRecipientDisbursement_Address1: val?.sRecipientDisbursement_Address1,
      sRecipientDisbursement_Address2: val?.sRecipientDisbursement_Address2,
      sRecipientDisbursement_CityName: val?.sRecipientDisbursement_CityName,
      sRecipientDisbursement_State: val?.sRecipientDisbursement_State,
      sRecipientDisbursement_Zip: val?.sRecipientDisbursement_Zip,
      sRecipientDisbursement_Zip4: val?.sRecipientDisbursement_Zip4,
      bAuto: val?.bAuto,

    });
  });
  return arr;
};
export const setTableDisbursementTableData = (dataa) => {
  const arr = [];
  dataa.map((val, i) => {
    arr.push({
      Date: convertDateFormat(val?.dDisbursement),
      "Bak Account": val?.sBankAccountNumber,
      "Disburse Method": val?.sDisburseMethodName,
      "Recipient Entry": val?.sEntryUserName,
      "Recipient Name": val?.sRecipientName,
      "Amount Disbursed": val?.fAmount,
      "Check #/ACH/Misc": val?.sCheckNumber,
      "Check Batch": val?.sBatchNumber,
      Reference: val?.sReference,
      Void: val?.bVoid,
      DOE: convertDateFormat(val?.dDoe),
      "Entered By": val?.sEntryUserName,
      ID: i,
      gDisbursementId: val?.gDisbursementId,
      gEntryUserId: val?.gEntryUserId,
      gDisburseId: val?.gDisburseId,
      gBankAccountId: val?.gBankAccountId,
      gEntityId: val?.gEntityId,
      gObligationId: val?.gObligationId,
    });
  });
  return arr;
};
export const setObligationOverviewTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      DOE: convertDateFormat(val?.dDoe),
      "Date of Receipt": convertDateFormat(val?.dReceipt),
      Amount: val?.fAmount,
      "Payment Method": val?.sPaymentMethodName,
      "Cash Drawer": val?.sCashDrawerName,
      Settled: val?.bSettled,
      "Entered By": val?.sEntryUserName,
      Reference: val?.sReference,
      "Receipt #": val?.sReceiptNumber,
      Receipt: val?.Receipt,
      Void: val?.bVoid,
      "Deposit Ref": val?.sSbDisbursementReference,
      "Transaction ID": val?.sCcTransactionId,
      "Card Number": val?.sCcCardNumber,
      gPaymentId: val?.gPaymentId,
      gCashDrawerId: val?.gCashDrawerId,
      gPaymentMethodId: val?.gPaymentMethodId,
      sManualReceiptNumber: val?.sManualReceiptNumber,
      sReceiptNote: val?.sReceiptNote,
      ID: i,
    });
  });
  return arr;
};
export const setTableGeneratePaymentPlanTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Pay Date": convertDateFormat(val?.dPay),
      "Payment Due": val?.fAmount,
      Paid: val?.fPaid,
      "Total Paid": val?.fTotalPaid,
      "Total Due": val?.fTotalDue,
      Balance: val?.fBalance,
      Status: val?.sCashDrawerName,
      bStrikeOut: val?.bStrikeOut,
      bIsTolled: val?.bIsTolled,
      ID: i,
    });
  });
  return arr;
};

export const setEmPeriodObligationTableData = (data) => {
  console.log('data', data);
  const arr = [];
  data.map((val, i) => {
    var date1 = new Date(val?.dRateStart);
    var date2 = new Date(val?.dRateEnd);
    var differenceInMilliseconds = Math.abs(date2 - date1);
    var differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));
    arr.push({
      Ref: val?.sReference,
      Obligation: val?.sddsViolationDescription,
      'Equipment Type': val?.sEquipmentTypeName,
      "Monitoring Type": val?.gEmTypeName,
      "Rate Start": convertDateFormat(val?.dRateStart),
      "Rate End": convertDateFormat(val?.dRateEnd),
      Amount: val?.fRate,
      Frequency: val?.gRateFrequencyName,
      "Days Active": val?.iDaysActive,
      Void: val.bVoid || false,
      "Payment Type": val?.sPaymentTypeName,
      dStart: convertDateFormat(val?.dStart),
      dEnd: convertDateFormat(val?.dEnd),
      ID: i,
      gDocketId: val?.gDocketId,
      gEmTypeId: val?.gEmTypeId,
      gPeriodId: val?.gPeriodId,
      gRateFrequencyId: val?.gRateFrequencyId,
      gPeriodObligationId: val?.gPeriodObligationId,
      sPaymentTypeName: val?.sPaymentTypeName,
      sPaymentTypeId: val?.sPaymentTypeId,
      gObligationId: val?.gObligationId,
      gEquipmentTypeId: val?.gEquipmentTypeId,
    });
  });
  return arr;
};
export const setEmEquipmentLogObligationTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Hookup Date": convertDateFormat(val?.dHookup),
      Type: val?.sEquipmentTypeName,
      "Serial No": val?.sSerialNumber,
      "Return Status": val?.sEquipmentReturnCondition,
      "Removal Date": convertDateFormat(val?.dRemoval),
      ID: i,
      gEmEquipmentReturnedConditionId: val?.gEmEquipmentReturnedConditionId,
      gPersonId: val?.gPersonId,
      gEntryUserId: val?.gEntryUserId,
      gEmEquipmentLogId: val?.gEmEquipmentLogId,
      gEmEquipmentId: val?.gEmEquipmentId,
      sNote: val?.sNote,
      sEquipmentStatusName: val?.sEquipmentStatusName,
      gEmPeriodId: val?.gEmPeriodId,
    });
  });
  return arr;
};

export const setEmCreateInvoiceTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      // Select: val.bSelect || false,
      "Offender #": val?.sOffenderNumber,
      Name: val?.sLastFirst,
      Type: val?.sEM_TYPE,
      Fee: val?.sDDSViolationDescription,
      Start: convertDateFormat(val?.dBillingStart),
      End: convertDateFormat(val?.dBillingEnd),
      Days: val?.iDays,
      Rate: val?.fRate,
      Court: val?.sCourtName,
      "Paid By": val?.sPaymentTypeName,
      "Agency Amount": val?.fAmount,
      Invoice: val?.sInvoice,
      Flag: val?.bFlag || false,
      Remarks:val?.sFeeRemarks,
      ID: i,
      sDDSLegalCode: val?.sDDSLegalCode,
      sBillingAgency: val?.sBillingAgency,
      sLocalLegalCodeExtra: val?.sLocalLegalCodeExtra,
      gPersonID: val?.gPersonID,
      gPeriodObligationID: val?.gPeriodObligationID,
      gObligationID: val?.gObligationID,
      sDescription: val?.sDescription,
      sFeeRemarks: val?.sFeeRemarks
    });
  });
  return arr;
};
