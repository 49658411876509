import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { DateBox, LoadPanel, SelectBox } from "devextreme-react";
import { ApiError } from "../../../../../../Error/ApiError";
import { Table1 } from "../../../../../../Administration/Table1/Table1";
import { MonitoringFunction } from "../../../../../../Monitoring/MonitoringFunction";
import { ColumnDropdown } from "../../../../../../../components/Dropdownn";
import { useNavigate } from "react-router-dom";
import { emCreateInvoiceTabelColumn } from "../../Data";
import { handleGetLocal } from "../../../../../../../services/auth-service";
import { logOut } from "../../../../../../../components/Logout";
import { Toster } from "../../../../../../../components/Toster/Toster";
import { useSelector } from "react-redux";
import { getAccessData } from "../../../../../../../utils/getAccessPermission";
import { DynamicTabb } from "../../../../../../../components/DynamicTab/DynamicTab";
import MonitoringCrumbs from "../../../../../../Monitoring/components/MonitoringCrumbs";
import { CreateInvoicePopup } from "../../../../Financial/components/overviewPopUp/CreateInvoicePopup";
import { InvoiceCreatedPopup } from "../../../../Financial/components/overviewPopUp/InvoiceCreatedPopup";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled, width, value }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name || value}
        reset={reset}
        disabled={disabled}
        width={width}
      />
    </>
  );
};

export const Months = [
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
  { key: "5", value: "5" },
  { key: "6", value: "6" },
  { key: "7", value: "7" },
  { key: "8", value: "8" },
  { key: "9", value: "9" },
  { key: "10", value: "10" },
  { key: "11", value: "11" },
  { key: "12", value: "12" },
];

export const generateYearDropdown = (currentYear, numPrevious, numNext) => {
  const years = [];
  const startYear = currentYear - numPrevious;
  const endYear = currentYear + numNext;
  for (let year = startYear; year <= endYear; year++) {
    years.push({
      id: year.toString(),
      name: year.toString(),
    });
  }
  return years;
};

export const CreateInvoice = () => {
  const [quickDatevalue, setquickDatevalue] = useState("Previous Month");
  const { userAccessData, user_data } = useSelector((store) => store.Home);
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const [paymentAgencyDropdownData, setPaymentAgencyDropdownData] = useState([]);
  const [billingCompanyDropdownData, setBillingCompanyDropdownData] = useState([]);
  const initialState = {
    paymentagency: {
      id: "",
      name: "",
    },
    year: {
      id: "",
      name: "",
    },
    month: {
      id: "",
      name: "",
    },
    previousmonth: {
      id: "",
      name: "",
    },
    billingcompany: {
      id: "",
      name: "",
    },
    periodstart: "",
    periodend: "",
    invoicedate: "",
    notes: "",
    quickdate: "",
    checkboxinvoices: false,
    checkboxnames: false,
  };
  const [fields, setFields] = useState(initialState);
  const [selectedRowCheckbox, setSelectedRowCheckbox] = useState([]);
  const [receiveInvoiceData, setReceiveInvoiceData] = useState([]);
  const [errorhandling, setErrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [buttonRefreshClicked, setButtonRefreshClicked] = useState(false);
  const currentYear = new Date().getFullYear();
  const previousMonthID = new Date().getMonth();
  const navigate = useNavigate();
  const ApiClass = new MonitoringFunction();
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [invoicecreatedPopup, setInvoiceCreatedPopup] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const dateRanges = [
    { name: "Current Month", id: "Current Month" },
    { name: "Previous Month", id: "Previous Month" },
  ];

  const resetFields = () => {
    setFields(initialState);
    setFields((prev) => ({
      ...prev,
      year: { name: currentYear.toString(), id: currentYear.toString() },
      month: { name: previousMonthID.toString(), id: previousMonthID },
      periodstart: moment().subtract(1, 'months').startOf('month').format('YYYY/MM/DD'),
      periodend: moment().subtract(1, 'months').endOf('month').format('YYYY/MM/DD'),
      invoicedate: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
    }));
    if (paymentAgencyDropdownData.length) {
      setFields(
        {
          ...fields,
          paymentagency: paymentAgencyDropdownData[0]
        }
      )
    }
    setReceiveInvoiceData([]);
    setSelectedRowCheckbox([]);
    setInvoiceCreatedPopup(false);
  };

  const handleRefreshButtonClick = () => {
    setButtonRefreshClicked(true);
  };

  const handleSelectAll = () => {
    setSelectedRowCheckbox(receiveInvoiceData);
  };

  const handleDeselectAll = () => {
    setSelectedRowCheckbox([]);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange1 = () => {
    setFields((prev) => ({
      ...prev,
      checkboxinvoices: !prev.checkboxinvoices,
    }));
  };

  const handleCheckboxChange2 = () => {
    setFields((prev) => ({
      ...prev,
      checkboxnames: !prev.checkboxnames,
    }));
  };

  const handleCreate = async () => {
    const mappedData = selectedRowCheckbox.map((item) => ({
      sOffenderNumber: item["Offender #"] || "",
      sLastFirst: item.Name || "",
      fRate: item.Rate || null,
      sDDSLegalCode: item?.sDDSLegalCode || "",
      sDDSViolationDescription: item?.Fee || "",
      sPaymentTypeName: item["Paid By"] || "",
      sBillingAgency: item?.sBillingAgency || "",
      sEM_TYPE: item.Type || "",
      sLocalLegalCodeExtra: item?.sLocalLegalCodeExtra || "",
      dBillingStart: item.Start ? new Date(item.Start) : null,
      dBillingEnd: item.End ? new Date(item.End) : null,
      iDays: item.Days || null,
      fAmount: item["Agency Amount"] || null,
      gPersonID: item?.gPersonID || "00000000-0000-0000-0000-000000000000",
      gPeriodObligationID: item?.gPeriodObligationID || "00000000-0000-0000-0000-000000000000",
      gObligationID: item?.gObligationID || "00000000-0000-0000-0000-000000000000",
      sAccountsPayableAddress: "",
      sDescription: item?.sDescription || "",
      gEntityID: fields?.paymentagency?.id || "",
      sPrimarySupervisorName: null,
      sCourtName: item.Court || "",
      sFeeRemarks: item?.sFeeRemarks || ""
    }));

    const data = {
      PaymentAgencyId: fields?.billingcompany.id,
      BillingCompanyId: fields?.paymentagency.id,
      PeriodStart: fields?.periodstart,
      PeriodEnd: fields.periodend,
      InvoiceDate: fields.invoicedate,
      Notes: fields.notes,
      IsSeparateInvoices: fields.checkboxinvoices,
      IsSuppressNames: fields.checkboxnames,
      GridData: mappedData,
    };

    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/monitoring/create-invoice`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${handleGetLocal("pathURL")}`,
        },
      })
      .then((val) => {
        setInvoiceNumber(val.data?.invoiceNumber);
        setLoading(false);
        setConfirmPopup(false);
        setInvoiceCreatedPopup(true);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  };

  const formattedPrice = (price) => {
    let formatPrice = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
    }).format(Math.abs(price));
    return price >= 0 ? formatPrice : `(${formatPrice})`;
  };

  const getUserAccessPermissions = () => {
    const accessData = getAccessData(userAccessData, "EM6");
    setAddAccess(accessData?.Add);
    setEditAccess(accessData?.Edit);
    setViewAccess(accessData?.View);
  };

  useEffect(() => {
    getUserAccessPermissions();
    ApiClass.getPaymentAgencyDropdown(setLoading, navigate, setPaymentAgencyDropdownData);
    setFields((prev) => ({
      ...prev,
      year: { name: currentYear.toString(), id: currentYear.toString() },
      month: { name: previousMonthID.toString(), id: previousMonthID },
      periodstart: moment().subtract(1, 'months').startOf('month').format('YYYY/MM/DD'),
      periodend: moment().subtract(1, 'months').endOf('month').format('YYYY/MM/DD'),
      invoicedate: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
    }));
  }, []);

  useEffect(() => {
    if (paymentAgencyDropdownData.length) {
      setFields(
        {
          ...fields,
          paymentagency: paymentAgencyDropdownData[0]
        }
      )
    }
  }, [paymentAgencyDropdownData]);

  useEffect(() => {
    if (fields.billingcompany?.id) {
      ApiClass.getInvoiceData(
        setLoading,
        navigate,
        setReceiveInvoiceData,
        setErrorhandling,
        fields?.billingcompany?.id,
        fields?.periodstart,
        fields?.periodend
      );
    }
  }, [fields?.billingcompany?.id, fields?.periodstart, fields?.periodend, buttonRefreshClicked]);

  useEffect(() => {
    setSelectedRowCheckbox(receiveInvoiceData);
  }, [receiveInvoiceData]);

  useEffect(() => {
    if (user_data?.gLocationId) {
      ApiClass.getBillingCompanyDropdown(setLoading, navigate, setBillingCompanyDropdownData, user_data?.gLocationId);
    }
    if (fields?.paymentagency?.id) {
      ApiClass.getBillingCompanyDropdown(setLoading, navigate, setBillingCompanyDropdownData, fields?.paymentagency?.id);
    }
  }, [fields?.paymentagency?.id, user_data?.gLocationId]);

  return (
    <>
      <CreateInvoicePopup
        confirmPopup={confirmPopup}
        setConfirmPopup={setConfirmPopup}
        saveApi={handleCreate}
        amount={selectedRowCheckbox?.reduce((sum, item) => sum + item["Agency Amount"], 0).toFixed(2)}
      />
      <InvoiceCreatedPopup
        invoicecreatedPopup={invoicecreatedPopup}
        setInvoiceCreatedPopup={setInvoiceCreatedPopup}
        resetFields={resetFields}
        invoiceNumber={invoiceNumber}
      />
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <MonitoringCrumbs title={"Create Agency Invoice"} />
      <div className="financial-page">
        <DynamicTabb />
        <div className="financial-page-section">
          <p className="page-info-number">EM.6</p>
          {viewAccess ? (
            <div
              className="financial-page-section receive-agency-payment"
              style={{
                pointerEvents: editAccess || addAccess ? "auto" : "none",
                opacity: editAccess || addAccess ? 1 : 0.6,
                cursor: editAccess || addAccess ? "default" : "not-allowed",
              }}
            >
              <div className="page-details-search-content">
                <div className="display-flex-div edit_content_space" >
                  <label className="edit_container_label" style={{ textAlign: "right", width: "160px" }}>Billing Company Entity</label>
                  <DropDown
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"paymentagency"}
                    dropDownData={paymentAgencyDropdownData}
                    width={350}
                    value={user_data?.sLocationName}
                  />
                </div>
                <div className="display-flex-div edit_content_space">
                  <label className="edit_container_label" style={{ textAlign: "right", width: "100px" }}>Invoice Date</label>
                  <DateBox
                    useMaskBehavior={true}
                    value={fields.invoicedate}
                    onValueChange={(e) => {
                      if (e) {
                        setFields((prev) => ({
                          ...prev,
                          invoicedate: e ? e : null,
                        }));
                      }
                    }}
                  />
                </div>
              </div>
              <div className="page-details-search-content">
                <div className="display-flex-div edit_content_space">
                  <label className="edit_container_label">Payment Agency (Entity)</label>
                  <DropDown
                    width={350}
                    setfields={setFields}
                    fields={fields}
                    fieldNmae={"billingcompany"}
                    dropDownData={billingCompanyDropdownData}
                  />
                </div>
                <div className="display-flex-div edit_content_space">
                  <label className="edit_container_label" style={{ textAlign: "right", width: "100px" }}>Quick Date</label>
                  <SelectBox
                    dataSource={dateRanges}
                    valueExpr="name"
                    displayExpr="name"
                    searchEnabled={true}
                    searchExpr={"name"}
                    value={quickDatevalue}
                    onItemClick={(e) => {
                      setquickDatevalue(e?.itemData?.name);
                      let startDate = null;
                      let endDate = null;
                      switch (e?.itemData?.name) {
                        case "Current Month":
                          startDate = new Date();
                          startDate.setDate(1);
                          endDate = new Date();
                          break;
                        case "Previous Month":
                          startDate = new Date();
                          startDate.setMonth(startDate.getMonth() - 1);
                          startDate.setDate(1);
                          endDate = new Date();
                          endDate.setDate(0);
                          break;
                        default:
                          startDate = new Date();
                          endDate = new Date();
                          break;
                      }
                      setFields((prev) => ({
                        ...prev,
                        periodstart: startDate.toISOString().slice(0, 10),
                        periodend: endDate.toISOString().slice(0, 10),
                      }));

                    }}
                  />
                </div>
              </div>
              <div className="page-details-search-content">
                <div className="display-flex-div edit_content_space">
                  <label className="edit_container_label" style={{ textAlign: "right", width: "160px" }}>
                    Invoice Note
                  </label>
                  <textarea
                    style={{
                      width: "230px",
                      resize: "none",
                      height: "38px",
                      border: "1px solid #00000099"
                    }}
                    name="notes"
                    id="notes"
                    value={fields?.notes}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <div className="display-flex-div edit_content_space">
                  <label className="edit_container_label" style={{ textAlign: "right", width: "220px" }}>Period Start</label>
                  <DateBox
                    useMaskBehavior={true}
                    value={fields.periodstart}
                    onValueChange={(e) => {
                      if (e) {
                        setFields((prev) => ({
                          ...prev,
                          periodstart: e ? e : null,
                        }));
                      }
                    }}
                  />
                </div>
              </div>
              <div className="page-details-search-content">
                <div className="display-flex-div edit_content_space" style={{ marginLeft: '166px' }}>
                  <div style={{
                    textAlign: "right",
                    width: "260px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <input
                        type="checkbox"
                        name="ch1"
                        id="ch1"
                        checked={fields.checkboxinvoices}
                        onChange={handleCheckboxChange1}
                      />
                      <label className="custom-flag-label">
                        Separate Invoices
                      </label>
                    </div><div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <input
                        type="checkbox"
                        name="ch2"
                        id="ch2"
                        checked={fields.checkboxnames}
                        onChange={handleCheckboxChange2}
                      />
                      <label className="custom-flag-label">
                        Suppress Names
                      </label>
                    </div>
                  </div>
                </div>
                <div className="display-flex-div edit_content_space">
                  <label className="edit_container_label" style={{ textAlign: "right", width: "194px" }}>Period End</label>
                  <DateBox
                    useMaskBehavior={true}
                    value={fields.periodend}
                    onValueChange={(e) => {
                      if (e) {
                        setFields((prev) => ({
                          ...prev,
                          periodend: e ? e : null,
                        }));
                      }
                    }}
                  />
                </div>
              </div>

              <div className="page-info-buttons">
                <div className="buttons" style={{ alignItems: "baseline" }}>
                  <div className="agency-column-fields">
                    <button className="btn" onClick={handleSelectAll}>
                      Select All
                    </button>
                  </div>
                  <div className="agency-column-fields">
                    <button className="btn" onClick={handleDeselectAll}>
                      Deselect All
                    </button>
                  </div>
                  <div className="agency-column-fields">
                    <button className="btn"
                      onClick={() => setConfirmPopup(true)}
                      disabled={
                        selectedRowCheckbox?.reduce((sum, item) => sum + item["Agency Amount"], 0) === 0
                      }
                    >
                      Create
                    </button>
                  </div>
                  <div className="agency-column-fields">
                    <button className="btn">Import</button>
                  </div>
                  <div className="agency-column-fields">
                    <button className="btn">Print</button>
                  </div>
                  <div className="agency-column-fields">
                    <button className="btn" onClick={handleRefreshButtonClick}>
                      Refresh
                      <svg
                        className="svg-blue-white"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_6247_15093)">
                          <path
                            d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                            fill="#424167"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_6247_15093">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                  <p className="edit_container_label">
                    INVOICE AMOUNT:{" "}
                    {formattedPrice(
                      selectedRowCheckbox?.reduce((sum, item) => sum + item["Agency Amount"], 0).toFixed(2)
                    )}
                  </p>
                </div>
              </div>
              <div className="table-section">
                {errorhandling ? (
                  <ApiError />
                ) : (
                  <>
                    {receiveInvoiceData?.length ? (
                      <Table1
                        editTable={false}
                        height={"auto"}
                        Tablecoloum={emCreateInvoiceTabelColumn}
                        tableRow={receiveInvoiceData}
                        load={loading}
                        coloumWidthText={"Court"}
                        mandatoryColoum={"Offender #"}
                        setSelectedRowCheckbox={setSelectedRowCheckbox}
                        selectedRowCheckbox={selectedRowCheckbox}
                        createInvoiceMenu={true}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
