import { useSelector } from "react-redux";
import { ColumnDropdown } from "../../../components/Dropdownn";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toster } from "../../../components/Toster/Toster";
import { DateBox, LoadPanel, Popup } from "devextreme-react";
import { DynamicTabb } from "../../../components/DynamicTab/DynamicTab";
import { Table2 } from "../../../components/Table2/Table2";
import { Tablecoloum } from "./Data";
import { CloseoutWizardFunction } from "./CloseoutWizardFunction";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
    return (
        <>
            <ColumnDropdown
                data={dropDownData}
                setfields={setfields}
                fields={fields}
                fieldNmae={fieldNmae}
                value={fields[fieldNmae]?.name}
                reset={reset}
                disabled={disabled}
            />
        </>
    );
};

const CloseoutWizard = () => {
    const { closeoutWizardInfo } = useSelector((store) => store.participate);
    const ApiClass = new CloseoutWizardFunction();
    const [loading, setLoading] = useState(false);
    const [viewAccess, setViewAccess] = useState(true);
    const [headerdata, setheaderdata] = useState([]);
    const [gridrows, setgridrows] = useState([]);
    const [closestatuses, setclosestatuses] = useState([]);
    const [approverlist, setapproverlist] = useState([]);
    const [programendreason, setprogramendreason] = useState([]);
    const [fields, setfields] = useState({
        notes: "",
        toBeApprovedBy: "",
        effectiveDate: new Date().toISOString().slice(0, 10),
        programEndReason: {
            id: "00000000-0000-0000-0000-000000000000",
            name: ""
        },
        closeStatuses: {
            id: "00000000-0000-0000-0000-000000000000",
            name: ""
        },
        writeOff: 0,
        adjustments: 0,
        balance: 0
    });
    const navigate = useNavigate();

    const actionDropdown = [
        { name: "-select-", id: "-select-" },
        { name: "Close", id: "Close" },
        { name: "WriteOff", id: "WriteOff" },
        { name: "Expunge", id: "Expunge" },
        { name: "Waive", id: "Waive" },
        { name: "Exempt", id: "Exempt" }
    ];

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: "",
        message: "",
    });

    const handleSubmit = () => {
        let isValid = true;
        const data = {
            gPersonID: closeoutWizardInfo.gPersonId,
            closeStatusesID: fields?.closeStatuses?.id,
            toBeApprovedBy: fields?.toBeApprovedBy,
            effectiveDate: fields?.effectiveDate,
            notes: fields?.notes,
            programEndReasonID: fields?.programEndReason.id,
            gridData: gridrows?.map((item) => {
                if (item.Action === "-select-") {
                    isValid = false;
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        message: "please enter action",
                        type: "error",
                    });
                    setTimeout(() => {
                        setToastConfig({
                            ...toastConfig,
                            isVisible: false,
                            message: "",
                            type: "",
                        });
                    }, 1900);
                }
                let body = {
                    sCourtName: item.Court,
                    sDocketNumberCourtCounts: item["Docket/Count"],
                    sFeeCode: item["Fee Code"],
                    sFeeName: item["Fee Name"],
                    fAmount: item.Amount,
                    fBalance: item.Balance,
                    action: item.Action,
                    adjustmentAmount: item["Adjustment Amount"],
                }
                return (body)
            }),
        };
        if (isValid) {
            ApiClass.submitCloseoutWizardData(
                navigate,
                setLoading,
                setToastConfig,
                toastConfig,
                data);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setfields((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    const handleCellValueChanged = (e) => {
        const writeOffTotal = (gridrows
            .filter((item) => item.Action === "WriteOff")
            .reduce((sum, item) => sum + (item["Adjustment Amount"] || 0), 0)).toFixed(2);
      
        const waiveExemptTotal = (gridrows
            .filter((item) => item.Action === "Waive" || item.Action === "Exempt")
            .reduce((sum, item) => sum + (item["Adjustment Amount"] || 0), 0)).toFixed(2);

        const balanceAdjustmentDiff =
            (gridrows.reduce((sum, item) => sum + (item.Balance || 0), 0) -
            gridrows.reduce((sum, item) => sum + (item["Adjustment Amount"] || 0), 0)).toFixed(2);

            setfields((prevFields) => ({
                ...prevFields,
                writeOff: writeOffTotal,
                adjustments:waiveExemptTotal,
                balance:balanceAdjustmentDiff
            }));
    }

    useEffect(() => {
        ApiClass.loadCloseoutWizardData(navigate, setLoading, setheaderdata, setgridrows, setclosestatuses, setapproverlist, setprogramendreason, closeoutWizardInfo.gPersonId);
    }, []);

    return (
        <>
            <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
            <LoadPanel shadingColor="true" visible={loading} delay={10} />
            <Popup
                onHiding={() => {
                }}
                showTitle={false}
                width={1180}
                height={860}
                showCloseButton={true}
                hideOnOutsideClick={true}
            >
            </Popup>
            <div className="particcipentPage">
                <DynamicTabb />
                {viewAccess ? (
                    <div>
                        <div className="screen_num" style={{ margin: ".5%" }}>
                            <p>PS.6</p>
                        </div>
                        <div className="RecivePaymentModel_block1">
                            <div style={{ flexGrow: "1" }}>
                                <div className="RecivePaymentModel_block1_sub">
                                    <div className="display-flex-div">
                                        <label className="edit_container_label">
                                            Offender Number  </label><div id="Query">{headerdata && headerdata.length > 0 && headerdata[0].sOffenderNumber
                                                ? headerdata[0].sOffenderNumber
                                                : ""}
                                        </div>
                                    </div>
                                    <div id="Query">
                                        {headerdata && headerdata.length > 0 && headerdata[0].sLastFirst
                                            ? headerdata[0].sLastFirst
                                            : ""}
                                    </div>
                                    <div className="display-flex-div">
                                        <label className="edit_container_label">
                                            Active Dockets</label>   <div id="Query">
                                            {headerdata && headerdata.length > 0 && headerdata[0].iActiveDocketCourt
                                                ? headerdata[0].iActiveDocketCourt
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="RecivePaymentModel_block1_sub">
                                    <div className="display-flex-div">
                                        <label className="edit_container_label">Dockets </label><div id="Query"> {headerdata && headerdata.length > 0 && headerdata[0].sDocketNumberCourtCounts
                                            ? headerdata[0].sDocketNumberCourtCounts
                                            : ""}
                                        </div>

                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "5px",
                                            marginTop: "2%",
                                        }}
                                    >
                                    </div>
                                </div>
                                <div className="page-info-buttons">
                                    <div className="buttons" style={{ alignItems: "baseline" }}>
                                        <button className="btn primary_btn" onClick={handleSubmit}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: "1.5%", marginTop: "2.5%" }}>
                            <Table2
                                editTable={false}
                                Tablecoloum={Tablecoloum}
                                tableRow={gridrows}
                                load={loading}
                                coloumWidthText={"CloseoutWizardTableWidth"}
                                mandatoryColoum={"Docket/Count"}
                                showTotalBlock={true}
                                editableParticularValue={true}
                                actionDropdown={actionDropdown}
                                handleCellValueChanged={handleCellValueChanged}
                            />
                        </div>
                        <div className="RecivePaymentModel_block1">
                            <div style={{ flexGrow: "1" }}>
                                <div className="RecivePaymentModel_block1_sub">
                                    <div className="display-flex-div" >
                                        <label className="edit_container_label" style={{ textAlign: "right", width: "160px" }}>Close Statuses</label>
                                        <DropDown
                                            setfields={setfields}
                                            width="350px"
                                            fields={fields}
                                            fieldNmae={"closeStatuses"}
                                            dropDownData={closestatuses?.map((val) => {
                                                return {
                                                    name: val?.sName,
                                                    id: val?.gSupervisionStatusID,
                                                };
                                            })}
                                        />
                                    </div>
                                    <div className="display-flex-div" >
                                        <label className="edit_container_label" style={{ textAlign: "right", width: "160px" }}>To Be Approved By</label>
                                        <textarea
                                            style={{
                                                width: "350px",
                                                resize: "none",
                                                height: "50px",
                                                border: "1px solid #00000099"
                                            }}
                                            name="toBeApprovedBy"
                                            id="toBeApprovedBy"
                                            // value={approverlist && approverlist.length > 0 && approverlist[0].gUserID
                                            //     ? `${approverlist[0].gUserID}, ${approverlist[0].sLastFirst}`
                                            //     : ""}
                                            value={approverlist && approverlist.length > 0 && approverlist[0].gUserID
                                                ? approverlist[0].sLastFirst
                                                : ""}
                                            onChange={handleInputChange}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="RecivePaymentModel_block1">
                            <div style={{ flexGrow: "1" }}>
                                <div className="RecivePaymentModel_block1_sub">
                                    <div className="display-flex-div">
                                        <label className="edit_container_label">Effective Date</label>
                                        <DateBox
                                            useMaskBehavior={true}
                                            value={headerdata && headerdata.length > 0 && headerdata[0].dEffective
                                                ? headerdata[0].dEffective
                                                : ""}
                                            onValueChange={(e) => {
                                                setfields((prevFields) => ({
                                                    ...prevFields,
                                                    effectivedateDate: e || null,
                                                }));
                                            }}
                                        />
                                        <label className="edit_container_label">
                                            Valid Dates  </label><div id="Query">{headerdata && headerdata.length > 0 && headerdata[0].sCloseDates
                                                ? headerdata[0].sCloseDates
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="RecivePaymentModel_block1">
                            <div style={{ flexGrow: "1" }}>
                                <div className="RecivePaymentModel_block1_sub">
                                    <div className="display-flex-div">
                                        <label className="edit_container_label">Enter Close-Out Notes Below</label>
                                        <textarea value={headerdata && headerdata.length > 0 && headerdata[0].sNote
                                            ? headerdata[0].sNote
                                            : ""} style={{ margin: "1%", height: "80px" }}
                                            name="notes"
                                            id="notes"
                                            onChange={handleInputChange}
                                        />

                                    </div>
                                    <label className="edit_container_label">Obligation Write Offs </label><div id="Query"> 
                                        {/* ${headerdata && headerdata.length > 0 && headerdata[0].fWriteOff
                                        ? headerdata[0].fWriteOff
                                        : ""} */}
                                        ${fields.writeOff}
                                    </div>

                                </div>
                                <div className="display-flex-div">
                                    <label className="edit_container_label">Obligation Adjustments</label><div id="Query"> 
                                        {/* ${headerdata && headerdata.length > 0 && headerdata[0].fAdjustment
                                        ? headerdata[0].fAdjustment
                                        : ""} */}
                                        ${fields.adjustments}
                                    </div>

                                    <label className="edit_container_label">Remaining Balance</label><div id="Query"> 
                                        {/* ${headerdata && headerdata.length > 0 && headerdata[0].fRemainingBalance
                                        ? headerdata[0].fRemainingBalance
                                        : ""} */}
                                        ${fields.balance}
                                    </div>
                                </div>
                                <div className="display-flex-div">
                                    <label className="edit_container_label">Programs to be Closed</label><div id="Query"> ${headerdata && headerdata.length > 0 && headerdata[0].iProgramsToBeClosed
                                        ? headerdata[0].iProgramsToBeClosed
                                        : ""}
                                    </div>
                                    <DropDown
                                        setfields={setfields}
                                        width="350px"
                                        fields={fields}
                                        fieldNmae={"programEndReason"}
                                        dropDownData={programendreason?.map((val) => {
                                            return {
                                                name: val?.sName,
                                                id: val?.gProgramEndReasonID,
                                            };
                                        })}
                                    />
                                </div>
                                <div className="display-flex-div">
                                    <label className="edit_container_label">CSW to be Written Off</label><div id="Query"> ${headerdata && headerdata.length > 0 && headerdata[0].fCswToBeWrittenOff
                                        ? headerdata[0].fCswToBeWrittenOff
                                        : ""}
                                    </div>
                                    <label className="edit_container_label">Jail Time to be Written Off</label><div id="Query"> ${headerdata && headerdata.length > 0 && headerdata[0].iJailTimeToBeWrittenOff
                                        ? headerdata[0].iJailTimeToBeWrittenOff
                                        : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    "No view access"
                )}
            </div>
        </>
    );
};

export default CloseoutWizard;
