import axios from "axios";
import { handleGetLocal } from "../../../services/auth-service";
import { logOut } from "../../../components/Logout";

export class ObligationFeeListFunction {
    constructor() {
      this.pathname = handleGetLocal("pathURL");
    }

    async getObligationFeeList(setLoading, seterorhandling, navigate, setObligationFeeList,setTableData,setdata) {
        setLoading(true);
        await axios
          .get(`${process.env.REACT_APP_API_URL}/api/Administration/get-obligationFeeList`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              Domain: `${this.pathname}`,
            },
          })
          .then((res) => {
            setLoading(false);
            setObligationFeeList(res.data.data);
            setdata(setTableData(res.data.data));
          })
          .catch((err) => {
            setLoading(false);
            if (!err?.response) {
              navigate(`/error/500`);
            } else if (err?.response?.status == 401) {
              logOut();
            } else {
              seterorhandling(true);
            }
          });
      }
}