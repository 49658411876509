import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  HeaderFilter,
  Pager,
  Summary,
  TotalItem,
  StateStoring,
  Editing,
  Lookup,
  RequiredRule,
  Selection,
  ColumnChooser,
} from "devextreme-react/data-grid";
import LoadPanel from "devextreme-react/load-panel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setcontectTableAddDataaa,
  setcontectTableAddDataaa2,
  setgoToDocketPerson,
} from "../../reducer/ParticipateReducer";

import { Button } from "devextreme-react/button";
import { removeProntactEntitiesdynamicTab, set_selected_row } from "../../../src/reducer/AdminReadReducer";
import "./Table.css";
import { ContextMenu, Popup } from "devextreme-react";
import { ExportFile } from "../ExportGride/ExportFile";
import axios from "axios";
import { handleGetLocal } from "../../services/auth-service";
import { logOut } from "../Logout";
import {
  setAddObligation,
  setdockerValue,
  setgotooblogationValue,
  setresetReportDates,
} from "../../reducer/participantNoteReducer";
import ActiveIcon from "../../assets/images/icons/success-check.svg";
import UnActiveIcon from "../../assets/images/close.png";
import ThreeDotMenu from "../Common/Icons/ThreeDotMenu";
import { QuickaddObligationPopup } from "../../pages/Participants/ParticipantSubTabs/Dockect/overview/QuickaddObligation";
import { setRecentDocket } from "../../reducer/AddParticipantWizardReducer";
import { removeDynamicTab, setDynamicTab } from "../../reducer/HomePageReducer";
let selected;
let source;
let last;
let gUserId = localStorage.getItem("gUserId");

const QuickaddObligation = ({
  setgetQuickaddObligationPopup,
  getQuickaddObligationPopup,
  dropdownData,
  docketId,
  toastConfig,
  setToastConfig,
  header,
  callObligationApi,
  setCallObligationApi,
  updateDocketManagement,
}) => {
  const dispatch = useDispatch();
  const { resetReportDates } = useSelector((store) => store.participateNotes);

  return (
    <Popup
      visible={getQuickaddObligationPopup}
      onHiding={() => {
        dispatch(setresetReportDates(!resetReportDates));
        setgetQuickaddObligationPopup(false);
      }}
      showTitle={false}
      width={608}
      height={850}
      showCloseButton={true}
      hideOnOutsideClick={true}
    >
      <QuickaddObligationPopup
        docketId={docketId}
        getQuickaddObligationPopup={getQuickaddObligationPopup}
        setgetQuickaddObligationPopup={setgetQuickaddObligationPopup}
        dropdownData={dropdownData}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        header={header}
        callObligationApi={callObligationApi}
        setCallObligationApi={setCallObligationApi}
        updateDocketManagement={updateDocketManagement}
      />
    </Popup>
  );
};

const Exports = ({ setexportPopupOpen, exportPopupOpen, data, exportwidth, exporttitle, exportFont, allData }) => {
  return (
    <>
      <Popup
        visible={exportPopupOpen}
        onHiding={() => {
          setexportPopupOpen(false);
        }}
        showTitle={false}
        width={500}
        height={300}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <ExportFile
          alldata={data}
          setexportPopupOpen={setexportPopupOpen}
          exportwidth={exportwidth}
          exporttitle={exporttitle}
          exportFont={exportFont}
          allData={allData}
        />
      </Popup>
    </>
  );
};

const getdocument = async (id, setLoading, loading, type, navigate) => {
  setLoading(true);
  const pathname = handleGetLocal("pathURL");
  await axios
    .get(`${process.env.REACT_APP_API_URL}/api/attachments/get-single-attachment?binaryDataId=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        Domain: `${pathname}`,
      },
    })
    .then((val) => {
      setLoading(false);
      const openFileInNewTab = (base64Data, fileName, fileType) => {
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: fileType });
        const url = URL.createObjectURL(blob);

        if (fileType == "image/jpeg") {
          window.open(url);
        } else {
          const link = document.createElement("a");
          link.href = url;
          link.download = fileName;
          link.target = "_blank";
          link.rel = "noopener noreferrer";
          link.click();

          URL.revokeObjectURL(url);
        }
      };

      const imageData = val?.data?.data?.doc;
      let imageType = type;
      const imageName = val?.data?.data?.fileName;

      openFileInNewTab(imageData, imageName, imageType);
    })
    .catch((val) => {
      setLoading(false);
      if (!val?.response) {
        navigate(`/error/500`);
      } else if (val?.response?.status == 401) {
        logOut();
      }
    });
};

const viewDocument = (rowData, setLoading, loading, navigate) => {
  let imageType;
  switch (true) {
    case rowData.data.Filename.includes("jpg"):
    case rowData.data.Filename.includes("jpeg"):
    case rowData.data.Filename.includes("png"):
    case rowData.data.Filename.includes("gif"):
    case rowData.data.Category === "Photo":
      imageType = "image/jpeg";
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, imageType, navigate);
      break;
    case rowData.data.Filename.includes("pdf"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "application/pdf", navigate);
      break;
    case rowData.data.Filename.includes("txt"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "text/plain", navigate);
      break;
    case rowData.data.Filename.includes("tif"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "image/tiff", navigate);
      break;
    case rowData.data.Filename.includes("mp3"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "audio/mpeg", navigate);
      break;
    case rowData.data.Filename.includes("mp4"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "video/mp4", navigate);
      break;
    case rowData.data.Filename.includes("mov"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "video/quicktime", navigate);
      break;
    case rowData.data.Filename.includes("csv"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "text/plain", navigate);
      break;
    case rowData.data.Filename.includes("eml"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "message/rfc822", navigate);
      break;
    case rowData.data.Filename.includes("msg"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "application/vnd.ms-outlook", navigate);
      break;
    case rowData.data.Filename.includes("wav"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "audio/wav", navigate);
      break;
    case rowData.data.Filename.includes("doc"):
    case rowData.data.Filename.includes("rtf"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "application/msword", navigate);
      break;
    case rowData.data.Filename.includes("json"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "application/json", navigate);
      break;
    case rowData.data.Filename.includes("docx"):
      getdocument(
        rowData?.data?.binaryDataId,
        setLoading,
        loading,
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        navigate
      );
      break;
    case rowData.data.Filename.includes("xls"):
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "application/vnd.ms-excel", navigate);
      break;
    case rowData.data.Filename.includes("xlsx"):
      getdocument(
        rowData?.data?.binaryDataId,
        setLoading,
        loading,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        navigate
      );
      break;
    default:
      getdocument(rowData?.data?.binaryDataId, setLoading, loading, "text/plain", navigate);
      break;
  }
};

export const Table2 = ({
  Tablecoloum,
  tableRow,
  load,
  coloumWidthText,
  setPerson,
  approveTaskProcessCloseout,
  AllocationAdjustment,
  height,
  editTable,
  newrowData,
  tabledata,
  setShowPersonModal,
  mandatoryColoum,
  setpopUp,
  wordWrapEnabled,
  setIsPopupVisible,
  contactInfoType,
  editParticipant,
  showTotalBlock,
  recivePayment,
  setNoteEditTable,
  setaddDataIngTable,
  setGetEditData,
  resetvalueonedit,
  Contextmenu,
  setenterAttendencePopup,
  setreportDataId,
  setvoidPopup,
  setLast,
  setremoveAttendenceStatus,
  removeAttendenceStatus,
  setPostReportNote,
  editableperticularValue,
  exportInDifferentFormate,
  exportPopupOpen,
  setexportPopupOpen,
  exportwidth,
  exporttitle,
  exportFont,
  getAttachmenteditData,
  seteditAttachmentPopupOpen,
  attachmentRightCickPopup,
  setattachmentdeletePopup,
  DocketObligationTableSum,
  setgetbinaryDataIdInAttachment,
  DocketObligationTableRightClick,
  setopenGeneratePaymentPlanpopup,
  setWrieOffpopup,
  setObligationData,
  setgeneratePaymenttogal,
  multiselection,
  Revocationscontextmenu,
  seteditRevocationspopup,
  setgetEDitdataRevocations,
  setVoidPopup,
  taskcontextmenu,
  taskcontextmenu2,
  setAddQuickPopup,
  setresettask,
  resettask,
  setgettaskEditDAta,
  gettaskEditDAta,
  setPendingPOpuptogal,
  getCompletTask,
  setVoideFalse,
  doToDocketFind,
  setDocketFind,
  CheckListTableRightClick,
  seteditCHeckoutNotePOpup,
  setgetCheckLIstActivity,
  nojump,
  checklistgetTopDynamicDocketid,
  setresetCHecklist,
  CHecklistreset,
  editCheclListOptionrightClist,
  editchecklistoptionsetpopup,
  seteditdatachecklist,
  GoToChildDocketrightClick,
  goToObligationFinfDocket,
  setGoTOObligationReference,
  setfindDocker,
  goToObligationFinfObligation,
  setfindObligation,
  docketlisthiperline,
  setentitidata,
  entitidata,
  formjump,
  personStatusActiveFilter,
  setConfirmationPopup,
  setConfirmationData,
  REportWizardActiveLIst,
  REportWizardPaymentlist,
  PaymentlistReportWizardTotal,
  ActivitylistReportWizardTotal,
  FindProntactEntities,
  setProntactEntitiesfind,
  ContactPositiondropdown,
  Contactdropdown,
  ProntactEntitiesSubTabContectLIst,
  preview,
  prewviewtogal,
  ActivePersonList,
  DocketLIstreportwizard,
  ObligationLIstReportWizard,
  DisbursementReportWizard,
  ReportDateReportWizard,
  DrugTestREportWizardReportWizard,
  ReportWizardDocketLIstrightclick,
  ActivePersonListrightclick,
  ReportDaterightclick,
  Disbursementrightclick,
  DrugTestREportWizardrightclick,
  ObligationLIstrightclick,
  AllocationAdjustmenttgrid2,
  AllocationAdjustmenttgrid1,
  setupdategrid1,
  setupdategrid2,
  SharequeryTableselectall,
  setgetselectdata,
  sharequeryshow,
  settablereload,
  tablereload,
  coloumchosser,
  PreDisbursementsReport,
  PreDisbursementsReporteightclick,
  DocketManagementRightClick,
  setPrintCheckPopup,
  DocketManagementTotalshow,
  GoToDocketSupervisiontable,
  DocketSupervisionDropdowndata,
  DocketSupervisioncheckdata,
  setdata2deopdown,
  ReportDatestablerowdata,
  Checklist,
  setchecklisteditdata,
  arrearageMinderMenu,
  setArrearageRowData,
  arrearageRowData,
  setSendEmailPopup,
  setAddTaskPopupType,
  setReassignTasksPopup,
  selectedRowCheckbox,
  setSelectedRowCheckbox,
  saveContact,
  setSaveContact,
  setGoodToSaveParticipant,
  updateDocketManagement,
  setCheckedFalse,
  setVisibleRows,
  visibleRows,
  revenueReportMenu,
  revenueRowData,
  setRevenueRowData,
  revenueReportTotal,
  agingReportMenu,
  agingRowData,
  setAgingRowData,
  setChangesuperviserDatePOpup,
  disbursementrowdata,
  setdisbursementrowdata,
  setChangeFinancialStatusPopup,
  obligationDetailTotal,
  reallocatePaymentTotal,
  editableAllocationAmount,
  setAddTollingPopup,
  setChangeSupervisorPopup,
  selectedPersonIds,
  setSelectedPersonIds,
  showSendEmailPopup,
  personListControl,
  periodSummaryControl,
  docketListControl,
  AddParticipant,
  updateTotalAmount,
  setIsMergePopupVisible,
  setSourcePersonId,
  fromOP3,
  fromPersonOverview,
  resetPersonFind,
  approveRefund,
  obligationfeeListMenu,
  obligationFeeListRowData,
  setobligationFeeListRowData,
  setConfirmPopup,
  setMergedPerson,
  fromParticipantHeader,
  actionDropdown,
  handleCellValueChanged
}) => {
  // const orders = tableRow.map(item => {
  //   for (const key in item) {
  //     if (typeof item[key] === 'string') {
  //       item[key] = item[key].trim();
  //     }
  //   }
  //   return item;
  // });
  const orders = tableRow;

  const [filterValues, setFilterValues] = useState([true]);

  const dataGrid = useRef(null);
  const saveData = async () => {
    await dataGrid.current.instance().saveEditData();
    if (dataGrid.current.instance().hasEditData()) {
      setSaveContact(false);
    }
  };
  useEffect(() => {
    if (saveContact === true) {
      if (dataGrid?.current?.instance()?.hasEditData()) {
        saveData();
      } else {
        setGoodToSaveParticipant(true);
      }
    }
  }, [saveContact]);

  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(0);

  const customizeDate = (data) => {
    setCount(data.value);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { contectTableAddData, contectTableAddData2 } = useSelector((store) => store.participate);
  const [tabledataa, settabledataa] = useState([...contectTableAddData]);
  const STORAGE_KEY = `${coloumWidthText}ColumnOrder`;
  const [columns, setColumns] = useState(Tablecoloum);
  const loadSavedOrder = () => {
    const savedOrder = JSON.parse(localStorage.getItem(STORAGE_KEY));
    if (savedOrder) {
      const reorderedColumns = savedOrder
        .map((field) => columns.find((col) => col.title === field))
        .filter(Boolean);
      setColumns(reorderedColumns.length ? reorderedColumns : columns);
    }
  };

  const saveColumnOrder = (newOrder) => {
    const order = newOrder.map((col) => col.title);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(order));
  };


  useEffect(() => {
    if (AddParticipant) {
      dispatch(setcontectTableAddDataaa(tabledataa));
    }
  }, [tabledataa]);

  const [Attendance, setAttendance] = useState("");
  const pathname = handleGetLocal("pathURL");

  const saveCookieCrum = async (perdonId) => {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${perdonId}`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then(() => { })
      .catch((val) => { });
  };

  const [selectedRow, setselectedRow] = useState(undefined);
  const [desableGeneratePaymetPLan, setdesableGeneratePaymetPLan] = useState(false);
  const [desablewriteoff, setdesablewriteoff] = useState(false);
  const [desableReportData, setdesableReportData] = useState(false);
  const [desableRevocation, setdesableRevocation] = useState(false);

  const [locationappend, setlocationappend] = useState(entitidata ? entitidata : []);

  const [DocketSupervisioncheck, setDocketSupervisioncheck] = useState(
    DocketSupervisioncheckdata?.length ? DocketSupervisioncheckdata : []
  );

  const [DocketSupervisioncheckclone, setDocketSupervisioncheckclone] = useState([]);

  const actionOptions = {
    // { name: "WriteOff" },
    // { name: "Expunge" },
    // { name: "Waive" },
    // { name: "Exempt" },

    store: {
      type: "array",
      data: ["None", "Add", "View", "Edit", "Full"],
      key: "id",
    },
  }

  useEffect(() => {
    setlocationappend(entitidata ? entitidata : []);
  }, [entitidata]);

  useEffect(() => {
    setDocketSupervisioncheck(DocketSupervisioncheckdata?.length ? DocketSupervisioncheckdata : []);
    setDocketSupervisioncheckclone([]);
  }, [DocketSupervisioncheckdata]);

  useEffect(() => {
    if (setentitidata) {
      setentitidata(locationappend);
    }
  }, [locationappend]);

  useEffect(() => {
    if (setdata2deopdown) {
      setdata2deopdown([
        ...DocketSupervisioncheckclone,
        ...DocketSupervisioncheck.filter((val) => {
          let temp = true;
          for (let i = 0; i < DocketSupervisioncheckdata?.length; i++) {
            if (DocketSupervisioncheckdata[i]?.ID?.toLowerCase() == val?.ID?.toLowerCase()) {
              temp = false;
            }
          }
          if (temp) {
            return val;
          }
        }),
      ]);
    }
  }, [DocketSupervisioncheck]);

  const printTableData = (dataGrid) => {
    const dataGridInstance = dataGrid.current.instance();

    const visibleColumns = dataGridInstance.getVisibleColumns();
    const headers = [];
    visibleColumns.map((column) => {
      if (column.caption !== "Edit" && column.caption !== "Action") {
        headers.push(column.caption);
      }
    });
    const rows = [];

    const visibleRows = dataGridInstance.getVisibleRows();
    visibleRows.forEach((row) => {
      const rowData = [];
      visibleColumns.map((column) => {
        if (column.dataField !== "Edit" && column.caption !== "Action") {
          if (column.dataField === "DOE") {
            rowData.push(
              new Date(row.data[column.dataField]).toLocaleString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })
            );
          } else {
            rowData.push(row.data[column.dataField]);
          }
        }
      });
      rows.push(rowData);
    });

    var printWindow = window.open("", "", "width=600,height=600");
    printWindow.document.open();
    printWindow.document.write(`
    <html>
    <head>
      <title>Table Data</title>
      <style>
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th, td {
          border: 1px solid #000;
          padding: 8px;
          text-align: left;
        }
        tr:nth-child(even) {
          background-color: #f2f2f2;
        }
      </style>
    </head>
    <body>
      <table>
        <thead>
          <tr>
            ${headers.map((header) => `<th>${header}</th>`).join("")}
          </tr>
        </thead>
        <tbody>
          ${rows
        .map((rowData) => `<tr>${rowData.map((cell) => `<td>${cell ? cell : ""}</td>`).join("")}</tr>`)
        .join("")}
        </tbody>
      </table>
    </body>
    </html>
  `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  useEffect(() => {
    setTimeout(() => {
      if (preview && prewviewtogal !== undefined) {
        printTableData(dataGrid);
      }
    }, 100);
  }, [prewviewtogal]);

  useEffect(() => {
    if (setVoideFalse) {
      const coloum = JSON.parse(localStorage.getItem(coloumWidthText))?.columns?.map((val) => {
        if (val?.dataField == "Void") {
          return {
            ...val,
            filterValues: [false],
            filterType: "include",
          };
        } else {
          return val;
        }
      });
      const data = {
        ...JSON.parse(localStorage.getItem(coloumWidthText)),
        columns: coloum,
      };
      localStorage.setItem(coloumWidthText, JSON.stringify(data));
    }
    loadSavedOrder();
  }, []);

  useEffect(() => {
    if (setCheckedFalse) {
      const coloum = JSON.parse(localStorage.getItem(coloumWidthText))?.columns?.map((val) => {
        if (val?.dataField == "Active") {
          return {
            ...val,
            filterValues: [false],
            filterType: "include",
          };
        } else {
          return val;
        }
      });
      const data = {
        ...JSON.parse(localStorage.getItem(coloumWidthText)),
        columns: coloum,
      };
      localStorage.setItem(coloumWidthText, JSON.stringify(data));
    }
  }, []);

  useEffect(() => {
    if (SharequeryTableselectall) {
      setTimeout(() => {
        dataGrid?.current?.instance()?.clearSelection();
      }, 10);
    }
  }, [sharequeryshow]);

  const [gridKey, setGridKey] = useState(1);
  const [selectAll, setselectAll] = useState(false);
  const [selectTaskAll, setselectTaskAll] = useState(false);
  const [taskManagercheckbox, setTaskManagercheckbox] = useState([]);
  const [PreDisbursementsReportcheckbox, setPreDisbursementsReportcheckbox] = useState([]);

  useEffect(() => {
    if (settablereload) {
      setGridKey((prevGridKey) => prevGridKey + 1);
    }
  }, [tablereload]);

  const [checklistdata, setchecklistdata] = useState([]);
  useEffect(() => {
    if (Checklist) {
      let arr = [];
      tableRow?.map((val) => {
        if (val?.bAllowCheck) {
          arr = [
            ...arr,
            {
              recordId: checklistgetTopDynamicDocketid,
              itemRecordId: val?.gCheckListItemRecordId ? val?.gCheckListItemRecordId : null,
              itemId: val?.gCheckListItemId,
              note: val?.Note ? val?.Note : "",
              complete: val?.["Completed ?"] ? true : false,
            },
          ];
        }
        return val;
      });

      setchecklistdata([...arr]);
    }
  }, [tableRow]);

  useEffect(() => {
    if (setchecklisteditdata) {
      setchecklisteditdata(checklistdata);
    }
  }, [checklistdata]);
  const [getQuickaddObligationPopup, setgetQuickaddObligationPopup] = useState(false);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const [header, setHeader] = useState({});

  const getDropdownDatas = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/docket/docket-dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setDropdownData(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const [dropdownData, setDropdownData] = useState(null);

  const { AddParticipateUserInfo } = useSelector((store) => store.participate);

  useEffect(() => {
    if (coloumWidthText === "DocketManagementTable") {
      setHeader({
        ...header,
        sPersonNameLastFirst: AddParticipateUserInfo.sLastFirst,
      });
      getDropdownDatas();
    }

    if (personStatusActiveFilter) {
      let location = localStorage.getItem("location") || '';
      const coloum = JSON.parse(localStorage.getItem(coloumWidthText))?.columns?.map((val) => {
        if (val?.dataField === "Person Status") {
          return {
            ...val,
            filterValues: ['Active'],
            filterType: "include",
          };
        }
        else if (val?.dataField === "Primary Location") {
          return {
            ...val,
            filterValues: [location],
            filterType: "include",
          }
        }
        else {
          return val;
        }

      });
      const data = {
        ...JSON.parse(localStorage.getItem(coloumWidthText)),
        columns: coloum,
      };
      localStorage.setItem(coloumWidthText, JSON.stringify(data));
    }
  }, []);

  const [docketId, setDocketId] = useState(null);

  return (
    <div style={{ width: "100%" }}>
      <LoadPanel shadingColor="true" visible={load} delay={10} />
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <QuickaddObligation
        setgetQuickaddObligationPopup={setgetQuickaddObligationPopup}
        getQuickaddObligationPopup={getQuickaddObligationPopup}
        dropdownData={dropdownData}
        docketId={docketId}
        toastConfig={toastConfig}
        setToastConfig={setToastConfig}
        header={header}
        updateDocketManagement={updateDocketManagement}
      />
      {exportInDifferentFormate && (
        <Exports
          exportPopupOpen={exportPopupOpen}
          setexportPopupOpen={setexportPopupOpen}
          data={dataGrid}
          exportwidth={exportwidth}
          exporttitle={exporttitle}
          exportFont={exportFont}
          allData={tableRow}
        />
      )}

      <DataGrid
        wordWrapEnabled={wordWrapEnabled}
        className={showTotalBlock ? "" : "showtotal"}
        id="gridContainer"
        key={gridKey}
        onContextMenuPreparing={(e) => {
          setselectedRow(e.row?.data);
          if (setreportDataId && editParticipant) {
            setAttendance(e.row?.data?.Attendance);
            setreportDataId(e?.row?.key);
            setPostReportNote(e?.row?.data?.[`Pre-Report Note`]);
            setdesableReportData(e?.row?.data?.Void);
          } else if (attachmentRightCickPopup && setgetbinaryDataIdInAttachment) {
            setgetbinaryDataIdInAttachment(e.row?.data?.binaryDataId);
          } else if (DocketObligationTableRightClick && setObligationData) {
            if (e?.row?.data?.["Balance Owed"] <= 0) {
              setdesableGeneratePaymetPLan(true);
            } else {
              setdesableGeneratePaymetPLan(false);
            }

            if (e?.row?.data?.["Balance Owed"] <= 0) {
              setdesablewriteoff(true);
            } else {
              setdesablewriteoff(false);
            }
            setObligationData(e?.row?.data);
            setgeneratePaymenttogal((prev) => !prev);
          } else if (setgetEDitdataRevocations && Revocationscontextmenu) {
            setgetEDitdataRevocations(e?.row?.data);
            setdesableRevocation(e?.row?.data?.Void);
          } else if (taskcontextmenu && setgettaskEditDAta) {
            setgettaskEditDAta(e?.row?.data);
          } else if (CheckListTableRightClick && setgetCheckLIstActivity) {
            setgetCheckLIstActivity(e?.row?.data);
          } else if (seteditdatachecklist && editCheclListOptionrightClist) {
            seteditdatachecklist(e?.row?.data);
          } else if (ReportDatestablerowdata) {
            ReportDatestablerowdata(e.row?.data);
          } else if (arrearageMinderMenu) {
            setArrearageRowData(e.row?.data);
          } else if (obligationfeeListMenu) {
            setobligationFeeListRowData(e.row?.data);
          } else if (taskcontextmenu2 && setgettaskEditDAta) {
            setgettaskEditDAta(e?.row?.data);
          } else if (revenueReportMenu) {
            setRevenueRowData(e?.row?.data);
          } else if (agingReportMenu) {
            setAgingRowData(e?.row?.data);
          }
        }}
        ref={dataGrid}
        dataSource={orders}
        cellHintEnabled={true}
        keyExpr="ID"
        showBorders={true}
        height={"auto"}
        loadPanel={false}
        allowColumnResizing={true}
        columnResizingMode={"nextColumn"}
        rowAlternationEnabled={true}
        onCellPrepared={(e) => {
          if (SharequeryTableselectall) {
          }
          if (e.isAltRow) {
            e.cellElement.style.backgroundColor = "var(--table-second-row-light-black)";
            e.cellElement.style.color = "var(--white-black)";
          }
        }}
        showColumnLines={true}
        showRowLines={true}
        allowColumnReordering={true}
        onContentReady={(e) => {
          e.component.columnOption("command:edit", "visibleIndex", -1);
          if (recivePayment) {
            if (visibleRows?.length !== e.component.getVisibleRows().length) {
              setVisibleRows(e.component.getVisibleRows());
            }
          }
        }}
        onRowInserting={(e) => {
          if (tabledata && editParticipant) {
            newrowData({ data: e, name: "insert" });
          }
        }}
        onSaved={(e) => {
          if (AddParticipant) {

            if (e?.changes[0]?.type == "insert") {
              if (ProntactEntitiesSubTabContectLIst) {
                dispatch(setcontectTableAddDataaa2([...contectTableAddData2, e?.changes[0]?.data]));
                return;
              } else {
                settabledataa([...tabledataa, ...e?.changes.map((change) => change?.data)]);
              }
            } else if (e?.changes[0]?.type == "update") {
              if (ProntactEntitiesSubTabContectLIst) {
                dispatch(
                  setcontectTableAddDataaa2(
                    contectTableAddData2?.map((val) => {
                      if (val.ID === e.changes[0].data.ID) {
                        return e.changes[0].data;
                      } else {
                        return val;
                      }
                    })
                  )
                );
                return;
              } else {
                settabledataa(
                  tabledataa.map((val) => {
                    e.changes.forEach((change) => {
                      if (val.ID === change.data.ID) return change.data;
                    });
                    return val;
                  })
                );
              }
            }
          }
          if (actionDropdown?.length) { handleCellValueChanged(); }
        }}
        onRowUpdating={(e) => {
          if (recivePayment && editableperticularValue) {
            updateTotalAmount(e?.newData["Amount to Pay"], e?.key);
          }
          if (tabledata && editParticipant) {
            newrowData({ data: e, name: "edit" });
          }
          if (AllocationAdjustmenttgrid1 && setupdategrid1) {
            setupdategrid1(e);
          }
          if (AllocationAdjustmenttgrid1 && setupdategrid2) {
            setupdategrid2(e);
          }
          if (GoToDocketSupervisiontable) {
            setDocketSupervisioncheck(
              DocketSupervisioncheck.map((val) => {
                if (val?.ID?.toLowerCase() == e?.key?.toLowerCase()) {
                  return { ...val, Type: e?.newData?.Type };
                } else {
                  return val;
                }
              })
            );
          }
        }}
        onRowRemoving={(e) => {
          if (tabledata && editParticipant) {
            newrowData({ data: e, name: "delete" });
          }
          if (!editParticipant) {
            if (ProntactEntitiesSubTabContectLIst) {
              dispatch(
                setcontectTableAddDataaa2(
                  contectTableAddData2?.filter((val) => {
                    if (val.ID !== e.data.ID) {
                      return val;
                    }
                  })
                )
              );
              return;
            }
            settabledataa(
              tabledataa.filter((val) => {
                if (val.ID !== e.data.ID) {
                  return val;
                }
              })
            );
          }
        }}
        onSelectionChanged={(e) => {
          if (setgetselectdata && SharequeryTableselectall) {
            setgetselectdata(e);
          }
        }}
        onOptionChanged={(e) => {
          if (e.fullName === "columns[1].filterValues" && recivePayment) {
            setFilterValues(e.value);
          }
          if (e.name === "columns" && e.fullName.includes("visibleIndex")) {
            const updatedColumns = e.component.getVisibleColumns();
            const newOrder = updatedColumns
              .map((col) => {
                const matchedColumn = columns.find(
                  (item) => item.title === col.name
                );
                if (!matchedColumn) {
                  console.warn(
                    `No matching column found for dataField: ${col.name}`
                  );
                }
                return matchedColumn;
              })
              .filter(Boolean);

            saveColumnOrder(newOrder);
          }
        }}
        customizeColumns={(columns) => {
          columns.forEach((column, index) => (column.visibleIndex = index));
        }}
      >
        {/* DataGrid tag end */}
        <HeaderFilter visible={true} allowSearch={true} />
        {multiselection && <Selection mode="multiple" showCheckBoxesMode="always" />}

        {SharequeryTableselectall && <Selection mode="multiple" showCheckBoxesMode="always" />}
        {coloumchosser && <ColumnChooser enabled={true} mode="dragAndDrop" />}

        {ProntactEntitiesSubTabContectLIst ? (
          <Editing mode="batch" allowAdding={editTable} allowDeleting={false} allowUpdating={editTable} />
        ) : editParticipant ? (
          <Editing mode="batch" allowAdding={editTable} allowDeleting={editTable} allowUpdating={editTable} />
        ) : (
          ""
        )}

        {editableperticularValue && <Editing mode="cell" allowUpdating={true} />}
        {editableAllocationAmount && <Editing mode="cell" allowUpdating={editableAllocationAmount} />}
        {AllocationAdjustmenttgrid1 && <Editing mode="cell" allowUpdating={true} />}
        {GoToDocketSupervisiontable && <Editing mode="cell" allowUpdating={true} allowAdding={false} />}
        {actionDropdown?.length && <Editing mode="cell" allowUpdating={true} allowAdding={false} />}
        <StateStoring savingTimeout={10} enabled={true} type="localStorage" storageKey={coloumWidthText} />
        <Summary>
          <TotalItem column={mandatoryColoum} summaryType="count" customizeText={customizeDate} />
          {recivePayment && (
            <TotalItem
              displayFormat="{0}"
              column="Amount Due"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {recivePayment && (
            <TotalItem
              displayFormat="{0}"
              column="Balance Due"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {recivePayment && (
            <TotalItem
              displayFormat="{0}"
              column="Amount Payable"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {recivePayment && (
            <TotalItem
              displayFormat="{0}"
              column="Arrears"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {recivePayment && (
            <TotalItem
              displayFormat="{0}"
              column="Amount to Pay"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {DocketObligationTableSum && (
            <TotalItem
              displayFormat="{0}"
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {DocketObligationTableSum && (
            <TotalItem
              displayFormat="{0}"
              column="Paid"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {DocketObligationTableSum && (
            <TotalItem
              displayFormat="{0}"
              column="On Hand"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {DocketObligationTableSum && (
            <TotalItem
              displayFormat="{0}"
              column="Written Off"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {DocketObligationTableSum && <TotalItem column="Obligation Type" displayFormat="{0}" summaryType="count" />}
          {DocketObligationTableSum && (
            <TotalItem
              displayFormat="{0}"
              column="Balance Owed"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {DocketObligationTableSum && (
            <TotalItem
              displayFormat="{0}"
              column="Disbursed"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {DocketObligationTableSum && (
            <TotalItem
              displayFormat="{0}"
              column="Arrears"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}

          {PaymentlistReportWizardTotal && <TotalItem displayFormat="{0}" column="Offender No" summaryType="count" />}
          {PaymentlistReportWizardTotal && (
            <TotalItem
              displayFormat="{0}"
              column="Allocation Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {PaymentlistReportWizardTotal && (
            <TotalItem
              displayFormat="{0}"
              column="Payment Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {ActivitylistReportWizardTotal && <TotalItem displayFormat="{0}" column="Offender #" summaryType="count" />}
          {ActivePersonList && <TotalItem displayFormat="{0}" column="Last Name" summaryType="count" />}
          {DocketLIstreportwizard && <TotalItem displayFormat="{0}" column="Offender" summaryType="count" />}
          {DocketLIstreportwizard && (
            <TotalItem
              displayFormat="{0}"
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {DocketLIstreportwizard && (
            <TotalItem
              displayFormat="{0}"
              column="Write Off"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {DocketLIstreportwizard && (
            <TotalItem
              displayFormat="{0}"
              column="Paid"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {DocketLIstreportwizard && (
            <TotalItem
              displayFormat="{0}"
              column="Balance"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {ObligationLIstReportWizard && <TotalItem displayFormat="{0}" column="Offender" summaryType="count" />}
          {ObligationLIstReportWizard && (
            <TotalItem
              displayFormat="{0}"
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {ObligationLIstReportWizard && (
            <TotalItem
              displayFormat="{0}"
              column="Paid"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {ObligationLIstReportWizard && (
            <TotalItem
              displayFormat="{0}"
              column="Write Off"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {ObligationLIstReportWizard && (
            <TotalItem
              displayFormat="{0}"
              column="Balance"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {ObligationLIstReportWizard && (
            <TotalItem
              displayFormat="{0}"
              column="On Hand"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {ObligationLIstReportWizard && (
            <TotalItem
              displayFormat="{0}"
              column="Arrears"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {DisbursementReportWizard && (
            <TotalItem
              displayFormat="{0}"
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {ReportDateReportWizard && <TotalItem displayFormat="{0}" column="Offender #" summaryType="count" />}
          {DrugTestREportWizardReportWizard && (
            <TotalItem displayFormat="{0}" column="Offender #" summaryType="count" />
          )}
          {AllocationAdjustmenttgrid1 && (
            <TotalItem
              displayFormat="{0}"
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {AllocationAdjustmenttgrid1 && (
            <TotalItem
              displayFormat="{0}"
              column="Paid"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {AllocationAdjustmenttgrid1 && (
            <TotalItem
              displayFormat="{0}"
              column="Write Off"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {AllocationAdjustmenttgrid1 && (
            <TotalItem
              displayFormat="{0}"
              column="Balance"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {AllocationAdjustmenttgrid1 && (
            <TotalItem
              displayFormat="{0}"
              column="On Hand"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {AllocationAdjustmenttgrid1 && (
            <TotalItem
              displayFormat="{0}"
              column="Amount to Deallocate"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {AllocationAdjustmenttgrid2 && (
            <TotalItem
              displayFormat="{0}"
              column="Amount to Allocate"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}

          {PreDisbursementsReport && (
            <TotalItem
              displayFormat="{0}"
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {PreDisbursementsReport && (
            <TotalItem
              displayFormat="{0}"
              column="Added Disbursement"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {PreDisbursementsReport && (
            <TotalItem
              displayFormat="{0}"
              column="Sub'd Payment"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {DocketManagementTotalshow && (
            <TotalItem
              displayFormat="{0}"
              column="Balance"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {revenueReportTotal && (
            <TotalItem
              displayFormat="{0}"
              column="Revenue"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {revenueReportTotal && (
            <TotalItem
              displayFormat="{0}"
              column="Collections"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {obligationDetailTotal && (
            <TotalItem
              displayFormat="{0}"
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {reallocatePaymentTotal && (
            <TotalItem
              displayFormat="{0}"
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {reallocatePaymentTotal && (
            <TotalItem
              displayFormat="{0}"
              column="Balance"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {reallocatePaymentTotal && (
            <TotalItem
              displayFormat="{0}"
              column="Allocation Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {actionDropdown?.length && (
            <TotalItem
              displayFormat="{0}"
              column="Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {actionDropdown?.length && (
            <TotalItem
              displayFormat="{0}"
              column="Balance"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
          {actionDropdown?.length && (
            <TotalItem
              displayFormat="{0}"
              column="Adjustment Amount"
              summaryType="sum"
              valueFormat={{ type: "currency", precision: 2 }}
            />
          )}
        </Summary>

        {columns.map((val, i) => {
          if (val?.dropdown) {
            return (
              <Column dataField={val.title} minWidth={"240"} caption={val.title} width={170}>
                <RequiredRule />
                <Lookup dataSource={contactInfoType} displayExpr="name" valueExpr={"name"} />
              </Column>
            );
          } else if (val?.actiondropdown) {
            console.log(val, 'val', actionDropdown);
            return (
              <Column dataField={val.title} minWidth={"240"} caption={val.title} width={170}>
                <RequiredRule />
                <Lookup dataSource={actionDropdown} displayExpr="name" valueExpr={"id"} />
              </Column>
            );
          } else if (val?.dropdown2) {
            return (
              <Column dataField={val.title} caption={val.title} width={170}>
                <RequiredRule />
                <Lookup dataSource={Contactdropdown} displayExpr="name" valueExpr={"id"} />
              </Column>
            );
          } else if (val?.dropdown3) {
            return (
              <Column dataField={val.title} caption={val.title} width={170}>
                <RequiredRule />
                <Lookup dataSource={ContactPositiondropdown} displayExpr="name" valueExpr={"id"} />
              </Column>
            );
          } else if (val?.dropdown4) {
            return (
              <Column dataField={val.title} caption={val.title} width={170}>
                <Lookup
                  dataSource={DocketSupervisionDropdowndata?.map((val) => {
                    return { name: val?.value, id: val?.key };
                  })}
                  displayExpr="name"
                  valueExpr={"name"}
                />
              </Column>
            );
          } else if (val?.requiredColoumWhileEdit) {
            return (
              <Column dataField={val.title} caption={val.title} width={170}>
                <RequiredRule />
              </Column>
            );
          } else if (val?.title == "Active" && recivePayment) {
            return (
              <Column
                dataField={val.title}
                caption={val.title}
                dataType={"boolean"}
                width={"auto"}
                filterValues={filterValues ? [...filterValues] : null}
                cellRender={(rowData) => {
                  return (
                    <>
                      {rowData?.data?.Active ? (
                        <img src={ActiveIcon} alt={rowData?.data?.Active} />
                      ) : (
                        <img src={UnActiveIcon} alt={rowData?.data?.Active} style={{ height: "18px" }} />
                      )}
                    </>
                  );
                }}
              >
                Active
              </Column>
            );
          } else {
            return (
              <Column
                headerFilter={{
                  ...(val.customwidth && { width: val.customwidth }),
                  allowSearch: true,
                }}
                allowEditing={val?.editable ? true : false}
                type={val.action || val.Action || val?.viewImage || val.moreoption ? "buttons" : ""}
                width={val.customwidth ? val.customwidth : "auto"}
                alignment={val?.bool ? "center" : "left"}
                dataField={val.title}
                caption={val.title}
                resizable={true}
                format={val?.currency && { type: "currency", precision: 2 }}
                dataType={
                  val?.date ? "date" : val?.number ? "number" : val?.bool ? "boolean" : val?.datetime ? "datetime" : ""
                }
                sortOrder={(val?.title === 'Last Name' && personListControl) ? "asc" : ""}
                cellRender={
                  val?.underLine
                    ? (rowData) => {
                      return (
                        <>
                          <div
                            style={{
                              width: "100%",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textDecoration: val?.underLine && "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (FindProntactEntities) {
                                dispatch(
                                  setDynamicTab({
                                    title: `Entity : ${rowData?.data?.Entity}`,
                                    link: `/prontact/entity/${rowData?.data?.gEntityId}`,
                                    Offender: `/prontact/entity/${rowData?.data?.gEntityId}`,
                                    entityid: rowData?.data?.gEntityId,
                                  })
                                );
                                navigate(`/prontact/entity/${rowData?.data?.gEntityId}`);
                                dispatch(removeProntactEntitiesdynamicTab(`/prontact/entity`));
                                setProntactEntitiesfind(false);
                                return;
                              }
                              if (formjump) {
                                setConfirmationPopup(true);
                                setConfirmationData(rowData?.data);
                                return;
                              }
                              if (docketlisthiperline) {
                                dispatch(
                                  setDynamicTab({
                                    Offender: `/docket/${rowData?.data?.sDocketNumberCourtCounts.replace(
                                      /\//g,
                                      "-"
                                    )}`,
                                    link: `/docket/${rowData?.data?.sDocketNumberCourtCounts.replace(
                                      /\//g,
                                      "-"
                                    )}`,
                                    title: `Docket ${rowData?.data?.sDocketNumberCourtCounts}`,
                                    sDocketNumberCourtCounts: rowData?.data?.sDocketNumberCourtCounts,
                                    userinfo: {
                                      ...rowData?.data?.userinfo,
                                      gPersonId: rowData?.data?.userinfo?.gPersonID,
                                    },
                                    getTopDynamicDocketid: rowData?.data?.gDocketID,
                                  })
                                );
                                dispatch(
                                  setdockerValue({
                                    getTopDynamicDocketid: rowData?.data?.gDocketID,
                                    userinfo: {
                                      ...rowData?.data?.userinfo,
                                      gPersonId: rowData?.data?.userinfo?.gPersonID,
                                    },
                                  })
                                );
                                navigate(`/docket/${rowData?.data?.sDocketNumberCourtCounts.replace(/\//g, "-")}`);
                                return;
                              }
                              if (fromOP3) {
                                setConfirmationData(rowData?.data);
                                resetPersonFind();
                                return;
                              }

                              if (fromPersonOverview) {
                                setConfirmationData(rowData?.data);
                                resetPersonFind();
                                return;
                              }
                              if (nojump) {
                                dispatch(
                                  setgoToDocketPerson({
                                    name: rowData?.data?.Name,
                                    id: rowData?.data?.gPersonId,
                                  })
                                );
                                setShowPersonModal && setShowPersonModal(false);
                                return;
                              }
                              if (doToDocketFind) {
                                dispatch(
                                  setDynamicTab({
                                    Offender: `/docket/${rowData?.data?.["Docket #"]?.replace(/\//g, "-")}`,
                                    link: `/docket/${rowData?.data?.["Docket #"]?.replace(/\//g, "-")}`,
                                    title: `Docket ${rowData?.data?.["Docket #"]}`,
                                    sDocketNumberCourtCounts: rowData?.data?.["Docket #"],
                                    userinfo: rowData?.data,
                                    getTopDynamicDocketid: rowData?.data?.gDocketID,
                                  })
                                );

                                dispatch(
                                  setdockerValue({
                                    getTopDynamicDocketid: rowData?.data?.gDocketID,
                                    userinfo: rowData?.data,
                                  })
                                );
                                setDocketFind(false);

                                navigate(`/docket/${rowData?.data?.["Docket #"]?.replace(/\//g, "-")}`);

                                return;
                              }
                              if (goToObligationFinfObligation) {
                                dispatch(
                                  setDynamicTab({
                                    Offender: `/obligation/${rowData?.data?.sDocketNumberCourtCounts?.replace(
                                      "/",
                                      "-"
                                    )}/${rowData?.data?.["Obligation Ref #"]}`,
                                    link: `/obligation/${rowData?.data?.sDocketNumberCourtCounts?.replace(
                                      "/",
                                      "-"
                                    )}/${rowData?.data?.["Obligation Ref #"]}`,
                                    title: `Obligation : ${rowData?.data?.sDocketNumberCourtCounts} / ${rowData?.data?.["Obligation Ref #"]}`,
                                    sDocketNumberCourtCounts: rowData?.data?.sDocketNumberCourtCounts,
                                    ObligationRef: rowData?.data?.["Obligation Ref #"],
                                    gObligationId: rowData?.data?.gObligationID,
                                    userinfo: rowData?.data?.userinfo,
                                  })
                                );
                                dispatch(
                                  setgotooblogationValue({
                                    gObligationId: rowData?.data?.gObligationID,
                                    userinfo: rowData?.data?.userinfo,
                                  })
                                );
                                navigate(
                                  `/obligation/${rowData?.data?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${rowData?.data?.["Obligation Ref #"]
                                  }`
                                );

                                setfindObligation(false);

                                return;
                              }
                              if (goToObligationFinfDocket) {
                                setGoTOObligationReference({
                                  Reference: rowData.data.Reference,
                                  gDocketID: rowData.data.gDocketID,
                                });
                                setfindDocker(false);
                                return;
                              }
                              if (rowData.data.personlistfullName) {
                                saveCookieCrum(rowData.data.gPersonID);
                                dispatch(
                                  setDynamicTab({
                                    link: `/participant/${rowData.data["Offender #"]}`,
                                    title: rowData.data.personlistfullName,
                                  })
                                );
                              } else {
                                saveCookieCrum(rowData.data.gPersonId);
                                dispatch(
                                  setDynamicTab({
                                    link: `/participant/${rowData.data["Offender #"]}`,
                                    title: rowData?.data?.sLastName,
                                  })
                                );
                              }
                              if (fromParticipantHeader) {
                                let currentPath = window.location.href;
                                const [baseUrl, hashPart] = currentPath.split("#");
                                console.log('path', hashPart);
                                dispatch(removeDynamicTab(hashPart));
                              }
                              navigate(`/participant/${rowData.data["Offender #"]}`);
                              setShowPersonModal && setShowPersonModal(false);
                            }}
                          >
                            {`${rowData.value}`}
                          </div>
                        </>
                      );
                    }
                    : val?.action
                      ? (rowData) => {
                        return (
                          <>
                            <Button
                              style={{ marginLeft: "6px", marginTop: "-3px" }}
                              icon="edit"
                              onClick={() => {
                                if (setpopUp) {
                                  setpopUp(1);
                                  setIsPopupVisible(true);
                                  dispatch(
                                    set_selected_row({
                                      selected_row: rowData.data,
                                    })
                                  );
                                }
                              }}
                            />
                            <Button
                              style={{ marginLeft: "6px", marginTop: "-3px" }}
                              icon="trash"
                              onClick={() => {
                                if (setpopUp) {
                                  setpopUp(2);
                                  setIsPopupVisible(true);
                                  dispatch(
                                    set_selected_row({
                                      selected_row: [rowData.data],
                                    })
                                  );
                                }
                              }}
                            />
                          </>
                        );
                      }
                      : val?.Action
                        ? (rowData) => {
                          return (
                            <>
                              {!rowData.data.bSystem &&
                                rowData.data.showedit === new Date().toISOString().split("T")[0] && (
                                  <Button
                                    style={{
                                      marginLeft: "6px",
                                      marginTop: "-3px",
                                    }}
                                    icon="edit"
                                    onClick={() => {
                                      if (setNoteEditTable) {
                                        setGetEditData(rowData.data);
                                        setNoteEditTable(true);
                                        setaddDataIngTable(false);
                                        resetvalueonedit();
                                      }
                                    }}
                                  />
                                )}
                              {getAttachmenteditData && seteditAttachmentPopupOpen && (
                                <Button
                                  style={{
                                    marginLeft: "6px",
                                    marginTop: "-3px",
                                  }}
                                  icon="edit"
                                  onClick={() => {
                                    if (getAttachmenteditData && seteditAttachmentPopupOpen) {
                                      seteditAttachmentPopupOpen(true);
                                      getAttachmenteditData(rowData.data);
                                    }
                                  }}
                                />
                              )}
                            </>
                          );
                        }
                        : val?.viewImage
                          ? (rowData) => {
                            return (
                              <>
                                <Button
                                  style={{
                                    marginLeft: "6px",
                                    marginTop: "-3px",
                                  }}
                                  icon="find"
                                  onClick={() => {
                                    viewDocument(rowData, setLoading, loading, navigate);
                                  }}
                                />
                              </>
                            );
                          }
                          : val?.warrningIcon
                            ? (rowData) => {
                              return (
                                <>
                                  {!rowData.data["!"] && (
                                    <Button
                                      style={{
                                        marginLeft: "6px",
                                        marginTop: "-3px",
                                      }}
                                      icon="warning"
                                    />
                                  )}
                                </>
                              );
                            }
                            : val?.showBool
                              ? (rowData) => {
                                let check = false;
                                checklistdata.map((val) => {
                                  if (val?.itemId?.toLowerCase() == rowData?.data?.gCheckListItemId?.toLowerCase()) {
                                    check = val?.complete;
                                  }
                                  return val;
                                });

                                return (
                                  <>
                                    {rowData?.data?.bAllowCheck && (
                                      <input
                                        type="checkbox"
                                        checked={check}
                                        onChange={() => {
                                          if (check) {
                                            let arr = checklistdata.map((val) => {
                                              if (
                                                val?.itemId?.toLowerCase() == rowData?.data?.gCheckListItemId?.toLowerCase()
                                              ) {
                                                return {
                                                  ...val,
                                                  complete: !val?.complete,
                                                };
                                              } else {
                                                return val;
                                              }
                                            });
                                            setchecklistdata(arr);
                                          } else {
                                            let arr = checklistdata.map((val) => {
                                              if (
                                                val?.itemId?.toLowerCase() == rowData?.data?.gCheckListItemId?.toLowerCase()
                                              ) {
                                                return {
                                                  ...val,
                                                  complete: !val?.complete,
                                                };
                                              } else {
                                                return val;
                                              }
                                            });
                                            setchecklistdata(arr);
                                          }
                                        }}
                                      />
                                    )}
                                  </>
                                );
                              }
                              : val?.Selectionbool
                                ? (rowData) => {
                                  let data;
                                  locationappend.map((val) => {
                                    if (val.id?.toUpperCase() == rowData?.data?.id?.toUpperCase()) {
                                      data = true;
                                    }
                                  });

                                  return (
                                    <>
                                      <input
                                        type="checkbox"
                                        checked={data}
                                        onChange={() => {
                                          let temp;
                                          locationappend.map((val) => {
                                            if (val.id == rowData?.data?.id) {
                                              temp = true;
                                            }
                                          });
                                          if (temp) {
                                            setlocationappend(
                                              locationappend.filter((val) => {
                                                if (val?.id !== rowData?.data?.id) {
                                                  return val;
                                                }
                                              })
                                            );
                                          } else {
                                            setlocationappend([
                                              ...locationappend,
                                              {
                                                Location: rowData?.data?.Location,
                                                id: rowData?.data?.id,
                                              },
                                            ]);
                                          }
                                        }}
                                      />
                                    </>
                                  );
                                }
                                : val?.Selectionbool2
                                  ? (rowData) => {
                                    let data;
                                    locationappend.map((val) => {
                                      if (val.id?.toUpperCase() == rowData?.data?.id?.toUpperCase()) {
                                        data = true;
                                      }
                                    });

                                    return (
                                      <>
                                        <input
                                          type="checkbox"
                                          checked={data}
                                          onChange={() => {
                                            let temp;
                                            locationappend.map((val) => {
                                              if (val.id.toLocaleLowerCase() == rowData?.data?.id.toLocaleLowerCase()) {
                                                temp = true;
                                              }
                                            });
                                            if (temp) {
                                              setlocationappend(
                                                locationappend.filter((val) => {
                                                  if (val?.id.toLocaleLowerCase() !== rowData?.data?.id.toLocaleLowerCase()) {
                                                    return val;
                                                  }
                                                })
                                              );
                                            } else {
                                              setlocationappend([
                                                ...locationappend,
                                                {
                                                  "Entity Type": rowData?.data?.["Entity Type"],
                                                  id: rowData?.data?.id,
                                                },
                                              ]);
                                            }
                                          }}
                                        />
                                      </>
                                    );
                                  }
                                  : val?.Selectionbool3
                                    ? (rowData) => {
                                      let data;
                                      DocketSupervisioncheck.map((val) => {
                                        if (val.ID?.toUpperCase() == rowData?.data?.ID?.toUpperCase()) {
                                          data = true;
                                        }
                                      });
                                      return (
                                        <>
                                          <input
                                            checked={data}
                                            onChange={() => {
                                              let temp;
                                              DocketSupervisioncheck.map((val) => {
                                                if (val.ID.toLocaleLowerCase() == rowData?.data?.ID.toLocaleLowerCase()) {
                                                  temp = true;
                                                }
                                              });
                                              if (temp) {
                                                setDocketSupervisioncheck(
                                                  DocketSupervisioncheck.filter((val) => {
                                                    if (val?.ID.toLocaleLowerCase() !== rowData?.data?.ID.toLocaleLowerCase()) {
                                                      return val;
                                                    }
                                                  })
                                                );

                                                let temp;
                                                DocketSupervisioncheckdata.map((val) => {
                                                  if (val.ID.toLocaleLowerCase() == rowData?.data?.ID.toLocaleLowerCase()) {
                                                    temp = true;
                                                  }
                                                });

                                                if (temp) {
                                                  setDocketSupervisioncheckclone([...DocketSupervisioncheckclone, rowData?.data]);
                                                }
                                              } else {
                                                setDocketSupervisioncheck([...DocketSupervisioncheck, rowData?.data]);
                                              }
                                            }}
                                            type="checkbox"
                                          />
                                        </>
                                      );
                                    }
                                    : val?.customSelectBox
                                      ? (rowData) => {
                                        let temp = true;
                                        return (
                                          <>
                                            <input
                                              checked={
                                                selectAll
                                                  ? selectAll
                                                  : PreDisbursementsReportcheckbox.includes(rowData?.data?.gPersonID)
                                                    ? true
                                                    : rowData?.data?.bSelect
                                              }
                                              type="checkbox"
                                              onChange={() => {
                                                if (!PreDisbursementsReportcheckbox.includes(rowData?.data?.gPersonID)) {
                                                  setPreDisbursementsReportcheckbox([
                                                    ...PreDisbursementsReportcheckbox,
                                                    rowData?.data?.gPersonID,
                                                  ]);
                                                  temp = false;
                                                } else {
                                                  temp = true;
                                                  setPreDisbursementsReportcheckbox(
                                                    PreDisbursementsReportcheckbox?.filter((val) => {
                                                      if (val !== rowData?.data?.gPersonID) {
                                                        return val;
                                                      }
                                                    })
                                                  );
                                                }
                                              }}
                                            />
                                          </>
                                        );
                                      }
                                      : val?.DocketManagementSelect
                                        ? (rowData) => {
                                          let temp = true;
                                          return (
                                            <>
                                              <input
                                                checked={
                                                  selectTaskAll
                                                    ? selectTaskAll
                                                    : selectedRowCheckbox.includes(rowData?.data?.ID)
                                                      ? true
                                                      : rowData?.data?.bSelect
                                                }
                                                type="checkbox"
                                                onChange={() => {
                                                  if (!selectedRowCheckbox.includes(rowData?.data?.ID)) {
                                                    setSelectedRowCheckbox([...selectedRowCheckbox, rowData?.data?.ID]);
                                                    temp = false;
                                                  } else {
                                                    temp = true;
                                                    setSelectedRowCheckbox(
                                                      selectedRowCheckbox?.filter((val) => {
                                                        if (val !== rowData?.data?.ID) {
                                                          return val;
                                                        }
                                                      })
                                                    );
                                                  }
                                                }}
                                              />
                                            </>
                                          );
                                        }
                                        : val?.personListControl && val?.bool
                                          ? (rowData) => {
                                            return (
                                              <>
                                                <input
                                                  type="checkbox"
                                                  checked={selectedPersonIds?.includes(rowData?.data?.["Person ID"])}
                                                  onChange={(e) => {
                                                    if (!selectedPersonIds?.includes(rowData?.data?.["Person ID"])) {
                                                      setSelectedPersonIds([...selectedPersonIds, rowData?.data?.["Person ID"]]);
                                                    } else {
                                                      setSelectedPersonIds(
                                                        selectedPersonIds?.filter((id) => {
                                                          if (id !== rowData?.data?.["Person ID"]) return id;
                                                        })
                                                      );
                                                    }
                                                  }}
                                                />
                                              </>
                                            );
                                          }
                                          : val?.docketListControl && val?.bool
                                            ? (rowData) => {
                                              return (
                                                <>
                                                  <input
                                                    type="checkbox"
                                                    checked={selectedPersonIds?.includes(rowData?.data?.gPersonId)}
                                                    onChange={(e) => {
                                                      if (!selectedPersonIds?.includes(rowData?.data?.gPersonId)) {
                                                        setSelectedPersonIds([...selectedPersonIds, rowData?.data?.gPersonId]);
                                                      } else {
                                                        setSelectedPersonIds(
                                                          selectedPersonIds?.filter((id) => {
                                                            if (id !== rowData?.data?.gPersonId) return id;
                                                          })
                                                        );
                                                      }
                                                    }}
                                                  />
                                                </>
                                              );
                                            }
                                            : val?.checkIconBool
                                              ? (rowData) => {
                                                return (
                                                  <>
                                                    {rowData?.data?.Active ? (
                                                      <img src={ActiveIcon} alt={rowData?.data?.Active} />
                                                    ) : (
                                                      <img src={UnActiveIcon} alt={rowData?.data?.Active} style={{ height: "18px" }} />
                                                    )}
                                                  </>
                                                );
                                              }
                                              : val?.moreoption && taskcontextmenu2
                                                ? (rowData) => {
                                                  return (
                                                    <>
                                                      <div
                                                        className="add_navBar_hamburger"
                                                        id="threedots"
                                                        onClick={() => {
                                                          setgettaskEditDAta(rowData?.data);
                                                        }}
                                                      >
                                                        <ThreeDotMenu />
                                                        <ContextMenu
                                                          showEvent="dxclick"
                                                          dataSource={[
                                                            {
                                                              text: "Edit Task",
                                                              icon: "edit",
                                                            },
                                                            {
                                                              text: "Add Task",
                                                              icon: "add",
                                                            },
                                                            {
                                                              text: "Jump to Person",
                                                              icon: "search",
                                                            },
                                                            {
                                                              text: "Pending/Delay 30 Days",
                                                              icon: "clock",
                                                            },
                                                            {
                                                              text: "Reassign Selected Tasks",
                                                              icon: "add",
                                                            },
                                                            {
                                                              text: "Task Completed",
                                                              icon: "check",
                                                            },
                                                            {
                                                              text: "Approve/Process Close-out",
                                                              disabled: "true",
                                                            },
                                                            {
                                                              text: "Approve/Process Allocation Adjustment",
                                                              disabled: "true",
                                                            },
                                                            {
                                                              text: "Approve/Process Refund",
                                                              // disabled: !(gettaskEditDAta?.categoryId === "A62E2033-D400-4FDB-A4AC-7C78C8813FD4".toLowerCase())
                                                              disabled: !(gUserId.toLowerCase() === gettaskEditDAta?.assignedToId.toLowerCase())
                                                            },
                                                          ]}
                                                          target="#threedots"
                                                          onItemClick={(e) => {
                                                            if (e.itemData.text === "Edit Task") {
                                                              setAddQuickPopup(true);
                                                              setresettask(!resettask);
                                                            } else if (e.itemData.text === "Add Task") {
                                                              setAddQuickPopup(true);
                                                              setAddTaskPopupType("Add");
                                                            } else if (e.itemData.text === "Pending/Delay 30 Days") {
                                                              setPendingPOpuptogal(true);
                                                              setresettask(!resettask);
                                                            } else if (e.itemData.text === "Task Completed") {
                                                              getCompletTask(false);
                                                            } else if (e.itemData.text === "Approve/Process Close-out") {
                                                              const taskId = gettaskEditDAta?.taskId;
                                                              const userId = localStorage.getItem("gUserId");

                                                              if (taskId && userId) {
                                                                approveTaskProcessCloseout(taskId, userId); // Ensure taskId and userId are passed correctly
                                                              } else {
                                                                // console.error("Task ID or User ID is missing.");
                                                              }
                                                            } else if (e.itemData.text === "Approve/Process Allocation Adjustment") {
                                                              const taskId = gettaskEditDAta?.taskId;
                                                              const userId = localStorage.getItem("gUserId");

                                                              if (taskId && userId) {
                                                                AllocationAdjustment(taskId, userId); // Ensure taskId and userId are passed correctly
                                                              } else {
                                                                console.error("Task ID or User ID is missing.");
                                                              }
                                                            }
                                                            else if (e.itemData.text === "Approve/Process Refund") {
                                                              approveRefund();
                                                            }
                                                            else if (e.itemData.text === "Reassign Selected Tasks") {
                                                              setReassignTasksPopup(true);
                                                            } else if (e.itemData.text === "Jump to Person") {
                                                              if (!gettaskEditDAta?.offenderNumber) {
                                                                return;
                                                              }
                                                              dispatch(
                                                                setDynamicTab({
                                                                  link: `/participant/${gettaskEditDAta?.offenderNumber}`,
                                                                  Offender: gettaskEditDAta?.offenderNumber,
                                                                  title: gettaskEditDAta?.name?.split(",")[0],
                                                                })
                                                              );
                                                              navigate(`/participant/${gettaskEditDAta?.offenderNumber}`);
                                                            }
                                                          }}
                                                        />
                                                      </div>
                                                    </>
                                                  );
                                                }
                                                : val?.moreoption && arrearageMinderMenu
                                                  ? (rowData) => {
                                                    return (
                                                      <>
                                                        <div
                                                          className="add_navBar_hamburger"
                                                          id="threedotsarrearage"
                                                          onClick={() => {
                                                            setArrearageRowData(rowData.data);
                                                          }}
                                                        >
                                                          <ThreeDotMenu />
                                                          <ContextMenu
                                                            showEvent="dxclick"
                                                            dataSource={[
                                                              {
                                                                text: "Jump to Person",
                                                                icon: "search",
                                                              },
                                                              {
                                                                text: "Send Arrearage Notification",
                                                                icon: "email",
                                                              },
                                                            ]}
                                                            target="#threedotsarrearage"
                                                            onItemClick={(e) => {
                                                              if (e.itemData.text === "Jump to Person") {
                                                                if (!arrearageRowData?.["Client Id"]) {
                                                                  return;
                                                                }
                                                                dispatch(
                                                                  setDynamicTab({
                                                                    link: `/participant/${arrearageRowData?.["Client Id"]}`,
                                                                    Offender: arrearageRowData?.["Client Id"],
                                                                    title: arrearageRowData?.["Client Name"]?.split(",")[0],
                                                                  })
                                                                );
                                                                navigate(`/participant/${arrearageRowData?.["Client Id"]}`);
                                                              } else if (e.itemData.text === "Send Arrearage Notification") {
                                                                setSendEmailPopup(true);
                                                              }
                                                            }}
                                                          />
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                  : val?.moreoption && revenueReportMenu
                                                    ? (rowData) => {
                                                      return (
                                                        <>
                                                          <div
                                                            className="add_navBar_hamburger"
                                                            id="threedotsarrearage"
                                                            onClick={() => {
                                                              setRevenueRowData(rowData.data);
                                                            }}
                                                          >
                                                            <ThreeDotMenu />
                                                            <ContextMenu
                                                              showEvent="dxclick"
                                                              dataSource={[
                                                                {
                                                                  text: "Jump to Person",
                                                                  icon: "search",
                                                                },
                                                              ]}
                                                              target="#threedotsarrearage"
                                                              onItemClick={(e) => {
                                                                if (e.itemData.text === "Jump to Person") {
                                                                  if (!revenueRowData?.["Client Id"]) {
                                                                    return;
                                                                  }
                                                                  dispatch(
                                                                    setDynamicTab({
                                                                      link: `/participant/${revenueRowData?.["Client Id"]}`,
                                                                      Offender: revenueRowData?.["Client Id"],
                                                                      title: revenueRowData?.["Client Name"]?.split(",")[0],
                                                                    })
                                                                  );
                                                                  navigate(`/participant/${revenueRowData?.["Client Id"]}`);
                                                                }
                                                              }}
                                                            />
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                    : val?.moreoption && agingReportMenu
                                                      ? (rowData) => {
                                                        return (
                                                          <>
                                                            <div
                                                              className="add_navBar_hamburger"
                                                              id="threedotsarrearage"
                                                              onClick={() => {
                                                                setAgingRowData(rowData.data);
                                                              }}
                                                            >
                                                              <ThreeDotMenu />
                                                              <ContextMenu
                                                                showEvent="dxclick"
                                                                dataSource={[
                                                                  {
                                                                    text: "Jump to Person",
                                                                    icon: "search",
                                                                  },
                                                                ]}
                                                                target="#threedotsarrearage"
                                                                onItemClick={(e) => {
                                                                  if (e.itemData.text === "Jump to Person") {
                                                                    if (!agingRowData?.["Client Id"]) {
                                                                      return;
                                                                    }
                                                                    dispatch(
                                                                      setDynamicTab({
                                                                        link: `/participant/${agingRowData?.["Client Id"]}`,
                                                                        Offender: agingRowData?.["Client Id"],
                                                                        title: agingRowData?.["Client Name"]?.split(",")[0],
                                                                      })
                                                                    );
                                                                    navigate(`/participant/${agingRowData?.["Client Id"]}`);
                                                                  }
                                                                }}
                                                              />
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                      : val?.moreoption && DocketManagementRightClick
                                                        ? (rowData) => {
                                                          return (
                                                            <>
                                                              <div className="add_navBar_hamburger" id="threedotsarrearage" onClick={() => { }}>
                                                                <ThreeDotMenu />
                                                                <ContextMenu
                                                                  showEvent="dxclick"
                                                                  dataSource={[
                                                                    {
                                                                      text: "Jump To Docket",
                                                                      icon: "search",
                                                                    },
                                                                    {
                                                                      text: "Quick Add Obligation",
                                                                      disabled: false,
                                                                    },
                                                                    {
                                                                      text: "Change Supervision Status",
                                                                      disabled: !selectedRowCheckbox.length,
                                                                    },
                                                                    {
                                                                      text: "Change Finacial Status",
                                                                      disabled: !selectedRowCheckbox.length,
                                                                    },
                                                                    {
                                                                      text: "Change Supervisor",
                                                                      disabled: !selectedRowCheckbox.length,
                                                                    },
                                                                    {
                                                                      text: "Add Revocation(s)",
                                                                      disabled: true,
                                                                    },
                                                                    {
                                                                      text: "Add Revocation(s)",
                                                                      disabled: true,
                                                                    },
                                                                    {
                                                                      text: "Add Tolling",
                                                                      disabled: !selectedRowCheckbox.length,
                                                                    },
                                                                    {
                                                                      text: "Move Obligation to Another Docket ",
                                                                      disabled: true,
                                                                    },
                                                                    {
                                                                      text: "Delete Docket",
                                                                      disabled: true,
                                                                    },
                                                                  ]}
                                                                  target=".dx-data-row"
                                                                  onItemClick={(e) => {
                                                                    if (e.itemData.text === "Jump To Docket") {
                                                                      if (!rowData?.data?.userinfo?.gDocketId && !rowData?.data?.userinfo?.gPersonId) {
                                                                        return;
                                                                      }
                                                                      dispatch(
                                                                        setDynamicTab({
                                                                          Offender: `/docket/${`${rowData?.data?.sDocketNumberCourtCounts}`?.replace(
                                                                            "/",
                                                                            "-"
                                                                          )}`,
                                                                          link: `/docket/${`${rowData?.data?.sDocketNumberCourtCounts}`?.replace(
                                                                            "/",
                                                                            "-"
                                                                          )}`,
                                                                          title: `Docket ${`${rowData?.data?.sDocketNumberCourtCounts}`}`,
                                                                          sDocketNumberCourtCounts: `${rowData?.data?.sDocketNumberCourtCounts}`,
                                                                          userinfo: {
                                                                            ...rowData?.data?.userinfo,
                                                                            gPersonId: rowData?.data?.userinfo?.gPersonId,
                                                                          },
                                                                          getTopDynamicDocketid: rowData?.data?.userinfo?.gDocketId,
                                                                        })
                                                                      );
                                                                      dispatch(
                                                                        setdockerValue({
                                                                          getTopDynamicDocketid: rowData?.data?.userinfo?.gDocketId,
                                                                          userinfo: {
                                                                            ...rowData?.data?.userinfo,
                                                                            gPersonId: rowData?.data?.userinfo?.gPersonId,
                                                                          },
                                                                        })
                                                                      );
                                                                      navigate(
                                                                        `/docket/${`${rowData?.data?.sDocketNumberCourtCounts}`?.replace(/\//g, "-")}`
                                                                      );
                                                                    }

                                                                    if (e.itemData.text === "Quick Add Obligation") {
                                                                      setHeader({
                                                                        ...header,
                                                                        sDocketNumberCourtCounts: rowData?.data?.["Docket/Count"],
                                                                      });
                                                                      setDocketId(rowData?.data?.userinfo?.gDocketId);
                                                                      dispatch(setRecentDocket(rowData?.data));
                                                                      setgetQuickaddObligationPopup(true);
                                                                    }

                                                                    if (e.itemData.text === "Change Supervision Status") {
                                                                      setChangesuperviserDatePOpup(true);
                                                                    }

                                                                    if (e.itemData.text === "Change Finacial Status") {
                                                                      setChangeFinancialStatusPopup(true);
                                                                    }

                                                                    if (e.itemData.text === "Change Supervisor") {
                                                                      setChangeSupervisorPopup(true);
                                                                    }

                                                                    if (e.itemData.text === "Add Tolling") {
                                                                      setAddTollingPopup(true);
                                                                    }
                                                                  }}
                                                                />
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                        : val?.addObligation && DocketManagementRightClick
                                                          ? (rowData) => (
                                                            <Button
                                                              icon="add"
                                                              stylingMode="text"
                                                              onClick={(e) => {
                                                                setHeader({
                                                                  ...header,
                                                                  sDocketNumberCourtCounts: rowData?.data?.["Docket/Count"],
                                                                });
                                                                setDocketId(rowData?.data?.userinfo?.gDocketId);
                                                                dispatch(setRecentDocket(rowData?.data));
                                                                setgetQuickaddObligationPopup(true);
                                                              }}
                                                            />
                                                          )
                                                          : val?.moreoption && Contextmenu
                                                            ? (rowData) => {
                                                              return (
                                                                <>
                                                                  <div
                                                                    className="add_navBar_hamburger"
                                                                    id="threedotsarrearage"
                                                                    onClick={() => {
                                                                      // setAgingRowData(rowData.data);
                                                                      if (setreportDataId && editParticipant) {
                                                                        setAttendance(rowData?.data?.Attendance);
                                                                        setreportDataId(rowData?.key);
                                                                        setPostReportNote(rowData?.data?.[`Pre-Report Note`]);
                                                                        setdesableReportData(rowData?.data?.Void);
                                                                      }
                                                                    }}
                                                                  >
                                                                    <ThreeDotMenu />
                                                                    <ContextMenu
                                                                      showEvent="dxclick"
                                                                      dataSource={
                                                                        Attendance
                                                                          ? [
                                                                            { text: "Remove Attendance Status", icon: "trash" },
                                                                            {
                                                                              text: "Void Appointment",
                                                                              icon: "remove",
                                                                              disabled: desableReportData,
                                                                            },
                                                                            {
                                                                              text: "Print Next Report Date",
                                                                            },
                                                                          ]
                                                                          : [
                                                                            {
                                                                              text: "Enter Attendance",
                                                                              icon: "event",
                                                                            },
                                                                            { text: "Remove Attendance Status", icon: "trash" },
                                                                            {
                                                                              text: "Void Appointment",
                                                                              icon: "remove",
                                                                              disabled: desableReportData,
                                                                            },
                                                                            {
                                                                              text: "Print Next Report Date",
                                                                            },
                                                                          ]
                                                                      }
                                                                      target="#threedotsarrearage"
                                                                      onItemClick={async (e) => {
                                                                        if (e.itemData.text === "Enter Attendance") {
                                                                          setenterAttendencePopup(true);
                                                                        } else if (e.itemData.text === "Void Appointment") {
                                                                          setvoidPopup(true);
                                                                        } else if (e.itemData.text === "Remove Attendance Status") {
                                                                          setremoveAttendenceStatus(!removeAttendenceStatus);
                                                                        } else if (e.itemData.text === "Print Next Report Date") {
                                                                          setLoading(true);
                                                                          await axios
                                                                            .get(
                                                                              `${process.env.REACT_APP_CRYSTAL_API_URL}/reports-api/reports/get-print-nrd?sOffenderNumber=${selectedRow.sOffenderNumber}&sActivityId=${selectedRow.gPreReportActivityNoteId}`,
                                                                              {
                                                                                headers: {
                                                                                  Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                                                                                  Domain: `${pathname}`,
                                                                                },
                                                                                responseType: "blob",
                                                                              }
                                                                            )
                                                                            .then((val) => {
                                                                              setLoading(false);
                                                                              if (val?.data?.size > 20) {
                                                                                const pdfBlob = new Blob([val.data], {
                                                                                  type: "application/pdf",
                                                                                });
                                                                                const pdfUrl = URL.createObjectURL(pdfBlob);
                                                                                const downloadLink = document.createElement("a");
                                                                                downloadLink.href = pdfUrl;
                                                                                downloadLink.download = "Report_Date" + ".pdf";
                                                                                downloadLink.style.display = "none";
                                                                                document.body.appendChild(downloadLink);
                                                                                downloadLink.click();
                                                                                document.body.removeChild(downloadLink);
                                                                              }
                                                                            })
                                                                            .catch((val) => {
                                                                              setLoading(false);
                                                                            });
                                                                        }
                                                                      }}
                                                                    />
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                            : val?.moreoption && DocketObligationTableRightClick
                                                              ? (rowData) => {
                                                                return (
                                                                  <>
                                                                    <div
                                                                      className="add_navBar_hamburger"
                                                                      id="threedots"
                                                                      onClick={() => {
                                                                        setselectedRow(rowData?.data);
                                                                        if (setObligationData) {
                                                                          if (rowData?.data?.["Balance Owed"] <= 0) {
                                                                            setdesableGeneratePaymetPLan(true);
                                                                          } else {
                                                                            setdesableGeneratePaymetPLan(false);
                                                                          }

                                                                          if (rowData?.data?.["Balance Owed"] <= 0) {
                                                                            setdesablewriteoff(true);
                                                                          } else {
                                                                            setdesablewriteoff(false);
                                                                          }
                                                                          setObligationData(rowData?.data);
                                                                        }
                                                                      }}
                                                                    >
                                                                      <ThreeDotMenu />
                                                                      <ContextMenu
                                                                        showEvent="dxclick"
                                                                        dataSource={[
                                                                          {
                                                                            text: "Generate Payment Plan",
                                                                            disabled: desableGeneratePaymetPLan,
                                                                          },
                                                                          { text: "Write Off Amount", disabled: desablewriteoff },
                                                                          { text: "Go To Obligation" },
                                                                        ]}
                                                                        target="#threedots"
                                                                        onItemClick={(e) => {
                                                                          if (e.itemData.text === "Generate Payment Plan") {
                                                                            if (setopenGeneratePaymentPlanpopup) {
                                                                              setopenGeneratePaymentPlanpopup(true);
                                                                            }
                                                                          } else if (e.itemData.text === "Write Off Amount") {
                                                                            if (setWrieOffpopup) {
                                                                              setWrieOffpopup(true);
                                                                            }
                                                                          } else if (e.itemData.text === "Go To Obligation") {
                                                                            setgeneratePaymenttogal((prev) => !prev);
                                                                            if (!selectedRow) {
                                                                              return;
                                                                            }
                                                                            dispatch(setAddObligation(false));
                                                                            dispatch(
                                                                              setDynamicTab({
                                                                                Offender: `/obligation/${selectedRow?.sDocketNumberCourtCounts?.replace(
                                                                                  "/",
                                                                                  "-"
                                                                                )}/${selectedRow?.["Obligation Ref#"]}`,
                                                                                link: `/obligation/${selectedRow?.sDocketNumberCourtCounts?.replace(
                                                                                  "/",
                                                                                  "-"
                                                                                )}/${selectedRow?.["Obligation Ref#"]}`,
                                                                                title: `Obligation : ${selectedRow?.sDocketNumberCourtCounts} / ${selectedRow?.["Obligation Ref#"]}`,
                                                                                sDocketNumberCourtCounts: selectedRow?.sDocketNumberCourtCounts,
                                                                                ObligationRef: selectedRow?.["Obligation Ref#"],
                                                                                gObligationId: selectedRow?.gObligationId,
                                                                                userinfo: selectedRow?.userinfo,
                                                                              })
                                                                            );
                                                                            dispatch(
                                                                              setgotooblogationValue({
                                                                                gObligationId: selectedRow?.gObligationId,
                                                                                userinfo: selectedRow?.userinfo,
                                                                              })
                                                                            );
                                                                            navigate(
                                                                              `/obligation/${selectedRow?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${selectedRow?.["Obligation Ref#"]
                                                                              }`
                                                                            );
                                                                          }
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </>
                                                                );
                                                              }
                                                              : val?.moreoption && attachmentRightCickPopup
                                                                ? (rowData) => {
                                                                  return (
                                                                    <>
                                                                      <div className="add_navBar_hamburger" id="threedotsarrearage">
                                                                        <ThreeDotMenu />
                                                                        <ContextMenu
                                                                          showEvent="dxclick"
                                                                          dataSource={[
                                                                            { text: "Email Attachment", icon: "email" },
                                                                            { text: "Delete", icon: "remove" },
                                                                          ]}
                                                                          target="#threedotsarrearage"
                                                                          onItemClick={(e) => {
                                                                            if (e.itemData.text === "Delete") {
                                                                              if (attachmentRightCickPopup) {
                                                                                setattachmentdeletePopup(true);
                                                                              }
                                                                            }
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    </>
                                                                  );
                                                                }
                                                                : val?.moreoption && taskcontextmenu
                                                                  ? (rowData) => {
                                                                    return (
                                                                      <>
                                                                        <div
                                                                          className="add_navBar_hamburger"
                                                                          id="threedotsarrearage"
                                                                          onClick={() => {
                                                                            // setArrearageRowData(rowData.data);
                                                                          }}
                                                                        >
                                                                          <ThreeDotMenu />
                                                                          <ContextMenu
                                                                            showEvent="dxclick"
                                                                            dataSource={[
                                                                              {
                                                                                text: "Edit Task",
                                                                                icon: "edit",
                                                                              },
                                                                              {
                                                                                text: "Pending/Delay 30 Days",
                                                                                icon: "clock",
                                                                              },
                                                                              {
                                                                                text: "Complete Task",
                                                                                icon: "check",
                                                                              },
                                                                            ]}
                                                                            target="#threedotsarrearage"
                                                                            onItemClick={(e) => {
                                                                              if (e.itemData.text === "Edit Task") {
                                                                                if (setAddQuickPopup) {
                                                                                  setAddQuickPopup(true);
                                                                                  setresettask(!resettask);
                                                                                }
                                                                              } else if (e.itemData.text === "Pending/Delay 30 Days") {
                                                                                setPendingPOpuptogal(true);
                                                                                setresettask(!resettask);
                                                                              } else if (e.itemData.text === "Complete Task") {
                                                                                getCompletTask(false);
                                                                              }
                                                                            }}
                                                                          />
                                                                        </div>
                                                                      </>
                                                                    );
                                                                  }
                                                                  : val?.moreoption && CheckListTableRightClick
                                                                    ? (rowData) => {
                                                                      return (
                                                                        <>
                                                                          <div
                                                                            className="add_navBar_hamburger"
                                                                            id="threedotsarrearage"
                                                                            onClick={() => {
                                                                              // setArrearageRowData(rowData.data);
                                                                            }}
                                                                          >
                                                                            <ThreeDotMenu />
                                                                            <ContextMenu
                                                                              showEvent="dxclick"
                                                                              dataSource={[
                                                                                {
                                                                                  text: "Edit Note",
                                                                                  icon: "edit",
                                                                                },
                                                                              ]}
                                                                              target="#threedotsarrearage"
                                                                              onItemClick={(e) => {
                                                                                if (e.itemData.text === "Edit Note") {
                                                                                  if (CheckListTableRightClick) {
                                                                                    setresetCHecklist(!CHecklistreset);
                                                                                    seteditCHeckoutNotePOpup(true);
                                                                                  }
                                                                                }
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        </>
                                                                      );
                                                                    }
                                                                    : val?.moreoption && Revocationscontextmenu
                                                                      ? (rowData) => {
                                                                        return (
                                                                          <>
                                                                            <div className="add_navBar_hamburger" id="threedotsarrearage">
                                                                              <ThreeDotMenu />
                                                                              <ContextMenu
                                                                                showEvent="dxclick"
                                                                                dataSource={[
                                                                                  {
                                                                                    text: "Edit Revocation",
                                                                                    icon: "edit",
                                                                                  },
                                                                                  {
                                                                                    text: "Void Revocation",
                                                                                    icon: "remove",
                                                                                    disabled: desableRevocation,
                                                                                  },
                                                                                ]}
                                                                                target="#threedotsarrearage"
                                                                                onItemClick={(e) => {
                                                                                  if (e.itemData.text === "Edit Revocation") {
                                                                                    if (seteditRevocationspopup) {
                                                                                      seteditRevocationspopup(true);
                                                                                    }
                                                                                  } else if (e.itemData.text === "Void Revocation") {
                                                                                    setVoidPopup(true);
                                                                                  }
                                                                                }}
                                                                              />
                                                                            </div>
                                                                          </>
                                                                        );
                                                                      }
                                                                      : val?.moreoption && PreDisbursementsReporteightclick
                                                                        ? (rowData) => {
                                                                          return (
                                                                            <>
                                                                              <div
                                                                                className="add_navBar_hamburger"
                                                                                id="threedotsarrearage"
                                                                                onClick={() => {
                                                                                  setdisbursementrowdata(rowData.data);
                                                                                }}
                                                                              >
                                                                                <ThreeDotMenu />
                                                                                <ContextMenu
                                                                                  showEvent="dxclick"
                                                                                  dataSource={[
                                                                                    {
                                                                                      text: "Jump To Person",
                                                                                      icon: "search",
                                                                                    },
                                                                                    {
                                                                                      text: "Jump To Obligation",
                                                                                      icon: "search",
                                                                                    },
                                                                                    {
                                                                                      text: "Jump To Docket",
                                                                                      icon: "search",
                                                                                    },
                                                                                    {
                                                                                      text: "Select All",
                                                                                    },
                                                                                    {
                                                                                      text: "Select None",
                                                                                    },
                                                                                    {
                                                                                      text: "Print Checks",
                                                                                    },
                                                                                  ]}
                                                                                  target="#threedotsarrearage"
                                                                                  onItemClick={(e) => {
                                                                                    if (e.itemData.text === "Jump To Person") {
                                                                                      if (!disbursementrowdata || !disbursementrowdata?.["Offender #"]) {
                                                                                        return;
                                                                                      }
                                                                                      dispatch(
                                                                                        setDynamicTab({
                                                                                          link: `/participant/${disbursementrowdata?.["Offender #"]}`,
                                                                                          Offender: disbursementrowdata?.["Offender #"],
                                                                                          title: disbursementrowdata?.["Person Name"]?.split(",")[0],
                                                                                        })
                                                                                      );
                                                                                      navigate(`/participant/${disbursementrowdata?.["Offender #"]}`);
                                                                                    } else if (e.itemData.text === "Jump To Docket") {
                                                                                      if (!disbursementrowdata || disbursementrowdata?.userinfo?.gPersonID == null) {
                                                                                        return;
                                                                                      }
                                                                                      dispatch(
                                                                                        setDynamicTab({
                                                                                          Offender: `/docket/${`${"tempdata2"} - ${"tempdata2"}`?.replace(/\//g, "-")}`,
                                                                                          link: `/docket/${`${"tempdata2"} - ${"tempdata2"}`?.replace(/\//g, "-")}`,
                                                                                          title: `Docket ${`${"tempdata2"} - ${"tempdata2"}`}`,
                                                                                          sDocketNumberCourtCounts: `${"tempdata2"} - ${"tempdata2"}`,
                                                                                          userinfo: {
                                                                                            ...disbursementrowdata?.userinfo,
                                                                                            gPersonId: disbursementrowdata?.userinfo?.gPersonID,
                                                                                          },
                                                                                          getTopDynamicDocketid: disbursementrowdata?.userinfo?.gDocketID,
                                                                                        })
                                                                                      );
                                                                                      dispatch(
                                                                                        setdockerValue({
                                                                                          getTopDynamicDocketid: tableRow?.userinfo?.gDocketID,
                                                                                          userinfo: {
                                                                                            ...disbursementrowdata?.userinfo,
                                                                                            gPersonId: disbursementrowdata?.userinfo?.gPersonID,
                                                                                          },
                                                                                        })
                                                                                      );
                                                                                      navigate(`/docket/${`${"tempdata2"} - ${"tempdata2"}`?.replace(/\//g, "-")}`);
                                                                                    } else if (e.itemData.text === "Jump To Obligation") {
                                                                                      dispatch(
                                                                                        setDynamicTab({
                                                                                          Offender: `/obligation/${`${disbursementrowdata?.["sReference"]} - ${disbursementrowdata?.["sReference"]}`?.replace(
                                                                                            "/",
                                                                                            "-"
                                                                                          )}/${"tempdata"}`,
                                                                                          link: `/obligation/${`${disbursementrowdata?.["sReference"]} - ${disbursementrowdata?.["sReference"]}`?.replace(
                                                                                            "/",
                                                                                            "-"
                                                                                          )}/${"tempdata"}`,
                                                                                          title: `Obligation : ${`${disbursementrowdata?.["sReference"]} - ${disbursementrowdata?.["sReference"]}`} / ${"tempdata"}`,
                                                                                          sDocketNumberCourtCounts: `${disbursementrowdata?.["sReference"]} - ${disbursementrowdata?.["sReference"]}`,
                                                                                          ObligationRef: "tempdata",
                                                                                          gObligationId: disbursementrowdata?.gObligationID,
                                                                                          userinfo: {
                                                                                            ...disbursementrowdata?.userinfo,
                                                                                            gPersonId: disbursementrowdata?.userinfo?.gPersonID,
                                                                                          },
                                                                                        })
                                                                                      );
                                                                                      dispatch(
                                                                                        setgotooblogationValue({
                                                                                          gObligationId: disbursementrowdata?.gObligationID,
                                                                                          userinfo: {
                                                                                            ...disbursementrowdata?.userinfo,
                                                                                            gPersonId: disbursementrowdata?.userinfo?.gPersonID,
                                                                                          },
                                                                                        })
                                                                                      );
                                                                                      navigate(
                                                                                        `/obligation/${`${disbursementrowdata?.["sReference"]} - ${disbursementrowdata?.["sReference"]}`?.replace(
                                                                                          "/",
                                                                                          "-"
                                                                                        )}/${"tempdata"}`
                                                                                      );
                                                                                    } else if (e.itemData.text === "Select All") {
                                                                                      setselectAll(true);
                                                                                    } else if (e.itemData.text === "Select None") {
                                                                                      setPreDisbursementsReportcheckbox([]);
                                                                                      setselectAll(false);
                                                                                    } else if (e.itemData.text === "Print Checks") {
                                                                                      setPrintCheckPopup(true);
                                                                                    }
                                                                                  }}
                                                                                />
                                                                              </div>
                                                                            </>
                                                                          );
                                                                        }
                                                                        : val?.moreoption && val?.personListControl
                                                                          ? (rowData) => {
                                                                            return (
                                                                              <>
                                                                                <div className="add_navBar_hamburger" id="threedotsarrearage" onClick={() => {
                                                                                  selected = rowData.data;
                                                                                  source = rowData.data["Offender #"];
                                                                                  last = `${rowData.data["Last Name"]}, ${rowData.data["First Name"]}`;
                                                                                }}>

                                                                                  <ThreeDotMenu />

                                                                                  <ContextMenu
                                                                                    showEvent="dxclick"
                                                                                    dataSource={[
                                                                                      {
                                                                                        text: "Jump To Person",
                                                                                        icon: "search",
                                                                                      },
                                                                                      {
                                                                                        text: "Send Email",
                                                                                        icon: "email",
                                                                                      },
                                                                                      {
                                                                                        text: "Merge person",
                                                                                      },
                                                                                    ]}
                                                                                    target="#threedotsarrearage"
                                                                                    onItemClick={(e) => {
                                                                                      if (e.itemData.text === "Jump To Person") {
                                                                                        if (selected?.gMergePersonID) {
                                                                                          setMergedPerson(selected?.gMergePersonID);
                                                                                          setConfirmPopup(true);
                                                                                        }
                                                                                        else {
                                                                                          if (selected?.personlistfullName) {
                                                                                            saveCookieCrum(selected?.gPersonID);
                                                                                            dispatch(
                                                                                              setDynamicTab({
                                                                                                link: `/participant/${selected["Offender #"]}`,
                                                                                                Offender: selected["Offender #"],
                                                                                                title: selected?.personlistfullName,
                                                                                              })
                                                                                            );
                                                                                          } else {
                                                                                            saveCookieCrum(selected?.gPersonId);
                                                                                            dispatch(
                                                                                              setDynamicTab({
                                                                                                link: `/participant/${selected["Offender #"]}`,
                                                                                                Offender: selected["Offender #"],
                                                                                                title: selected?.sLastName,
                                                                                              })
                                                                                            );
                                                                                          }

                                                                                          navigate(`/participant/${selected["Offender #"]}`);
                                                                                        }
                                                                                      }

                                                                                      if (e.itemData.text === "Send Email") {
                                                                                        showSendEmailPopup();
                                                                                      }
                                                                                      if (e.itemData.text === "Merge person") {
                                                                                        setPerson(selected?.gPersonID);
                                                                                        setLast(last);
                                                                                        setSourcePersonId(source);
                                                                                        setMergedPerson(selected?.gMergePersonID);
                                                                                        if (selected?.gMergePersonID) {
                                                                                          setConfirmPopup(true);
                                                                                        }
                                                                                        else {
                                                                                          setIsMergePopupVisible(true);
                                                                                        }
                                                                                      }
                                                                                    }}
                                                                                  />
                                                                                </div>
                                                                              </>
                                                                            );
                                                                          }
                                                                          : val?.moreoption && periodSummaryControl
                                                                            ? (rowData) => {
                                                                              return (
                                                                                <>
                                                                                  <div
                                                                                    className="add_navBar_hamburger"
                                                                                    id="threedotsPeriodSummary"
                                                                                    onClick={() => {
                                                                                      setSelectedPersonIds(rowData.data);
                                                                                    }}
                                                                                  >
                                                                                    <ThreeDotMenu />
                                                                                    <ContextMenu
                                                                                      showEvent="dxclick"
                                                                                      dataSource={[
                                                                                        {
                                                                                          text: "Jump To Person",
                                                                                          icon: "search",
                                                                                        },
                                                                                      ]}
                                                                                      target="#threedotsPeriodSummary"
                                                                                      onItemClick={(e) => {
                                                                                        if (e.itemData?.text === "Jump To Person") {
                                                                                          if (selectedPersonIds["Offender Name"]) {
                                                                                            saveCookieCrum(selectedPersonIds["Offender #"]);
                                                                                            dispatch(
                                                                                              setDynamicTab({
                                                                                                link: `/participant/${selectedPersonIds["Offender #"]}`,
                                                                                                Offender: selectedPersonIds["Offender #"],
                                                                                                title: selectedPersonIds["Offender Name"]?.split(",")[0],
                                                                                              })
                                                                                            );
                                                                                          }
                                                                                          navigate(`/participant/${selectedPersonIds["Offender #"]}`);
                                                                                        }
                                                                                      }}
                                                                                    />
                                                                                  </div>
                                                                                </>
                                                                              );
                                                                            }
                                                                            : val?.moreoption && val?.docketListControl
                                                                              ? (rowData) => {
                                                                                return (
                                                                                  <>
                                                                                    <div className="add_navBar_hamburger" id="threedotsarrearage" onClick={() => { }}>
                                                                                      <ThreeDotMenu />
                                                                                      <ContextMenu
                                                                                        showEvent="dxclick"
                                                                                        dataSource={[
                                                                                          {
                                                                                            text: "Jump To Docket",
                                                                                            icon: "search",
                                                                                          },
                                                                                          {
                                                                                            text: "Send Email",
                                                                                            icon: "email",
                                                                                          },
                                                                                        ]}
                                                                                        target="#threedotsarrearage"
                                                                                        onItemClick={(e) => {
                                                                                          if (e.itemData.text === "Jump To Docket") {
                                                                                            dispatch(
                                                                                              setDynamicTab({
                                                                                                Offender: `/docket/${rowData?.data?.sDocketNumberCourtCounts.replace(
                                                                                                  /\//g,
                                                                                                  "-"
                                                                                                )}`,
                                                                                                link: `/docket/${rowData?.data?.sDocketNumberCourtCounts.replace(
                                                                                                  /\//g,
                                                                                                  "-"
                                                                                                )}`,
                                                                                                title: `Docket ${rowData?.data?.sDocketNumberCourtCounts}`,
                                                                                                sDocketNumberCourtCounts: rowData?.data?.sDocketNumberCourtCounts,
                                                                                                userinfo: {
                                                                                                  ...rowData?.data?.userinfo,
                                                                                                  gPersonId: rowData?.data?.userinfo?.gPersonID,
                                                                                                },
                                                                                                getTopDynamicDocketid: rowData?.data?.gDocketID,
                                                                                              })
                                                                                            );
                                                                                            dispatch(
                                                                                              setdockerValue({
                                                                                                getTopDynamicDocketid: rowData?.data?.gDocketID,
                                                                                                userinfo: {
                                                                                                  ...rowData?.data?.userinfo,
                                                                                                  gPersonId: rowData?.data?.userinfo?.gPersonID,
                                                                                                },
                                                                                              })
                                                                                            );
                                                                                            navigate(`/docket/${rowData?.data?.gDocketID}`);
                                                                                          }

                                                                                          if (e.itemData.text === "Send Email") {
                                                                                            showSendEmailPopup();
                                                                                          }
                                                                                        }}
                                                                                      />
                                                                                    </div>
                                                                                  </>
                                                                                );
                                                                              }
                                                                              : val?.note
                                                                                ? (rowData) => {
                                                                                  return <div style={{ textWrap: "wrap" }}>{rowData.text}</div>;
                                                                                }
                                                                                : val?.moreoption && obligationfeeListMenu
                                                                                  ? (rowData) => {
                                                                                    return (
                                                                                      <>
                                                                                        <div
                                                                                          className="add_navBar_hamburger"
                                                                                          id="threedotsarrearage"
                                                                                          onClick={() => {
                                                                                            setobligationFeeListRowData(rowData.data);
                                                                                          }}
                                                                                        >
                                                                                          <ThreeDotMenu />
                                                                                          <ContextMenu
                                                                                            showEvent="dxclick"
                                                                                            dataSource={[
                                                                                              {
                                                                                                text: "Jump to Fee",
                                                                                                icon: "search",
                                                                                              },
                                                                                            ]}
                                                                                            target="#threedotsarrearage"
                                                                                            onItemClick={(e) => {
                                                                                              if (e.itemData.text === "Jump to Fee") {
                                                                                                dispatch(removeDynamicTab(`/administration/users/obligationfee`));
                                                                                                dispatch(
                                                                                                  setDynamicTab({
                                                                                                    link: `/administration/users/obligationfee`,
                                                                                                    title: "Obligation Fee",
                                                                                                    Offender: obligationFeeListRowData
                                                                                                  })
                                                                                                );
                                                                                                navigate(`/administration/users/obligationfee`);
                                                                                              }
                                                                                            }}
                                                                                          />
                                                                                        </div>
                                                                                      </>
                                                                                    );
                                                                                  }
                                                                                  : ""
                }
              />
            );
          }
        })}
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 20, 30, 50]}
          showNavigationButtons={true}
          showInfo={true}
        />
      </DataGrid>

      {Contextmenu && (
        <ContextMenu
          dataSource={
            Attendance
              ? [
                { text: "Remove Attendance Status", icon: "trash" },
                {
                  text: "Void Appointment",
                  icon: "remove",
                  disabled: desableReportData,
                },
                {
                  text: "Print Next Report Date",
                },
              ]
              : [
                {
                  text: "Enter Attendance",
                  icon: "event",
                },
                { text: "Remove Attendance Status", icon: "trash" },
                {
                  text: "Void Appointment",
                  icon: "remove",
                  disabled: desableReportData,
                },
                {
                  text: "Print Next Report Date",
                },
              ]
          }
          target=".dx-data-row"
          onItemClick={async (e) => {
            if (e.itemData.text === "Enter Attendance") {
              setenterAttendencePopup(true);
            } else if (e.itemData.text === "Void Appointment") {
              setvoidPopup(true);
            } else if (e.itemData.text === "Remove Attendance Status") {
              setremoveAttendenceStatus(!removeAttendenceStatus);
            } else if (e.itemData.text === "Print Next Report Date") {
              setLoading(true);
              await axios
                .get(
                  `${process.env.REACT_APP_CRYSTAL_API_URL}/reports-api/reports/get-print-nrd?sOffenderNumber=${selectedRow.sOffenderNumber}&sActivityId=${selectedRow.gPreReportActivityNoteId}`,
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                      Domain: `${pathname}`,
                    },
                    responseType: "blob",
                  }
                )
                .then((val) => {
                  setLoading(false);
                  if (val?.data?.size > 20) {
                    const pdfBlob = new Blob([val.data], {
                      type: "application/pdf",
                    });
                    const pdfUrl = URL.createObjectURL(pdfBlob);
                    const downloadLink = document.createElement("a");
                    downloadLink.href = pdfUrl;
                    downloadLink.download = "Report_Date" + ".pdf";
                    downloadLink.style.display = "none";
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                  }
                })
                .catch((val) => {
                  setLoading(false);
                });
            }
          }}
        />
      )}
      {attachmentRightCickPopup && (
        <ContextMenu
          dataSource={[
            { text: "Email Attachment", icon: "email" },
            { text: "Delete", icon: "remove" },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Delete") {
              if (attachmentRightCickPopup) {
                setattachmentdeletePopup(true);
              }
            }
          }}
        />
      )}
      {DocketObligationTableRightClick && (
        <ContextMenu
          dataSource={[
            {
              text: "Generate Payment Plan",
              disabled: desableGeneratePaymetPLan,
            },
            { text: "Write Off Amount", disabled: desablewriteoff },
            { text: "Go To Obligation" },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Generate Payment Plan") {
              if (setopenGeneratePaymentPlanpopup) {
                setopenGeneratePaymentPlanpopup(true);
              }
            } else if (e.itemData.text === "Write Off Amount") {
              if (setWrieOffpopup) {
                setWrieOffpopup(true);
              }
            } else if (e.itemData.text === "Go To Obligation") {
              if (!selectedRow) {
                return;
              }
              dispatch(setAddObligation(false));
              localStorage.setItem("RedirecttoObligation", selectedRow?.gObligationId);
              dispatch(
                setDynamicTab({
                  Offender: `/obligation/${selectedRow?.sDocketNumberCourtCounts?.replace("/", "-")}/${selectedRow?.["Obligation Ref#"]}`,
                  link: `/obligation/${selectedRow?.sDocketNumberCourtCounts?.replace("/", "-")}/${selectedRow?.["Obligation Ref#"]}`,
                  title: `Obligation : ${selectedRow?.sDocketNumberCourtCounts} / ${selectedRow?.["Obligation Ref#"]}`,
                  sDocketNumberCourtCounts: selectedRow?.sDocketNumberCourtCounts,
                  ObligationRef: selectedRow?.["Obligation Ref#"],
                  gObligationId: selectedRow?.gObligationId,
                  userinfo: selectedRow?.userinfo,
                })
              );
              dispatch(
                setgotooblogationValue({
                  gObligationId: selectedRow?.gObligationId,
                  userinfo: selectedRow?.userinfo,
                })
              );
              navigate(
                `/obligation/${selectedRow?.sDocketNumberCourtCounts?.replace(/\//g, "-")}/${selectedRow?.["Obligation Ref#"]
                }`
              );
            }
          }}
        />
      )}
      {Revocationscontextmenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Revocation",
              icon: "edit",
            },
            {
              text: "Void Revocation",
              icon: "remove",
              disabled: desableRevocation,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Revocation") {
              if (seteditRevocationspopup) {
                seteditRevocationspopup(true);
              }
            } else if (e.itemData.text === "Void Revocation") {
              setVoidPopup(true);
            }
          }}
        />
      )}
      {taskcontextmenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Task",
              icon: "edit",
            },
            {
              text: "Pending/Delay 30 Days",
              icon: "clock",
            },
            {
              text: "Complete Task",
              icon: "check",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Task") {
              if (setAddQuickPopup) {
                setAddQuickPopup(true);
                setresettask(!resettask);
              }
            } else if (e.itemData.text === "Pending/Delay 30 Days") {
              setPendingPOpuptogal(true);
              setresettask(!resettask);
            } else if (e.itemData.text === "Complete Task") {
              getCompletTask(false);
            }
          }}
        />
      )}
      {taskcontextmenu2 && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Task",
              icon: "edit",
            },
            {
              text: "Add Task",
              icon: "add",
            },
            {
              text: "Jump to Person",
              icon: "search",
            },
            {
              text: "Pending/Delay 30 Days",
              icon: "clock",
            },
            {
              text: "Reassign Selected Tasks",
              icon: "add",
            },
            {
              text: "Task Completed",
              icon: "check",
            },
            {
              text: "Approve/Process Close-out",
              disabled: "true",
            },
            {
              text: "Approve/Process Allocation Adjustment",
              disabled: "true",
            },
            {
              text: "Approve/Process Refund",
              // disabled: !(selectedRow?.categoryId === "A62E2033-D400-4FDB-A4AC-7C78C8813FD4".toLowerCase())
              disabled: !(gUserId.toLowerCase() === selectedRow?.assignedToId.toLowerCase())
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Task") {
              setAddQuickPopup(true);
              setresettask(!resettask);
            } else if (e.itemData.text === "Add Task") {
              setAddQuickPopup(true);
              setAddTaskPopupType("Add");
            } else if (e.itemData.text === "Pending/Delay 30 Days") {
              setPendingPOpuptogal(true);
              setresettask(!resettask);
            } else if (e.itemData.text === "Task Completed") {
              getCompletTask(false);
            } else if (e.itemData.text === "Reassign Selected Tasks") {
              setReassignTasksPopup(true);
            } else if (e.itemData.text === "Jump to Person") {
              if (!selectedRow || !selectedRow?.offenderNumber) {
                return;
              }
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow?.offenderNumber}`,
                  Offender: selectedRow?.offenderNumber,
                  title: selectedRow?.name?.split(",")[0],
                })
              );
              navigate(`/participant/${selectedRow?.offenderNumber}`);
            }
            else if (e.itemData.text === "Approve/Process Refund") {
              approveRefund();
            }
          }}
        />
      )}
      {CheckListTableRightClick && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit Note",
              icon: "edit",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit Note") {
              if (CheckListTableRightClick) {
                setresetCHecklist(!CHecklistreset);
                seteditCHeckoutNotePOpup(true);
              }
            }
          }}
        />
      )}
      {editCheclListOptionrightClist && (
        <ContextMenu
          dataSource={[
            {
              text: "Edit",
              icon: "edit",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Edit") {
              if (editCheclListOptionrightClist) {
                editchecklistoptionsetpopup(true);
              }
            }
          }}
        />
      )}
      {GoToChildDocketrightClick && (
        <ContextMenu
          dataSource={[
            {
              text: "Go To Docket",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Go To Docket") {
              if (!selectedRow) {
                return;
              }
              dispatch(
                setDynamicTab({
                  Offender: `/docket/${selectedRow?.["Docket #"]?.replace(/\//g, "-")}`,
                  link: `/docket/${selectedRow?.["Docket #"]?.replace(/\//g, "-")}`,
                  title: `Docket ${selectedRow?.["Docket #"]}`,
                  sDocketNumberCourtCounts: selectedRow?.["Docket #"],
                  userinfo: selectedRow?.userinfo,
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketId,
                })
              );
              dispatch(
                setdockerValue({
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketId,
                  userinfo: selectedRow?.userinfo,
                })
              );
              navigate(`/docket/${selectedRow?.["Docket #"]?.replace(/\//g, "-")}`);
            }
          }}
        />
      )}
      {REportWizardActiveLIst && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump To Source",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Source") {
              if (!selectedRow || !selectedRow?.["Offender #"]) {
                return;
              }
              if (selectedRow?.Source === "Person") {
                dispatch(
                  setDynamicTab({
                    link: `/participant/${selectedRow?.["Offender #"]}`,
                    Offender: selectedRow?.["Offender #"],
                    title: selectedRow?.["Offender Name"]?.split(",")[0],
                  })
                );
                navigate(`/participant/${selectedRow?.["Offender #"]}`);
              }
            }
          }}
        />
      )}
      {REportWizardPaymentlist && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump To Person",
              icon: "search",
            },
            {
              text: "Jump To Obligation",
              icon: "search",
            },
            {
              text: "Jump To Docket",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Person") {
              if (!selectedRow || !selectedRow?.["Offender No"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow?.["Offender No"]}`,
                  Offender: selectedRow?.["Offender No"],
                  title: selectedRow?.["Person Name"]?.split(",")[0],
                })
              );
              navigate(`/participant/${selectedRow?.["Offender No"]}`);
            } else if (e.itemData.text === "Jump To Docket") {
              dispatch(
                setDynamicTab({
                  Offender: `/docket/${`${"tempdata2"} - ${"tempdata2"}`?.replace(/\//g, "-")}`,
                  link: `/docket/${`${"tempdata2"} - ${"tempdata2"}`?.replace(/\//g, "-")}`,
                  title: `Docket ${`${"tempdata2"} - ${"tempdata2"}`}`,
                  sDocketNumberCourtCounts: `${"tempdata2"} - ${"tempdata2"}`,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonID,
                  },
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketID,
                })
              );
              dispatch(
                setdockerValue({
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketID,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonID,
                  },
                })
              );
              navigate(`/docket/${`${"tempdata2"} - ${"tempdata2"}`?.replace(/\//g, "-")}`);
            } else if (e.itemData.text === "Jump To Obligation") {
              dispatch(
                setDynamicTab({
                  Offender: `/obligation/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}/${"tempdata"}`,
                  link: `/obligation/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}/${"tempdata"}`,
                  title: `Obligation : ${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`} / ${"tempdata"}`,
                  sDocketNumberCourtCounts: `${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`,
                  ObligationRef: "tempdata",
                  gObligationId: selectedRow?.gObligationID,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonID,
                  },
                })
              );
              dispatch(
                setgotooblogationValue({
                  gObligationId: selectedRow?.gObligationID,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonID,
                  },
                })
              );
              navigate(
                `/obligation/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                  "/",
                  "-"
                )}/${"tempdata"}`
              );
            }
          }}
        />
      )}
      {ReportWizardDocketLIstrightclick && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump To Person",
              icon: "search",
            },
            {
              text: "Jump To Docket",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Person") {
              if (!selectedRow || !selectedRow?.["Offender"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow?.["Offender"]}`,
                  Offender: selectedRow?.["Offender"],
                  title: selectedRow?.["Offender Name"]?.split(",")[0],
                })
              );
              navigate(`/participant/${selectedRow?.["Offender"]}`);
            } else if (e.itemData.text === "Jump To Docket") {
              if (!selectedRow || selectedRow?.userinfo?.gPersonId == null) {
                return;
              }
              dispatch(
                setDynamicTab({
                  Offender: `/docket/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}`,
                  link: `/docket/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}`,
                  title: `Docket ${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`}`,
                  sDocketNumberCourtCounts: `${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`,
                  userinfo: selectedRow?.userinfo,
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketId,
                })
              );
              dispatch(
                setdockerValue({
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketId,
                  userinfo: selectedRow?.userinfo,
                })
              );
              navigate(
                `/docket/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(/\//g, "-")}`
              );
            }
          }}
        />
      )}
      {ActivePersonListrightclick && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump To Person",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Person") {
              if (!selectedRow || !selectedRow?.["Offender Number"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow?.["Offender Number"]}`,
                  Offender: selectedRow?.["Offender Number"],
                  title: selectedRow?.["Last Name"],
                })
              );
              navigate(`/participant/${selectedRow?.["Offender Number"]}`);
            }
          }}
        />
      )}
      {ReportDaterightclick && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump To Person",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Person") {
              if (!selectedRow || !selectedRow?.["Offender #"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow?.["Offender #"]}`,
                  Offender: selectedRow?.["Offender #"],
                  title: selectedRow?.["Offender Name"]?.split(",")[0],
                })
              );
              navigate(`/participant/${selectedRow?.["Offender #"]}`);
            }
          }}
        />
      )}
      {Disbursementrightclick && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump To Person",
              icon: "search",
            },
            {
              text: "Jump To Obligation",
              icon: "search",
            },
            {
              text: "Jump To Docket",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Person") {
              if (!selectedRow || !selectedRow?.["Offender #"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow?.["Offender #"]}`,
                  Offender: selectedRow?.["Offender #"],
                  title: selectedRow?.["Offender Name"]?.split(",")[0],
                })
              );
              navigate(`/participant/${selectedRow?.["Offender #"]}`);
            } else if (e.itemData.text === "Jump To Docket") {
              if (!selectedRow || selectedRow?.userinfo?.gPersonId == null) {
                return;
              }
              dispatch(
                setDynamicTab({
                  Offender: `/docket/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}`,
                  link: `/docket/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}`,
                  title: `Docket ${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`}`,
                  sDocketNumberCourtCounts: `${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonId,
                  },
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketID,
                })
              );
              dispatch(
                setdockerValue({
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketID,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonId,
                  },
                })
              );
              navigate(
                `/docket/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(/\//g, "-")}`
              );
            } else if (e.itemData.text === "Jump To Obligation") {
              dispatch(
                setDynamicTab({
                  Offender: `/obligation/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}/${"tempdata"}`,
                  link: `/obligation/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}/${"tempdata"}`,
                  title: `Obligation : ${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`} / ${"tempdata"}`,
                  sDocketNumberCourtCounts: `${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`,
                  ObligationRef: "tempdata",
                  gObligationId: selectedRow?.gObligationId,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonId,
                  },
                })
              );
              dispatch(
                setgotooblogationValue({
                  gObligationId: selectedRow?.gObligationId,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonId,
                  },
                })
              );
              navigate(
                `/obligation/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                  "/",
                  "-"
                )}/${"tempdata"}`
              );
            }
          }}
        />
      )}
      {DrugTestREportWizardrightclick && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump To Person",
              icon: "search",
            },
            {
              text: "Jump To Docket",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Person") {
              if (!selectedRow || !selectedRow?.["Offender #"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow?.["Offender #"]}`,
                  Offender: selectedRow?.["Offender #"],
                  title: selectedRow?.["Offender Name"]?.split(",")[0],
                })
              );
              navigate(`/participant/${selectedRow?.["Offender #"]}`);
            } else if (e.itemData.text === "Jump To Docket") {
              if (!selectedRow || selectedRow?.userinfo?.gPersonId == null) {
                return;
              }
              dispatch(
                setDynamicTab({
                  Offender: `/docket/${selectedRow?.["sDocketNumberCourtCounts"]?.replace(/\//g, "-")}`,
                  link: `/docket/${selectedRow?.["sDocketNumberCourtCounts"]?.replace(/\//g, "-")}`,
                  title: `Docket ${selectedRow?.["sDocketNumberCourtCounts"]}`,
                  sDocketNumberCourtCounts: selectedRow?.["sDocketNumberCourtCounts"],
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonId,
                  },
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketId,
                })
              );
              dispatch(
                setdockerValue({
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketId,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonId,
                  },
                })
              );
              navigate(`/docket/${selectedRow?.["sDocketNumberCourtCounts"]?.replace(/\//g, "-")}`);
            }
          }}
        />
      )}
      {ObligationLIstrightclick && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump To Person",
              icon: "search",
            },
            {
              text: "Jump To Obligation",
              icon: "search",
            },
            {
              text: "Jump To Docket",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Person") {
              if (!selectedRow || !selectedRow?.["Offender"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow?.["Offender"]}`,
                  Offender: selectedRow?.["Offender"],
                  title: selectedRow?.["Offender Name"]?.split(",")[0],
                })
              );
              navigate(`/participant/${selectedRow?.["Offender"]}`);
            } else if (e.itemData.text === "Jump To Docket") {
              if (!selectedRow || selectedRow?.userinfo?.gPersonID == null) {
                return;
              }
              dispatch(
                setDynamicTab({
                  Offender: `/docket/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}`,
                  link: `/docket/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}`,
                  title: `Docket ${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`}`,
                  sDocketNumberCourtCounts: `${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonID,
                  },
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketId,
                })
              );
              dispatch(
                setdockerValue({
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketId,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonID,
                  },
                })
              );
              navigate(
                `/docket/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(/\//g, "-")}`
              );
            } else if (e.itemData.text === "Jump To Obligation") {
              dispatch(
                setDynamicTab({
                  Offender: `/obligation/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}/${"tempdata"}`,
                  link: `/obligation/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                    "/",
                    "-"
                  )}/${"tempdata"}`,
                  title: `Obligation : ${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`} / ${"tempdata"}`,
                  sDocketNumberCourtCounts: `${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`,
                  ObligationRef: "tempdata",
                  gObligationId: selectedRow?.gObligationId,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonId,
                  },
                })
              );
              dispatch(
                setgotooblogationValue({
                  gObligationId: selectedRow?.gObligationId,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonId,
                  },
                })
              );
              navigate(
                `/obligation/${`${selectedRow?.["sDocketNumber"]} - ${selectedRow?.["iCourtCounts"]}`?.replace(
                  "/",
                  "-"
                )}/${"tempdata"}`
              );
            }
          }}
        />
      )}
      {PreDisbursementsReporteightclick && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump To Person",
              icon: "search",
            },
            {
              text: "Jump To Obligation",
              icon: "search",
            },
            {
              text: "Jump To Docket",
              icon: "search",
            },
            {
              text: "Select All",
            },
            {
              text: "Select None",
            },
            {
              text: "Print Checks",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Person") {
              if (!selectedRow || !selectedRow?.["Offender #"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow?.["Offender #"]}`,
                  Offender: selectedRow?.["Offender #"],
                  title: selectedRow?.["Person Name"]?.split(",")[0],
                })
              );
              navigate(`/participant/${selectedRow?.["Offender #"]}`);
            } else if (e.itemData.text === "Jump To Docket") {
              if (!selectedRow || selectedRow?.userinfo?.gPersonID == null) {
                return;
              }
              dispatch(
                setDynamicTab({
                  Offender: `/docket/${`${"tempdata2"} - ${"tempdata2"}`?.replace(/\//g, "-")}`,
                  link: `/docket/${`${"tempdata2"} - ${"tempdata2"}`?.replace(/\//g, "-")}`,
                  title: `Docket ${`${"tempdata2"} - ${"tempdata2"}`}`,
                  sDocketNumberCourtCounts: `${"tempdata2"} - ${"tempdata2"}`,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonID,
                  },
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketID,
                })
              );
              dispatch(
                setdockerValue({
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketID,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonID,
                  },
                })
              );
              navigate(`/docket/${`${"tempdata2"} - ${"tempdata2"}`?.replace(/\//g, "-")}`);
            } else if (e.itemData.text === "Jump To Obligation") {
              dispatch(
                setDynamicTab({
                  Offender: `/obligation/${`${selectedRow?.["sReference"]} - ${selectedRow?.["sReference"]}`?.replace(
                    "/",
                    "-"
                  )}/${"tempdata"}`,
                  link: `/obligation/${`${selectedRow?.["sReference"]} - ${selectedRow?.["sReference"]}`?.replace(
                    "/",
                    "-"
                  )}/${"tempdata"}`,
                  title: `Obligation : ${`${selectedRow?.["sReference"]} - ${selectedRow?.["sReference"]}`} / ${"tempdata"}`,
                  sDocketNumberCourtCounts: `${selectedRow?.["sReference"]} - ${selectedRow?.["sReference"]}`,
                  ObligationRef: "tempdata",
                  gObligationId: selectedRow?.gObligationID,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonID,
                  },
                })
              );
              dispatch(
                setgotooblogationValue({
                  gObligationId: selectedRow?.gObligationID,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonID,
                  },
                })
              );
              navigate(
                `/obligation/${`${selectedRow?.["sReference"]} - ${selectedRow?.["sReference"]}`?.replace(
                  "/",
                  "-"
                )}/${"tempdata"}`
              );
            } else if (e.itemData.text === "Select All") {
              setselectAll(true);
            } else if (e.itemData.text === "Select None") {
              setPreDisbursementsReportcheckbox([]);
              setselectAll(false);
            } else if (e.itemData.text === "Print Checks") {
              setPrintCheckPopup(true);
            }
          }}
        />
      )}
      {DocketManagementRightClick && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump To Docket",
              icon: "search",
            },
            {
              text: "Quick Add Obligation",
              disabled: false,
            },
            {
              text: "Change Supervision Status",
              disabled: !selectedRowCheckbox.length,
            },
            {
              text: "Change Finacial Status",
              disabled: !selectedRowCheckbox.length,
            },
            {
              text: "Change Supervisor",
              disabled: !selectedRowCheckbox.length,
            },
            {
              text: "Add Revocation(s)",
              disabled: true,
            },
            {
              text: "Add Revocation(s)",
              disabled: true,
            },
            {
              text: "Add Tolling",
              disabled: !selectedRowCheckbox.length,
            },
            {
              text: "Move Obligation to Another Docket ",
              disabled: true,
            },
            {
              text: "Delete Docket",
              disabled: true,
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Docket") {
              if (!selectedRow?.userinfo?.gDocketId && !selectedRow?.userinfo?.gPersonId) {
                return;
              }
              dispatch(
                setDynamicTab({
                  Offender: `/docket/${`${selectedRow?.sDocketNumberCourtCounts}`?.replace(/\//g, "-")}`,
                  link: `/docket/${`${selectedRow?.sDocketNumberCourtCounts}`?.replace(/\//g, "-")}`,
                  title: `Docket ${`${selectedRow?.sDocketNumberCourtCounts}`}`,
                  sDocketNumberCourtCounts: `${selectedRow?.sDocketNumberCourtCounts}`,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonId,
                  },
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketId,
                })
              );
              dispatch(
                setdockerValue({
                  getTopDynamicDocketid: selectedRow?.userinfo?.gDocketId,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonId,
                  },
                })
              );
              navigate(`/docket/${`${selectedRow?.sDocketNumberCourtCounts}`?.replace(/\//g, "-")}`);
            }

            if (e.itemData.text === "Quick Add Obligation") {
              setHeader({
                ...header,
                sDocketNumberCourtCounts: selectedRow["Docket/Count"],
              });
              setDocketId(selectedRow?.userinfo?.gDocketId);
              dispatch(setRecentDocket(selectedRow?.data));
              setgetQuickaddObligationPopup(true);
            }

            if (e.itemData.text === "Change Supervision Status") {
              setChangesuperviserDatePOpup(true);
            }

            if (e.itemData.text === "Change Finacial Status") {
              setChangeFinancialStatusPopup(true);
            }

            if (e.itemData.text === "Change Supervisor") {
              setChangeSupervisorPopup(true);
            }

            if (e.itemData.text === "Add Tolling") {
              setAddTollingPopup(true);
            }
          }}
        />
      )}
      {arrearageMinderMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump to Person",
              icon: "search",
            },
            {
              text: "Send Arrearage Notification",
              icon: "email",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump to Person") {
              if (!selectedRow || !selectedRow?.["Client Id"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow?.["Client Id"]}`,
                  Offender: selectedRow?.["Client Id"],
                  title: selectedRow?.["Client Name"]?.split(",")[0],
                })
              );
              navigate(`/participant/${selectedRow?.["Client Id"]}`);
            } else if (e.itemData.text === "Send Arrearage Notification") {
              setSendEmailPopup(true);
            }
          }}
        />
      )}
      {obligationfeeListMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump to Fee",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump to Fee") {
              dispatch(removeDynamicTab(`/administration/users/obligationfee`));
              dispatch(
                setDynamicTab({
                  link: `/administration/users/obligationfee`,
                  title: "Obligation Fee",
                  Offender: selectedRow
                })
              );
              navigate(`/administration/users/obligationfee`);
            }
          }}
        />
      )}
      {revenueReportMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump to Person",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump to Person") {
              if (!selectedRow || !selectedRow?.["Client Id"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow?.["Client Id"]}`,
                  Offender: selectedRow?.["Client Id"],
                  title: selectedRow?.["Client Name"]?.split(",")[0],
                })
              );
              navigate(`/participant/${selectedRow?.["Client Id"]}`);
            }
          }}
        />
      )}
      {agingReportMenu && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump to Person",
              icon: "search",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump to Person") {
              if (!selectedRow || !selectedRow?.["Client Id"]) {
                return;
              }
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow?.["Client Id"]}`,
                  Offender: selectedRow?.["Client Id"],
                  title: selectedRow?.["Client Name"]?.split(",")[0],
                })
              );
              navigate(`/participant/${selectedRow?.["Client Id"]}`);
            }
          }}
        />
      )}
      {personListControl && (
        <ContextMenu
          target=".dx-data-row"
          dataSource={[
            {
              text: "Jump To Person",
              icon: "search",
            },
            {
              text: "Send Email",
              icon: "email",
            },
            {
              text: "Merge person",
            },
          ]}
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Person") {
              if (selectedRow?.gMergePersonID) {
                setMergedPerson(selected?.gMergePersonID);
                setConfirmPopup(true);
              }
              else {
                if (selectedRow.personlistfullName) {
                  saveCookieCrum(selectedRow.gPersonID);
                  dispatch(
                    setDynamicTab({
                      link: `/participant/${selectedRow["Offender #"]}`,
                      Offender: selectedRow["Offender #"],
                      title: selectedRow.personlistfullName,
                    })
                  );
                } else {
                  saveCookieCrum(selectedRow.gPersonId);
                  dispatch(
                    setDynamicTab({
                      link: `/participant/${selectedRow["Offender #"]}`,
                      Offender: selectedRow["Offender #"],
                      title: selectedRow?.sLastName,
                    })
                  );
                }

                navigate(`/participant/${selectedRow["Offender #"]}`);
              }
            }

            if (e.itemData.text === "Send Email") {
              showSendEmailPopup();
            }
            if (e.itemData.text === "Merge person") {
              setPerson(selectedRow?.gPersonID);
              setLast(`${selectedRow["Last Name"]}, ${selectedRow["First Name"]}`)
              setSourcePersonId(selectedRow["Offender #"]);
              setMergedPerson(selectedRow?.gMergePersonID);
              if (selectedRow?.gMergePersonID) {
                setConfirmPopup(true);
              }
              else {
                setIsMergePopupVisible(true);
              }
            }
          }}
        />
      )}
      {periodSummaryControl && (
        <ContextMenu
          target=".dx-data-row"
          dataSource={[
            {
              text: "Jump To Person",
              icon: "search",
            },
          ]}
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Person") {
              saveCookieCrum(selectedRow["Offender Name"]);
              dispatch(
                setDynamicTab({
                  link: `/participant/${selectedRow["Offender #"]}`,
                  Offender: selectedRow["Offender #"],
                  title: selectedRow["Offender Name"].slice(0, selectedRow["Offender Name"].indexOf(",")),
                })
              );
              navigate(`/participant/${selectedRow["Offender #"]}`);
            }
          }}
        />
      )}
      {docketListControl && (
        <ContextMenu
          dataSource={[
            {
              text: "Jump To Docket",
              icon: "search",
            },
            {
              text: "Send Email",
              icon: "email",
            },
          ]}
          target=".dx-data-row"
          onItemClick={(e) => {
            if (e.itemData.text === "Jump To Docket") {
              dispatch(
                setDynamicTab({
                  Offender: `/docket/${selectedRow?.sDocketNumberCourtCounts.replace(/\//g, "-")}`,
                  link: `/docket/${selectedRow?.sDocketNumberCourtCounts.replace(/\//g, "-")}`,
                  title: `Docket ${selectedRow?.sDocketNumberCourtCounts}`,
                  sDocketNumberCourtCounts: selectedRow?.sDocketNumberCourtCounts,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonID,
                  },
                  getTopDynamicDocketid: selectedRow?.gDocketID,
                })
              );
              dispatch(
                setdockerValue({
                  getTopDynamicDocketid: selectedRow?.gDocketID,
                  userinfo: {
                    ...selectedRow?.userinfo,
                    gPersonId: selectedRow?.userinfo?.gPersonID,
                  },
                })
              );
              navigate(`/docket/${selectedRow?.gDocketID}`);
            }

            if (e.itemData.text === "Send Email") {
              showSendEmailPopup();
            }
          }}
        />
      )}
      <p
        style={{
          color: "var(--TextColor)",
          fontWeight: 800,
          marginTop: "10px",
          //float: "left",
        }}
      >
        Count: {count}
      </p>
    </div>
  );
};
