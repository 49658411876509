import { useEffect, useState } from "react";
import "./style/administration.css";
import "./style/reportEditor.css";
import { Popup } from "devextreme-react/popup";
import { getAuthToken } from "../../services/auth-service";
import httpService from "../../services/http-service";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../components/Logout";
import { Button, DataGrid, LoadPanel, SelectBox, TextBox } from "devextreme-react";
import AdminCrumbs from "./components/AdminCrumbs";
import { Column, Editing, Lookup, Paging, RequiredRule } from "devextreme-react/data-grid";
import { AddReport } from "./AddReport";
import { ApiError } from "../Error/ApiError";
import { SelectReportCriteria } from "../Report/SelectReportCriteria/SelectReportCriteria";
import { ReportFunction } from "../Report/REportFunction";
import { DynamicTabb } from "../../components/DynamicTab/DynamicTab";

const SelectReportCriteriaPopup = ({
  SelectReportCriteriaPopupopen,
  setSelectReportCriteriaPopupopen,
  data,
  sReportPathname,
  sReportName,
}) => {
  return (
    <>
      <Popup
        visible={SelectReportCriteriaPopupopen}
        onHiding={() => {
          setSelectReportCriteriaPopupopen(false);
        }}
        showTitle={false}
        width={900}
        height={700}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <SelectReportCriteria
          SelectReportCriteriaPopupopen={SelectReportCriteriaPopupopen}
          setSelectReportCriteriaPopupopen={setSelectReportCriteriaPopupopen}
          data={data}
          sReportPathname={sReportPathname}
          sReportName={sReportName}
        />
      </Popup>
    </>
  );
};

const initialColumns = [
  {
    dataField: "sParameterName",
    caption: "Parameter Name",
    rules: [{ type: "required" }],
  },
  {
    dataField: "sCaption",
    caption: "Caption",
    rules: [{ type: "required" }],
  },
  {
    dataField: "sParameterType",
    caption: "Parameter Type",
    rules: [{ type: "required" }],
    lookup: {
      dataSource: [
        "CashDrawer",
        "Company",
        "Court",
        "Date",
        "Payment Method",
        "Person Status",
        "Quick Date",
        "Status Type",
        "Supervision Status",
        "Supervisor",
      ],
    },
  },
  {
    dataField: "iParameterSort",
    caption: "Parameter Sort",
    defaultSortOrder: "asc",
  },
  {
    dataField: "sDefaultValue",
    caption: "Default Value",
  },
];

export const ReportEditor = () => {

  const STORAGE_KEY = "reportEditorColumnOrder";

  const [popUp, setPopUp] = useState(0);
  const navigate = useNavigate();
  const [errorhandling, seterrorhandling] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);
  const [reportID, setReportID] = useState("");
  const [reportData, setReportData] = useState([]);
  const [reportName, setReportName] = useState("");
  const [canReplace, setCanReplace] = useState(false);

  let domainName = (url) => url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
  let domailURL = window.location.href;
  let pathURL = domainName(domailURL);
  const token = getAuthToken();
  const [SelectReportCriteriaPopupopen, setSelectReportCriteriaPopupopen] = useState(false);
  const [data, setdata] = useState();
  const [sReportPathname, setsReportPathname] = useState("");

  const [columns, setColumns] = useState(initialColumns);

  const clas = new ReportFunction();

  const buttonapi = () => {
    clas.getparamete(navigate, setLoading, reportID, setdata, setSelectReportCriteriaPopupopen);
  };

  const showReportNamesDropdown = async (e) => {
    try {
      const response = await httpService.get(`api/ReportDashboard/get-reports`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
      });
      if (response.data.isSuccess) {
        seterrorhandling(false);
        const dropdownData = response.data.data.map((report) => {
          return {
            name: report.sReportName,
            id: report.gReportId,
            path: report.sReportPath,
          };
        });
        dropdownData.sort((reportA, reportB) => {
          return reportA.name.localeCompare(reportB.name);
        });
        setDropDownData(dropdownData);
      } else {
        seterrorhandling(true);
      }
    } catch (error) {
      setLoading(false);
      if (!error?.response) {
        navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        logOut();
      } else {
        seterrorhandling(true);
      }
    }
  };

  const tableDataApi = async () => {
    try {
      setLoading(true);
      const response = await httpService.get(`api/ReportDashboard/populate-report-data?reportId=${reportID}`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
      });
      if (response.data.isSuccess) {
        setLoading(false);
        setReportData(response.data.data);
        seterrorhandling(false);
      } else {
        setLoading(true);
      }
    } catch (error) {
      setLoading(false);
      if (!error?.response) {
        navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        logOut();
      } else {
        seterrorhandling(true);
      }
    }
  };

  const loadParameter = async () => {
    if (reportName === "") return;
    try {
      setLoading(true);
      const response = await httpService.get(`api/ReportDashboard/load-pronto-report-parameters?reportId=${reportID}`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
      });
      if (response.data.isSuccess) {
        setLoading(false);
        setReportData(response.data.data);
        setCanReplace(true);
      } else {
        setLoading(false);
        seterrorhandling(true);
      }
    } catch (error) {
      setLoading(false);
      if (!error?.response) {
        navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        logOut();
      } else {
        seterrorhandling(true);
      }
    }
  };

  const cancelReplace = () => {
    setCanReplace(false);
    tableDataApi();
  };

  const replaceParameter = async () => {
    try {
      setLoading(true);
      const response = await httpService.post(
        `api/ReportDashboard/replace-report-parameters?reportId=${reportID}`,
        reportData.map((data) => {
          return { ...data, gReportId: reportID };
        }),
        {
          headers: { Authorization: `Bearer ${token}`, Domain: `${pathURL}` },
        }
      );
      if (response.data.isSuccess) {
        setLoading(false);
        setCanReplace(false);
      } else {
        setLoading(true);
      }
    } catch (error) {
      setLoading(false);
      if (!error?.response) {
        navigate(`/error/500`);
      } else if (error?.response?.status === 401) {
        logOut();
      } else {
        seterrorhandling(true);
      }
    }
  };

  const loadSavedOrder = () => {
    const savedOrder = JSON.parse(localStorage.getItem(STORAGE_KEY));
    if (savedOrder) {
      const reorderedColumns = savedOrder
        .map((field) => columns.find((col) => col.dataField === field))
        .filter(Boolean);
      setColumns(reorderedColumns.length ? reorderedColumns : initialColumns);
    }
  };

  const saveColumnOrder = (newOrder) => {
    const order = newOrder.map((col) => col.dataField);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(order));
  };

  useEffect(() => {
    if (reportID !== "") {
      tableDataApi();
      setCanReplace(false);
    }
  }, [reportID]);

  useEffect(() => {
    showReportNamesDropdown();
    if (localStorage.getItem("user_designation") !== "Administrator") {
      navigate("/");
    }
    loadSavedOrder();
  }, []);

  return (
    <>
      <SelectReportCriteriaPopup
        SelectReportCriteriaPopupopen={SelectReportCriteriaPopupopen}
        setSelectReportCriteriaPopupopen={setSelectReportCriteriaPopupopen}
        data={data}
        sReportPathname={sReportPathname}
        sReportName={reportName}
      />
      <Popup
        visible={popUp}
        onHiding={() => {
          setPopUp(false);
        }}
        showTitle={false}
        width={1500}
        height={650}
        showCloseButton={true}
        hideOnOutsideClick={true}
      >
        <AddReport setPopUp={setPopUp} />
      </Popup>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <AdminCrumbs title={"Report Editor"} user={"Data"} />
      <div className="administration">
        <DynamicTabb />
        <div className="screen_num" style={{ margin: "1%" }}>
          <p>CR.11</p>
        </div>
        <div className="reportEditor">
          <div className="reportEditor_sub">
            <p className="reportEditor_label">Select Report</p>
            <SelectBox
              dataSource={dropDownData}
              placeholder="Select Report Name"
              valueExpr="name"
              displayExpr="name"
              searchEnabled={true}
              searchExpr={"name"}
              dropDownOptions={{ height: "360px" }}
              onItemClick={(e) => {
                setReportID(e?.itemData?.id);
                setsReportPathname(e?.itemData?.path);
                setReportName(e?.itemData?.name);
              }}
              style={{ width: "500px" }}
            />
            <button
              className="add_report_button"
              onClick={() => {
                setPopUp(true);
              }}
            >
              Manage Reports
            </button>
          </div>
          <div className="reportEditor_sub" style={{ display: "none" }}>
            <p className="reportEditor_label">Report ID</p>
            <TextBox placeholder="Report ID" style={{ width: "500px" }} value={reportID} disabled />
          </div>
          {errorhandling ? (
            <ApiError />
          ) : (
            <div className="reportTable" style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  zIndex: 1,
                  left: "20%",
                  display: "flex",
                  gap: "20px",
                }}
              >
                <Button onClick={loadParameter} visible={!canReplace}>
                  Load Parameter
                </Button>
                <Button onClick={replaceParameter} visible={canReplace}>
                  Replace Parameter
                </Button>
                <Button onClick={cancelReplace} visible={canReplace}>
                  Cancel
                </Button>
                <Button onClick={buttonapi}>Run Report</Button>
              </div>
              <DataGrid
                height={"auto"}
                dataSource={reportData}
                onSaved={async (e) => {
                  if (canReplace) setCanReplace(false);
                  replaceParameter();
                }}
                keyExpr={"gReportParamaterId"}
                allowColumnReordering={true}
                onOptionChanged={(e) => {
                  if (e.name === "columns" && e.fullName.includes("visibleIndex")) {
                    const updatedColumns = e.component.getVisibleColumns();
                    const newOrder = updatedColumns
                      .map((col) => columns.find((item) => item.dataField === col.dataField))
                      .filter(Boolean);
                    saveColumnOrder(newOrder);
                  }
                }}
              >
                <Paging enabled={false} />
                <Editing mode="batch" allowUpdating={true} allowAdding={true} allowDeleting={true} />
                {columns.map((col, index) => (
                  <Column
                    key={col.dataField}
                    dataField={col.dataField}
                    caption={col.caption}
                    defaultSortOrder={col.defaultSortOrder || undefined}
                    visibleIndex={index}
                    lookup={col.lookup ? { dataSource: col.lookup.dataSource } : undefined} // Attach lookup directly here
                  >
                    <RequiredRule />
                  </Column>
                ))} 
              </DataGrid>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
