export const Tablecoloum = [
  { title: "Court" },
  { title: "Docket/Count" },
  { title: "Fee Code" },
  { title: "Fee Name" },
  { title: "Amount", currency: true },
  { title: "Balance", currency: true },
  {
    title: "Action", actiondropdown: true,
    editable: true,
    requiredColoumWhileEdit: true,
  },
  { title: "Adjustment Amount", currency: true, editable: true },
];

export const setTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Court": val?.sCourtName,
      "Docket/Count": val?.sDocketNumberCourtCounts,
      "Fee Code": val?.sFeeCode,
      "Fee Name": val?.sFeeName,
      Amount: val?.fAmount,
      Balance: val?.fBalance,
      Action: val?.fBalance == 0 ? "Close" : "-select-",
      "Adjustment Amount": val?.fBalance,
      ID: i
    });
  });
  return arr;
};

