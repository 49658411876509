import React, { useState } from "react";
import * as XLSX from "xlsx";
import moment from "moment";

const SpreadsheetUploader = ({ setExcelParsedData, setExcelName }) => {
    const [filePath, setFilePath] = useState(""); // To display the file path
    const [parsedData, setParsedData] = useState([]); // To store parsed data

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setFilePath(file.name); // Display the file name as path
        setExcelName(file.name);

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary" });

            // Find the first tab named "Manual Invoice"
            const manualInvoiceSheet = workbook.Sheets["Manual Invoice"];
            if (manualInvoiceSheet) {
                // Parse the sheet data into JSON array
                const jsonData = XLSX.utils.sheet_to_json(manualInvoiceSheet);
                function excelDateToJSDate(serial) {
                    const epoch = new Date(1900, 0, 1);  // Excel's base date is 1900-01-01
                    const msPerDay = 86400000;  // 24 * 60 * 60 * 1000
                    const date = new Date(epoch.getTime() + (serial - 2) * msPerDay);  // Adjust for Excel's offset
                    date.setHours(0, 0, 0, 0);  // Ensure it's at the start of the day
                    return date;
                }
                const formattedData = jsonData.map((item) => ({
                    Qty: item["Qty"] || 0.0,
                    Defendant: item.Defendant || "",
                    SIN: item.SIN || "",
                    MFRPart: item["MFR Part"] || "",
                    Discription: item.Discription || "",
                    Case: item.Case || "",
                    StartDate: item["Start Date"] ? moment(excelDateToJSDate(item["Start Date"]).toISOString().split('T')[0], 'YYYY-MM-DD').add(1, 'days').format('MM-DD-YYYY') : null,  // Convert to date string (YYYY-MM-DD)
                    EndDate: item["End Date"] ? moment(excelDateToJSDate(item["End Date"]).toISOString().split('T')[0], 'YYYY-MM-DD').add(1, 'days').format('MM-DD-YYYY') : null,  // Convert to date string (YYYY-MM-DD)
                    Rate: item.Rate || 0.0,
                    Amount: item.Amount || 0.0,
                    MonitoringDays: item["Monitoring Days"] || 0,
                }));
                setParsedData(formattedData);
                setExcelParsedData(formattedData);
            } else {
                alert("Sheet 'Manual Invoice' not found in the uploaded file.");
            }
        };

        reader.readAsBinaryString(file); // Read file as binary string
    };

    return (
        <div>
            <div className="page-info-buttons">
                <div className="buttons" style={{ alignItems: "baseline" }}>
                    <div className="agency-column-fields">
                        <div>
                            {filePath || "No file selected"}
                        </div>
                    </div>
                    <div className="agency-column-fields">
                        <label htmlFor="fileUpload" className="btn">
                            Browse
                        </label>
                        <input
                            type="file"
                            id="fileUpload"
                            style={{ display: "none" }}
                            accept=".xls,.xlsx"
                            onChange={handleFileChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetUploader;
