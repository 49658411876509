import React, { useEffect, useState } from "react";
import { logOut } from "../../../../../../components/Logout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { handleGetLocal } from "../../../../../../services/auth-service";
import { DynamicTabb } from "../../../../../../components/DynamicTab/DynamicTab";
import { Toster } from "../../../../../../components/Toster/Toster";

const VoidTest = ({
  // voidPopup,
  // setVoidPopup,
  // voidSubHeading,
  // setLoading,
  // toastConfig,
  // setToastConfig,
  rowData,
  // apiURl,
  // refreshApi,
}) => {
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState("");
  const [commentValidation, setCommentValidation] = useState("");

  useEffect(() => {
    setComments("");
    setCommentValidation("");
  }, []);

  const data = {
    gDrugTestRandomizerScheduleID: rowData?.gDrugTestRandomizerScheduleId,
    gUserID: localStorage.getItem("gUserId"),
    EnteredReason: comments,
  };

  const handleSave = () => {
    if (comments.trim() === "" || comments[0] === " ") {
      setCommentValidation("Please enter comment");
    } else {
      saveApi(data);
      setCommentValidation("");
    }
  };
  const navigate = useNavigate();

  const saveApi = async (body) => {
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/RandomDrugTesting/add-edit-drug-test`, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        console.log('val', val);
        // setVoidPopup(false);
        setComments("");
        setCommentValidation("");
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: val?.data?.responseMessage || "Saved successfully!",
          type: 'success',
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
            message: '',
            type: '',
          });
        }, 1900);
        // refreshApi();
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  return (
    <div style={{margin:'20px 0', padding: "20px", background: 'white' }}>
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <DynamicTabb />
      <br/>
      <p className="page-info-number">VD.1</p>
      <br/>
      <div className="program_void_content" style={{padding:'0px'}}>
        <div className="program_void_content_info" style={{gap:'0'}}>
          <p className="sub_para">Void Test Date</p>
          <p className="highlight_para">This can not be undone</p>
        </div>
        <p className="info_para">This should only be used to correct error</p>
        <div className="program_void_content_textarea">
          <label htmlFor="comments">
            Please enter a detailed comment
            <span className="marks_mandatary">*</span>
          </label>
          <textarea
            id="comments"
            value={comments}
            placeholder="Write comment here"
            onChange={(e) => {
              const value = e.target.value;
              setComments(value);
              if (value.trim() === "" || value[0] === " ") {
                setCommentValidation("Please enter comment");
              } else {
                setCommentValidation("");
              }
            }}
            className="dark-mode-textarea"
          ></textarea>
          <p className="error_validation">{commentValidation}</p>
        </div>
        <div className="popup_buttons">
          <button onClick={handleSave} className="btn primary_btn">
            Save
          </button>
          <button
            className="btn"
            onClick={() => {
              // setVoidPopup(false);
              setComments("");
              setCommentValidation("");
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default VoidTest;
