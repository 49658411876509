import axios from "axios";
import { handleGetLocal } from "../../services/auth-service";
import { logOut } from "../../components/Logout";
import "jspdf-autotable";
import ExcelJS from "exceljs";

import {
  getAgencyInvoicesTableData,
  getAgencyPaymentGridDatas,
  geteditInvoiceTableData,
  getReceiveAgencyPaymentInvoiceDropdownData,
  setEquimentLogTableData,
  setEquimentTableData,
  setRateEditorTableData,
  setReferralTableData,
  setVendorInventoryTableData,
} from "./Data";
import { setEmCreateInvoiceTableData } from "../Participants/ParticipantSubTabs/G0ToObligation/GoTOObligationSubTabs/Data";

export class MonitoringFunction {
  constructor() {
    this.pathname = handleGetLocal("pathURL");
  }
  async getEquimentData(setLoading, navigate, setEquimentData, setErrorhandling) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/monitoring/get-equipment`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setEquimentData(setEquimentTableData(val?.data?.data));
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }
  async getEquimentLogData(setLoading, navigate, setEquimentLogData, setErrorhandling) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/monitoring/get-equipment-log`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setEquimentLogData(setEquimentLogTableData(val?.data?.data));
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }
  async getInvoiceData(
    setLoading,
    navigate,
    setReceiveInvoiceData,
    setErrorhandling,
    paymentagencyid,
    periodstart,
    periodend
  ) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/monitoring/get-em-invoice?gEntityID=${paymentagencyid}&dStart=${periodstart}&dEnd=${periodend}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setReceiveInvoiceData(setEmCreateInvoiceTableData(val?.data?.data));
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }
  async getVendorInventoryData(setLoading, navigate, setVendorInventoryData, setErrorhandling) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/monitoring/get-vendor-inventory`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setVendorInventoryData(setVendorInventoryTableData(val?.data?.data));
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }
  async getMonitoringReferralData(setLoading, navigate, setVendorInventoryData, setErrorhandling) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/monitoring/get-referral`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setVendorInventoryData(setReferralTableData(val?.data?.data));
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }
  async getVendorDropdownApi(setLoading, navigate, setVendorDropdownData) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=EM Vendor`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setVendorDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async getEquipmentTypeDropdownApi(setLoading, navigate, setEquipmentTypeDropdownData) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=EM Equipment Type`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setEquipmentTypeDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async getEquipmentStatusDropdownApi(setLoading, navigate, setEquipmentStatusDropdownData) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=EM Equipment Status`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setEquipmentStatusDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async getEquipmentLocationDropdownApi(setLoading, navigate, setEquipmentLocationDropdownData) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Reporting Entity`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setEquipmentLocationDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async saveAddEditEquiment(
    setAddEquipmentPopup,
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    refreshApi,
    addEquipmentPopupType,
    data
  ) {
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/monitoring/add-edit-equipment`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setAddEquipmentPopup(false);
          refreshApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: addEquipmentPopupType === "Add" ? "Successfully Added!" : "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: addEquipmentPopupType === "Add" ? "Successfully Added!" : "Successfully Updated!",
              type: "success",
            });
          }, 1900);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }
  async removeListMonitoringReferral(
    setRemoveFromListPopup,
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    refreshApi,
    gReferralId
  ) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/monitoring/remove-from-list?gReferralID=${gReferralId}`, "", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setRemoveFromListPopup(false);
          refreshApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Deleted!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Deleted!",
              type: "success",
            });
          }, 1900);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }
  async linkPersonMonitoringReferral(
    setLinkPersonReferralConfirmPopup,
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    refreshApi,
    data,
    setLinkPersonReferral
  ) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/monitoring/link-to-person`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setLinkPersonReferralConfirmPopup(false);
          setLinkPersonReferral(false);
          refreshApi();
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }
  async createMonitoringReferral(
    setCreateNewPersonPopup,
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    refreshApi,
    gReferralId,
    setNoteConfirmationPopup
  ) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/monitoring/create-new-person?gReferralID=${gReferralId}`, "", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setCreateNewPersonPopup(false);
          setNoteConfirmationPopup(true);
          refreshApi();
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }
  async getRateEditorLogData(setLoading, navigate, setRateEditorData, setErrorhandling) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/rate-editor/get-rate-editor`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setRateEditorData(setRateEditorTableData(val?.data?.data));
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }
  async getRateEditorEntityDropdownApi(setLoading, navigate, setDataSource) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/rate-editor/dropdown`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setDataSource(
          val?.data?.data?.billingAgency?.map((val) => {
            return {
              "Entity Name": val?.entityName,
              Id: val?.entitySequence,
              gEntityId: val?.gEntityId,
            };
          })
        );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async saveAddEditRateEditor(
    setAddNewRatePopup,
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    refreshApi,
    addNewRatePopupType,
    data
  ) {
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/rate-editor/add-edit-rate-editor`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setAddNewRatePopup(false);
          refreshApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: addNewRatePopupType === "Add" ? "Successfully Added!" : "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: addNewRatePopupType === "Add" ? "Successfully Added!" : "Successfully Updated!",
              type: "success",
            });
          }, 1900);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }
  async removeMonitoringRateEditior(
    setAddNewRatePopup,
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    refreshApi,
    gRateId
  ) {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/rate-editor/delete?gRateId=${gRateId}`, "", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setAddNewRatePopup(false);
          refreshApi();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Deleted!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Deleted!",
              type: "success",
            });
          }, 1900);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }
  async getAgencyInvoices(setLoading, navigate, setAgencyInvoicesData, setErrorhandling, stateDAte, endDAte) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/monitoring/get-agency-invoices?StartDate=${stateDAte}&EndDate=${endDAte}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setAgencyInvoicesData(getAgencyInvoicesTableData(val?.data?.data));
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }

  async getInvoiceHeader(setLoading, navigate, setErrorhandling,invoiceNumber,setHeader,setgBillingID) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/receive-agency-payment/get-invoice-data?invoiceNumber=${invoiceNumber}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setHeader(val?.data?.data);
          setgBillingID(val?.data?.data?.gBillingId);
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }

  async getInvoiceGrid(setLoading, navigate, setErrorhandling,gBillingId,setGridData) {
    setLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/receive-agency-payment/agency-payment-grid?gBillingID=${gBillingId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setGridData(geteditInvoiceTableData(val?.data?.data));
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }
  async ProcessScheduleChange(
    setLoading,
    navigate,
    setErrorhandling,
    GReferralId,
    IFormId,
    IEntryNo,
    SFormName,
    SLastName,
    SFirstName,
    SMiddleName,
    DDob,
    SAddress1,
    SAddress2,
    SCity,
    SState,
    SZip,
    SHomePhone,
    SCellPhone,
    DDoe,
    SStatus,
    GPersonId,
    SEntryData,
    SAttachmentName,
    SAttachmentUrl,
    SRecordType,
    SAgent,
    SAgentEmail,
    SAgentPhone,
    SOffenderNumber,
    SLastFirst,
    SGpmEntityName,
    SPrimarySupervisorName,
    SCompany,
    BAttachment,
    setToastConfig,
    toastConfig,
    refreshApi
  ) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/monitoring/referral-process-schedule-change`,
        {
          GReferralId,
          IFormId,
          IEntryNo,
          SFormName,
          SLastName,
          SFirstName,
          SMiddleName,
          DDob,
          SAddress1,
          SAddress2,
          SCity,
          SState,
          SZip,
          SHomePhone,
          SCellPhone,
          DDoe,
          SStatus,
          GPersonId,
          SEntryData,
          SAttachmentName,
          SAttachmentUrl,
          SRecordType,
          SAgent,
          SAgentEmail,
          SAgentPhone,
          SOffenderNumber,
          SLastFirst,
          SGpmEntityName,
          SPrimarySupervisorName,
          SCompany,
          BAttachment,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        if (val?.data?.isSuccess) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1900);
          refreshApi();
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }
  async createPersonVendorInventory(navigate, setLoading, setToastConfig, toastConfig, refreshApi, gInventoryID) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/monitoring/vendor-inventory-create-person?gInventoryID=${gInventoryID}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Added!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Added!",
              type: "success",
            });
          }, 1900);
          refreshApi();
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }
  async saveProcessEndMonitoring(
    navigate,
    setLoading,
    setToastConfig,
    toastConfig,
    refreshApi,
    processEndMonitoringData
  ) {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/monitoring/referral-process-end-monitoring`,
        processEndMonitoringData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Updated!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Updated!",
              type: "success",
            });
          }, 1900);
          refreshApi();
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }
  async getExportInvoiceToExcelApi(setLoading, navigate, setExportInvoiceToExcelData, sInvoiceNumber) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/monitoring/export-invoice-to-excel?sInvoiceNumber=${sInvoiceNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setExportInvoiceToExcelData(
          val?.data?.data?.map((dataItem, i) => ({
            ...dataItem,
            id: i,
          }))
        );
        let data = val?.data?.data;
        if (!data || data.length === 0) {
          console.error("No data to export to Excel");
        } else {
          const mappedData = data;
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Sheet 1");
          const headers = Object.keys(mappedData[0]);
          worksheet.addRow(headers);
          mappedData.forEach((row) => {
            const values = Object.values(row);
            worksheet.addRow(values);
          });
          workbook.xlsx
            .writeBuffer()
            .then((buffer) => {
              const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              const url = URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.href = url;
              link.download = `Invoice ${sInvoiceNumber}.xlsx`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            })
            .catch((error) => {
              console.error("Error generating Excel file:", error);
              
            });
        }
      });
  }
  async getReceiveAgencyPaymentInvoiceDropdown(setLoading, navigate, setDataSource) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/receive-agency-payment/get-agency-payment`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setDataSource(getReceiveAgencyPaymentInvoiceDropdownData(val?.data?.data));
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async getAgencyPaymentGridData(setLoading, navigate, setReceiveAgencyPaymentData, setErrorhandling, gBillingID) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/receive-agency-payment/agency-payment-grid?gBillingID=${gBillingID}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        if (val?.data?.isSuccess) {
          setErrorhandling(false);
          setReceiveAgencyPaymentData(getAgencyPaymentGridDatas(val?.data?.data));
        } else {
          setErrorhandling(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (!error?.response) {
          navigate(`/error/500`);
        } else if (error?.response?.status === 401) {
          logOut();
        } else {
          setErrorhandling(true);
        }
      });
  }
  async getAgencyPaymentCashDrawerDropdownApi(setLoading, navigate, setCashDrawerDropdownData) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=CashDrawer`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setCashDrawerDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
  async saveReceiveAgencyPayment(navigate, setLoading, setToastConfig, toastConfig, data, resetFields) {
    setLoading(true);
    const pathname = handleGetLocal("pathURL");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/receive-agency-payment/receive-agency-payment`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        if (val?.data?.isSuccess) {
          resetFields();
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Successfully Added!",
            type: "success",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Successfully Added!",
              type: "success",
            });
          }, 1900);
        } else {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            message: "Error",
            type: "error",
          });
          setTimeout(() => {
            setToastConfig({
              ...toastConfig,
              isVisible: false,
              message: "Error",
              type: "error",
            });
          }, 1900);
        }
      })
      .catch((val) => {
        setLoading(false);
        if (val?.response?.status === 401) {
          logOut();
        } else {
          if (val?.response?.status) {
            navigate(`/error/500`);
          } else {
            navigate("/error/500");
          }
        }
      });
  }

  async getScramCompanyDropdownApi(navigate, setScramCompanyDropDownData) {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/em/populate-scram-company`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setScramCompanyDropDownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.sName,
                id: val?.gEntityId,
              };
            })
          );
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }

  // async getPaymentAgencyDropdown(setLoading, navigate, setPaymentAgencyDropdownData) {
  //   setLoading(true);
  //   await axios
  //     .get(`${process.env.REACT_APP_API_URL}/api/monitoring/get-company`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  //         Domain: `${this.pathname}`,
  //       },
  //     })
  //     .then((val) => {
  //       val?.data?.data?.length &&
  //         setPaymentAgencyDropdownData(
  //           val?.data?.data?.map((val) => {
  //             return {
  //               name: val?.value,
  //               id: val?.key,
  //             };
  //           })
  //         );
  //       setLoading(false);
  //     })
  //     .catch((val) => {
  //       if (!val?.response) {
  //         navigate(`/error/500`);
  //       } else if (val?.response?.status === 401) {
  //         logOut();
  //       }
  //     });
  // }

  async getPaymentAgencyDropdown(setLoading, navigate, setPaymentAgencyDropdownData) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Company Entity`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setPaymentAgencyDropdownData(
            val?.data?.data?.map((vall) => {
              return {
                name: vall.value,
                id: vall.id ? vall.id : "00000000-0000-0000-0000-000000000000",
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  async getBillingCompanyDropdown(setLoading, navigate, setBillingCompanyDropdownData, paymentAgencyId) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Billing Agency By Company&supplementalData=${paymentAgencyId}`,
         {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setBillingCompanyDropdownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
        setLoading(false);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }

  async getAgencyDropdownApi(navigate, setAgencyDropDownData) {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/obligations/generic-parameter-lookup?parameter=Court`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data?.length &&
          setAgencyDropDownData(
            val?.data?.data?.map((val) => {
              return {
                name: val?.value,
                id: val?.id,
              };
            })
          );
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }

  async getClientsDropDownApi(gCompanyID, gAgencyID, navigate, setClientsDropDownData) {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/em/populate-clients-list?gCompanyId=${gCompanyID}&gAgencyId=${gAgencyID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            Domain: `${this.pathname}`,
          },
        }
      )
      .then((val) => {
        val?.data?.data?.length && setClientsDropDownData(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }

  async getClientsDataApi(gClientsId, gCompanyId, navigate, setClientsData) {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/em/load-clients?gClientsId=${gClientsId}&gCompanyID=${gCompanyId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        val?.data?.data && setClientsData(val?.data?.data);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }

  async getMissingEMRates(setData, navigate, setLoading) {
    setLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/monitoring/missing-em-rates`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${this.pathname}`,
        },
      })
      .then((val) => {
        setLoading(false);
        setData(val?.data?.data?.rates, val?.data?.data?.obligations);
      })
      .catch((val) => {
        setLoading(false);
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  }
}
