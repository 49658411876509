import { useNavigate, useParams } from "react-router-dom";
import AdminCrumbs from "../components/AdminCrumbs";
import "./feeSchedule.css";
import { useEffect, useState } from "react";
import { ContextMenu, DateBox, DropDownBox, LoadPanel, Popup } from "devextreme-react";
import { Toster } from "../../../components/Toster/Toster";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAccessData } from "../../../utils/getAccessPermission";
import { DynamicTabb } from "../../../components/DynamicTab/DynamicTab";
import { setDynamicTab } from "../../../reducer/HomePageReducer";
import DataGrid, { Column, HeaderFilter, Pager } from "devextreme-react/cjs/data-grid";
import ThreeDotMenu from "../../../components/Common/Icons/ThreeDotMenu";
import { ObligationFeeListFunction } from "./ObligationFeeListFunction";
import { ExportFile } from "../../../components/ExportGride/ExportFile";
import { Table2 } from "../../../components/Table2/Table2";

const ObligationFeeList = () => {
  const apiClass = new ObligationFeeListFunction();
  const [erorhandling, seterorhandling] = useState(false);
  const { userAccessData } = useSelector((store) => store.Home);
  const [viewAccess, setViewAccess] = useState(true);
  const [editAccess, setEditAccess] = useState(false);
  const [addAccess, setAddAccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [obligationFeeList, setObligationFeeList] = useState([]);
  const [exportPopupOpen, setexportPopupOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [obligationFeeListRowData, setobligationFeeListRowData] = useState([]);

  const getUserAccessPermissions = () => {
    const accessData = getAccessData(userAccessData, "CO2");
    setAddAccess(accessData?.Add);
    setEditAccess(accessData?.Edit);
    setViewAccess(accessData?.View);
  };

  const Tablecoloum = [
    { title:"", moreoption:true, customwidth:"50px"},
    { title: "Fee Code" },
    { title: "Fee Name"},
    { title: "Amount", currency: true },
    { title: "Pay Priority"},
    { title: "Category Name" },
    // { title: "Type" },
    { title: "Bank Account"},
    { title: "Obligation Type"},
    { title: "Disburse Method"},
    { title: "Disburse Entity", customwidth:"300px" },
    { title: "Fee Schedule Type"},
  ];
  
  const setTableData = (data) => {
    const arr = [];
    data.map((val) => {
      arr.push({
        ID: val?.gFeeId,
        "Fee Code": val?.sFeeCode,
        "Fee Name": val?.sFeeName,
        Amount: val?.fAmount,
        "Pay Priority": val?.iPayPriority,
        "Category Name": val.sFeeScheduleCategoryName,
        "Type": val.sObligationTypeName,
        "Bank Account": val.sBankAccount,
        "Obligation Type": val?.sObligationTypeName,
        "Disburse Method": val?.sDisburseMethodName,
        "Disburse Entity": val?.sDisburseEntityName, 
        "Fee Schedule Type":val?.sFeeScheduleTypeName,    
        obj: val   
      });
    });
    return arr;
  };

  useEffect(() => {
    apiClass.getObligationFeeList(setLoading, seterorhandling, navigate, setObligationFeeList,setTableData,setdata);
  }, []);

  return (
    <>
      <LoadPanel shadingColor="true" visible={loading} delay={10} />
      <Toster message={toastConfig.message} type={toastConfig.type} visible={toastConfig.isVisible} />
      <AdminCrumbs title={"Obligation Fee List"} user={"Data"} />
      <div className="fee-schedule-container">
        <DynamicTabb />
        {viewAccess ? <div className="fee-schedule-content">
          <p className="page-info-number">OF.2</p>
          <div className="fee-schedule-buttons"
          >
            <div className="buttons">
              <button
                className="btn"
                onClick={() => {
                  setexportPopupOpen(true);
                }}
              >
                Export
                <svg
                  className="svg-blue-dark"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                >
                  <path
                    d="M11.59 6H10V1C10 0.45 9.55 0 9 0H5C4.45 0 4 0.45 4 1V6H2.41C1.52 6 1.07 7.08 1.7 7.71L6.29 12.3C6.68 12.69 7.31 12.69 7.7 12.3L12.29 7.71C12.92 7.08 12.48 6 11.59 6ZM0 16C0 16.55 0.45 17 1 17H13C13.55 17 14 16.55 14 16C14 15.45 13.55 15 13 15H1C0.45 15 0 15.45 0 16Z"
                    fill="#424167"
                  />
                </svg>
              </button>
              <button
                className="btn"
                onClick={() => {
                  apiClass.getObligationFeeList(setLoading, seterorhandling, navigate, setObligationFeeList,setTableData,setdata);
                }}
              >
                Refresh
                <svg
                  className="svg-blue-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_6247_15093)">
                    <path
                      d="M17.6493 6.34902C16.0193 4.71902 13.7093 3.77902 11.1693 4.03902C7.49929 4.40902 4.47929 7.38902 4.06929 11.059C3.51929 15.909 7.26929 19.999 11.9993 19.999C15.1893 19.999 17.9293 18.129 19.2093 15.439C19.5293 14.769 19.0493 13.999 18.3093 13.999C17.9393 13.999 17.5893 14.199 17.4293 14.529C16.2993 16.959 13.5893 18.499 10.6293 17.839C8.40929 17.349 6.61929 15.539 6.14929 13.319C5.30929 9.43902 8.25929 5.99902 11.9993 5.99902C13.6593 5.99902 15.1393 6.68902 16.2193 7.77902L14.7093 9.28902C14.0793 9.91902 14.5193 10.999 15.4093 10.999H18.9993C19.5493 10.999 19.9993 10.549 19.9993 9.99902V6.40902C19.9993 5.51902 18.9193 5.06902 18.2893 5.69902L17.6493 6.34902Z"
                      fill="#424167"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6247_15093">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
        </div>
          :
          "No CO.2 view access"}
        <div className="Detail_Contact_Tab">
          <div style={{ marginTop: "1.5%" }}>
            <Table2
              Tablecoloum={Tablecoloum}
              tableRow={data}
              load={loading}
              coloumWidthText={"ObligationFeeList"}
              height={"auto"}
              editTable={false}
              mandatoryColoum={"Status"}
              showTotalBlock={false}
              exportInDifferentFormate={true}
              exportPopupOpen={exportPopupOpen}
              setexportPopupOpen={setexportPopupOpen}
              exportwidth={16.3}
              exporttitle={"obligation fee list"}
              obligationfeeListMenu={true}
              obligationFeeListRowData={obligationFeeListRowData}
              setobligationFeeListRowData={setobligationFeeListRowData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ObligationFeeList;
