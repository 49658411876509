import { DropDownBox } from "devextreme-react";
import DataGrid, { Column, FilterRow, Scrolling, Selection } from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const MultipalDropdown = ({
  dataSource,
  field,
  fieldname,
  setField,
  coloum,
  reset,
  onChange,
  IsChangefunction,
  disabled,
  mainFieldName,
  showFilter,
}) => {
  const [DataSource, setDataSource] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchedDataSource = dataSource?.map((vall) => {
      let obj = {};
      coloum.map((val, i) => {
        obj[val?.key] = vall[val.pair];
      });
      return obj;
    });
    setDataSource(fetchedDataSource);
  }, [dataSource]);

  return (
    <>
      <DropDownBox
        disabled={disabled}
        style={{ marginTop: "3.5%" }}
        dataSource={DataSource}
        value={field?.[fieldname].name}
        valueExpr={mainFieldName}
        displayExpr={mainFieldName}
        contentRender={() => (
          <DataGrid
            dataSource={DataSource}
            hoverStateEnabled={true}
            paging={true}
            height={300}
            onRowClick={(e) => {
              if (reset) {
                reset("");
              }
              if (onChange) {
                dispatch(IsChangefunction(true));
              }
              setField({
                ...field,
                [fieldname]: {
                  name: e?.data?.[mainFieldName],
                  id: e?.data?.id,
                },
              });
            }}
          >
            <Scrolling mode="virtual" /> {/* Enable virtual scrolling */}
            <Selection mode="single" />
            {showFilter !== false && <FilterRow visible={true} />}

            {coloum.map((val) => {
              return val.key !== "id" && <Column dataField={val.key} caption={val.key} width={"auto"} />;
            })}
          </DataGrid>
        )}
      />
    </>
  );
};
