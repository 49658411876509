import React from 'react';
import logo from "./../../../src/assets/images/logo.png";

const About = ({setAboutPopup}) => {
  const buildNumber = process.env.REACT_APP_BUILD_NUMBER;
  const buildTimeStamp = process.env.REACT_APP_BUILD_TIMESTAMP;
  return (
    <div style={styles.dialogContainer}>
      {/* Header */}
      <div style={styles.header}>
      <div style={{marginBottom: '26px'}}>
            <img
              style={{width: '100px'}}
              src={logo}
              alt=""
            />
          </div>
      </div>

      {/* Content */}
      <div style={styles.content}>
        <p style={styles.buildText}>Build {buildNumber}</p>
        <p style={styles.dateText}>{buildTimeStamp}</p>
        <p style={styles.copyrightText}>© Pronto Consulting, LLC</p>
      </div>

      {/* Footer */}
      <div style={styles.footer}>
        <button style={styles.okButton} onClick={() => setAboutPopup(false)}>OK</button>
      </div>
    </div>
  );
};

const styles = {
  dialogContainer: {
    width: '300px',
    // border: '2px solid black',
    borderRadius: '6px',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  logo: {
    width: '50px',
    height: '50px',
    marginBottom: '8px',
  },
  headerText: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#FF6600',
  },
  subHeaderText: {
    fontSize: '12px',
    color: '#808080',
    fontWeight: 'normal',
  },
  content: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  buildText: {
    fontSize: '14px',
    margin: '0',
  },
  dateText: {
    fontSize: '14px',
    margin: '0',
    marginTop: '4px',
  },
  copyrightText: {
    fontSize: '12px',
    marginTop: '16px',
    color: '#808080',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  okButton: {
    padding: '8px 16px',
    border: '2px solid black',
    borderRadius: '4px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    fontWeight: 'bold',
  }
};

export default About;
