const moment = require('moment');

const convertDateFormat = (dateString) => {
  if (dateString) {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-US");
  } else {
    return "";
  }
};

export const schedulingDateRanges = [
  { name: "Today" },
  { name: "Yesterday" },
  { name: "This Week" },
  { name: "Last Week" },
  { name: "This Month" },
  { name: "Last Month" },
  { name: "This Quarter" },
  { name: "Last Quarter" },
  { name: "This Year" },
  { name: "Last Year" },
];

export const disbursementObligationListTableTitle = [
  { title: "Check Date", date: true },
  { title: "Bank Account" },
  { title: "Check Number" },
  { title: "Memo" },
  { title: "Person" },
  { title: "Offender #" },
  { title: "Fee Code" },
  { title: "Amount in Check", currency: true },
  { title: "Obligation Amount", currency: true },
  { title: "Amount on Hand", currency: true },
  { title: "Amount to Disburse", currency: true },
  { title: "Amount Disbursed", currency: true },
  { title: "Recipient Balance", currency: true },
];
export const disbursementObligationCheckListTableTitle = [
  { title: "Check Date", date: true },
  { title: "Batch Number" },
  { title: "Bank Account" },
  { title: "Check Number" },
  { title: "Amount", currency: true },
  { title: "Entity / Receipt" },
  { title: "Memo" },
  { title: "Created", date: true },
  { title: "Obligation Count" },
  { title: "Void", bool: true },
];

export const transactionListTableTitle = [
  {moreoption:true, customwidth:"50px"},
  { title: "Name"},
  { title: "Offender #" },
  { title: "Offender # PIN" },
  { title: "Payment Method" },
  { title: "Cash Drawer" },
  { title: "Receipt Date" },
  { title: "Date of Entry" },
  { title: "Docket"},
  { title: "Reference"},
  { title: "Receipt Number"},
  { title: "Pay Reference"},
  { title: "Amount", currency: true },
  { title: "Fee Code"},
  { title: "Fee Description"},
  { title: "G/L Account"},
  { title: "Entered By"},
  { title: "Settled", bool:true},
];

export const receiptListTableTitle = [
  { moreoption: true, customwidth: 50 },
  { title: "Select", bool: true, receiptListBool: true, customwidth: 50 },
  { title: "Name"},
  { title: "Offender #" },
  { title: "Offender # PIN" },
  { title: "Cash Drawer" },
  { title: "Receipt #"},
  { title: "Payment Method" },
  { title: "Amount", currency: true },
  { title: "Receipt Date" },
  { title: "Date of Entry" },
  { title: "Entered By"},
  { title: "Settled"},
  { title: "Deposit Ref"},
  { title: "Bank Account"},
  { title: "Batch/Rcpt"}
];

export const setDisbursementChecklistTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Check Date": convertDateFormat(val?.dCheck),
      "Batch Number": val?.sBatchNumber,
      "Bank Account": val?.sBankAccountNumber,
      "Check Number": val?.iCheckNumber,
      Amount: val?.fAmount,
      "Entity / Receipt": val?.sPayToTheOrderOf,
      Memo: val?.sMemo,
      Created: convertDateFormat(val?.dDoe),
      "Obligation Count": val?.iObligationCount,
      Void: val?.bVoid,
      gEntryUserId: val?.gEntryUserId,
      gEntityId: val?.gEntityId,
      gDisbursementCheckId: val?.gDisbursementCheckId,
      gDisbursementCheckBatchId: val?.gDisbursementCheckBatchId,
      ID: i,
    });
  });
  return arr;
};
export const setDisbursementCheckObligationlistTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Check Date": convertDateFormat(val?.dCheck),
      "Bank Account": val?.sBankAccountNumber,
      "Check Number": val?.iCheckNumber,
      "Entity / Receipt": val?.sPayToTheOrderOf,
      Memo: val?.sMemo,
      Person: val?.sPersonNameLastFirst,
      "Offender #": val?.sOffenderNumber,
      "Fee Code": val?.sLocalLegalCodeFull,
      "Amount in Check": val?.fDisbursementAmount,
      "Obligation Amount": val?.fObligationAmount,
      "Amount on Hand": val?.fBalanceEligible,
      "Amount to Disburse": val?.fAmountToDisburse,
      "Amount Disbursed": val?.fAmountDisbursed,
      "Recipient Balance": val?.fBalanceRecipient,
      gDisbursementCheckBatchId: val?.gDisbursementCheckBatchId,
      gObligationID: val?.gObligationID,
      gPersonID: val?.gPersonID,
      ID: i,
    });
  });
  return arr;
};

export const setTransactionlistTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      "Name": val?.sPersonNameLastFirst,
      "Offender #": val?.sOffenderNumber,
      "Offender # PIN": val?.sOffenderNumberPIN,
      "Payment Method": val?.sPaymentMethodName,
      "Cash Drawer": val?.sCashDrawerName,
      "Receipt Date": moment(val?.dReceipt).format('MM-DD-YYYY'),
      "Date of Entry": moment(val?.dDOE).format('MM-DD-YYYY'),
      "Docket": val?.sDocketNumber,
      "Reference": val?.sReferenceNumber,
      "Receipt Number": val?.sReceiptNumber,
      "Pay Reference": val?.sDepositReference,
      "Amount": val?.fAmount,
      "Fee Code": val?.sLocalLegalCodeFull,
      "Fee Description": val?.sLocalViolationDescription,
      "G/L Account": val?.sAccountNumber,
      "Entered By": val?.sEntryUserName,
      Settled: val?.bSettled,
      gDocketID:val?.gDocketID,
      gObligationID:val?.gObligationID,
      gPersonID:val?.gPersonID,
      iCourtCounts:val?.iCourtCounts,
      ID: i,
    });
  });
  return arr;
};

export const setReceiptlistTableData = (data) => {
  const arr = [];
  data.map((val, i) => {
    arr.push({
      ID: i,
      "Name": val?.sPersonNameLastFirst,
      "Offender #": val?.sOffenderNumber,
      "Offender # PIN": val?.sOffenderNumberPIN,
      "Payment Method": val?.sPaymentMethodName,
      "Cash Drawer": val?.sCashDrawerName,
      "Receipt Date": moment(val?.dReceipt).format('MM-DD-YYYY'),
      "Date of Entry": moment(val?.dDoe).format('MM-DD-YYYY'),
      "Receipt #": val?.sReceiptNumber,
      "Amount": val?.fAmount,
      "Entered By": val?.sEntryUserName,
      Settled: val?.bSettled,
      "Deposit Ref": "",
      "Deposit Date": "",
      "Bank Account": val?.sBankAccountName,
      "Batch/Rcpt": "",
      gPersonId: val?.gPersonId,
      gPaymentId: val?.gPaymentId,
      sBankAccountName: val?.sBankAccountName,
      obj: val
    });
  });
  return arr;
};