import React, { useEffect, useState } from "react";
import { ColumnDropdown } from "../../../../../../../components/Dropdownn";
import { DateBox, NumberBox, ScrollView } from "devextreme-react";
import { GoTOObligationFunction } from "../../../GoTOObligationFunction";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const DropDown = ({ dropDownData, setfields, fields, fieldNmae, reset, disabled }) => {
  return (
    <>
      <ColumnDropdown
        data={dropDownData}
        setfields={setfields}
        fields={fields}
        fieldNmae={fieldNmae}
        value={fields[fieldNmae]?.name}
        reset={reset}
        disabled={disabled}
      />
    </>
  );
};

const AddEditEMPeriodPopup = ({
  addEditEMPeriodPopup,
  setAddEditEMPeriodPopup,
  typeTab,
  rowLevelData,
  gObligationID,
  emTypeDropdownData,
  frequencyDropdownData,
  setLoading,
  setToastConfig,
  toastConfig,
  refreshApi,
  gotooblogationValue,
  paymentTypeDropdownData,
  obligationDropdownData,
  equipmentTypeDropdownData,
  isAccrual,
}) => {
  const [fields, setFields] = useState({
    primaryEmType: { name: "", id: "" },
    periodEmStart: "",
    periodEmEnd: "",
    obligationEmType: { name: "", id: "" },
    obligation: { name: "", id: "" },
    obligationRateStartDate: "",
    obligationRateEndDate: "",
    obligationFrequency: { name: "", id: "" },
    rate: "",
    paymentTypeDropdown: { name: "", id: "" },
    equipmentTypeDropdown: { name: "", id: "" },
  });

  const [days, setDays] = useState(0);

  const apiClass = new GoTOObligationFunction();
  const navigate = useNavigate();
  const [emTypeErrorValidation, setEmTypeErrorValidation] = useState("");
  const [obligationFrequencyErrorValidation, setObligationFrequencyErrorValidation] = useState("");
  const [rateErrorValidation, setRateErrorValidation] = useState("");
  const [paymentTypeErrorValidation, setPaymentTypeErrorValidation] = useState("");
  const [equipmentTypeErrorValidation, setEquipmentTypeErrorValidation] = useState("");
  const [startDateErrorValidation, setstartDateErrorValidation] = useState("");
  const [obligationErrorValidation, setObligationErrorValidation] = useState("");

  const { dockerValue } = useSelector((store) => store.participateNotes);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "rate") {
      setRateErrorValidation("");
    }
  };

  useEffect(() => {
    setEmTypeErrorValidation("");
    setObligationFrequencyErrorValidation("");
    setRateErrorValidation("");
    setPaymentTypeErrorValidation("");
    setstartDateErrorValidation("");
    setObligationErrorValidation("");
    setEquipmentTypeErrorValidation("");
    if (typeTab === "Add") {
      const findObligationkey = obligationDropdownData?.find(
        (item) => item?.id?.toLowerCase() === gObligationID && item?.type === "Accrual"
      );
      const defaultFrequency = frequencyDropdownData.find((item) => {
        if (item.name === "Daily") return true;
      });
      const defaultPaymentType = paymentTypeDropdownData.find((item) => {
        if (item.name === "Offender") return true;
      });
      setFields({
        primaryEmType: { name: "", id: "" },
        periodEmStart: "",
        periodEmEnd: "",
        obligationEmType: { name: "", id: "" },
        obligation: {
          name: findObligationkey?.name,
          id: findObligationkey?.id,
        },
        obligationRateStartDate: "",
        obligationRateEndDate: "",
        obligationFrequency: defaultFrequency || { name: "", id: "" },
        rate: "",
        paymentTypeDropdown: defaultPaymentType || { name: "", id: "" },
        equipmentTypeDropdown: { name: "", id: "" },
      });
    } else {
      const equipmentType = equipmentTypeDropdownData.find(
        (x) => x.id?.toLowerCase() === rowLevelData?.gEquipmentTypeId?.toLowerCase()
      );
      setFields((prev) => ({
        ...prev,
        primaryEmType: {
          name: rowLevelData?.[`Monitoring Type`],
          id: rowLevelData?.gEmTypeId,
        },
        periodEmStart: rowLevelData?.dStart,
        periodEmEnd: rowLevelData?.dEnd,
        obligationEmType: {
          name: rowLevelData?.[`Monitoring Type`],
          id: rowLevelData.gEmTypeId,
        },
        obligationRateStartDate: rowLevelData?.[`Rate Start`],
        obligationRateEndDate: rowLevelData?.[`Rate End`],
        obligationFrequency: {
          name: rowLevelData.Frequency,
          id: rowLevelData.gRateFrequencyId,
        },
        rate: rowLevelData.Amount,
        paymentTypeDropdown: {
          name: rowLevelData?.sPaymentTypeName,
          id: rowLevelData?.sPaymentTypeId,
        },
        obligation: {
          name: rowLevelData?.Obligation,
          id: rowLevelData?.gObligationId,
        },
        equipmentTypeDropdown: { ...equipmentType },
      }));
    }
  }, [addEditEMPeriodPopup, obligationDropdownData, frequencyDropdownData, paymentTypeDropdownData]);

  const getDocketId = () => {
    if (typeTab === "Add") {
      if (gotooblogationValue && gotooblogationValue.gDocketId) {
        return gotooblogationValue.gDocketId;
      } else if (dockerValue && dockerValue.getTopDynamicDocketid) {
        return dockerValue.getTopDynamicDocketid;
      } else if (gotooblogationValue && gotooblogationValue.getTopDynamicDocketid) {
        return gotooblogationValue.getTopDynamicDocketid;
      }
    } else {
      if (rowLevelData && rowLevelData.gDocketId) {
        return rowLevelData.gDocketId;
      }
    }
    return null;
  };

  const apiPayload = {
    GPeriodObligationId: typeTab === "Add" ? "00000000-0000-0000-0000-000000000000" : rowLevelData?.gPeriodObligationId,
    GEmPeriodId: typeTab === "Add" ? rowLevelData?.gEmPeriodId : rowLevelData?.gPeriodId,
    GDocketId: getDocketId(),
    GEMTypeId: fields?.obligationEmType.id, //mandatory filed
    GObligationId: typeTab === "Add" ? gObligationID : fields.obligation.id,
    FRate: fields.rate, //mandatory filed
    GRateFrequencyId: fields.obligationFrequency.id, //mandatory filed
    GEntryUserId: localStorage.getItem("gUserId"),
    DStart: fields.periodEmStart,
    DEnd: fields.periodEmEnd,
    DRateStart: fields.obligationRateStartDate,
    DRateEnd: fields.obligationRateEndDate,
    GEmPaymentTypeId: fields.paymentTypeDropdown.id,
    GEmEquipmentTypeId: fields.equipmentTypeDropdown.id,
  };
  const handleSave = () => {
    let isValid = true;
    if (fields.obligationEmType.id === "") {
      isValid = false;
      setEmTypeErrorValidation("Please Select EM Type");
    }
    if (fields.obligation.id === "" || !fields.obligation.id) {
      isValid = false;
      setObligationErrorValidation("Please Select Obligation");
    }
    if (fields.obligationFrequency.id === "") {
      isValid = false;
      setObligationFrequencyErrorValidation("Please Select Frequency");
    }
    if (fields.paymentTypeDropdown.id === "") {
      isValid = false;
      setPaymentTypeErrorValidation("Please Select Payment Type");
    }
    if (!fields.rate) {
      isValid = false;
      setRateErrorValidation("Please Enter Valid Amount");
    }
    if (fields.periodEmStart === "" || fields.obligationRateStartDate === "") {
      isValid = false;
      setstartDateErrorValidation("Please Enter Start Date");
    }
    if (fields.equipmentTypeDropdown.id === "" && isAccrual) {
      isValid = false;
      setEquipmentTypeErrorValidation("Please Select Equipment Type");
    }
    if (isValid) {
      if (getDocketId() === null) {
        console.log("Something went wrong with gDocketId");
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: "Something went wrong with gDocketId",
          type: "error",
        });
        setTimeout(() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
          });
        }, 1900);
        return;
      }
      apiClass.addEditPeriodObligation(
        navigate,
        setLoading,
        setToastConfig,
        toastConfig,
        apiPayload,
        typeTab,
        setAddEditEMPeriodPopup,
        refreshApi
      );
    }
  };

  return (
    <>
      <div className="add-edit-emperiod-popup">
        <div className="popup_header">
          <div className="popup_header_title">
            <div class="popup_header_title_data">
              <p>Monitoring Periods</p>
            </div>
            <p class="popup_header_title_para">EM.5</p>
          </div>
          <svg
            onClick={() => {
              setAddEditEMPeriodPopup(false);
            }}
            style={{
              cursor: "pointer",
            }}
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72406 0.282539C9.43156 -0.00996077 8.95906 -0.00996077 8.66656 0.282539L4.99906 3.94254L1.33156 0.275039C1.03906 -0.0174609 0.566563 -0.0174609 0.274063 0.275039C-0.0184375 0.567539 -0.0184375 1.04004 0.274063 1.33254L3.94156 5.00004L0.274063 8.66754C-0.0184375 8.96004 -0.0184375 9.43254 0.274063 9.72504C0.566563 10.0175 1.03906 10.0175 1.33156 9.72504L4.99906 6.05754L8.66656 9.72504C8.95906 10.0175 9.43156 10.0175 9.72406 9.72504C10.0166 9.43254 10.0166 8.96004 9.72406 8.66754L6.05656 5.00004L9.72406 1.33254C10.0091 1.04754 10.0091 0.567539 9.72406 0.282539Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="add-edit-emperiod-container">
          <p className="container-fields-heading">EM Period</p>
          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                EM Type <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"obligationEmType"}
                dropDownData={emTypeDropdownData}
                reset={setEmTypeErrorValidation}
              />
              {emTypeErrorValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "175px" }}>
                  {emTypeErrorValidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                EM Period Start <span className="marks_mandatary">*</span>
              </p>
              <DateBox
                useMaskBehavior={true}
                value={fields.periodEmStart}
                onValueChange={(e) => {
                  if (e) {
                    setFields((prev) => ({
                      ...prev,
                      periodEmStart: e ? e : null,
                      obligationRateStartDate: e ? e : null,
                    }));
                    setstartDateErrorValidation("");
                  }
                }}
              />
              {startDateErrorValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "265px" }}>
                  {startDateErrorValidation}
                </p>
              )}
            </div>
            <div style={{ display: "flex" }}>
              <NumberBox
                width={"15%"}
                onKeyDown={(e) => {
                  const { event } = e;
                  const str = event.key || String.fromCharCode(event.which);
                  if (/^[.,e]$/.test(str)) {
                    event.preventDefault();
                  }
                }}
                defaultValue={0}
                onValueChange={(e) => {
                  setDays(e);
                }}
                isValid={true}
                value={days}
              />
              <button
                onClick={() => {
                  if (!fields || !fields.periodEmStart || !days) {
                    return;
                  }

                  const startDate = new Date(fields.periodEmStart);
                  const daysToAdd = parseInt(days, 10);

                  if (isNaN(daysToAdd)) {
                    return;
                  }

                  const endDate = new Date(startDate);
                  endDate.setDate(startDate.getDate() + daysToAdd);
                  const formattedEndDate = endDate.toISOString().split("T")[0];

                  setFields({
                    ...fields,
                    periodEmEnd: formattedEndDate,
                    obligationRateEndDate: formattedEndDate,
                  });
                }}
                style={{ width: "auto", marginLeft: "10px" }}
              >
                Add Days
              </button>
              <button
                onClick={() => {
                  if (!fields || !fields.periodEmStart || !days) {
                    return;
                  }

                  const startDate = new Date(fields.periodEmStart);
                  const monthsToAdd = parseInt(days, 10);

                  if (isNaN(monthsToAdd)) {
                    return;
                  }

                  const endDate = new Date(startDate);
                  endDate.setMonth(startDate.getMonth() + monthsToAdd);
                  const formattedEndDate = endDate.toISOString().split("T")[0];

                  setFields({
                    ...fields,
                    periodEmEnd: formattedEndDate,
                    obligationRateEndDate: formattedEndDate,
                  });
                }}
                style={{ width: "auto", marginLeft: "10px" }}
              >
                Add Months
              </button>
            </div>
          </div>

          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">EM Period End</p>
              <DateBox
                // useMaskBehavior={true}
                value={fields.periodEmEnd}
                onValueChange={(e) => {
                  if (e) {
                    setFields((prev) => ({
                      ...prev,
                      periodEmEnd: e ? e : null,
                      obligationRateEndDate: e ? e : null,
                    }));
                  }
                }}
              />
            </div>
          </div>
          <p className="container-fields-heading">EM Obligation</p>
          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Obligation <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"obligation"}
                // dropDownData={obligationDropdownData.filter((obligation) => obligation?.type === "Accrual")}
                dropDownData={obligationDropdownData.filter((obligation) => obligation?.id === gObligationID)}
                // disabled={typeTab === "Add"}
                reset={setObligationErrorValidation}
              />
              {obligationErrorValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "478px" }}>
                  {obligationErrorValidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Payment Type <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"paymentTypeDropdown"}
                dropDownData={paymentTypeDropdownData}
                reset={setPaymentTypeErrorValidation}
              />
              {paymentTypeErrorValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "389px" }}>
                  {paymentTypeErrorValidation}
                </p>
              )}
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Rate Start Date <span className="marks_mandatary">*</span>
              </p>
              <DateBox
                useMaskBehavior={true}
                value={fields.obligationRateStartDate}
                onValueChange={(e) => {
                  if (e) {
                    setFields((prev) => ({
                      ...prev,
                      obligationRateStartDate: e ? e : null,
                    }));
                  }
                  setstartDateErrorValidation("");
                }}
              />
              {startDateErrorValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "565px" }}>
                  {startDateErrorValidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">Rate End Date</p>
              <DateBox
                value={fields.obligationRateEndDate}
                onValueChange={(e) => {
                  if (e) {
                    setFields((prev) => ({
                      ...prev,
                      obligationRateEndDate: e ? e : null,
                    }));
                  } else {
                    setFields((prev) => ({
                      ...prev,
                      obligationRateEndDate: null
                    }));
                  }
                }}
              />
            </div>
          </div>
          <div className="edit_container_first">
            <div className="edit_content_flex">
              <p className="edit_container_label">
                Rate <span className="marks_mandatary">*</span>
              </p>
              <input
                id="rate"
                name="rate"
                type="number"
                placeholder="Enter Rate"
                className="edit_container_input"
                value={fields?.rate}
                onChange={handleInputChange}
                style={{ width: "310px" }}
              />
              {rateErrorValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "655px" }}>
                  {rateErrorValidation}
                </p>
              )}
            </div>
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Frequency <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"obligationFrequency"}
                dropDownData={frequencyDropdownData}
                reset={setObligationFrequencyErrorValidation}
              />
              {obligationFrequencyErrorValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "567px" }}>
                  {obligationFrequencyErrorValidation}
                </p>
              )}
            </div>
          </div>

          <div className="edit_container_first">
            <div className="edit_content_flex edit_content_space">
              <p className="edit_container_label">
                Equipment Type <span className="marks_mandatary">*</span>
              </p>
              <DropDown
                setfields={setFields}
                fields={fields}
                fieldNmae={"equipmentTypeDropdown"}
                dropDownData={equipmentTypeDropdownData}
                reset={setEquipmentTypeErrorValidation}
              />
              {equipmentTypeErrorValidation && (
                <p className="error_validation" style={{ position: "absolute", top: "750px" }}>
                  {equipmentTypeErrorValidation}
                </p>
              )}
            </div>
          </div>

          <div className="popup_buttons">
            <button className="btn primary_btn" onClick={handleSave}>
              Save
            </button>
            <button
              className="btn"
              onClick={() => {
                setAddEditEMPeriodPopup(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditEMPeriodPopup;
