import React, { useEffect, useRef, useState } from "react";
import { Groups } from "./components/Groups";
import { Entities } from "./components/Entities";
import "./style/userData.css";
import { getAuthToken, handleGetLocal } from "../../services/auth-service";
import httpService from "../../services/http-service";
import { LoadPanel, TextBox, Toast } from "devextreme-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AddAuthenticationPhone, removeAdminTab, setAdminSavePopup } from "../../reducer/AdminReadReducer";
import { useSelector } from "react-redux";
import axios from "axios";
import image5 from "../../assets/images/image5.jpg";
import errorIcon from "../../assets/images/errorIcon.svg";
import { logOut } from "../../components/Logout";
import { set_user_img, set_user_name } from "../../reducer/HomePageReducer";
import { UserAccess } from "./components/UserAccess";

const smallTextStyle = {
  fontSize: "10px",
  lineHeight: "13px",
};

const UserData = ({ type }) => {
  console.log('type-', type);
  // #region State variables
  const [activeTab, setActiveTab] = useState("tab3");
  const [imageUploadValidation, setimageUploadValidation] = useState("");
  const [image, setImage] = useState();
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({
    image: "",
    userID: "",
    firstName: "",
    middleName: "",
    lastName: "",
    password: "",
    email: "",
    employeeID: "",
    roleId: "",
    userRole: "Select",
    activeUser: false,
    sendNotification: false,
    resourceUser: false,
    courtUser: false,
    addressUser: "",
    cityUser: "",
    stateUser: "",
    zipUser: "",
    enableAuth: false,
    twoFactorAuthenticationEmail: null,
    twoFactorAuthenticationPhone: null,
  });
  const [isEditable, setisEditable] = useState(true);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gropuData, setGroupData] = useState([]);
  const [entityData, setEntityData] = useState([]);
  const [entityResult, setEntityResult] = useState([]);
  const [groupResult, setGroupResult] = useState([]);
  const [userAccessData, setUserAccessData] = useState([]);

  const [saveUser, setSaveUser] = useState(false);

  const [goodToSaveUser, setGoodToSaveUser] = useState(false);

  // #endregion

  const [eyeTogel, seteyesTogel] = useState({
    password: false,
  });
  const [fieldValidation, setFieldValidation] = useState({
    password: "",
  });

  const [isPasswordCorrect, setIsPasswordCorrect] = useState({
    upper: false,
    lower: false,
    special: false,
    numeric: false,
    isValidPassword: false,
  });

  var passPattern = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})");
  const upperCaseRegex = new RegExp("[A-Z]");
  const lowerCaseRegex = new RegExp("[a-z]");
  const specialCharRegex = new RegExp("[!@#%^&$*()_+\\-=[\\]{}|;':\",./<>?~`]");
  const numericRegex = new RegExp(`[0-9]`);

  const CheckPasswordValidation = (event) => {
    setIsPasswordCorrect({
      upper: upperCaseRegex.test(event.target.value) ? true : false,
      lower: lowerCaseRegex.test(event.target.value) ? true : false,
      special: specialCharRegex.test(event.target.value) ? true : false,
      numeric: numericRegex.test(event.target.value) ? true : false,
      isValidPassword: passPattern.test(event.target.value) ? true : false,
    });
  };

  const token = getAuthToken();
  const params = useParams();
  let domainName = (url) => url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
  let domailURL = window.location.href;
  let pathname = domainName(domailURL);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { AuthenticationPhone } = useSelector((store) => store.AdminTable);
  const userListID = params.id;
  let imageUserID = "";
  const { user_img } = useSelector((store) => store.Home);
  const [file, setFile] = useState(user_img ? user_img : "");
  const inputRef = useRef(null);
  // #region Functionality
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      let a = file.name;
      if (!(a.includes("jpg") || a.includes("png") || a.includes("jpeg"))) {
        setimageUploadValidation("Upload valid image");
        return;
      }
      setimageUploadValidation("");
      setImage(file);
      const url = URL.createObjectURL(file);
      document.querySelector(".participate_block1_sub1_div_imag").src = url;
    }
  };

  function handleImgChange(e) {
    const File = e;
    if (!e) {
      return;
    }
    let a = e.name;
    if (!(a.includes("jpg") || a.includes("png") || a.includes("jpeg"))) {
      setimageUploadValidation("Upload valid image");
      return;
    } else {
      setimageUploadValidation("");
      setImage(e);
    }
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        setFile(reader.result);
      },
      false
    );
    if (File) {
      reader.readAsDataURL(File);
    }
  }

  const handleChange = (e) => {
    let field = fields,
      namePattern = /^[a-zA-Z\s!@#$%^&*(),.?":{}|<>_\-+=\\/\[\]`~]*$/,
      cityPattern = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
    var mailPattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (e.target.name === "userID") {
      if (field["userID"] === "" || fields.userID.trim() === "") {
        errors["userID"] = "Please enter valid user ID";
      } else {
        errors["userID"] = "";
      }
    }
    if (e.target.name === "firstName") {
      if (!namePattern.test(e.target.value)) {
        errors["firstName"] = "Please enter valid first name";
      } else {
        errors["firstName"] = "";
      }
    }
    if (e.target.name === "lastName") {
      if (!e.target.value) {
        errors["lastName"] = "Please enter valid last name";
      } else {
        errors["lastName"] = "";
      }
    }
    if (e.target.name === "email") {
      if (!mailPattern.test(e.target.value)) {
        errors["email"] = "Please enter valid email";
      } else {
        errors["email"] = "";
      }
    }
    if (e.target.name === "twoFactorAuthenticationEmail") {
      if (!mailPattern.test(e.target.value)) {
        errors["twoFactorAuthenticationEmail"] = "Please enter valid email";
      } else {
        errors["twoFactorAuthenticationEmail"] = "";
      }
    }
    if (e.target.name === "employeeID") {
      if (!namePattern.test(e.target.value)) {
        errors["employeeID"] = "Please enter employee ID";
      } else {
        errors["employeeID"] = "";
      }
    }
    if (e.target.name === "addressUser") {
      var addPattern = /^[a-zA-Z0-9\s,'-]*$/;
      if (!addPattern.test(e.target.value)) {
        errors["addressUser"] = "Please enter valid address";
      } else {
        errors["addressUser"] = "";
      }
    }
    if (e.target.name === "cityUser") {
      if (!cityPattern.test(e.target.value)) {
        errors["cityUser"] = "Please enter valid city name";
      } else {
        errors["cityUser"] = "";
      }
    }
    if (e.target.name === "stateUser") {
      if (!cityPattern.test(e.target.value)) {
        errors["stateUser"] = "Please enter valid state name";
      } else {
        errors["stateUser"] = "";
      }
    }
    if (e.target.name === "zipUser") {
      var zipcPattern = /[0-9]$/;
      if (!zipcPattern.test(e.target.value)) {
        errors["zipUser"] = "Please enter valid zip code";
      } else if (e.target.value.length < 5) {
        errors["zipUser"] = "Please enter valid zip code";
      } else {
        errors["zipUser"] = "";
      }
    }
    if (!e.target.name === "password") {
      errors["password"] = "Please enter valid password";
    } else {
      errors["password"] = "";
    }

    setFields({
      ...fields,
      [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const roleChange = (e) => {
    setFields((prev) => ({
      ...prev,
      roleId: e.target.value,
    }));
    if (!e.target.value) {
      errors["userRole"] = "Please select User Role";
    } else {
      errors["userRole"] = "";
    }
  };

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });

  const validateLogin = () => {
    let error = {};
    let formIsValid = true;
    let namePattern = /^[a-zA-Z\s!@#$%^&*(),.?":{}|<>_\-+=\\/\[\]`~]*$/;
    var mailPattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

    if (!fields.userID || fields.userID.trim() === "") {
      formIsValid = false;
      error["userID"] = "Please enter valid user ID";
    }
    if (!fields.firstName || fields.firstName.trim() === "") {
      formIsValid = false;
      error["firstName"] = "Please enter valid email";
    }
    if (!namePattern.test(fields.firstName)) {
      formIsValid = false;
      error["firstName"] = "Please enter valid first name";
    }
    if (!fields.lastName || fields.lastName.trim() === "") {
      formIsValid = false;
      error["lastName"] = "Please enter your last name";
    }
    if (!namePattern.test(fields.lastName)) {
      formIsValid = false;
      error["lastName"] = "Please enter valid last name";
    }
    if (!fields.password || fields.password.trim() === "") {
      formIsValid = false;
      error["password"] = "Please enter valid password";
    }
    if (fields.password.length < 10 || fieldValidation.password) {
      formIsValid = false;
      error["password"] = "Please enter valid password";
    }
    if (!fields.email || fields.email.trim() === "" || !mailPattern.test(fields.email)) {
      formIsValid = false;
      error["email"] = "Please enter valid email";
    }
    if (fields.roleId === "") {
      formIsValid = false;
      error["userRole"] = "Please select User Role";
    }
    if (fields.enableAuth === true) {
      if (
        !fields.twoFactorAuthenticationEmail ||
        fields.twoFactorAuthenticationEmail.trim() === "" ||
        !mailPattern.test(fields.twoFactorAuthenticationEmail)
      ) {
        formIsValid = false;
        error["twoFactorAuthenticationEmail"] = "Please enter Two Factor Authentication Email";
      }
      if (AuthenticationPhone?.length <= 9 && AuthenticationPhone.length !== 0) {
        formIsValid = false;
        error["twoFactorAuthenticationPhone"] = "Please enter valid Phone";
      } else {
        error["twoFactorAuthenticationPhone"] = "";
      }
    }

    return {
      errors: error,
      formIsValid: formIsValid,
    };
  };

  const validateLoginForm = () => {
    let response = validateLogin();
    setErrors(response.errors);
    return response.formIsValid;
  };

  const saveUserData = async () => {
    const data = {
      gUserId: type === "Edit" ? userListID : "",
      dDoe: "0001-01-01T00:00:00",
      gEntryUserId: null,
      sUserId: fields.userID,
      sPassword: fields.password,
      sFirstName: fields.firstName,
      sLastName: fields.lastName,
      sMiddleName: fields.middleName,
      bActive: fields.activeUser,
      BSendTaskNotifications: fields.sendNotification,
      sProfileSkin: null,
      iColor: null,
      mPhoto: null,
      bShowCookie: false,
      gRoleId: fields.roleId,
      bSysop: false,
      bResource: fields.resourceUser,
      sEmployeeId: fields.employeeID,
      sEmail: fields.email,
      dLastLogin: null,
      sAddress1: fields.addressUser,
      sAddress2: null,
      sCity: fields.cityUser,
      sState: fields.stateUser,
      sZip: fields.zipUser,
      sPhone: null,
      sNote: null,
      bCourt: fields.courtUser,
      gCashDrawerId: null,
      sReceiptPrinter: null,
      sTwoFactorTextNumber: AuthenticationPhone,
      sTwoFactorEmail: fields.twoFactorAuthenticationEmail,
      bTwoFactorEnabled: fields.enableAuth,
      sTwoFactorVc: null,
      sTwoFactorVccreated: null,
      sTwoFactorVcexpired: null,
      defaultLocation: null,
      roleName: fields.userRole,
    };
    try {
      setLoading(true);
      const response = await httpService.post("api/User/SaveAndEditUserDetail", data, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      });
      if (response.data.isSuccess) {
        await uploadImg(image);
        imageUserID = response.data.data.id;
        await AddGroupList(response.data.data.id);
        await AddEntityList(response.data.data.id);
        setLoading(false);
        dispatch(setAdminSavePopup({ adminSavePopup: false }));
        dispatch(removeAdminTab("Users"));
        dispatch(removeAdminTab(fields.userID));
        if (params.id === localStorage.getItem("gUserId")) {
          dispatch(
            set_user_name(`${fields.firstName ? fields.firstName : ""} ${fields.lastName ? fields.lastName : ""}`)
          );
        }
        navigate("/administration/user-list");
        setToastConfig({
          ...toastConfig,
          isVisible: true,
          message: type === "Edit" ? "Successfully Updated!" : "Successfully Added!",
          type: "success",
        });
        dispatch(
          AddAuthenticationPhone({
            AuthenticationPhone: "",
          })
        );
      }
    } catch (error) {
      const errorMessage = error.response.data.Message;
      if (errorMessage.includes("User ID already exist")) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          userID: errorMessage,
        }));
      } else if (errorMessage.includes("Email already exist")) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: errorMessage,
        }));
      }

      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (validateLoginForm()) {
      console.log('here-1');
      // setisEditable(!isEditable);
      setErrors({});
      saveUserData();
      setSaveUser(true);
    }
  };

  useEffect(() => {
    if (goodToSaveUser) {
      saveUserData();
    }
  }, [goodToSaveUser]);

  if (type === "Edit") {
    imageUserID = userListID;
  }

  const uploadImg = async (image) => {
    if (!image) {
      return;
    }
    const formData = new FormData();
    formData.append("image", image);
    const imageUrl = URL.createObjectURL(image);
    if (params.id === localStorage.getItem("gUserId")) {
      dispatch(set_user_img(imageUrl));
    }
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/Account/UploadImage?UserID=${fields.userID}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          Domain: `${pathname}`,
        },
      })
      .then((val) => { })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const getImg = async (userId) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/Account/GetImage?UserID=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Domain: `${pathname}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const reader = new FileReader();
        const imageBlob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        reader.onloadend = () => {
          setFile(reader.result);
        };
        reader.readAsDataURL(imageBlob);
      })
      .catch((error) => console.log(error));
  };

  // #endregion
  function callAPI() {
    setErrors({});
    editData();
    EntityList();
    GroupList();
    UserAccessList();
  }

  useEffect(() => {
    if (type === "Edit") {
      callAPI();
    }
    setFile();
    roleList();
    AllGroupList();
    AllEntityList();
  }, [params.id]);

  const editData = async () => {
    const pathname = handleGetLocal("pathURL");
    setLoading(true);
    await httpService
      .get(`api/user/getUserById?id=${userListID}`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        if (val.data.isSuccess) {
          getImg(val?.data?.data?.sUserId);
          setFields({
            ...fields,
            email: val?.data?.data?.sEmail,
            lastName: val?.data?.data?.sLastName ? val?.data?.data?.sLastName : "",
            firstName: val?.data?.data?.sFirstName ? val?.data?.data?.sFirstName : "",
            middleName: val?.data?.data?.sMiddleName ? val?.data?.data?.sMiddleName : "",
            userID: val?.data?.data?.sUserId ? val?.data?.data?.sUserId : "",
            password: val?.data?.data?.sPassword,
            roleId: val?.data?.data?.gRoleId || "",
            userRole: val?.data?.data?.roleName,
            employeeID: val?.data?.data?.sEmployeeId ? val?.data?.data?.sEmployeeId : "",
            activeUser: val?.data?.data?.bActive,
            sendNotification: val?.data?.data?.bSendTaskNotifications,
            courtUser: val?.data?.data?.bCourt,
            resourceUser: val?.data?.data?.bResource,
            stateUser: val?.data?.data?.sState ? val?.data?.data?.sState : "",
            cityUser: val?.data?.data?.sCity ? val?.data?.data?.sCity : "",
            zipUser: val?.data?.data?.sZip ? val?.data?.data?.sZip : "",
            addressUser: val?.data?.data?.sAddress1 ? val?.data?.data?.sAddress1 : "",
            enableAuth: val?.data?.data?.bTwoFactorEnabled,
            twoFactorAuthenticationEmail: val?.data?.data?.sTwoFactorEmail ? val?.data?.data?.sTwoFactorEmail : "",
            twoFactorAuthenticationPhone: val?.data?.data?.sTwoFactorTextNumber
              ? val?.data?.data?.sTwoFactorTextNumber
              : "",
          });
          setLoading(false);
          dispatch(
            AddAuthenticationPhone({
              AuthenticationPhone: val?.data?.data?.sTwoFactorTextNumber,
            })
          );
        }
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const cancelEdit = () => {
    dispatch(setAdminSavePopup({ adminSavePopup: false }));
    setErrors({});
    setimageUploadValidation("");
    dispatch(removeAdminTab(fields.userID));
    dispatch(
      AddAuthenticationPhone({
        AuthenticationPhone: "",
      })
    );
    navigate("/administration/user-list");
  };

  const roleList = async () => {
    await httpService
      .get("api/person/masterDropDownValuesGet", {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        setRoles(val.data.data.roles || []);
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const setGroupTableData = (data) => {
    const arr = [];
    data.map((val) => {
      arr.push({
        "Group Name": val.groupName,
        userId: val.userId,
        groupId: val.groupId,
      });
    });
    return arr;
  };

  const AllGroupList = async () => {
    await httpService
      .get(`api/user/get-all-groups`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        setGroupData(setGroupTableData(val?.data.data));
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const GroupList = async () => {
    await httpService
      .get(`api/user-groups/get-all?userId=${imageUserID}`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        setGroupResult(setGroupTableData(val?.data.data));
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const AddGroupList = async (userId) => {
    let modifiedGroupData = groupResult.map((item) => {
      return { ...item, userId };
    });
    if (modifiedGroupData.length === 0) {
      modifiedGroupData = [
        {
          groupId: "",
          userId: userId,
        },
      ];
    }
    await httpService
      .post(`api/user-groups/add`, modifiedGroupData, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => { })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const setEntityTableData = (data) => {
    const arr = [];
    data.map((val) => {
      arr.push({
        Entity: val.entityName,
        userId: val.userId,
        entityId: val.entityId,
        Default: val.isDefault,
        isDefault: val.isDefault,
      });
    });
    return arr;
  };

  const AllEntityList = async () => {
    await httpService
      .get(`api/user/get-all-entities`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        setEntityData(setEntityTableData(val?.data.data));
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  const EntityList = async () => {
    await httpService
      .get(`api/user-entities/get-all?userId=${imageUserID}`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => {
        setEntityResult(setEntityTableData(val?.data.data));
      })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status === 401) {
          logOut();
        }
      });
  };

  const UserAccessList = async () => {
    await httpService
      .get(`api/user/get-user-access-by-userid?gUserId=${userListID}`, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((res) => {
        if (res.data.isSuccess) {
          setUserAccessData(res.data.data);
        }
      })
      .catch((err) => {
        if (!err?.response) {
          navigate(`/error/500`);
        } else if (err?.response?.status === 401) {
          logOut();
        }
      });
  };

  const AddEntityList = async (userId) => {
    let modifiedEntityData = entityResult.map((item) => {
      return { ...item, userId };
    });
    if (modifiedEntityData.length === 0) {
      modifiedEntityData = [
        {
          groupId: "",
          userId: userId,
        },
      ];
    }
    await httpService
      .post(`api/user-entities/add`, modifiedEntityData, {
        headers: { Authorization: `Bearer ${token}`, Domain: `${pathname}` },
      })
      .then((val) => { })
      .catch((val) => {
        if (!val?.response) {
          navigate(`/error/500`);
        } else if (val?.response?.status == 401) {
          logOut();
        }
      });
  };

  return (
    <>
      <Toast
        visible={toastConfig.isVisible}
        position={"top"}
        maxWidth={400}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={() => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
          });
        }}
        displayTime={600}
      />
      <LoadPanel shadingColor="true" visible={loading} delay={10} />

      <div className="admin_profile_content">
        <div className="utf">
          <p className="utf_heading">US.1</p>
        </div>
        {type === "Edit" ? (
          <>
            <div className="admin_profile_buttons">
              <div className="edit_button_content">
                {/* {!isEditable && (
                  <div
                    className="edit_button"
                    onClick={() => {
                      setisEditable(!isEditable);
                    }}
                  >
                    Edit
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                    >
                      <path
                        d="M0 15.4601V18.5001C0 18.7801 0.22 19.0001 0.5 19.0001H3.54C3.67 19.0001 3.8 18.9501 3.89 18.8501L14.81 7.94006L11.06 4.19006L0.15 15.1001C0.0500001 15.2001 0 15.3201 0 15.4601ZM17.71 5.04006C18.1 4.65006 18.1 4.02006 17.71 3.63006L15.37 1.29006C14.98 0.900059 14.35 0.900059 13.96 1.29006L12.13 3.12006L15.88 6.87006L17.71 5.04006Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                )} */}
              </div>
              {isEditable && (
                <div className="buttons">
                  <button className="btn" onClick={cancelEdit}>
                    Cancel
                  </button>
                  <button
                    className="primary_btn"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path
                        d="M16 10V15C16 15.55 15.55 16 15 16H3C2.45 16 2 15.55 2 15V10C2 9.45 1.55 9 1 9C0.45 9 0 9.45 0 10V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10ZM10 9.67L11.88 7.79C12.27 7.4 12.9 7.4 13.29 7.79C13.68 8.18 13.68 8.81 13.29 9.2L9.7 12.79C9.31 13.18 8.68 13.18 8.29 12.79L4.7 9.2C4.31 8.81 4.31 8.18 4.7 7.79C5.09 7.4 5.72 7.4 6.11 7.79L8 9.67V1C8 0.45 8.45 0 9 0C9.55 0 10 0.45 10 1V9.67Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="admin_profile_buttons admin_profile_buttons_float">
              <div className="edit_button_content">
                <button
                  className="btn"
                  onClick={() => {
                    dispatch(setAdminSavePopup({ adminSavePopup: false }));
                    dispatch(removeAdminTab("Users"));
                    navigate("/administration/user-list");
                  }}
                >
                  Cancel
                </button>
                <div
                  className="edit_button"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path
                      d="M16 10V15C16 15.55 15.55 16 15 16H3C2.45 16 2 15.55 2 15V10C2 9.45 1.55 9 1 9C0.45 9 0 9.45 0 10V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V10C18 9.45 17.55 9 17 9C16.45 9 16 9.45 16 10ZM10 9.67L11.88 7.79C12.27 7.4 12.9 7.4 13.29 7.79C13.68 8.18 13.68 8.81 13.29 9.2L9.7 12.79C9.31 13.18 8.68 13.18 8.29 12.79L4.7 9.2C4.31 8.81 4.31 8.18 4.7 7.79C5.09 7.4 5.72 7.4 6.11 7.79L8 9.67V1C8 0.45 8.45 0 9 0C9.55 0 10 0.45 10 1V9.67Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="admin_edit_content">
          <div className="first_edit_content">
            <div className="profilePic">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    disabled={!isEditable}
                    type="file"
                    onChange={(e) => {
                      handleImgChange(e.target.files[0]);
                    }}
                    id="imageUpload"
                    ref={inputRef}
                    name="image"
                    accept=".png, .jpg, .jpeg"
                  />
                  {isEditable && <label for="imageUpload"></label>}
                </div>
                <div
                  onDragOver={(e) => {
                    isEditable && handleDragOver(e);
                  }}
                  onDrop={(e) => {
                    isEditable && handleDrop(e);
                  }}
                  className="avatar-preview"
                >
                  {fields.image !== "" ? (
                    <div id="imagePreview">{file && <img src={file} alt="profile" />}</div>
                  ) : (
                    <div
                      id="imagePreview"
                      style={{
                        backgroundImage: `url(${file ? file : image5})`,
                        border: "1px solid #dbdbdb",
                        backgroundColor: "#dbdbdb",
                      }}
                    ></div>
                  )}
                  {imageUploadValidation && <p style={{ color: "red" }}>{imageUploadValidation}</p>}
                </div>
              </div>
            </div>
            <div>
              <div className="inputs_container user_layout">
                <div className="edit_input">
                  <label className="go-to-label-filed">
                    User ID <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter User ID"
                    name="userID"
                    id="userID"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    value={fields.userID}
                    disabled={type === "Edit" && true}
                  />
                  <p className="error" style={{ color: "red" }}>
                    {errors.userID}
                  </p>
                </div>
                <div className="edit_input">
                  <label className="go-to-label-filed">
                    Password <span>*</span>
                    {fields.password && fieldValidation.password ? (
                      <>
                        {
                          <img
                            className="errorIcon popup-label"
                            src={errorIcon}
                            alt=""
                            style={{ marginLeft: "2%", marginBottom: "-5px" }}
                          />
                        }
                        <div className="popup_content_password">
                          <span style={smallTextStyle}>
                            <p
                              style={{
                                fontSize: "10px",
                                lineHeight: "13px",
                                color: isPasswordCorrect.isValidPassword ? "green" : "red",
                              }}
                              className="small-text"
                            >
                              Password should be 10 digits alphanumeric with at Least:
                            </p>
                            <li
                              style={{
                                color: isPasswordCorrect.upper ? "green" : "red",
                              }}
                              className="small-text"
                            >
                              1 Upper Case Character,
                            </li>
                            <li
                              style={{
                                color: isPasswordCorrect.lower ? "green" : "red",
                              }}
                              className="small-text"
                            >
                              1 Lower Case Character,
                            </li>
                            <li
                              style={{
                                color: isPasswordCorrect.numeric ? "green" : "red",
                              }}
                              className="small-text"
                            >
                              1 Numeric Character and
                            </li>
                            <li
                              style={{
                                color: isPasswordCorrect.special ? "green" : "red",
                              }}
                              className="small-text"
                            >
                              1 Special Character.
                            </li>
                          </span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </label>
                  <div className="password_admin_eye">
                    <input
                      type={eyeTogel.password ? "text" : "password"}
                      placeholder="Enter Password"
                      name="password"
                      id="password"
                      onChange={(e) => {
                        handleChange(e);
                        CheckPasswordValidation(e);
                        setFieldValidation({
                          ...fieldValidation,
                          password: !passPattern.test(e.target.value) ? "Please enter valid password" : "",
                        });
                      }}
                      value={fields.password}
                      disabled={!isEditable}
                    />
                    <span
                      onClick={() => {
                        seteyesTogel({
                          ...eyeTogel,
                          password: !eyeTogel.password,
                        });
                      }}
                      className={`password_open_eye  ${!eyeTogel.password ? " " : " password_close_eye"}`}
                    ></span>
                  </div>
                  {errors.password && (
                    <p className="error" style={{ color: "red" }}>
                      {errors.password}
                    </p>
                  )}
                </div>
                <div className="edit_input">
                  <label className="go-to-label-filed">
                    First Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter First Name"
                    name="firstName"
                    id="firstName"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    value={fields.firstName}
                    disabled={!isEditable}
                  />
                  <p className="error" style={{ color: "red" }}>
                    {errors.firstName}
                  </p>
                </div>
                <div className="edit_input">
                  <label className="go-to-label-filed">Address</label>
                  <input
                    type="text"
                    placeholder="Enter Address"
                    name="addressUser"
                    id="addressUser"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    value={fields.addressUser}
                    disabled={!isEditable}
                  />
                  <p className="error" style={{ color: "red" }}>
                    {errors.addressUser}
                  </p>
                </div>
                <div className="edit_input" style={{width:"47%"}}>
                  <label className="go-to-label-filed">
                    Email <span>*</span>
                  </label>
                  <input style={{width:"97%"}}
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    id="email"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    value={fields.email}
                    // disabled={type === "Edit" && true}
                    disabled={!isEditable}
                  />
                  <p className="error" style={{ color: "red" }}>
                    {errors.email}
                  </p>
                </div>
                <div className="edit_input">
                  <label className="go-to-label-filed">Middle Name</label>
                  <input
                    type="text"
                    placeholder="Enter Middle Name"
                    name="middleName"
                    id="middleName"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    value={fields.middleName}
                    disabled={!isEditable}
                  />
                </div>
                <div className="edit_input">
                  <label className="go-to-label-filed">City</label>
                  <input
                    type="text"
                    placeholder="Enter City"
                    name="cityUser"
                    id="cityUser"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    value={fields.cityUser}
                    disabled={!isEditable}
                  />
                  <p className="error" style={{ color: "red" }}>
                    {errors.cityUser}
                  </p>
                </div>
                <div className="edit-input" style={{width:"47%"}}>
                  <div className="edit-checkbox">
                    <input
                      type="checkbox"
                      name="sendNotification"
                      id="sendNotification"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(setAdminSavePopup({ adminSavePopup: true }));
                      }}
                      checked={fields.sendNotification}
                      disabled={!isEditable}
                    />
                    <label htmlFor="sendNotification" className="go-to-label-filed">
                      Send Task Notifications
                    </label>
                  </div>
                </div>
                <div className="edit_input">
                  <label className="go-to-label-filed">
                    Last Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Last Name"
                    name="lastName"
                    id="lastName"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    value={fields.lastName}
                    disabled={!isEditable}
                  />
                  <p className="error" style={{ color: "red" }}>
                    {errors.lastName}
                  </p>
                </div>
                <div className="edit_input input_modify">
                  <label className="go-to-label-filed">State</label>
                  <input style={{width:"100%"}}
                    type="text"
                    placeholder="Enter State"
                    name="stateUser"
                    id="stateUser"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    value={fields.stateUser}
                    disabled={!isEditable}
                  />
                  <p className="error" style={{ color: "red" }}>
                    {errors.stateUser}
                  </p>
                </div>
                <div className="edit_input input_modify">
                  <label className="go-to-label-filed">Zip</label>
                  <input  style={{width:"100%"}}
                    type="number"
                    placeholder="Enter Zip"
                    name="zipUser"
                    id="zipUser"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    value={fields.zipUser}
                    disabled={!isEditable}
                  />
                  <p className="error" style={{ color: "red" }}>
                    {errors.zipUser}
                  </p>
                </div>

                <div className="edit_input">
                  <label className="go-to-label-filed">Employee ID</label>
                  <input
                    type="text"
                    placeholder="Enter Employee ID"
                    name="employeeID"
                    id="employeeID"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    value={fields.employeeID}
                    disabled={!isEditable}
                  />
                </div>
                <div className="edit_input">
                  <label className="go-to-label-filed">
                    Role <span>*</span>
                  </label>
                  <div>
                    <select
                      onChange={(e) => {
                        roleChange(e);
                        dispatch(setAdminSavePopup({ adminSavePopup: true }));
                      }}
                      className="admin_role_select"
                    >
                      <option value="" selected={fields.roleId === ""}>
                        {" "}
                        -- Select --{" "}
                      </option>
                      {roles.map((role) => (
                        <option key={role.gRoleId} selected={fields.roleId === role.gRoleId} value={role.gRoleId}>
                          {role.sName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <p className="error" style={{ color: "red" }}>
                    {errors.userRole}
                  </p>
                </div>
              </div>
              <div className="edit_checkbox_container">
                <div className="edit_checkbox">
                  <input
                    type="checkbox"
                    name="activeUser"
                    id="activeUser"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    checked={fields.activeUser}
                    disabled={!isEditable}
                  />
                  <label className="go-to-label-filed" htmlFor="activeUser">
                    Active User
                  </label>
                </div>
                <div className="edit_checkbox">
                  <input
                    type="checkbox"
                    name="resourceUser"
                    id="resourceUser"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    checked={fields.resourceUser}
                    disabled={!isEditable}
                  />
                  <label htmlFor="resourceUser" className="go-to-label-filed">
                    Resource
                  </label>
                </div>
                <div className="edit_checkbox">
                  <input
                    type="checkbox"
                    name="courtUser"
                    id="courtUser"
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(setAdminSavePopup({ adminSavePopup: true }));
                    }}
                    checked={fields.courtUser}
                    disabled={!isEditable}
                  />
                  <label htmlFor="courtUser">Court</label>
                </div>
              </div>
            </div>
          </div>
          <div className="second_edit_content">
            <div className="enable_auth">
              <input
                type="checkbox"
                name="enableAuth"
                id="enableAuth"
                checked={fields.enableAuth}
                onChange={(e) => {
                  handleChange(e);
                  dispatch(setAdminSavePopup({ adminSavePopup: true }));
                }}
                disabled={!isEditable}
              />
              <label htmlFor="enableAuth" className="go-to-label-filed">
                Enable Two factor Authentication
              </label>
            </div>
            {fields.enableAuth && (
              <>
                <div className="enable_auth_line"></div>
                <p className="enable_auth_para">Select where you want to get Two factor Authentication code.</p>
                <div className="enable_div">
                  <div className="enable_auth_email">
                    <div className="enable_auth_email_data">
                      <div className="radio-group">
                        <label htmlFor="twoFactorEmail" className="go-to-label-filed">
                          Mail <span>*</span>
                        </label>
                      </div>
                      <input
                        type="email"
                        placeholder="Enter Email address"
                        name="twoFactorAuthenticationEmail"
                        id="twoFactorAuthenticationEmail"
                        onChange={(e) => {
                          handleChange(e);
                          dispatch(setAdminSavePopup({ adminSavePopup: true }));
                        }}
                        value={fields.twoFactorAuthenticationEmail}
                        disabled={!isEditable || !fields.enableAuth}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {errors.twoFactorAuthenticationEmail}
                      </p>
                    </div>
                  </div>
                  <div className="enable_auth_email">
                    <div className="enable_auth_email_data">
                      <div className="radio-group">
                        <label htmlFor="twoFactorPhone" className="go-to-label-filed">
                          Mobile number
                        </label>
                      </div>
                      <TextBox
                        validationError={false}
                        showMaskMode="always"
                        name="twoFactorAuthenticationPhone"
                        id="twoFactorAuthenticationPhone"
                        maskRules={{ H: /^[0-9*]$/ }}
                        mask="(HHH) HHH-HHHH"
                        isValid={true}
                        value={String(AuthenticationPhone).replace(/\s/g, "")}
                        onValueChange={(e) => {
                          const sanitizedPhoneNumber = e.replace(/\s/g, "");
                          if (sanitizedPhoneNumber.length <= 9 && sanitizedPhoneNumber.length !== 0) {
                            errors["twoFactorAuthenticationPhone"] = "Please enter valid Phone";
                          } else {
                            errors["twoFactorAuthenticationPhone"] = "";
                          }
                          dispatch(
                            AddAuthenticationPhone({
                              AuthenticationPhone: e.replace(/\s/g, ""),
                            })
                          );
                        }}
                        disabled={!isEditable || !fields.enableAuth}
                      />
                      <p className="error" style={{ color: "red" }}>
                        {errors.twoFactorAuthenticationPhone}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="third_edit_content">
            <div className="admin_tabs">
              {/* <div
                className={activeTab === "tab1" ? "active admin_tab" : "admin_tab"}
                onClick={() => handleTabClick("tab1")}
              >
                Address
              </div> */}
              <div
                className={activeTab === "tab3" ? "active admin_tab" : "admin_tab"}
                onClick={() => handleTabClick("tab3")}
              >
                Groups
              </div>
              <div
                className={activeTab === "tab4" ? "active admin_tab" : "admin_tab"}
                onClick={() => handleTabClick("tab4")}
              >
                Entities
              </div>
              <div
                className={activeTab === "tab5" ? "active admin_tab" : "admin_tab"}
                onClick={() => handleTabClick("tab5")}
              >
                User Access
              </div>
            </div>
            <div className="admin_tab_content">
              {activeTab === "tab1" && (
                <div className="admin_tabs_content_data">

                  {/* <div className="admin_tabs_content_data_input">
                    <label className="go-to-label-filed">City</label>
                    <input
                      type="text"
                      placeholder="Enter City"
                      name="cityUser"
                      id="cityUser"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(setAdminSavePopup({ adminSavePopup: true }));
                      }}
                      value={fields.cityUser}
                      disabled={!isEditable}
                    />
                    <p className="error" style={{ color: "red" }}>
                      {errors.cityUser}
                    </p>
                  </div> */}
                  {/* <div className="admin_tabs_content_data_input">
                    <label className="go-to-label-filed">State</label>
                    <input
                      type="text"
                      placeholder="Enter State"
                      name="stateUser"
                      id="stateUser"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(setAdminSavePopup({ adminSavePopup: true }));
                      }}
                      value={fields.stateUser}
                      disabled={!isEditable}
                    />
                    <p className="error" style={{ color: "red" }}>
                      {errors.stateUser}
                    </p>
                  </div>
                  <div className="admin_tabs_content_data_input">
                    <label className="go-to-label-filed">Zip</label>
                    <input
                      type="number"
                      placeholder="Enter Zip"
                      name="zipUser"
                      id="zipUser"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(setAdminSavePopup({ adminSavePopup: true }));
                      }}
                      value={fields.zipUser}
                      disabled={!isEditable}
                    />
                    <p className="error" style={{ color: "red" }}>
                      {errors.zipUser}
                    </p>
                  </div> */}
                </div>
              )}
              {activeTab === "tab3" && (
                <Groups
                  type={type}
                  gropuData={gropuData}
                  setGroupData={setGroupData}
                  setGroupTableData={setGroupTableData}
                  setGroupResult={setGroupResult}
                  groupResult={groupResult}
                />
              )}
              {activeTab === "tab4" && (
                <Entities
                  type={type}
                  setEntityData={setEntityData}
                  entityData={entityData}
                  setEntityTableData={setEntityTableData}
                  setEntityResult={setEntityResult}
                  entityResult={entityResult}
                />
              )}
              {activeTab === "tab5" && (
                <UserAccess
                  userAccessData={userAccessData}
                  userId={userListID}
                  setUserAccessData={setUserAccessData}
                  saveUser={saveUser}
                  setSaveUser={setSaveUser}
                  setGoodToSaveUser={setGoodToSaveUser}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserData;
