import React, { useEffect, useState } from "react";
import { ContextMenu, DataGrid, LoadPanel } from "devextreme-react";
import { Column, HeaderFilter, Pager } from "devextreme-react/data-grid";
import { Toster } from "../../../components/Toster/Toster";
import { ApiError } from "../../Error/ApiError";
import { ProntactEntityFunction } from "./ProntactEntityFunction";
import { useNavigate } from "react-router-dom";
import ThreeDotMenu from "../../../components/Common/Icons/ThreeDotMenu";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setDynamicTab } from "../../../reducer/HomePageReducer";
import { DynamicTabb } from "../../../components/DynamicTab/DynamicTab";
import { handleGetLocal } from "../../../services/auth-service";

const STORAGE_KEY = "prontactEntitiesColumnOrder";

const initialColumns = [
  {
    visibleIndex: 1,
    dataField: "sName",
    caption: "Name",
  },
  {
    visibleIndex: 2,
    dataField: "sCompany",
    caption: "Company",
  },
  {
    visibleIndex: 3,
    dataField: "bIsBilling",
    caption: "Is Billing Agency",
    type: "boolean",
  },
  {
    visibleIndex: 4,
    dataField: "sEntityTypeName",
    caption: "EntityType",
  },
  {
    visibleIndex: 5,
    dataField: "sEmail",
    caption: "Email",
  },
  {
    visibleIndex: 6,
    dataField: "sRegionName",
    caption: "Region",
  },
  {
    visibleIndex: 7,
    dataField: "sCityName",
    caption: "City",
  },
  {
    visibleIndex: 8,
    dataField: "sZip",
    caption: "Zip",
  },
  {
    visibleIndex: 9,
    dataField: "sZip3",
    caption: "3-Zip",
  },
  {
    visibleIndex: 10,
    dataField: "sCountyName",
    caption: "County",
  },
  {
    visibleIndex: 11,
    dataField: "sReportingEntityName",
    caption: "Reporting Entity",
  },
];

export const ProntactEntitiesList = () => {
  const apiClass = new ProntactEntityFunction();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [erorhandling, seterorhandling] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState({});
  const [columns, setColumns] = useState(initialColumns);

  const saveCookieCrum = async (perdonId) => {
    const pathname = handleGetLocal("pathURL");
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/cookie-crumbs/save-cookie-crumb?recordId=${perdonId}`,
      null,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          Domain: `${pathname}`,
        },
      }
    );
  };

  const loadSavedOrder = () => {
    const savedOrder = JSON.parse(localStorage.getItem(STORAGE_KEY));
    if (savedOrder) {
      const reorderedColumns = savedOrder
        .map((field) => columns.find((col) => col.dataField === field))
        .filter(Boolean);
      setColumns(reorderedColumns.length ? reorderedColumns : initialColumns);
    }
  };

  const saveColumnOrder = (newOrder) => {
    const order = newOrder.map((col) => col.dataField);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(order));
  };

  useEffect(() => {
    apiClass.getEntityList(setLoading, seterorhandling, navigate, setEntityList);
    loadSavedOrder();
  }, []);

  return (
    <>
      <div className="particcipentPage">
        <LoadPanel shadingColor="true" visible={loading} delay={10} />
        <DynamicTabb />
        <div style={{ display: "flex" }}>
          <p className="popup_header_title_para2">EL.1</p>
        </div>
        {erorhandling ? (
          <ApiError />
        ) : (
          <div>
            <LoadPanel shadingColor="true" visible={false} delay={10} />
            <Toster
              message={toastConfig.message}
              type={toastConfig.type}
              visible={toastConfig.isVisible}
            />
            <div className="go-to-header-section">
              <div className="entity-list">
                <DataGrid
                  dataSource={entityList}
                  columnAutoWidth
                  showBorders={true}
                  loadPanel={false}
                  allowColumnResizing={true}
                  showColumnLines={true}
                  showRowLines={true}
                  allowColumnReordering={true}
                  onContextMenuPreparing={(e) => {
                    setSelectedEntity(e.row?.data);
                  }}
                  onOptionChanged={(e) => {
                    if (e.name === "columns" && e.fullName.includes("visibleIndex")) {
                      const updatedColumns = e.component.getVisibleColumns();
                      const newOrder = updatedColumns
                        .map((col) => columns.find((item) => item.dataField === col.dataField))
                        .filter(Boolean);
                      saveColumnOrder(newOrder);
                    }
                  }}

                >
                  <HeaderFilter visible={true} allowSearch={true} />
                  <Column
                    visibleIndex={0}
                    width={50}
                    type="buttons"
                    cellRender={(rowData) => (
                      <div
                        className="add_navBar_hamburger"
                        id="threedots"
                        onClick={() => {
                          setSelectedEntity(rowData.data);
                        }}
                      >
                        <ThreeDotMenu />
                        <ContextMenu
                          showEvent="dxclick"
                          target="#threedots"
                          dataSource={[
                            {
                              text: "Jump to Entity",
                              icon: "search",
                            },
                          ]}
                          onItemClick={(e) => {
                            if (e.itemData.text === "Jump to Entity") {
                              saveCookieCrum(selectedEntity?.gEntityId);
                              dispatch(
                                setDynamicTab({
                                  title: "Entity",
                                  link: `/prontact/entity/${selectedEntity?.gEntityId}`,
                                  Offender: "/prontact/entity",
                                })
                              );
                              navigate(`/prontact/entity/${selectedEntity?.gEntityId}`);
                            }
                          }}
                        />
                      </div>
                    )}
                  />
                  {columns.map((col, index) => (
                    <Column
                      key={col.dataField}
                      dataField={col.dataField}
                      caption={col.caption}
                      defaultSortOrder={col.defaultSortOrder || undefined}
                      visibleIndex={index}
                    >

                    </Column>
                  ))}
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 30, 50]}
                    showNavigationButtons={true}
                    showInfo={true}
                  />
                </DataGrid>
                <ContextMenu
                  dataSource={[
                    {
                      text: "Jump to Entity",
                      icon: "search",
                    },
                  ]}
                  target=".dx-data-row"
                  onItemClick={(e) => {
                    if (e.itemData.text === "Jump to Entity") {
                      saveCookieCrum(selectedEntity?.gEntityId);
                      dispatch(
                        setDynamicTab({
                          title: "Entity",
                          link: `/prontact/entity/${selectedEntity?.gEntityId}`,
                          Offender: "/prontact/entity",
                        })
                      );
                      navigate(`/prontact/entity/${selectedEntity?.gEntityId}`);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
